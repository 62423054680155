import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { getClient } from './base';

/*** エンティティ ***/

/*** [GET] /api/users ***/

export interface RequestGeneratorHsGet {}
export interface ResponseGeneratorHsGet {
  instance_id: string;
  instance_name: string;
  status: string;
  launch_time: ISO8601;
  remark_tag?: string;
  generator_url?: string;
  generator_id?: string;
  generator_pw?: string;
}

const sample_1: ResponseGeneratorHsGet = {
  instance_id: '000000001',
  instance_name: 'インスタンス1',
  status: 'running',
  launch_time: '2020-01-01T00:00:00+09:00' as ISO8601,
};

export const generatorHsGetAPI = (params: RequestGeneratorHsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/generator/hs`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ResponseGeneratorHsGet[]>(axios, path, query, form, method, [sample_1]);
};

/*** [POST] /api/users ***/

export interface RequestGeneratorHsPost {
  instance_id: string;
  action: 'run' | 'stop' | 'remark';
  remark_tag?: string;
}
export interface ResponseGeneratorHsPostAPI {}
export const generatorHsPostAPI = (params: RequestGeneratorHsPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/generator/hs`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ResponseGeneratorHsPostAPI>(axios, path, query, form, method, sample_1);
};
