// -- basic library --
import React, { forwardRef } from 'react';

// -- external components --
import styled from 'styled-components';
import styles from 'shared/styles/styles';
import { colors } from 'shared/styles/colors';

// -- type declaration --
interface TextAreaInputProps
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {}

type RefType =
  | ((instance: HTMLTextAreaElement | null) => void)
  | React.RefObject<HTMLTextAreaElement>
  | null
  | undefined;

// -- main component --

const TextAreaInput = (props: TextAreaInputProps, ref: RefType) => {
  // -- render part --
  return <StyledTextArea {...props} value={props.value} onChange={props.onChange} ref={ref} />;
};

// -- styled components --
const StyledTextArea = styled.textarea`
  padding: 10px 16px;
  line-height: inherit;
  box-sizing: border-box;
  border: 1px solid ${colors.component_small_border_color};
  border-radius: ${styles.border_radius};
  outline: none;
  font-weight: 400;
  font-family: inherit;
  color: ${colors.main_font_color};
  background-color: ${colors.white};
  box-shadow: none;
  &:focus {
    border-color: ${colors.component_main_color};
  }
  &:disabled {
    opacity: 1;
    background-color: ${colors.disabled_background_color};
    cursor: not-allowed;
  }
  &:placeholder {
    color: ${colors.placeholder_color};
  }
`;

// -- finally export part --

export default forwardRef(TextAreaInput);
