import React from 'react';
import { Process } from 'admin/api/processes';
import AppParameterInput from 'admin/components/molecules/AppParameterInput';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { StringifiedJSON } from 'shared/models';
import { AppParameter } from 'shared/models/AppParameter';

interface ProcessflowAppParameterDialogProps {
  is_open: boolean;
  value: AppParameter;
  process: Process;
  onClose: (parameter?: AppParameter) => void;
}
interface ProcessflowAppParameterDialogState {
  app_parameter: AppParameter;
}
/** プロセスフロー定義でのステップのパラメータ設定ダイアログです */
export default class ProcessflowAppParameterDialog extends React.PureComponent<
  ProcessflowAppParameterDialogProps,
  ProcessflowAppParameterDialogState
> {
  constructor(props: ProcessflowAppParameterDialogProps) {
    super(props);
    this.state = {
      app_parameter: props.value,
    };
  }
  private handleSetAppParameter = (value: string, key: string) => {
    const new_app_parameter = { ...this.state.app_parameter };
    new_app_parameter[key] = value as StringifiedJSON;
    this.setState({ app_parameter: new_app_parameter });
  };
  render() {
    return (
      <PfDialog isOpen={this.props.is_open} title='アプリパラメータ' onClose={() => this.props.onClose()}>
        {this.props.process.app_parameters.map((d, index) => {
          return (
            <AppParameterInput
              key={index}
              processAppParameter={d}
              appParameter={String(this.state.app_parameter[d.key] || d.default_value)}
              onChange={(app_parameter_value) => this.handleSetAppParameter(app_parameter_value, d.key)}
            />
          );
        })}
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onClose()} is_margin_right />
          <RoundedButton text='OK' onClick={() => this.props.onClose(this.state.app_parameter)} />
        </div>
      </PfDialog>
    );
  }
}

interface ProcessflowAppParameterDialogButtonProps {
  value?: AppParameter;
  process?: Process;
  onChange: (parameter: AppParameter) => void;
  useTableStylesButton?: boolean;
}
interface ProcessflowAppParameterDialogButtonState {
  isOpen: boolean;
}
/** アプリパラメータ設定ダイアログを開くためのボタンです。 */
export class ProcessflowAppParameterDialogButton extends React.PureComponent<
  ProcessflowAppParameterDialogButtonProps,
  ProcessflowAppParameterDialogButtonState
> {
  constructor(props: ProcessflowAppParameterDialogButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: AppParameter) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton text='パラメータ' onClick={this.handleOpen} disabled={this.props.process === undefined} small />
        {this.state.isOpen && this.props.process && (
          <ProcessflowAppParameterDialog
            is_open={true}
            value={this.props.value || {}}
            process={this.props.process}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}
