import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { DateString } from 'shared/models/DateString';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { StringBoolean } from 'shared/models/StringBoolean';
import { UserType } from 'shared/models/UserType';
import { getClient } from './base';
import { ShowStatus } from 'shared/models/ShowStatus';
import { ImageDataFileType } from 'shared/models';

/*** エンティティ ***/

export interface Notification {
  notification_id: string;
  show_date: DateString;
  show_date_from: DateString;
  show_date_to: DateString;
  show_type: UserType;
  title: string;
  content?: string;
  is_draft: boolean;
  image_files?: string[];
  download_urls?: string[];
  uuid?: string;
  show_status: ShowStatus;
  status: string;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface NotificationsWithPaging {
  items: Notification[];
  has_next: boolean;
  last_notification_id?: string;
}

export interface NotificationsImageWithPaging {
  upload_url: string;
  download_url: string;
  uuid: string;
}

const sample_notification_1: Notification = {
  notification_id: '00000001',
  show_date: '2020-01-01' as DateString,
  show_date_from: '2020-01-01' as DateString,
  show_date_to: '2020-01-01' as DateString,
  show_type: 'GENERAL',
  title: 'テストユーザー1',
  content: 'テストユーザー1',
  is_draft: false,
  show_status: 'PUBLIC',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_notification_2: Notification = {
  notification_id: '00000002',
  show_date: '2020-01-01' as DateString,
  show_date_from: '2020-01-01' as DateString,
  show_date_to: '2020-01-01' as DateString,
  show_type: 'ADMIN',
  title: 'テストユーザー2',
  content: 'テストユーザー2',
  is_draft: true,
  show_status: 'PRIVATE',
  status: 'DELETED',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_notification_image_1: NotificationsImageWithPaging = {
  upload_url: 'public/notification/uuid1/image1' as string,
  download_url: 'https://sample/public/notification/uuid1/image1' as string,
  uuid: 'uuid1' as string,
};
/*** Caching mechanism ***/

export class CachedNotifications {
  private searched = false;
  private cache: Notification[] = [];
  private params: RequestNotificationsGet;
  constructor(params: RequestNotificationsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<NotificationsWithPaging> = await notificationsGetAPI({
          ...this.params,
          exclusive_start_notification_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.last_notification_id;
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/notifications ***/
export interface RequestNotificationsGet {
  status?: StatusType;
  exclusive_start_notification_id?: string;
}

export const notificationsGetAPI = (params: RequestNotificationsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/notifications`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<NotificationsWithPaging>(axios, path, query, form, method, {
    items: [sample_notification_1, sample_notification_2],
    has_next: false,
  });
};

/*** [POST] /api/notifications ***/

export interface RequestNotificationsPost {
  show_date: string;
  show_date_from: string;
  show_date_to: string;
  show_type: string;
  title: string;
  content?: string;
  image_files?: string[];
  download_urls?: string[];
  uuid?: string;
  is_draft: StringBoolean;
}

export const notificationsPostAPI = (params: RequestNotificationsPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/notifications`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'image_files' && params.image_files) {
      const roop_number = params.image_files.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`image_files[${i}]`, params.image_files[i]);
      }
    } else if (key === 'download_urls' && params.download_urls) {
      const roop_number = params.download_urls.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`download_urls[${i}]`, params.download_urls[i]);
      }
    } else if (typeof value === 'string') {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<Notification>(axios, path, query, form, method, sample_notification_1);
};

/*** [GET] /api/notifications/{notification_id} ***/

export interface RequestNotificationsIdGet {
  notification_id: string;
}

export const notificationsIdGetAPI = (params: RequestNotificationsIdGet) => {
  const { notification_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/notifications/${notification_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Notification>(axios, path, query, form, method, sample_notification_1);
};

/*** [PUT] /api/notifications/{notification_id} ***/

export interface RequestNotificationsIdPut {
  notification_id: string;
  show_date: string;
  show_date_from: string;
  show_date_to: string;
  show_type: string;
  title: string;
  content?: string;
  image_files?: string[];
  download_urls?: string[];
  uuid?: string;
  is_draft: StringBoolean;
}

export const notificationsIdPutAPI = (params: RequestNotificationsIdPut) => {
  const { notification_id, ...form_params } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/notifications/${notification_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(form_params)) {
    if (key === 'image_files' && params.image_files) {
      const roop_number = params.image_files.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`image_files[${i}]`, params.image_files[i]);
      }
    } else if (key === 'download_urls' && params.download_urls) {
      const roop_number = params.download_urls.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`download_urls[${i}]`, params.download_urls[i]);
      }
    } else if (typeof value === 'string') {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<Notification>(axios, path, query, form, method, sample_notification_1);
};

/*** [DELETE] /api/notifications/{notification_id} ***/

export interface RequestNotificationsIdDelete {
  notification_id: string;
}

export const notificationsIdDeleteAPI = (params: RequestNotificationsIdDelete) => {
  const { notification_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/notifications/${notification_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<Notification>(axios, path, query, form, method, sample_notification_1);
};

/*** [POST] /api/notifications/image ***/
export interface RequestNotificationsImagePost {
  uuid?: string;
  out_file_name: string;
  image_file_name: string;
  image_file: ImageDataFileType;
}

export const notificationsImagePostAPI = async (params: RequestNotificationsImagePost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/notifications/image`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'image_file') {
      if (value.file instanceof File) {
        form.append(key, value.file, value.file_name);
      } else {
        form.append(key, value);
      }
    } else if (typeof value === 'string') {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<NotificationsImageWithPaging>(axios, path, query, form, method, sample_notification_image_1);
};

export interface RequestNotificationsImagePut {
  image_data: ImageDataFileType;
  upload_url: string;
}

export const notificationsImagePutAPI = async (params: RequestNotificationsImagePut) => {
  // ファイルからContent-Typeを取得
  const contentType = params.image_data.file ? params.image_data.file.type : 'application/octet-stream';

  // クライアントを定義
  const axios = getClient('arraybuffer', { header_options: { 'Content-Type': contentType }, disabled_load: true });

  // パス・メソッドを定義
  const path = params.upload_url;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query = {};

  // resultが他のapiのformの代わり
  let result: string | ArrayBuffer | null = '';

  // fileデータを読み込んでarraybufferに変換する処理
  const fileLoader = new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      result = reader.result;
      resolve(reader);
    };
    try {
      if (params.image_data.file) {
        reader.readAsArrayBuffer(params.image_data.file);
      }
    } catch (error) {
      console.log('Caught error while reading image file', error);
    }
  });
  await fileLoader;

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, result, method, new ArrayBuffer(8));
};
