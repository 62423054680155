// -- basic library --
import React, { useEffect, useState } from 'react';
import { tenantsDeleteAPI, CachedTenants } from 'admin/api/tenants';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';

import styles from 'shared/styles/styles';
import { isSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import TenantTable, { TableTenantType } from './TenantTable';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';

// -- main component --
const Tenants: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableTenantType[]>([]);
  const [bodies, setBodies] = useState<TableTenantType[] | undefined>(undefined);

  // -- handlers --
  const handleClick = (datas: TableTenantType[]) => {
    setSelectedBodies(datas);
  };

  const loadTableBodies = async () => {
    // テナントの取得
    const items = await new CachedTenants({}).get();
    // テーブル表示データへの変換
    const return_table_datas: TableTenantType[] = [];
    for (let i = 0; i < items.length; i++) {
      const returnTenantTableData: TableTenantType = {
        id: items[i].tenant_id,
        tenant_id: {
          value: items[i].tenant_id,
          url: `/tenants/${items[i].tenant_id}/set`,
          color: 'rgb(57, 131, 141)',
        },
        tenant_name: items[i].tenant_name,
        tenant_domain: items[i].tenant_domain,
        tenant_generator2_domain: items[i].tenant_generator2_domain ?? '',
        status: items[i].status,
        created_at: dateToYMDHMS(items[i].created_at),
        updated_at: dateToYMDHMS(items[i].updated_at),
        deleted_at: dateToYMDHMS(items[i].deleted_at ?? ''),
      };
      return_table_datas.push(returnTenantTableData);
    }
    setBodies(return_table_datas);
  };

  const handleCreateClick = () => {
    history.push(`/tenants/create`);
  };

  const handleOKClick = async (selected_datas: TableTenantType[]) => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_datas.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return tenantsDeleteAPI({ tenant_id: sd.id });
        }),
      );
      AlertDialog.show(
        `テナントの削除に成功しました\n${has_deleted_flag ? '※状態が「DELETED」のデータは削除できませんでした' : ''}`,
      );
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/tenants');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているテナントを削除します。\n本当によろしいですか？',
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // テナントの削除が可能かどうか判定する
  const istTenantsDeleteBtnActive = () => {
    if (selected_bodies.length === 0) return false;
    return true;
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, []);

  // -- render part --
  return (
    <PageWholeArea data-testid='Tenant'>
      <TopArea>
        <Title text='テナント' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規テナント追加'
          style={{ marginRight: styles.interval_narrow_margin }}
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {bodies !== undefined ? (
          <TenantTable
            bodies={bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたテナントを削除する',
              handleClick: handleDeleteClick,
              disabled: !istTenantsDeleteBtnActive(),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Tenants;
