// -- basic library --
import React from 'react';

import { IconNames } from '@blueprintjs/icons';
import { devicesPostAPI } from 'admin/api/device';
import { SoracomDevice, soracomDevicesGetAPI } from 'admin/api/soracom';
import { CachedTenants, Tenant } from 'admin/api/tenants';

import history from 'shared/browserHistory';
import { WholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/atoms/ContentsArea';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';

interface DeviceCreatePageParam {}
interface DeviceCreatePageState {
  tenants: Tenant[];
  soracom_devices: SoracomDevice[];
  tenant_id: string;
  soracom_device_id: string;
  device_name: string;
}

export class DeviceCreatePage extends React.PureComponent<DeviceCreatePageParam, DeviceCreatePageState> {
  constructor(props: DeviceCreatePageParam) {
    super(props);
    this.state = {
      tenants: [],
      soracom_devices: [],
      tenant_id: '',
      soracom_device_id: '',
      device_name: '',
    };
  }
  componentDidMount() {
    this.getTenants();
    this.getSoracomDevices();
  }
  private getTenants = () => {
    new CachedTenants({ status: 'ACTIVE' }).get().then((res) => {
      this.setState({ tenants: res });
    });
  };
  private getSoracomDevices = () => {
    soracomDevicesGetAPI({}).then((res) => {
      if (res.status === 200) {
        this.setState({ soracom_devices: res.data });
      }
    });
  };
  private onCancelClick = () => {
    history.push('/devices');
  };
  private onRegisterClick = () => {
    if (!isNotOnlySpace(this.state.tenant_id)) {
      AlertDialog.show('テナントIDは必須です');
      return;
    }
    if (!isNotOnlySpace(this.state.soracom_device_id)) {
      AlertDialog.show('デバイスは必須です');
      return;
    }
    if (!isNotOnlySpace(this.state.device_name)) {
      AlertDialog.show('デバイス名は必須です');
      return;
    }
    devicesPostAPI({
      tenant_id: this.state.tenant_id,
      soracom_device_id: this.state.soracom_device_id,
      device_name: this.state.device_name,
    }).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('新規デバイスの追加に成功しました。');
        history.push('/devices');
      }
    });
  };
  render() {
    return (
      <WholeArea data-testid='DeviceCreate'>
        <Breadcrumbs
          items={[
            { href: '/devices', icon: IconNames.CAMERA, text: 'デバイス' },
            { icon: IconNames.CUBE_ADD, text: 'SORACOMデバイスの追加' },
          ]}
        />
        <Title text='SORACOMデバイスの追加' />
        <Content>
          <FormContainer title='デバイスの割り当て'>
            <InputComponent text='テナント' required>
              <SelectBox
                onChange={(e) => this.setState({ tenant_id: e.currentTarget.value })}
                value={this.state.tenant_id}
                datas={this.state.tenants.map((d) => {
                  return {
                    name: d.tenant_name,
                    value: d.tenant_id,
                  };
                })}
                long
              />
            </InputComponent>
            <InputComponent text='デバイス' required>
              <SelectBox
                onChange={(e) => this.setState({ soracom_device_id: e.currentTarget.value })}
                value={this.state.soracom_device_id}
                datas={this.state.soracom_devices.map((d) => {
                  return {
                    name: d.soracom_device_id + ' | ' + d.soracom_device_name,
                    value: d.soracom_device_id,
                  };
                })}
                long
              />
            </InputComponent>
            <InputComponent text='デバイス名' required>
              <InputBox
                title='デバイス名'
                placeholder='入力してください(必須)'
                value={this.state.device_name}
                minLength={1}
                onChange={(e) => this.setState({ device_name: e.currentTarget.value })}
              />
            </InputComponent>
          </FormContainer>
          <Footer>
            <RoundedButton onClick={this.onCancelClick} text='キャンセル' is_margin_right is_white />
            <RoundedButton onClick={this.onRegisterClick} text='登録' />
          </Footer>
        </Content>
      </WholeArea>
    );
  }
}

// -- styled components --

// -- finally export part --

export default DeviceCreatePage;
