// -- basic library --
import React from 'react';
import InputBox from './InputBox';

// -- external datas --

export interface InputNumberBoxProps {
  value: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  min?: number | string;
  max?: number | string;
  step?: number | string;
  title?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

/***
 * 数値をvalueとできるinput
 * number型のNaNを利用して、空白の入力を許可する
 * ***/
export default class InputNumberBox extends React.Component<InputNumberBoxProps> {
  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 空文字列の時はNaNを挿入
    const new_value: number = e.currentTarget.value === '' ? NaN : Number(e.currentTarget.value);
    if (this.props.onChange) {
      this.props.onChange(new_value);
    }
  };
  render() {
    const value: string = isNaN(this.props.value) ? '' : this.props.value + '';
    return <InputBox {...this.props} type='number' value={value} onChange={this.onChange} />;
  }
}

// -- styled components --
