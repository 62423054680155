import { dashboardsGetAPI, Dashboard } from 'admin/api/dashboards';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';

/**
 * ダッシュボード一覧を取得するcustom hooks
 */
export const useDashboards = () => {
  const [dashboards, setDashboards] = useState<Dashboard[] | undefined>(undefined);
  const dashboards_record: Record<string, Dashboard> = useMemo(() => {
    return dashboards ? keyBy(dashboards, (ev) => ev.dashboard_id) : {};
  }, [dashboards]);
  const loadDashboards = useCallback(async () => {
    const res = await dashboardsGetAPI({});
    if (res.status === 200) {
      setDashboards(res.data.items);
    } else {
      setDashboards([]);
    }
  }, []);

  useEffect(() => {
    loadDashboards();
  }, [loadDashboards]);

  return useMemo(() => {
    return {
      dashboards,
      dashboards_record,
      setDashboards,
      loadDashboards,
    };
  }, [dashboards, dashboards_record, setDashboards, loadDashboards]);
};
