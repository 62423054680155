// -- basic library --
import React, { useState } from 'react';
import FileBox from 'shared/components/atoms/FileBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import styles from 'shared/styles/styles';

import { isMIMEtype } from 'shared/utils/is';
import { ImageDataFileType } from 'shared/models';
import styled from 'styled-components';

// -- type declaration --
interface Params {
  file?: ImageDataFileType;
  setFile: (file: ImageDataFileType) => void;
}

// -- main component --
/**
 * 画像データを入力するファイルボックス
 * **/
const NotificationFileBox: React.FC<Params> = (params) => {
  const [dropping, setDropping] = useState<boolean>(false);
  const [invalid_file_dropped, setInvalidFileDropped] = useState<string | undefined>(undefined);

  // 画像データを1つだけ選ぶ場合の関数
  const onChange1File = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files[0]) {
      const file = e.currentTarget.files[0];
      params.setFile({
        file: file,
        file_name: file.name || '',
      });
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!dropping) {
      setDropping(true);
    }
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    // 無効なファイル
    const data = event.dataTransfer.files;
    const file = data[0];
    const file_name = file.name || '';
    // MIMEタイプが正しければファイルを更新
    if (isMIMEtype(file.type, 'image/*')) {
      params.setFile({
        file: file,
        file_name: file.name,
      });
    } else {
      // 無効なファイル
      setInvalidFileDropped(file_name);
    }
    setDropping(false);
    // 無効なファイルの場合は、伝える
    if (invalid_file_dropped !== undefined) {
      AlertDialog.show(
        `[${invalid_file_dropped}]は、アップロードできませんでした。\n※'image/*'のファイル型のみアップロード可能。`,
      );
      setInvalidFileDropped(undefined); // 状態をクリアする
    }
  };
  // -- render part --
  return (
    <WholeArea onDragOver={onDragOver} onDrop={onDrop} onDragLeave={() => setDropping(false)} drop={dropping}>
      <FileBox
        onChange={onChange1File}
        placeholder='ファイルを選択してください(image/*)'
        button_text='ファイルを選択'
        accept='image/*'
        file_name={params.file?.file_name}
        style={{ height: '37px', overflow: 'auto' }}
      />
    </WholeArea>
  );
};

const WholeArea = styled.div<{
  drop?: boolean;
}>`
  width: 500px;
  height: auto;
  padding: ${styles.interval_narrow_margin};
  display: flex;
  flex-direction: column;
`;

// -- finally export part --

export default NotificationFileBox;
