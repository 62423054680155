import React from 'react';
import { CachedProcesses, Process } from 'admin/api/processes';
import { ProcessFlow, processflowsPostAPI } from 'admin/api/processflows';
import { CachedTenants } from 'admin/api/tenants';
import TenantSelectBox from 'admin/components/molecules/TenantSelectBox';
import { checkProcessFlowSteps } from 'admin/utils/checkProcessFlowSteps';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styled from 'styled-components';
import ProcessflowsStepEditForm from './ProcessflowsStepEditForm';
import TextAreaInput from 'shared/components/atoms/TextAreaInput';
import { CheckBoxArea } from 'shared/components/atoms/PfTable';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import SelectBox from 'shared/components/atoms/SelectBox';
import { toStringBoolean } from 'shared/models/StringBoolean';
import {
  convertProcessType,
  convertProcessTypesToProcessflowSelectDatas,
  ProcessType,
  processTypeToProcessflowUnitPrice,
} from 'shared/models/ProcessType';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import { isValidNumber } from 'shared/utils/is';

interface ProcessflowsCreateFormPanelProps {}
interface ProcessflowsCreateFormPanelState {
  tenant_id: string;
  tenants: { tenant_name: string; tenant_id: string }[];
  process_flow_name: string;
  steps: ProcessFlow['steps'];
  processes: Process[];
  is_billing: boolean;
  process_type: ProcessType | '';
  unit_price: number;
  memo: string;
  order: number;
}
export default class ProcessflowsCreateFormPanel extends React.PureComponent<
  ProcessflowsCreateFormPanelProps,
  ProcessflowsCreateFormPanelState
> {
  constructor(props: ProcessflowsCreateFormPanelProps) {
    super(props);
    this.state = {
      tenant_id: 'all',
      tenants: [],
      process_flow_name: '',
      steps: [],
      processes: [],
      is_billing: false,
      process_type: '',
      unit_price: 0,
      memo: '',
      order: NaN,
    };
  }
  async componentDidMount() {
    // テナント一覧の取得
    this.setState({ tenants: [...(await new CachedTenants({}).get())] });
    // プロセス一覧の取得
    this.setState({ processes: await new CachedProcesses({}).get() });
  }
  private setBilling = (is_billing: boolean) => {
    // 課金対象の設定
    this.setState({
      is_billing: is_billing,
    });
    // OFFの場合、プロセスタイプと単価をリセット
    if (!is_billing) {
      this.setState({
        process_type: '',
        unit_price: 0,
      });
    }
  };
  private handleBillingChange = (process_type: string) => {
    // 課金情報の設定
    this.setState({
      process_type: convertProcessType(process_type),
      unit_price: processTypeToProcessflowUnitPrice(convertProcessType(process_type)),
    });
  };
  private handleCancelClick = () => {
    history.push('/processflows');
  };
  private handleRegisterClick = () => {
    const { tenant_id, process_flow_name, steps, processes, is_billing, process_type, unit_price, memo, order } =
      this.state;
    try {
      // 入力チェック
      if (tenant_id.trim().length === 0) {
        throw Error('テナントが入力されていません。');
      }
      if (process_flow_name.trim().length === 0) {
        throw Error('プロセスフロー名が入力されていません。');
      }
      if (!checkProcessFlowSteps(steps, processes)) {
        throw Error('プロセスフローの入力で空欄があります。');
      }
      if (is_billing && process_type === '') {
        throw Error('プロセスタイプが選択されていません。');
      }
      if (!Number.isNaN(order) && !Number.isInteger(order)) {
        throw Error('オーダーを入力する場合は、整数でなければなりません');
      }
      processflowsPostAPI({
        tenant_id,
        process_flow_name,
        steps,
        is_billing: toStringBoolean(is_billing),
        process_type,
        unit_price,
        memo,
        order: isValidNumber({ num: order, integer: true, allow0: true }) ? order : undefined,
      }).then((res) => {
        if (res.status === 200) {
          AlertDialog.show('プロセスフローの登録に成功しました。', () => history.push('/processflows'));
        }
      });
    } catch (e) {
      AlertDialog.show(`エラーが発生しました。\n${String(e)}`);
    }
  };
  render() {
    return (
      <>
        <Panel>
          <FormContainer title='基本情報'>
            <InputComponent text='テナント' required>
              <TenantSelectBox
                tenant_id={this.state.tenant_id}
                tenants={this.state.tenants}
                setTenantId={(tenant_id) =>
                  this.setState({
                    tenant_id: tenant_id,
                  })
                }
                callback={async (tenant_id) => {
                  const new_processes = await new CachedProcesses({ tenant_id: tenant_id, filter_method: 'AND' }).get();
                  const new_steps = [...this.state.steps];
                  // 新しいprocesses内に、step[i].process_idが存在しない時は、空文字列にリセットする
                  for (let i = 0; i < this.state.steps.length; i++) {
                    const step = new_steps[i];
                    const searched = new_processes.find((np) => np.process_id === step.process_id);
                    if (!searched) {
                      new_steps[i] = {
                        ...step,
                        process_id: '',
                        input_streams: [],
                        app_parameter: {},
                      };
                    }
                  }
                  this.setState({
                    processes: new_processes,
                    steps: new_steps,
                  });
                }}
              />
            </InputComponent>
            <InputComponent text='プロセスフロー名' required>
              <InputBox
                value={this.state.process_flow_name}
                onChange={(e) => this.setState({ process_flow_name: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='課金対象'>
              <CheckBoxArea>
                <CheckBoxWithText
                  text='設定する'
                  checked={this.state.is_billing}
                  onClick={() => this.setBilling(!this.state.is_billing)}
                />
              </CheckBoxArea>
            </InputComponent>
            {this.state.is_billing && (
              <InputComponent text='プロセスタイプ'>
                <SelectBox
                  onChange={(e) => this.handleBillingChange(e.currentTarget.value)}
                  value={this.state.process_type}
                  datas={convertProcessTypesToProcessflowSelectDatas()}
                />
              </InputComponent>
            )}
            <InputComponent text='メモ'>
              <TextAreaInput
                style={{ width: '100%' }}
                rows={1}
                title='メモ'
                placeholder='入力してください(任意)'
                value={this.state.memo}
                onChange={(e) =>
                  this.setState({
                    memo: e.currentTarget.value,
                  })
                }
              />
            </InputComponent>
            <InputComponent text='オーダー'>
              <InputNumberBox
                value={this.state.order}
                onChange={(value) =>
                  this.setState({
                    order: value,
                  })
                }
              />
            </InputComponent>
          </FormContainer>
          <FormContainer title='プロセス情報'>
            <InputComponent text='プロセスフロー' required>
              <ProcessflowsStepEditForm
                steps={this.state.steps}
                processes={this.state.processes}
                onChange={(value) => this.setState({ steps: value })}
              />
            </InputComponent>
          </FormContainer>
        </Panel>
        <Footer>
          <RoundedButton
            text='キャンセル'
            is_white={true}
            onClick={this.handleCancelClick}
            style={{ marginRight: '0.5%' }}
          />
          <RoundedButton text='登録' onClick={this.handleRegisterClick} />
        </Footer>
      </>
    );
  }
}

const Panel = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
`;
