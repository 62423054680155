// -- basic library --
import { Slider as BlueprintSlider, SliderProps } from '@blueprintjs/core';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

// -- external components --

// -- type declaration --

export const Slider = (props: SliderProps) => {
  return <CustomSlider {...props} />;
};

export default Slider;

// -- styled components --
const CustomSlider = styled(BlueprintSlider)`
  .bp3-slider-progress.bp3-intent-primary {
    background-color: ${colors.component_main_color};
  }
  .bp3-slider-handle {
    border-radius: 50%;
    border: 3px solid ${colors.component_main_color};
    box-shadow: none;
  }
`;

// -- finally export part --
