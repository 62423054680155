import React, { useMemo, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import StreamTable, { TableStreamType } from './StreamTable';
import { useTenants } from 'admin/hooks/useTenants/useTenants';
import { useStreamsWithPaging } from 'admin/hooks/useStreamsWithPaging/useStreamsWithPaging';
import { isStreamingReception } from 'shared/utils/is';
import { streamDataNumberTypeToJP } from 'shared/models/StreamDataNumberType';
import { streamDataTypeToJP } from 'shared/models/StreamDataType';

// -- main component --
const Streams: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableStreamType[]>([]);
  const { tenants_record } = useTenants({ status: 'ALL' });
  const { streams, next_meta_data, loadStreams, addStreams } = useStreamsWithPaging({});

  const table_bodies = useMemo(() => {
    return streams?.map((stream) => {
      const tenant_name = tenants_record[stream.tenant_id]?.tenant_name ?? '';
      const streaming_endpoint = stream.streaming_endpoint;
      const data_number_type_jp = streamDataNumberTypeToJP(stream.data_number_type);
      const data_type_jp = streamDataTypeToJP(stream.data_type);
      const return_table_stream_data: TableStreamType = {
        id: stream.stream_id,
        stream_id: {
          value: stream.stream_id,
          url: `/streams/${stream.stream_id}/${stream.tenant_id}/detail`,
          color: 'rgb(57, 131, 141)',
        },
        stream_name: stream.stream_name,
        tenant_id: stream.tenant_id,
        tenant_name: String(tenant_name),
        channel_id: stream.channel_id,
        stream_type: isStreamingReception(streaming_endpoint) ? 'ストリーミング受信' : 'データアップロード',
        data_number_type: data_number_type_jp,
        data_type: data_type_jp,
        retention_period: String(~~(stream.data_store_period / 86400)) + '日',
      };
      return return_table_stream_data;
    });
  }, [tenants_record, streams]);

  // -- handlers --
  const handleClick = (datas: TableStreamType[]) => {
    setSelectedBodies(datas);
  };

  // -- render part --
  return (
    <PageWholeArea data-testid='Streams'>
      <TopArea>
        <Title text='ストリーム一覧' />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <StreamTable
            has_next={next_meta_data.has_next}
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            handleAddDatas={addStreams}
            handleAPISearch={loadStreams}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Streams;
