import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { CsvRow } from 'shared/models/CsvRow';
import { ExportCsvStatistics } from 'shared/models/ExportCsvStatistics';
import { ISO8601 } from 'shared/models/ISO8601';
import { JsonpathGroupCategory } from 'shared/models/JsonpathGroupCategory';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from './base';

/*** エンティティ ***/

export interface JsonpathGroup {
  jsonpath_group_id: string;
  jsonpath_group_name: string;
  category: JsonpathGroupCategory;
  display: string[];
  export_csv_statistics: ExportCsvStatistics;
  csv_rows: CsvRow[];
  memo: string | null;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface Jsonpath {
  jsonpath_group_id: string;
  csv_rows: CsvRow;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface JsonpathGroupWithPaging {
  items: JsonpathGroup[];
  has_next: boolean;
}

export interface JsonpathCsvWithPaging {
  items: CsvRow[];
  headers: string[];
  has_next: boolean;
}

const sample_json_path_group_1: JsonpathGroup = {
  jsonpath_group_id: '00000001',
  jsonpath_group_name: 'テストJSONパス1',
  export_csv_statistics: 'NONE',
  category: 'AI',
  display: ['DASHBOARD'],
  csv_rows: [],
  memo: 'このJSONPパスグループはダッシュボードで使います',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_json_path_group_2: JsonpathGroup = {
  jsonpath_group_id: '00000002',
  jsonpath_group_name: 'JSONパス1',
  export_csv_statistics: 'KEY',
  category: 'COUNTER',
  display: ['PROCESS'],
  csv_rows: [],
  memo: null,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** Caching mechanism ***/

export class CachedJsonpathGroup {
  private searched = false;
  private cache: JsonpathGroup[] = [];
  private params: RequestJsonpathGroupGet;
  constructor(params: RequestJsonpathGroupGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<JsonpathGroupWithPaging> = await jsonpathGroupGetAPI({
          ...this.params,
          exclusive_start_jsonpath_group_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.jsonpath_group_id).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/jsonpath ***/
export interface RequestJsonpathGroupGet {
  status?: string;
  exclusive_start_jsonpath_group_id?: string;
  display?: string;
}

export const jsonpathGroupGetAPI = (params: RequestJsonpathGroupGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<JsonpathGroupWithPaging>(axios, path, query, form, method, {
    items: [sample_json_path_group_1, sample_json_path_group_2],
    has_next: false,
  });
};

/*** [POST] /api/jsonpathGroups ***/

export interface RequestJsonpathGroupPost {
  jsonpath_group_name: string;
  category: JsonpathGroupCategory;
  display: string[];
  export_csv_statistics: ExportCsvStatistics;
  csv_rows: CsvRow[];
  memo?: string;
}

export const jsonpathGroupsPostAPI = (params: RequestJsonpathGroupPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'csv_rows') {
      const roop_number = params.csv_rows.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`csv_rows[${i}]`, JSON.stringify(params.csv_rows[i]));
      }
    } else if (key === 'display') {
      const roop_number = params.display.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`display[${i}]`, JSON.stringify(params.display[i]));
      }
    } else if (typeof value === 'string') {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};

/*** [GET] /api/jsonpathGroups/{jsonpath_group_id} ***/

export interface RequestJsonpathGroupIdGet {
  jsonpath_group_id: string;
}

export const jsonpathGroupsIdGetAPI = (params: RequestJsonpathGroupIdGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups/${params.jsonpath_group_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};

/*** [PUT] /api/jsonpath/{jsonpath_group_id} ***/

export interface RequestJsonpathGroupIdPut {
  jsonpath_group_id: string;
  jsonpath_group_name: string;
  category: JsonpathGroupCategory;
  display: string[];
  export_csv_statistics: ExportCsvStatistics;
  csv_rows: CsvRow[];
  memo?: string;
}

export const jsonpathGroupsIdPutAPI = (params: RequestJsonpathGroupIdPut) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups/${params.jsonpath_group_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'csv_rows') {
      const roop_number = params.csv_rows.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`csv_rows[${i}]`, JSON.stringify(params.csv_rows[i]));
      }
    } else if (key === 'display') {
      const roop_number = params.display.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`display[${i}]`, JSON.stringify(params.display[i]));
      }
    } else if (typeof value === 'string') {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};

/*** [DELETE] /api/jsonpath/{jsonpath_group_id} ***/

export interface RequestJsonpathGroupIdDelete {
  jsonpath_group_id: string;
}

export const jsonpathGroupsIdDeleteAPI = (params: RequestJsonpathGroupIdDelete) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups/${params.jsonpath_group_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};
