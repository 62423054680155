// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- type declaration --
export interface CheckBoxWithTextProps {
  checked: boolean;
  text: string;
  'data-testid'?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
  id?: string;
  disabled?: boolean;
}

export const CheckBoxWithText = ({
  onClick,
  checked,
  style,
  text,
  'data-testid': testid,
  disabled,
  id = 'checkbox_with_text',
}: CheckBoxWithTextProps) => {
  return (
    <ParentLabel onClick={onClick} data-testid={testid} style={style}>
      <Label htmlFor={id} checked={checked} disabled={disabled}>
        <InputCheckbox id={id} type='checkbox' checked={checked} disabled />
        <Text>{text}</Text>
      </Label>
    </ParentLabel>
  );
};

export default CheckBoxWithText;

// -- styled components --

const ParentLabel = styled.div`
  display: inline-block;
`;

const InputCheckbox = styled.input`
  display: none;
`;

// labelのafter時のstyle
const label_after_styles = `
{
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 14px;
  height: 10px;
  top: 2px;
  left: 3px;
  transform: rotate(-45deg);
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-color: ${colors.white};
}`;

const Label = styled.label<{
  checked: boolean;
  disabled?: boolean;
}>`
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  height: 20px;
  font-size: ${styles.small_text_size};
  &::before {
    content: '';
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    border: 1px solid;
    border-radius: 3px;
    border-color: ${colors.component_small_border_color};
    background-color: ${(params) => {
      // disabledなら問答無用でdisabledカラー
      if (params.disabled) {
        return colors.disabled_background_color;
      }
      // disabledではないならcheckedに応じて色を返す
      return params.checked ? colors.component_main_color : colors.white;
    }};
  }
  &::after {
    ${(params) => (params.checked ? label_after_styles : null)}
  }
`;

const Text = styled.div`
  // checkboxとのpositionを揃えるためにheightを指定している
  height: 16px;
  margin-left: 25px;
  cursor: pointer;
`;
