import React, { useEffect, useState } from 'react';
import { jsonpathGroupsIdDeleteAPI, JsonpathGroup, CachedJsonpathGroup } from 'admin/api/jsonpathGroups';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import JsonpathGroupTable, { TableJsonpathGroupType } from './JsonPathGroupTable';
import { jsonpathGroupCategoryToText } from 'shared/models/JsonpathGroupCategory';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';

// -- main component --
const Jsonpath: React.FC = () => {
  // -- redux preparations --

  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableJsonpathGroupType[]>([]);
  const [bodies, setBodies] = useState<TableJsonpathGroupType[] | undefined>(undefined);

  // -- handlers --
  const handleClick = (datas: TableJsonpathGroupType[]) => {
    setSelectedBodies(datas);
  };

  const getDisplay = (displays: string[]) => {
    let display_list = '';
    for (const display of displays) {
      if (display == 'DASHBOARD') {
        display_list = display_list + 'ダッシュボード';
      }
      if (display == 'STREAM') {
        if (display_list.length > 0) {
          display_list = display_list + ',';
        }
        display_list = display_list + 'ストリーム';
      }
      if (display == 'PROCESS') {
        if (display_list.length > 0) {
          display_list = display_list + ',';
        }
        display_list = display_list + 'ファイル処理/プロセスフロー';
      }
    }
    return display_list;
  };

  const loadTableBodies = async () => {
    const items: JsonpathGroup[] = await new CachedJsonpathGroup({}).get();
    const return_table_datas: TableJsonpathGroupType[] = [];
    for (const item of items) {
      const category = jsonpathGroupCategoryToText(item.category);
      let display = '';
      if (typeof item.display == 'object') {
        display = getDisplay(item.display);
      }
      const return_top_table_data: TableJsonpathGroupType = {
        id: item.jsonpath_group_id,
        jsonpath_group_id: {
          value: item.jsonpath_group_id,
          url: `/jsonpath/${item.jsonpath_group_id}/detail`,
          color: 'rgb(57, 131, 141)',
        },
        memo: item.memo ?? '',
        jsonpath_group_name: item.jsonpath_group_name,
        category: category,
        display: display,
        export_csv_statistics: item.export_csv_statistics,
        status: item.status,
        created_at: dateToYMDHMS(item.created_at),
        updated_at: dateToYMDHMS(item.updated_at),
        deleted_at: dateToYMDHMS(item.deleted_at ?? ''),
      };

      return_table_datas.push(return_top_table_data);
    }
    setBodies(return_table_datas);
  };

  const handleCreateClick = () => {
    history.push(`/jsonpath/create`);
  };
  const handleOKClick = async () => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_bodies.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return jsonpathGroupsIdDeleteAPI({ jsonpath_group_id: sd.id });
        }),
      );
      if (has_deleted_flag) {
        AlertDialog.show(`状態が「DELETED」のデータは削除できませんでした`);
      }

      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/jsonpath');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているJSONパスグループを削除します。\n本当によろしいですか？',
      handleOKClick,
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='Jsonpath'>
      <TopArea>
        <Title text='JSONパスグループ' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規JSONパスグループ追加'
          style={{ marginRight: styles.interval_narrow_margin }}
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {bodies !== undefined ? (
          <JsonpathGroupTable
            bodies={bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたJSONパスを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Jsonpath;
