import { eventsGetAPI, Event } from 'admin/api/Events';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';

/**
 * イベント一覧を取得するcustom hooks
 */
export const useEvents = () => {
  const [events, setEvents] = useState<Event[] | undefined>(undefined);
  const events_record: Record<string, Event> = useMemo(() => {
    return events ? keyBy(events, (ev) => ev.event_id) : {};
  }, [events]);
  const loadEvents = useCallback(async () => {
    const res = await eventsGetAPI({});
    if (res.status === 200) {
      setEvents(res.data.items);
    } else {
      setEvents([]);
    }
  }, []);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  return useMemo(() => {
    return {
      events,
      events_record,
      setEvents,
      loadEvents,
    };
  }, [events, events_record, setEvents, loadEvents]);
};
