// -- basic library --
import React from 'react';
import { ProcessAppParameter } from 'admin/api/processes';
import { CsvRowsInputDialogButton } from 'admin/dialogs/CsvRowsInputDialog';
import { InputJsonpathGroup } from 'admin/dialogs/CsvRowsInputDialog/index';
import { AppParamRowArea, AppParamRowText } from 'shared/components/atoms/ContentsArea';
import InputBox from 'shared/components/atoms/InputBox';
import RequiredIcon from 'shared/components/atoms/RequiredIcon';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import MultiSelectBox from 'shared/components/molecules/MultiSelectBox';

// -- type declaration --

interface Params {
  processAppParameter: ProcessAppParameter;
  appParameter: string;
  onChange: (appParameter: string) => void;
}

// -- main component --

const AppParameterInput: React.FC<Params> = (params) => {
  // MuiltiSelectの初期値
  const checkDefaultValue = (value: string): SelectData[] | undefined => {
    if (value === '') {
      return undefined;
    }
    return value.split(',').map((v) => {
      return {
        value: v,
        name: v,
      };
    });
  };

  // 複数選択項目が変わった時の関数
  const handleListItemsChange = (item: string) => {
    if (params.appParameter === '') {
      return item;
    }
    const inputItemList = params.appParameter.split(',');
    if (!inputItemList.includes(item)) {
      // 選択された要素を追加
      inputItemList.push(item);
    }
    return inputItemList.join(',');
  };

  // 複数選択項目を削除する時の関数
  const handleRemove = (index: number | undefined) => {
    const inputItemList = params.appParameter.split(',');
    if (typeof index === 'number') {
      // 選択された要素を削除
      inputItemList.splice(index, 1);
      return inputItemList.join(',');
    } else {
      return '';
    }
  };

  // -- render part --
  return (
    <AppParamRowArea>
      <AppParamRowText>
        {params.processAppParameter.name}
        {params.processAppParameter.required ? <RequiredIcon /> : null}
      </AppParamRowText>
      {params.processAppParameter.input_type === 'TEXT' && (
        <InputBox
          title={params.processAppParameter.description}
          placeholder='入力してください'
          value={params.appParameter}
          onChange={(e) => params.onChange(e.currentTarget.value)}
        />
      )}
      {params.processAppParameter.input_type === 'SELECT' && (
        <SelectBox
          value={params.appParameter}
          onChange={(e) => params.onChange(e.currentTarget.value)}
          datas={params.processAppParameter.list_items.split('\n').map((s) => {
            return {
              name: s,
              value: s,
            };
          })}
          long={true}
        />
      )}
      {params.processAppParameter.input_type === 'MULTISELECT' && (
        <MultiSelectBox
          selectedItems={checkDefaultValue(params.appParameter)}
          items={params.processAppParameter.list_items.split('\n').map((d) => {
            return {
              name: d,
              value: d,
            };
          })}
          onItemSelect={(item) => params.onChange(handleListItemsChange(item.value))}
          onRemove={(_item, index) => params.onChange(handleRemove(index))}
        />
      )}
      {params.processAppParameter.input_type === 'CROSSLINE' && '検知ラインには対応していません'}
      {params.processAppParameter.input_type === 'CROSSLINE2' && '検知ライン2には対応していません'}
      {params.processAppParameter.input_type === 'CROP' && 'クロップには対応していません'}
      {params.processAppParameter.input_type === 'VIDEOEDITOR' && 'ビデオエディタには対応していません'}
      {params.processAppParameter.input_type === 'CSVROWS' && (
        <CsvRowsInputDialogButton value={params.appParameter} onChange={params.onChange} />
      )}
      {params.processAppParameter.input_type === 'JSONPATHGROUPS' && (
        <InputJsonpathGroup value={params.appParameter} onChange={params.onChange} display={'PROCESS'} />
      )}
      {params.processAppParameter.input_type === 'SAFIEAUTH' && 'Safie認証には対応していません'}
      {params.processAppParameter.input_type === 'STAYAREA' && '滞留エリアには対応していません'}
      {params.processAppParameter.input_type === 'TRANSFORMEDAREA' && '射影変換エリアには対応していません'}
    </AppParamRowArea>
  );
};

// -- finally export part --

export default AppParameterInput;
