// -- basic library --
import React from 'react';

// -- external components --
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyMultipleValueType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { table_cell_toggle_button_width } from 'shared/styles/styles';

// -- type declaration --

interface Params {
  bodies: TableEventType[];
  selected_bodies: TableEventType[];
  handleCheckClick: (datas: TableEventType[]) => void;
}

export interface TableEventType {
  id: string;
  tenant_id: string;
  tenant_name: string;
  channel_event_condition_name: string;
  current_status: string;
  current_status_at: string;
  updated_at: string;
  is_billing: TableBodyMultipleValueType<string>;
}

export const head_cells: TableHeaderType[] = [
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'tenant_name',
    label: 'テナント名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'channel_event_condition_name',
    label: 'イベント名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'current_status',
    label: 'ステータス',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'current_status_at',
    label: 'ステータス日時',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'updated_at',
    label: '更新日時',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'is_billing',
    label: '課金対象',
    sortable: true,
    width: table_cell_toggle_button_width,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const EventTable: React.FC<Params> = (params) => {
  // -- render part --
  return <BaseTable {...params} table_name='events' headers={head_cells} />;
};

// -- styled components --

// -- finally export part --
export default EventTable;
