import { SelectData } from 'shared/components/atoms/SelectBox';
import { assertNever } from 'shared/utils/else/assertNever';

export type TableauRole = 'Explorer' | 'Viewer' | 'Not-used';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTableauRole = (data: any): data is TableauRole =>
  data === 'Explorer' || data === 'Viewer' || data === 'Not-used';

export const tableauRoleToJP = (tableau_role: TableauRole) => {
  switch (tableau_role) {
    case 'Explorer':
      return '編集権限';
    case 'Viewer':
      return '閲覧権限';
    case 'Not-used':
      return '権限なし';
    default:
      assertNever(tableau_role);
      return '';
  }
};

const unit = '円 / 月';
export const tableauRoleToPrice = (tableau_role: TableauRole) => {
  switch (tableau_role) {
    case 'Explorer':
      return `5000${unit}`;
    case 'Viewer':
      return `2000${unit}`;
    case 'Not-used':
      return `0${unit}`;
    default:
      assertNever(tableau_role);
      return `0${unit}`;
  }
};

/**
 * RadioBoxでの選択肢
 */
export const tableau_role_radio_select_items: SelectData[] = [
  {
    value: 'Explorer',
    name: `${tableauRoleToJP('Explorer')} [${tableauRoleToPrice('Explorer')}]`,
  },
  {
    value: 'Viewer',
    name: `${tableauRoleToJP('Viewer')} [${tableauRoleToPrice('Viewer')}]`,
  },
  {
    value: 'Not-used',
    name: `${tableauRoleToJP('Not-used')} [${tableauRoleToPrice('Not-used')}]`,
  },
];

export const isTableauExplorer = (tableau_role: TableauRole) => tableau_role === 'Explorer';
export const isTableauViewer = (tableau_role: TableauRole) => tableau_role === 'Viewer';
