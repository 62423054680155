// -- basic library --
import { memo, useCallback, useState } from 'react';
import topmenu_icon_menu from 'assets/topmenu_icon_menu.png';
import history from 'shared/browserHistory';
import IconButton from 'shared/components/molecules/IconButton';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import Popover from '../atoms/Popover';
import { getNotificationSessionStorage } from 'shared/utils/else/sessionStorageFunctions';
import { MaybeElement, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';

export interface HeaderMenuItem {
  id: string;
  text: string;
  for_admin?: boolean;
  icon?: IconName | MaybeElement;
}
interface PfHeaderProps {
  header_menu_items: HeaderMenuItem[];
  is_admin_user?: boolean;
  signOut(): void;
  login_id?: string;
}
// -- main component --
const PfHeader = memo(function Pfheader({ header_menu_items, is_admin_user, signOut, login_id }: PfHeaderProps) {
  const [is_open_menu_popover, setIsOpenMenuPopover] = useState<boolean>(false);
  const open = useCallback(() => {
    setIsOpenMenuPopover(true);
  }, []);
  const close = useCallback(() => {
    setIsOpenMenuPopover(false);
  }, []);

  const onHeaderMenuClick = useCallback(
    (id: string) => () => {
      close();
      history.push(`/${id}`);
    },
    [close],
  );

  const onNotificationClick = () => {
    history.push('/notifications');
  };

  const notification: string = getNotificationSessionStorage();

  // -- render part --
  return (
    <HeaderWrapper>
      <Notification onClick={onNotificationClick}>{notification}</Notification>
      <MarginText />
      <Popover
        content={
          <Menu>
            <MenuDivider title={login_id ?? ''} />
            {header_menu_items.map((header_menu, i) => {
              if (header_menu.for_admin && !is_admin_user) return null;
              return (
                <MenuItem
                  key={i}
                  icon={header_menu.icon}
                  text={header_menu.text}
                  onClick={onHeaderMenuClick(header_menu.id)}
                />
              );
            })}
            <MenuDivider />
            <MenuItem
              text='ログアウト'
              icon={IconNames.LOG_OUT}
              onClick={signOut} />
          </Menu>
        }
        isOpen={is_open_menu_popover}
        onClose={close}
      >
        <IconButton
          img_src={topmenu_icon_menu}
          style={{ width: 46, height: 26 }}
          handleClick={open}
          data_testid='account-icon-button'
        />
      </Popover>
    </HeaderWrapper>
  );
});

// -- styled components --

const HeaderWrapper = styled.div`
  width: 100%;
  height: ${styles.topmenu_height};
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
`;

const MarginText = styled.div`
  margin: auto;
`;

const Notification = styled.div`
  font-size: ${styles.normal_text_size};
  border-bottom: 1px solid;
  cursor: pointer;
`;

// -- finally export part --

export default PfHeader;
