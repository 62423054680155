import { CachedTenants, RequestTenantsGet, Tenant } from 'admin/api/tenants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';

/**
 * テナント一覧を取得するcustom hooks
 */
export const useTenants = ({ status, exclusive_start_tenant_id }: RequestTenantsGet) => {
  const [tenants, setTenants] = useState<Tenant[] | undefined>(undefined);
  const tenants_record: Record<string, Tenant> = useMemo(() => {
    return tenants ? keyBy(tenants, (t) => t.tenant_id) : {};
  }, [tenants]);
  const loadTenants = useCallback(
    async (request?: RequestTenantsGet) => {
      const r = request ?? { status, exclusive_start_tenant_id } ?? {};
      const new_roles = await new CachedTenants(r).get();
      setTenants(new_roles);
    },
    [status, exclusive_start_tenant_id],
  );

  useEffect(() => {
    loadTenants();
  }, [loadTenants]);

  return useMemo(() => {
    return {
      tenants,
      tenants_record,
      setTenants,
      loadTenants,
    };
  }, [tenants, tenants_record, setTenants, loadTenants]);
};
