import RoundedButton, { RoundedButtonProps } from 'shared/components/atoms/RoundedButton';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import BaseTable from './BaseTable';
import { BaseTableProps } from './type';

interface InputTableProps extends BaseTableProps {
  buttons?: RoundedButtonProps[];
  text?: string;
  required?: boolean;
}
export function InputBaseTable(props: InputTableProps) {
  return (
    <>
      <BaseTable {...props} hide_toparea />
      {/* フッター */}
      <InputTableFooter>
        {props.buttons
          ? props.buttons.map((button, index) => {
              return <RoundedButton {...button} key={index} small />;
            })
          : null}
      </InputTableFooter>
    </>
  );
}

export const InputTableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 25px;
  margin-top: ${styles.interval_narrow_margin};
`;
