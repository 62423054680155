import React, { useEffect, useState } from 'react';
import { CachedDevices, devicesIdDeleteAPI } from 'admin/api/device';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import DeviceTable, { TableDeviceType } from './DeviceTable';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';

// -- main component --
const Devices: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableDeviceType[]>([]);
  const [table_bodies, setTableBodies] = useState<TableDeviceType[] | undefined>(undefined);

  // -- handlers --
  const handleClick = (datas: TableDeviceType[]) => {
    setSelectedBodies(datas);
  };

  const loadTableBodies = async () => {
    const items = await new CachedDevices({}).get();

    const return_table_datas: TableDeviceType[] = [];
    for (let i = 0; i < items.length; i++) {
      const return_top_table_data: TableDeviceType = {
        id: items[i].device_id,
        device_id: {
          value: items[i].device_id,
          url: `/devices/${items[i].device_id}`,
          color: 'rgb(57, 131, 141)',
        },
        device_name: items[i].device_name,
        device_type: items[i].device_type,
        device_group_name: items[i].soracom_status?.device_group_name || '',
        subscriber_group_name: items[i].soracom_status?.subscriber_group_name || '',
        status: items[i].status,
        tenant_id: items[i].tenant_id,
        created_at: dateToYMDHMS(items[i].created_at),
        updated_at: dateToYMDHMS(items[i].updated_at),
        deleted_at: dateToYMDHMS(items[i].deleted_at ?? ''),
      };

      return_table_datas.push(return_top_table_data);
    }
    setTableBodies(return_table_datas);
  };

  const handleCreateClick = () => {
    history.push(`/devices/create`);
  };

  const handleOKClick = async () => {
    // 削除関数定義
    const deletes = async () => {
      // 並列に削除
      await Promise.all(
        selected_bodies.map((sd) => {
          return devicesIdDeleteAPI({ id: sd.id });
        }),
      );

      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/devices');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているデバイスを削除します。\n本当によろしいですか？',
      handleOKClick,
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='Device'>
      <TopArea>
        <Title text='デバイス' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規デバイス追加'
          style={{ marginRight: styles.interval_narrow_margin }}
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <DeviceTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたデバイスを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Devices;
