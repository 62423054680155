// -- basic library --
import React from 'react';
import {
  InputStreamConstraint,
  OutputStreamConstraint,
  Process,
  ProcessAppParameter,
  ProcessEcsparameter,
  processesIdPutAPI,
  ProcessHeartbeats,
  RequestProcessesIdPut,
} from 'admin/api/processes';
import { CachedTenants, TenantForSelect } from 'admin/api/tenants';

import TenantSelectBox from 'admin/components/molecules/TenantSelectBox';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RadioBox from 'shared/components/molecules/RadioBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import SliderBox from 'shared/components/atoms/Slider';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import AppParameterDialog, { AppParameterDialogFields } from 'shared/dialogs/AppParameterDialog';
import HeartbeatsDialog, { HeartbeatsDialogFields } from 'shared/dialogs/HeartbeatsDialog';
import { isNotOnlySpace, isValidNumber } from 'shared/utils/is';
import AppParameterTable, { TableAppParameterType } from '../../components/AppParameterTable';
import HeartbeatTable, { TableHeartbeatType } from '../../components/HeartbeatTable';
import InstanceForm from '../../components/InstanceForm';
import { toStringBoolean } from 'shared/models/StringBoolean';
import { RuntimeType } from 'shared/models/process/RuntimeType';
import { ProcessingTenancy } from 'shared/models/process/ProcessingTenancy';
import TextAreaInput from 'shared/components/atoms/TextAreaInput';
import { CheckBoxArea } from 'shared/components/atoms/PfTable';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import {
  convertProcessType,
  convertProcessTypesToProcessSelectDatas,
  ProcessType,
  processTypeToProcessUnitPrice,
} from 'shared/models/ProcessType';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';

// -- main component --
interface InformationSetPanelProps {
  process: Process;
}
interface InformationSetPanelState {
  tenant_id: string;
  tenants: TenantForSelect[];
  process_name: string;
  app_module: string;
  app_class: string;
  input_stream_count: number;
  input_stream_constraints: InputStreamConstraint[];
  input_sync: boolean;
  output_stream_count: number;
  output_stream_data_types: string[];
  output_stream_constraints: OutputStreamConstraint[];
  app_parameters: ProcessAppParameter[];
  heartbeats: ProcessHeartbeats[];
  processing_tenancy: ProcessingTenancy;
  runtime_type: RuntimeType;
  ecs_parameter: ProcessEcsparameter;
  selected_app_parameter_table_bodies: TableAppParameterType[];
  app_parameter_table_bodies: TableAppParameterType[];
  is_app_parameter_dialog_open: boolean;
  app_parameter_dialog_fields: AppParameterDialogFields;
  selected_heartbeat_table_bodies: TableHeartbeatType[];
  heartbeat_table_bodies: TableHeartbeatType[];
  is_heartbeat_dialog_open: boolean;
  heartbeat_dialog_fields: HeartbeatsDialogFields;
  ui_visible: boolean;
  is_billing: boolean;
  process_type: ProcessType | '';
  unit_price: number;
  memo: string;
  order: number;
}
export default class InformationSetPanel extends React.PureComponent<
  InformationSetPanelProps,
  InformationSetPanelState
> {
  constructor(props: InformationSetPanelProps) {
    super(props);
    this.state = {
      tenant_id: props.process.tenant_id,
      tenants: [],
      process_name: props.process.process_name,
      app_module: props.process.app_module,
      app_class: props.process.app_class,
      input_stream_count: Number(props.process.input_stream_count),
      input_stream_constraints: props.process.input_stream_constraints,
      input_sync: props.process.input_sync,
      output_stream_count: Number(props.process.output_stream_count),
      output_stream_data_types: props.process.output_stream_data_types,
      output_stream_constraints: props.process.output_stream_constraints,
      processing_tenancy: props.process.processing_tenancy,
      app_parameters: props.process.app_parameters,
      heartbeats: props.process.heartbeats,
      runtime_type: props.process.runtime_type,
      ecs_parameter:
        props.process.ecs_parameter != null
          ? props.process.ecs_parameter
          : {
              image: '',
              cpu: 512,
              memory: 1024,
              gpu: 0,
              cpu_arch: 'X86_64',
              compatibilities: ['EC2', 'FARGATE'],
              capacity_providers: [],
              vpc: '',
              security_group_name: '',
              max_tasks: 0,
            },
      selected_app_parameter_table_bodies: [],
      app_parameter_table_bodies: props.process.app_parameters.map((e) => {
        return {
          id: e.key,
          key: {
            value: e.key,
            onClick: () => this.handleAppParameterSetClick(e.key),
          },
          name: e.name,
          description: e.description,
          default_value: e.default_value,
          input_type: e.input_type,
          list_items: e.list_items,
          required: e.required ? 'true' : 'false',
          validate_regex: e.validate_regex,
          is_hidden: e.is_hidden ? 'true' : 'false',
        };
      }),
      is_app_parameter_dialog_open: false,
      app_parameter_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_value: '',
        input_type: '',
        list_items: '',
        required: false,
        validate_regex: '',
        is_hidden: false,
      },
      selected_heartbeat_table_bodies: [],
      heartbeat_table_bodies: props.process.heartbeats.map((e) => {
        return {
          id: e.key,
          key: {
            value: e.key,
            onClick: () => this.handleHeartbeatSetClick(e.key),
          },
          name: e.name,
          description: e.description,
          default_schedule: e.default_schedule,
          additionals: e.additionals,
          required: e.required ? 'true' : 'false',
          is_hidden: e.is_hidden ? 'true' : 'false',
        };
      }),
      is_heartbeat_dialog_open: false,
      heartbeat_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_schedule: '',
        additionals: '',
        required: false,
        is_hidden: false,
      },
      ui_visible: this.props.process.ui_visible,
      is_billing: this.props.process.is_billing ?? false,
      process_type: this.props.process.process_type ?? '',
      unit_price: this.props.process.unit_price,
      memo: this.props.process.memo ?? '',
      order: this.props.process.order ?? NaN,
    };
  }
  async componentDidMount() {
    // テナント一覧の取得
    this.setState({ tenants: [...(await new CachedTenants({}).get())] });
  }
  setBilling = (is_billing: boolean) => {
    // 課金対象の設定
    this.setState({
      is_billing: is_billing,
    });
    // OFFの場合、プロセスタイプと単価をリセット
    if (!is_billing) {
      this.setState({
        process_type: '',
        unit_price: 0,
      });
    }
  };
  handleBillingChange = (process_type: string) => {
    // 課金情報の設定
    this.setState({
      process_type: convertProcessType(process_type),
      unit_price: processTypeToProcessUnitPrice(convertProcessType(process_type)),
    });
  };
  handleAppParameterClick = (datas: TableAppParameterType[]) => {
    this.setState({ selected_app_parameter_table_bodies: datas });
  };
  handleAppParameterCreateClick = () => {
    this.setState({
      app_parameter_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_value: '',
        input_type: '',
        list_items: '',
        required: false,
        validate_regex: '',
        is_hidden: false,
      },
      is_app_parameter_dialog_open: true,
    });
  };
  handleAppParameterSetClick = (key: string) => {
    for (const p of this.state.app_parameter_table_bodies) {
      if (p.key.value === key) {
        this.setState({
          app_parameter_dialog_fields: {
            key: p.key.value,
            name: p.name,
            description: p.description,
            default_value: p.default_value,
            input_type: p.input_type,
            list_items: p.list_items,
            required: p.required === 'true',
            validate_regex: p.validate_regex,
            is_hidden: p.is_hidden === 'true',
          },
          is_app_parameter_dialog_open: true,
        });
      }
    }
  };
  handleAppParameterDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されている項目を削除します。\n本当によろしいですか？',
      () => this.handleAppParameterDeleteOKClick(this.state.selected_app_parameter_table_bodies),
      this.handleAppParameterDeleteCancelClick,
      undefined,
    );
  };
  handleAppParameterDeleteOKClick = async (selected_datas: TableAppParameterType[]) => {
    const aft: TableAppParameterType[] = [];
    for (const p of this.state.app_parameter_table_bodies) {
      let f = true;
      for (const d of selected_datas) {
        if (d.key === p.key) {
          f = false;
        }
      }
      if (f) aft.push(p);
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_value: e.default_value,
        input_type: e.input_type,
        list_items: e.list_items,
        required: e.required === 'true',
        validate_regex: e.validate_regex,
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({
      selected_app_parameter_table_bodies: [],
      app_parameter_table_bodies: aft,
      app_parameters: aft2,
    });
  };
  handleAppParameterDeleteCancelClick = () => {
    // do nothing
  };
  handleAppParameterDialogOkClose = (e: React.MouseEvent<HTMLElement>, f: AppParameterDialogFields) => {
    let find = false;
    for (const p of this.state.app_parameter_table_bodies) {
      if (p.key.value === f.key) {
        p.name = f.name;
        p.description = f.description;
        p.default_value = f.default_value;
        p.input_type = f.input_type;
        p.list_items = f.list_items;
        p.required = f.required ? 'true' : 'false';
        p.validate_regex = f.validate_regex;
        p.is_hidden = f.is_hidden ? 'true' : 'false';
        find = true;
        break;
      }
    }
    const aft = [...this.state.app_parameter_table_bodies];
    if (!find) {
      aft.push({
        id: f.key,
        key: {
          value: f.key,
          onClick: () => this.handleAppParameterSetClick(f.key),
        },
        name: f.name,
        description: f.description,
        default_value: f.default_value,
        input_type: f.input_type,
        list_items: f.list_items,
        required: f.required ? 'true' : 'false',
        validate_regex: f.validate_regex,
        is_hidden: f.is_hidden ? 'true' : 'false',
      });
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_value: e.default_value,
        input_type: e.input_type,
        list_items: e.list_items,
        required: e.required === 'true',
        validate_regex: e.validate_regex,
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({
      app_parameter_table_bodies: aft,
      app_parameters: aft2,
      is_app_parameter_dialog_open: false,
    });
  };
  handleAppParameterDialogCancelClose = () => {
    this.setState({
      is_app_parameter_dialog_open: false,
    });
  };
  handleHeartbeatClick = (datas: TableHeartbeatType[]) => {
    this.setState({ selected_heartbeat_table_bodies: datas });
  };
  handleHeartbeatCreateClick = () => {
    this.setState({
      heartbeat_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_schedule: '',
        additionals: '',
        required: false,
        is_hidden: false,
      },
      is_heartbeat_dialog_open: true,
    });
  };
  handleHeartbeatSetClick = (key: string) => {
    for (const p of this.state.heartbeat_table_bodies) {
      if (p.key.value === key) {
        this.setState({
          heartbeat_dialog_fields: {
            key: p.key.value,
            name: p.name,
            description: p.description,
            default_schedule: p.default_schedule,
            additionals: p.additionals,
            required: p.required === 'true',
            is_hidden: p.is_hidden === 'true',
          },
          is_heartbeat_dialog_open: true,
        });
      }
    }
  };
  handleHeartbeatDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されている項目を削除します。\n本当によろしいですか？',
      () => this.handleHeartbeatDeleteOKClick(this.state.selected_heartbeat_table_bodies),
      this.handleHeartbeatDeleteCancelClick,
      undefined,
    );
  };
  handleHeartbeatDeleteOKClick = async (selected_datas: TableHeartbeatType[]) => {
    const aft: TableHeartbeatType[] = [];
    for (const p of this.state.heartbeat_table_bodies) {
      let f = true;
      for (const d of selected_datas) {
        if (d.key === p.key) {
          f = false;
        }
      }
      if (f) aft.push(p);
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_schedule: e.default_schedule,
        additionals: e.additionals,
        required: e.required === 'true',
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({
      selected_heartbeat_table_bodies: [],
      heartbeat_table_bodies: aft,
      heartbeats: aft2,
    });
  };
  handleHeartbeatDeleteCancelClick = () => {
    // do nothing
  };
  handleHeartbeatDialogOkClose = (e: React.MouseEvent<HTMLElement>, f: HeartbeatsDialogFields) => {
    let find = false;
    for (const p of this.state.heartbeat_table_bodies) {
      if (p.key.value === f.key) {
        p.name = f.name;
        p.description = f.description;
        p.default_schedule = f.default_schedule;
        p.additionals = f.additionals;
        p.required = f.required ? 'true' : 'false';
        p.is_hidden = f.is_hidden ? 'true' : 'false';
        find = true;
        break;
      }
    }
    const aft = [...this.state.heartbeat_table_bodies];
    if (!find) {
      aft.push({
        id: f.key,
        key: {
          value: f.key,
          onClick: () => this.handleHeartbeatSetClick(f.key),
        },
        name: f.name,
        description: f.description,
        default_schedule: f.default_schedule,
        additionals: f.additionals,
        required: f.required ? 'true' : 'false',
        is_hidden: f.is_hidden ? 'true' : 'false',
      });
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_schedule: e.default_schedule,
        additionals: e.additionals,
        required: e.required === 'true',
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({
      heartbeat_table_bodies: aft,
      heartbeats: aft2,
      is_heartbeat_dialog_open: false,
    });
  };
  handleHeartbeatDialogCancelClose = () => {
    this.setState({
      is_heartbeat_dialog_open: false,
    });
  };
  handleMainCancel = () => {
    history.push('/processes');
  };
  handleInstanceFormChange = (runtimeType: RuntimeType, ecsParameter: ProcessEcsparameter) => {
    this.setState({ runtime_type: runtimeType, ecs_parameter: ecsParameter });
  };
  handleMainFinish = async (process_id: string) => {
    const {
      tenant_id,
      process_name,
      app_module,
      app_class,
      runtime_type,
      ecs_parameter,
      input_sync,
      ui_visible,
      app_parameters,
      heartbeats,
      is_billing,
      process_type,
      unit_price,
      memo,
      order,
    } = this.state;
    if (!Number.isNaN(order) && !Number.isInteger(order)) {
      AlertDialog.show('オーダーを入力する場合は、整数でなければなりません');
      return;
    }
    if (
      !isNotOnlySpace(tenant_id) ||
      !isNotOnlySpace(process_name) ||
      !isNotOnlySpace(app_module) ||
      !isNotOnlySpace(app_class) ||
      !InstanceForm.validate(runtime_type, ecs_parameter)
    ) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }
    if (this.state.is_billing && this.state.process_type === '') {
      AlertDialog.show('プロセスタイプが選択されていません。');
      return;
    }
    const is_ecs = runtime_type === 'ECS';

    let processesIdPutData: RequestProcessesIdPut = {
      tenant_id,
      process_id,
      process_name,
      input_sync: toStringBoolean(input_sync),
      app_parameters,
      heartbeats,
      app_module,
      app_class,
      runtime_type,
      ui_visible: toStringBoolean(ui_visible),
      is_billing: toStringBoolean(is_billing),
      process_type,
      unit_price,
      memo,
      order: isValidNumber({ num: order, integer: true, allow0: true }) ? order : undefined,
    };
    if (is_ecs) {
      processesIdPutData = {
        ...processesIdPutData,
        ecs_image: ecs_parameter.image,
        ecs_cpu: ecs_parameter.cpu,
        ecs_memory: ecs_parameter.memory,
        ecs_gpu: ecs_parameter.gpu,
        ecs_cpu_arch: ecs_parameter.cpu_arch,
        ecs_compatibilities: ecs_parameter.compatibilities.join(),
        ecs_capacity_providers: ecs_parameter.capacity_providers.join(),
        ecs_vpc: ecs_parameter.vpc,
        ecs_security_group_name: ecs_parameter.security_group_name,
        ecs_max_tasks: ecs_parameter.max_tasks,
      };
    }

    processesIdPutAPI(processesIdPutData).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('プロセスの変更に成功しました');
        history.push('/processes');
      }
    });
  };

  getLabel = (v: string) => {
    if (v.startsWith('TRANSFER:')) {
      const no = parseInt(v.substr(9));
      return '入力ストリーム(' + (no + 1) + ') と同じ';
    } else {
      return v;
    }
  };
  render() {
    return (
      <>
        <Content>
          <FormContainer title='基本情報'>
            <InputComponent text='テナント'>
              <TenantSelectBox
                tenant_id={this.state.tenant_id}
                tenants={this.state.tenants}
                setTenantId={(tenant_id) =>
                  this.setState({
                    tenant_id: tenant_id,
                  })
                }
              />
            </InputComponent>
            <InputComponent text='プロセスID'>
              <InputBox title='プロセスID' disabled value={this.props.process.process_id} />
            </InputComponent>
            <InputComponent text='プロセス名' required>
              <InputBox
                placeholder='入力してください(必須)'
                value={this.state.process_name}
                onChange={(e) => this.setState({ process_name: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='テナント可視性' required>
              <SelectBox
                onChange={(e) => this.setState({ ui_visible: e.currentTarget.value === 'true' })}
                value={this.state.ui_visible ? 'true' : 'false'}
                datas={[
                  { name: 'チャンネルプロセス画面で選択可能', value: 'true' },
                  { name: 'チャンネルプロセス画面で選択できない', value: 'false' },
                ]}
              />
            </InputComponent>
            <InputComponent text='課金対象'>
              <CheckBoxArea>
                <CheckBoxWithText
                  text='設定する'
                  checked={this.state.is_billing}
                  onClick={() => this.setBilling(!this.state.is_billing)}
                />
              </CheckBoxArea>
            </InputComponent>
            {this.state.is_billing && (
              <InputComponent text='プロセスタイプ'>
                <SelectBox
                  onChange={(e) => this.handleBillingChange(e.currentTarget.value)}
                  value={this.state.process_type}
                  datas={convertProcessTypesToProcessSelectDatas()}
                />
              </InputComponent>
            )}
            <InputComponent text='メモ'>
              <TextAreaInput
                style={{ width: '100%' }}
                rows={1}
                title='メモ'
                placeholder='入力してください(任意)'
                value={this.state.memo}
                onChange={(e) =>
                  this.setState({
                    memo: e.currentTarget.value,
                  })
                }
              />
            </InputComponent>
            <InputComponent text='オーダー'>
              <InputNumberBox
                value={this.state.order}
                onChange={(value) =>
                  this.setState({
                    order: value,
                  })
                }
              />
            </InputComponent>
          </FormContainer>

          <FormContainer title='入出力情報' description='プロセスの入力と出力'>
            <InputComponent text='入力ストリーム数'>
              <SliderBox value={this.state.input_stream_count} min={0} max={10} disabled />
            </InputComponent>

            {this.state.input_stream_constraints.map((c, index) => {
              return (
                <div key={index}>
                  <InputComponent text={`入力ストリームデータの種類(${index + 1})`}>
                    <InputBox disabled value={c.data_type} />
                  </InputComponent>
                  <InputComponent text={`入力ストリームデータ番号の基準(${index + 1})`}>
                    <InputBox disabled value={c.data_number_type} />
                  </InputComponent>
                  <InputComponent text={`入力ストリームの通知(${index + 1})`}>
                    <InputBox disabled value={c.no_message ? '通知しない' : '通知する'} />
                  </InputComponent>
                  <InputComponent text={`最大数(${index + 1})`}>
                    <SliderBox value={c.max_repeats} min={1} max={10} disabled />
                  </InputComponent>
                </div>
              );
            })}
            <InputComponent text='入力データの同期'>
              <SelectBox
                onChange={(e) => this.setState({ input_sync: e.currentTarget.value === 'true' })}
                value={this.state.input_sync ? 'true' : 'false'}
                datas={[
                  { name: '同期する', value: 'true' },
                  { name: '同期しない', value: 'false' },
                ]}
              />
            </InputComponent>
            <InputComponent text='出力ストリーム数'>
              <SliderBox value={this.state.output_stream_count} min={0} max={10} disabled />
            </InputComponent>

            {this.state.output_stream_constraints.map((c, index) => {
              return (
                <div key={index}>
                  <InputComponent text={`出力ストリームデータの種類(${index + 1})`}>
                    <InputBox
                      title={`出力ストリームデータの種類(${index + 1})`}
                      disabled
                      value={this.getLabel(c.data_type)}
                    />
                  </InputComponent>
                  <InputComponent text={`出力ストリームデータ番号の基準(${index + 1})`}>
                    <InputBox
                      title={`出力ストリームデータ番号の基準(${index + 1})`}
                      disabled
                      value={this.getLabel(c.data_number_type)}
                    />
                  </InputComponent>
                  <InputComponent text={`出力ストリームデータの繰り返し(${index + 1})`}>
                    <InputBox
                      title={`出力ストリームデータの繰り返し(${index + 1})`}
                      disabled
                      value={String(c.repeat)}
                    />
                  </InputComponent>
                </div>
              );
            })}
          </FormContainer>

          <FormContainer title='アプリケーション情報' description='プロセスで実行されるアプリケーション'>
            <InputComponent text='テナンシー'>
              <RadioBox
                datas={[
                  { name: '専有(Stateful)', value: 'Stateful' },
                  { name: '共有(Stateless)', value: 'Stateless' },
                ]}
                selectedValue={this.state.processing_tenancy}
                disabled
              />
            </InputComponent>
            <InputComponent text='アプリモジュール名' required>
              <InputBox
                title='アプリモジュール名'
                placeholder='入力してください(必須)'
                value={this.state.app_module}
                onChange={(e) => this.setState({ app_module: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='アプリクラス名' required>
              <InputBox
                title='アプリクラス名'
                placeholder='入力してください(必須)'
                value={this.state.app_class}
                onChange={(e) => this.setState({ app_class: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='アプリパラメータ'>
              <AppParameterTable
                bodies={this.state.app_parameter_table_bodies}
                selected_bodies={this.state.selected_app_parameter_table_bodies}
                handleCheckClick={this.handleAppParameterClick}
                buttons={[
                  {
                    text: '削除',
                    onClick: this.handleAppParameterDeleteClick,
                    is_white: true,
                    is_margin_right: true,
                  },
                  {
                    text: '追加',
                    onClick: this.handleAppParameterCreateClick,
                  },
                ]}
              />
            </InputComponent>
            <InputComponent text='ハートビート'>
              <HeartbeatTable
                bodies={this.state.heartbeat_table_bodies}
                selected_bodies={this.state.selected_heartbeat_table_bodies}
                handleCheckClick={this.handleHeartbeatClick}
                buttons={[
                  {
                    text: '削除',
                    onClick: this.handleHeartbeatDeleteClick,
                    is_white: true,
                    is_margin_right: true,
                  },
                  {
                    text: '追加',
                    onClick: this.handleHeartbeatCreateClick,
                  },
                ]}
              />
            </InputComponent>
          </FormContainer>

          <InstanceForm
            edit_mode
            processing_tenancy={this.state.processing_tenancy}
            runtimeType={this.state.runtime_type}
            ecsParameter={this.state.ecs_parameter}
            onChange={this.handleInstanceFormChange}
          />
          {this.state.is_app_parameter_dialog_open && (
            <AppParameterDialog
              title='アプリパラメータ'
              isOpen={this.state.is_app_parameter_dialog_open}
              fields={this.state.app_parameter_dialog_fields}
              isKeyFix={this.state.app_parameter_dialog_fields.key !== ''}
              handleOkClose={this.handleAppParameterDialogOkClose}
              handleCancelClose={this.handleAppParameterDialogCancelClose}
            />
          )}
          {this.state.is_heartbeat_dialog_open && (
            <HeartbeatsDialog
              title='ハートビート'
              isOpen={this.state.is_heartbeat_dialog_open}
              fields={this.state.heartbeat_dialog_fields}
              isKeyFix={this.state.heartbeat_dialog_fields.key !== ''}
              handleOkClose={this.handleHeartbeatDialogOkClose}
              handleCancelClose={this.handleHeartbeatDialogCancelClose}
            />
          )}
        </Content>
        <Footer>
          <RoundedButton onClick={this.handleMainCancel} text='戻る' is_white is_margin_right />
          <RoundedButton
            onClick={() => this.handleMainFinish(this.props.process.process_id)}
            text='更新'
            disabled={
              isNotOnlySpace(this.state.process_name) &&
              isNotOnlySpace(this.state.app_module) &&
              isNotOnlySpace(this.state.app_class) &&
              InstanceForm.validate(this.state.runtime_type, this.state.ecs_parameter)
                ? false
                : true
            }
          />
        </Footer>
      </>
    );
  }
}

// -- styled components --
