// この中にフォームを入れていくコンテナ

// -- basic library --
import React from 'react';
// import styled from 'styled-components';
import { Card, Elevation } from '@blueprintjs/core';

interface FormContainerParam {
  title?: string;
  description?: string;
}
export const FormContainer: React.FC<FormContainerParam> = (param) => {
  return (
    <Card style={{ padding: '15px', marginBottom: '15px' }} elevation={Elevation.ONE}>
      {(param.title || param.description) && (
        <div
          style={{
            margin: '-15px -15px 15px',
            padding: 'inherit',
            borderBottom: '1px solid #10161a26',
          }}
        >
          {param.title && <h2 style={{ margin: 0 }}>{param.title}</h2>}
          {param.description && <p style={{ margin: '5px 0 0 0' }}>{param.description}</p>}
        </div>
      )}
      {param.children}
    </Card>
  );
};
