import { ISO8601 } from './ISO8601';

export interface EdgeDeviceStatus {
  now: ISO8601;
  edge_device_heartbeat_at: ISO8601;
}
// nowとedge_device_heartbeat_atの値が5秒以内かどうかを判定する。
// 5秒以内であれば稼働中を、10秒以上経過している場合はオフラインを返します。

export const toEdgeDeviceStatusJP = (status: EdgeDeviceStatus | null) => {
  if (!status) return 'オフライン';

  const now_time = new Date(status.now).getTime();
  const heartbeat_time = new Date(status.edge_device_heartbeat_at).getTime();

  let status_word = '';
  if (heartbeat_time > now_time - 60000) {
    status_word = '稼働中';
  } else {
    status_word = 'オフライン';
  }
  return status_word;
};
