// -- basic library --
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { devicesIdGetAPI } from 'admin/api/device';
import Spinner from 'shared/components/atoms/Spinner';
import { BottomArea, Content } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { toSoracomStatusJP } from 'shared/models/SoracomStatus';
import { dateToYMDHMS } from 'shared/utils/converter/date';
interface Params extends RouteComponentProps<{ device_id: string }> {}

export interface TableChannelOverViewType {
  id: string;
  device_name: TableBodyUrlType;
  application_name: string;
  status: string;
  deployed_at: string;
  launched_at: string;
}

// -- external functions --

/**
 * テーブルの列の情報まとめたデータ
 * デバイス詳細テーブルは1行しかないため検索機能はつけない
 * */

const headers: TableHeaderType[] = [
  {
    id: 'device_name',
    label: 'デバイス名',
    sortable: true,
  },
  {
    id: 'launched_at',
    label: '起動日時',
    sortable: true,
  },
  {
    id: 'application_name',
    label: 'アプリ名',
    sortable: true,
  },
  {
    id: 'status',
    label: 'ステータス',
    sortable: true,
  },
  {
    id: 'deployed_at',
    label: 'デプロイ日時',
    sortable: true,
  },
];

// -- main component --
const DeviceBasicInformation: React.FC<Params> = (params) => {
  // local_states
  const [bodies, setBodies] = useState<TableChannelOverViewType[]>([]);
  const [selected_bodies, setSelectedBodies] = useState<TableChannelOverViewType[]>([]);

  const handleClick = (datas: TableChannelOverViewType[]) => {
    setSelectedBodies(datas);
  };

  const getDatas = async (device_id: string) => {
    const res = await devicesIdGetAPI({ id: device_id });
    if (res.status === 200) {
      const return_table_datas: TableChannelOverViewType[] = [];
      return_table_datas.push({
        id: res.data.device_id,
        device_name: {
          value: res.data.device_name,
          url: `/devices/${device_id}/edit`,
          color: 'rgb(57, 131, 141)',
        },
        application_name: res.data.soracom_status ? res.data.soracom_status.application_name : '',
        status: res.data.soracom_status ? toSoracomStatusJP(res.data.soracom_status) : '',
        deployed_at: dateToYMDHMS(res.data.soracom_status ? res.data.soracom_status.application_deployed_at : ''),
        launched_at: dateToYMDHMS(res.data.soracom_status ? res.data.soracom_status.application_launched_at : ''),
      });
      setBodies(return_table_datas);
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      getDatas(params.match.params.device_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      <BottomArea>
        {bodies !== undefined ? (
          <BaseTable
            bodies={bodies}
            headers={headers}
            table_name='devices-basicinfo'
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </Content>
  );
};
// -- styled components --

// -- finally export part --
export default DeviceBasicInformation;
