import { Branded, PretendDateType } from 'shared/models';

const correctTimeZoneReg = '((\\+[0-9]{2}:[0-9]{2})|Z)?';

/**
 * ISO8601の日付型
 * yyyy-MM-ddTHH:mm:ssZ
 */
export type ISO8601 = Branded<string, 'ISO8601'>;

export type TimeZoneName = 'JST' | 'UTC' | 'NONE';
const getTimeZone = (tz: string) => {
  switch (tz) {
    case 'JST':
      return '+09:00';
    case 'UTC':
      return 'Z';
    case 'NONE':
      return '';
    default:
      return '';
  }
};

export const toISO8601 = (date: PretendDateType, time_zone: TimeZoneName = 'JST') => {
  const tz = time_zone ? getTimeZone(time_zone) : '';
  const dt = new Date(date);
  const year = ('0000' + dt.getFullYear()).slice(-4);
  const month = ('00' + (dt.getMonth() + 1)).slice(-2);
  const day = ('00' + dt.getDate()).slice(-2);
  const hour = ('00' + dt.getHours()).slice(-2);
  const minute = ('00' + dt.getMinutes()).slice(-2);
  const second = ('00' + dt.getSeconds()).slice(-2);
  return `${year}-${month}-${day}T${hour}:${minute}:${second}${tz}` as ISO8601;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isISO8601 = (data: any): data is ISO8601 => {
  if (typeof data !== 'string') return false;

  const correctPattern = new RegExp(
    '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}' + correctTimeZoneReg + '$',
  );
  const match = data.match(correctPattern);
  return match !== null;
};
