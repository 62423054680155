// -- basic library --
import React, { ReactNode } from 'react';
import { Dialog, DialogProps } from '@blueprintjs/core';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

export interface PfDialogProps extends DialogProps {
  large?: boolean;
  /** 包含するdivエレメントに渡すプロパティを指定します。 */
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
  children?: ReactNode;
}
/**
 * 作成、詳細(更新)画面などのダイアログ
 */
export const PfDialog = (props: PfDialogProps) => {
  return (
    <StyledDialog {...props}>
      <PfDialogContent {...props.contentProps}>{props.children}</PfDialogContent>
    </StyledDialog>
  );
};

export default PfDialog;

// -- styled components --

const StyledDialog = styled(Dialog)<{
  large?: boolean;
}>`
  ${(params) => (params.large ? 'height: 90vh;' : 'max-height: 90vh;')}
  width: ${(params) => (params.large ? '95vw' : '80vw')};
  max-width: ${(params) => (params.large ? '95vw' : '900px')};
  min-width: calc(${styles.dialog_video_width} + 30px * 2);
  background-color: ${colors.white};
  border-radius: 10px;
  .bp3-dialog-header {
    min-height: 60px;
    background-color: ${colors.gray};
    .bp3-button.bp3-minimal {
      border: 1px solid ${colors.gray};
      border-radius: 50%;
    }
  }
`;

const PfDialogContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
`;
