import { ISO8601 } from './ISO8601';

export interface SoracomStatus {
  device_name: string;
  device_group_id?: string;
  device_group_name?: string;
  subscriber_group_id?: string;
  subscriber_group_name?: string;
  sim_is_online: boolean;
  is_powered_on: boolean;
  application_is_working: boolean;
  last_modified_time: number;
  application_name: string;
  application_version: string;
  application_deployed_at: ISO8601;
  application_launched_at: ISO8601;
  updated_at: ISO8601;
}

export const toSoracomStatusJP = (status: SoracomStatus | null) => {
  if (!status) return 'オフライン';

  let status_word = '';
  if (status.application_is_working) {
    status_word = '稼働中';
  } else if (status.sim_is_online) {
    status_word = 'オンライン';
  } else {
    status_word = 'オフライン';
  }
  return status_word;
};
