import { ReactNode } from 'react';
import { Alert, AlertProps } from '@blueprintjs/core';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// ボタンがdisabledの時のデザイン
const disabled_style = (disabled?: boolean) => {
  return `
    background: ${disabled ? colors.disabled_background_color : colors.component_main_color} !important; 
    color: ${disabled ? colors.main_font_color : colors.white} !important; 
    cursor: ${disabled ? 'not-allowed' : 'pointer'} !important;
  `;
};

interface PfAlertProps extends AlertProps {
  body_width?: string;
  disabled_first_button?: boolean;
  disabled_last_button?: boolean;
  children?: ReactNode;
}

// blueprintjsのAlertコンポーネントは、キャンセルのボタンが1番目、確認のボタンが2番目(キャンセルがない時は、確認が1番目)に配置されている。
// これを利用して、ボタンのdisabled時のデザインを変える。
const PfAlert = styled((props: PfAlertProps) => <Alert {...props} />)`
  .bp3-alert-body {
    width: ${(p) => (p.body_width ? p.body_width : 'auto')};
  }
  .bp3-alert-contents {
    width: ${(p) => (p.body_width ? p.body_width : 'auto')};
    white-space: pre-wrap;
  }
  .bp3-alert-footer {
    .bp3-button {
      border-radius: 25px;
      width: ${styles.super_small_button_width};
      font-family: inherit;
      font-weight: 500;
      border: none;
      cursor: pointer;
      color: ${colors.white};
      background: ${colors.component_main_color};
      :first-child {
        ${(p) => disabled_style(p.disabled_first_button)};
      }
      :last-child {
        ${(p) => disabled_style(p.disabled_last_button)};
      }
    }
  }
`;

export default PfAlert;
