// -- basic library --
import React, { useEffect, useState } from 'react';
import { CachedParameters, Parameter, parametersNameDeleteAPI } from 'admin/api/parameters';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import ParameterTable, { TableParameterType } from './ParameterTable';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';

// -- main component --
const ParametersTopPage: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableParameterType[]>([]);
  const [table_bodies, setTableBodies] = useState<TableParameterType[] | undefined>(undefined);

  // -- handlers --
  const handleClick = (datas: TableParameterType[]) => {
    setSelectedBodies(datas);
  };
  const loadTableBodies = async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    const items: Parameter[] = await new CachedParameters({}).get();

    const return_table_datas: TableParameterType[] = [];
    for (let i = 0; i < items.length; i++) {
      const return_top_table_data: TableParameterType = {
        id: items[i].parameter_name,
        parameter_name: {
          value: items[i].parameter_name,
          url: `/parameters/${items[i].parameter_name}/set`,
          color: 'rgb(57, 131, 141)',
        },
        value: items[i].value,
        status: items[i].status,
        created_at: dateToYMDHMS(items[i].created_at),
        updated_at: dateToYMDHMS(items[i].updated_at),
        deleted_at: dateToYMDHMS(items[i].deleted_at ?? ''),
      };

      return_table_datas.push(return_top_table_data);
    }
    setTableBodies(return_table_datas);
  };

  const handleCreateClick = () => {
    history.push(`/parameters/create`);
  };

  const handleOKClick = async (selected_datas: TableParameterType[]) => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_datas.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return parametersNameDeleteAPI({ parameter_name: sd.parameter_name.value });
        }),
      );
      if (has_deleted_flag) {
        AlertDialog.show(`状態が「DELETED」のデータは削除できませんでした`);
      }
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/parameters');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているパラメータを削除します。\n本当によろしいですか？',
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, []);

  // -- render part --
  return (
    <PageWholeArea data-testid='Parameters'>
      <TopArea>
        <Title text='パラメーター' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規パラメータ追加'
          style={{ marginRight: styles.interval_narrow_margin }}
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <ParameterTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたパラメータを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default ParametersTopPage;
