import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { BaseIconProps, BaseIcon } from './BaseIcon';

/**
 * ピンを表すアイコンです。
 */
export const PinIcon = memo(function PinIcon(props: Partial<BaseIconProps>) {
  return <BaseIcon {...props} icon={IconNames.PIN} />;
});
