/**
 * Tableauの埋め込みurlをテストするようの画面
 */
import { tableauJwtGetAPI } from 'admin/api/tableau';
import { useEffect, useState } from 'react';
import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import { TableauAuthoringViz } from 'shared/components/atoms/TableauComponent';

const Tableau = () => {
  // -- render part --
  const [token, setToken] = useState<string>();

  const loadJwtToken = async () => {
    const res = await tableauJwtGetAPI();
    if (res.status === 200) {
      setToken(res.data.token);
    }
  };
  useEffect(() => {
    loadJwtToken();
  }, []);

  return (
    <PageWholeArea>
      <Title text='tableau' />
      <TableauAuthoringViz
        src='https://prod-apnortheast-a.online.tableau.com/t/platformtableau/authoring/001/Sheet1'
        token={token}
      />
    </PageWholeArea>
  );
};

export default Tableau;
