import { RequestProcessesIdPut, RequestProcessesPost } from 'admin/api/processes';
import { toStringBoolean } from 'shared/models/StringBoolean';

export const processesPostAPIFormAppender = (form: FormData, params: RequestProcessesPost) => {
  const { input_stream_constraints, output_stream_constraints, app_parameters, heartbeats, ...remain } = params;
  // input_stream_constraints
  form.append('input_stream_count', String(params.input_stream_constraints.length));
  input_stream_constraints.forEach((isc, i) => {
    const base_key = `input_stream_constraints[${i}]`;
    form.append(`${base_key}.data_type`, isc.data_type);
    form.append(`${base_key}.data_number_type`, isc.data_number_type);
    form.append(`${base_key}.max_repeats`, String(isc.max_repeats));
    form.append(`${base_key}.no_message`, toStringBoolean(isc.no_message));
  });

  // output_stream_constraints
  form.append('output_stream_count', String(params.output_stream_constraints.length));
  output_stream_constraints.forEach((osc, i) => {
    const base_key = `output_stream_constraints[${i}]`;
    form.append(`${base_key}.data_type`, osc.data_type);
    form.append(`${base_key}.data_number_type`, osc.data_number_type);
    form.append(`${base_key}.repeat`, toStringBoolean(osc.repeat));
  });

  // app_parameters
  app_parameters?.forEach((ap, i) => {
    const base_key = `app_parameters[${i}]`;
    form.append(base_key + '.key', ap.key);
    form.append(base_key + '.name', ap.name);
    form.append(base_key + '.description', ap.description);
    form.append(base_key + '.default_value', ap.default_value);
    form.append(base_key + '.input_type', ap.input_type);
    form.append(base_key + '.list_items', ap.list_items);
    form.append(base_key + '.required', toStringBoolean(ap.required));
    form.append(base_key + '.validate_regex', ap.validate_regex);
    form.append(base_key + '.is_hidden', toStringBoolean(ap.is_hidden));
  });

  // heartbeats
  heartbeats?.forEach((h, i) => {
    const base_key = `heartbeats[${i}]`;
    form.append(base_key + '.key', h.key);
    form.append(base_key + '.name', h.name);
    form.append(base_key + '.description', h.description);
    form.append(base_key + '.default_schedule', h.default_schedule);
    form.append(base_key + '.additionals', h.additionals);
    form.append(base_key + '.required', toStringBoolean(h.required));
    form.append(base_key + '.is_hidden', toStringBoolean(h.is_hidden));
  });
  // 残りのパラメータ
  for (const [key, value] of Object.entries(remain)) {
    if (typeof value === 'number') {
      form.append(key, String(value));
    } else if (typeof value === 'string') {
      form.append(key, value);
    }
  }
};

export const processesIdPutAPIFormAppender = (form: FormData, params: Omit<RequestProcessesIdPut, 'process_id'>) => {
  const { app_parameters, heartbeats, ...remain } = params;

  // app_parameters
  app_parameters?.forEach((ap, i) => {
    const base_key = `app_parameters[${i}]`;
    form.append(base_key + '.key', ap.key);
    form.append(base_key + '.name', ap.name);
    form.append(base_key + '.description', ap.description);
    form.append(base_key + '.default_value', ap.default_value);
    form.append(base_key + '.input_type', ap.input_type);
    form.append(base_key + '.list_items', ap.list_items);
    form.append(base_key + '.required', toStringBoolean(ap.required));
    form.append(base_key + '.validate_regex', ap.validate_regex);
    form.append(base_key + '.is_hidden', toStringBoolean(ap.is_hidden));
  });

  // heartbeats
  heartbeats?.forEach((h, i) => {
    const base_key = `heartbeats[${i}]`;
    form.append(base_key + '.key', h.key);
    form.append(base_key + '.name', h.name);
    form.append(base_key + '.description', h.description);
    form.append(base_key + '.default_schedule', h.default_schedule);
    form.append(base_key + '.additionals', h.additionals);
    form.append(base_key + '.required', toStringBoolean(h.required));
    form.append(base_key + '.is_hidden', toStringBoolean(h.is_hidden));
  });
  // 残りのパラメータ
  for (const [key, value] of Object.entries(remain)) {
    if (typeof value === 'number') {
      form.append(key, String(value));
    } else if (typeof value === 'string') {
      form.append(key, value);
    }
  }
};
