import { PretendDateType } from 'shared/models';
import { MailAdress } from 'shared/models/MailAdress';
import { toNaNOrNumber } from 'shared/utils/converter';
import { escapeRegExp } from '../else/escapeRegExp';
import { isDateString } from 'shared/models/DateString';
import { isTimeString } from 'shared/models/TimeString';
import { WidgetSetting } from 'user/api/dashboardsWidgets';
import { ProcessAppParameter } from 'admin/api/processes';
import { StreamDataNumberType } from 'shared/models/StreamDataNumberType';

export const isNotOnlySpace = (str: string) => {
  return Boolean(!str.match(/^\s*$/));
};

/** valueがmin <= value <= maxならばtrue, それ以外ならばfalse**/
export const isBetweenRange = (value: number, min: number, max: number) => value >= min && value <= max;

/***
 * アラーム状態を決定するまでの評価期間をチェックする
 * ***/
export const isEvaluationPeriod = (params: { is_timestamp: boolean; evaluation_period: string | number }) => {
  const nep = params.evaluation_period === '' ? NaN : Number(params.evaluation_period);
  if (!params.is_timestamp) {
    return true;
  }
  if (nep > 0 && isBetweenRange(nep, 1, 3600)) {
    return true;
  }
  return false;
};

/***
 * 評価期間のデータポイントの集約期間をチェックする
 * ***/
export const isAggregationPeriod = (params: {
  is_timestamp: boolean;
  evaluation_period: string | number; // number
  aggregation_period: string | number; // number
}) => {
  const { is_timestamp, evaluation_period, aggregation_period } = params;
  const nep: number = evaluation_period === '' ? NaN : Number(evaluation_period);
  const nap: number = aggregation_period === '' ? NaN : Number(aggregation_period);
  if (!is_timestamp) {
    return true;
  }
  if (
    isValidNumber({
      num: nep,
      positive: true,
    }) &&
    isValidNumber({
      num: nap,
      positive: true,
    }) &&
    isDivisor(nap, nep)
  ) {
    return true;
  }
  return false;
};

/***
 * 滞留時間をチェックする
 * ***/
export const isStayingTime = (params: { is_check: boolean; staying_time: string | number | undefined }) => {
  const nep = params.staying_time === '' ? NaN : Number(params.staying_time);
  if (!params.is_check) {
    return true;
  }
  if (nep > 0 && isBetweenRange(nep, 1, 86400)) {
    return true;
  }
  return false;
};

export const isJsonPath = (json_path: string) => {
  return Boolean(json_path.match(/^(\$)+.*/));
};

/***
 * 整数かどうか
 * ***/
export const isInteger = (num: number) => {
  const flag1 = Number.isInteger(num);
  return flag1;
};

/***
 * 数値が正かどうか
 * allow0がtrueの時は0も許す
 * ***/
export const isPositiveNumber = (num: number, allow0 = false) => {
  const flag1 = allow0 ? num >= 0 : num > 0;
  return flag1;
};

/***
 * 数値をチェックする関数
 * ***/

export const isValidNumber = (p: {
  num: string | number;
  positive?: boolean; // 正の数か
  allow0?: boolean; // 0を許すか
  integer?: boolean; // 整数か
  allowNaN?: boolean; // NaNを許すか
}) => {
  const nnum: number = +p.num;
  // 正の数か(0を許す時はallow0をtrueにする)
  const flag1 = p.positive ? isPositiveNumber(nnum, p.allow0) : true;
  const flag2 = p.integer ? isInteger(nnum) : true;
  const flag3 = p.allowNaN ? true : !Number.isNaN(nnum);
  return flag1 && flag2 && flag3;
};

export const isMailAddressOptionally = (mail_address: string | null) => {
  // nullの時は省略するのでtrue
  if (mail_address === null) {
    return true;
  }
  // 空白のみの時省略するのでtrue
  if (!isNotOnlySpace(mail_address)) {
    return true;
  }
  // メールのフォーマットが正しい時はtrue(送信する)
  if (isValidEmailFormat(mail_address)) {
    return true;
  }
  // 入力しているのにフォーマットを満たしていない時はfalse
  return false;
};

export const isStreamingReception = (streaming_endpoint: string | null) => {
  return typeof streaming_endpoint === 'string' && streaming_endpoint.length >= 1;
};

export const isTimeStamp = (data_number_type: StreamDataNumberType | undefined) => {
  return data_number_type === 'TIMESTAMP';
};
/***
 * 複数のメールアドレスをチェックする
 * ***/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMailAddresses = <T = Record<string, any>>(mail_addresses: MailAdress<T>[]) => {
  for (let i = 0; i < mail_addresses.length; i++) {
    if (!isMailAddressOptionally(mail_addresses[i].mail_address)) {
      return false;
    }
  }
  return true;
};

/**
 * 全てが存在する or 全てがundefinedの時はtrue
 * @param args
 * @returns boolean
 */
export const isAllOrNothing = <T>(...args: T[]) => {
  // 全てのargがundefined or nullでない
  let all_exist_flag = true;
  // 全てのargがundefined or null
  let all_undefined_flag = true;
  args.forEach((arg) => {
    if (arg === undefined || arg === null) {
      all_exist_flag = all_exist_flag && false;
    } else {
      all_undefined_flag = all_undefined_flag && false;
    }
  });
  // どちらかがtrueであればtrue
  return all_exist_flag || all_undefined_flag;
};

/***
 * 配信の遅延をチェックする関数
 * ***/
export const isValidDelaySeconds = (delay_second: string | number): boolean => {
  if (typeof delay_second === 'string') {
    const ds: number = +delay_second;
    // 何も入力されていない時はtrue(省略可能なので)
    if (delay_second.length <= 0) {
      return true;
    }
    return isBetweenRange(ds, 0, 900);
  } else if (typeof delay_second === 'number') {
    // 省略可能
    if (isNaN(delay_second)) {
      return true;
    }
    return isBetweenRange(delay_second, 0, 900);
  }
  return false;
};

export const isDivisor = (divisor: number, dividend: number) => {
  if (dividend % divisor === 0) {
    return true;
  } else {
    return false;
  }
};

/***
 * 評価閾値をチェックする関数
 * ***/
export const isValidEvaluateConditionValues = (
  evaluate_condition_operator: string,
  evaluate_condition_value1: string | number,
  evaluate_condition_value2: string | number,
) => {
  const ecv1: number = evaluate_condition_value1 === '' ? NaN : Number(evaluate_condition_value1);
  const ecv2: number = evaluate_condition_value2 === '' ? NaN : Number(evaluate_condition_value2);
  if (evaluate_condition_operator === '') {
    return false;
  } else if (evaluate_condition_operator === 'Between') {
    if (
      isValidNumber({
        num: ecv1,
        positive: true,
      }) &&
      isValidNumber({
        num: ecv2,
        positive: true,
      }) &&
      ecv1 < ecv2
    ) {
      return true;
    }
  } else {
    if (
      isValidNumber({
        num: ecv1,
        positive: true,
      })
    ) {
      return true;
    }
  }
  return false;
};

/***
 * アラーム状態を決定するまでの評価期間が有効かを調べる
 * ***/

export const isValidEvaluationPeriod = (p: { is_timestamp: boolean; evaluation_period: string | number }) => {
  const nep = p.evaluation_period === '' ? NaN : Number(p.evaluation_period);
  if (!p.is_timestamp) {
    return true;
  }
  if (nep > 0 && isBetweenRange(nep, 1, 3600)) {
    return true;
  }
  return false;
};

export const isValidMetricFill = (fill: string) => {
  if (fill === 'True' || fill === 'False') {
    return true;
  }
  return false;
};

export const isValidJsonpathGroupId = (jsonpath_group_id: string | undefined) => {
  return jsonpath_group_id !== undefined;
};
/**
 * 指定のaccept(image/jpegのようなもの)をtypeが満たしている時はtrue(acceptが無効な形の時もtrue)
 * それ以外がfalse
 *
 * **/
export const isMIMEtype = (type: string, accept?: string) => {
  const valid_accept = /^.+\/.+$/gi;
  // 有効なMIMEタイプでないときは、acceptが指定されたなかったとして、true
  // 他にも、*/*の時は全て許すのでtrue
  if (!accept || !type.match(valid_accept) || accept === '*/*') {
    return true;
  }
  if (accept.match(/^.+\/\*$/gi)) {
    // image/*のようなとき
    const mime = accept.split('/')[0];
    const reg = new RegExp('^' + mime + '/.+$', 'gi');
    return Boolean(type.match(reg));
  } else {
    // image/jpegのようなときは、完全一致(*/jpegのような無効な形はここの条件分岐に入るため、必然的に除外される)
    return type === accept;
  }
};

/***
 * undefinedの可能性のある数値をチェックする関数
 *
 * ***/
export const isNumberStrUndefined = (num: string | undefined, is_integer = true, passing_NaN = false) => {
  const n_num = toNaNOrNumber(num, true);
  // NaNを通すならNaNならtrue
  if (passing_NaN && Number.isNaN(n_num)) {
    return true;
  }
  // NaNは無効
  if (!passing_NaN && Number.isNaN(n_num)) {
    return false;
  }
  // 整数必要
  if (is_integer && !Number.isInteger(n_num)) {
    return false;
  }
  return true;
};

export const isValidPeriod = (num: number) => {
  if (num < 60 || num > 3600) return false;
  if (num % 60 !== 0) return false;

  return true;
};

export const isResolution = (resolution: number | null) => {
  if (resolution === null) return false;
  if (!Number.isInteger(resolution) || resolution <= 0) return false;

  return true;
};

/**
 *
 * @param pathname urlのパス名
 * @returns boolean pathnameが存在するか
 */
export const isExistedPathname = (pathname?: string | null): boolean => {
  if (typeof pathname !== 'string') return false;
  const correctPattern = new RegExp(`^${escapeRegExp('/')}.+$`);
  const match = pathname.match(correctPattern);
  return Boolean(match);
};

export const isValidEmailFormat = (email: string) => {
  return Boolean(email.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/));
};

/** data_number_fromとdata_number_toのチェックを行う**/
export const validateDataNumberFromTo = (p: {
  data_number_type?: string;
  stream_data_number_from: string;
  stream_data_number_to: string;
}): Error | void => {
  if (p?.data_number_type === 'TIMESTAMP') {
    if (!isNotOnlySpace(p.stream_data_number_from)) {
      throw new Error('開始対象データは必須です\n日付を選択してください\n*yyyy-MM-dd hh:mm:ss');
    }
    if (!isNotOnlySpace(p.stream_data_number_to)) {
      throw new Error('終了対象データは必須です\n日付を選択してください\n*yyyy-MM-dd hh:mm:ss');
    }

    if (new Date(p.stream_data_number_from).getTime() >= new Date(p.stream_data_number_to).getTime()) {
      // 範囲は0秒より大きい必要がある
      throw new Error('対象データは(左側) ＜ (右側)');
    }
  } else {
    if (
      !isValidNumber({
        num: p.stream_data_number_from,
        integer: true,
        positive: true,
      })
    ) {
      throw new Error('開始対象データは必須です。\nデータ番号を入力してください\n※1以上の整数');
    }
    if (
      !isValidNumber({
        num: p.stream_data_number_to,
        integer: true,
        positive: true,
      })
    ) {
      throw new Error('終了対象データは必須です。\nデータ番号を入力してください\n※1以上の整数');
    }
    if (Number(p.stream_data_number_from) >= Number(p.stream_data_number_to)) {
      throw new Error('対象データは(左側) ＜ (右側)');
    }
  }
};

export const isInvalidDate = (dt: Date): boolean => Number.isNaN(dt.getTime());

/**
 *
 * @param params {
 *  dt: チェックした日付
 *  start: 開始日付
 *  end: 終了日付
 * }
 * @returns boolean
 */
export const isDateWithinRange = (params: {
  dt: PretendDateType;
  start: PretendDateType;
  end: PretendDateType;
}): boolean => {
  const { dt, start, end } = params;
  // Date型に変換
  const dt_date = new Date(dt);
  const start_date = new Date(start);
  const end_date = new Date(end);
  // start以上でtrue
  let is_more_start = true;
  // end以下でtrue
  let is_below_end = true;
  if (isInvalidDate(dt_date)) return false;
  if (!isInvalidDate(start_date)) {
    is_more_start = dt_date.getTime() >= start_date.getTime();
  }
  if (!isInvalidDate(end_date)) {
    is_below_end = dt_date.getTime() <= end_date.getTime();
  }
  return is_more_start && is_below_end;
};

/** N次元の配列かつ、末尾の型が指定のtype(string or number)である時にtrue**/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNDimentionTypeArray = (data: any, n: number, type: 'string' | 'number') => {
  let dimention = 0;
  let lasttype = '';
  // 要素の型がArrayである限り、再起的に呼び続け数を数える。
  // 要素が型がArray出ない時は、lasttypeにその型を格納して終了
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchDimention = (d: any) => {
    if (d instanceof Array && d[0] !== undefined) {
      dimention += 1;
      searchDimention(d[0]);
    } else {
      lasttype = typeof d;
    }
  };
  searchDimention(data);
  return dimention === n && type === lasttype;
};
export default isNDimentionTypeArray;

/** 配列の長さを確かめる**/
export const isSpecifiedArrLength = (arr: number[], len: number): boolean => {
  return arr.length === len;
};

/***
 * 配列がnumber[N][2]である時にtrueとなる関数
 * ***/
export const isNumberSquareArrayOfSize2 = (polygon: number[][]): boolean => {
  // polygon内の全ての配列要素の長さが2の時はtrue, それ以外はfalse
  let isAllLen2 = true;
  polygon.forEach((pol) => {
    isAllLen2 = isAllLen2 && isSpecifiedArrLength(pol, 2);
  });
  return isAllLen2;
};

/**
 * number[2][2]の形であるか確かめる
 * **/
export const is2DLen2Array = (points: number[][]): boolean => {
  const flag1 = points.length === 2;
  const flag2 = isNumberSquareArrayOfSize2(points);
  return flag1 && flag2;
};

/**
 * number[n: min < m < max][2]の形であるか確かめる
 * **/
export const isRangeLen2Array = (points: number[][], min: number, max: number): boolean => {
  const flag1 = points.length >= min && points.length <= max;
  const flag2 = isNumberSquareArrayOfSize2(points);
  return flag1 && flag2;
};

export const isSelected = (selected_number: number) => selected_number > 0;

export const isNotSelected = (selected_number: number) => selected_number === 0;

/**
 * 選択されている & 選択されているデータのステータスがDELETEDでない時True
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSelectedAndActive = (data_items_with_status: Record<string, any>[]) => {
  if (data_items_with_status.length === 0) return false;
  if (data_items_with_status.find((v) => v.status === 'DELETED')) return false;

  return true;
};

export const isSelected1 = (selected_number: number) => selected_number === 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBlob = (data: any): data is Blob => typeof data?.size === 'number' && typeof data?.type === 'string';
/**
 * widget_settingの日付の値のデフォルト値がどうかを判断
 */
export const isDefaultWidgetSettingDateTime = (widget_setting?: WidgetSetting) => {
  return (
    widget_setting?.start_date === null &&
    widget_setting?.end_date === null &&
    widget_setting?.start_time === '00:00' &&
    widget_setting?.end_time === '23:59'
  );
};
/**
 * widget_settingの日付の値が登録されていれば固定ラベルが必要と判断
 */
export const isRequiredFixedLabel = (widget_setting?: WidgetSetting) => {
  // defaultの値ならば登録されていない扱いにする
  if (isDefaultWidgetSettingDateTime(widget_setting)) return false;
  return (
    (isDateString(widget_setting?.start_date) && isDateString(widget_setting?.end_date)) ||
    (isTimeString(widget_setting?.start_time) && isTimeString(widget_setting?.end_time))
  );
};

export const isAppParameterJsonpath = (
  app_parameter: { [key: string]: string },
  process_app_parameters?: ProcessAppParameter[],
) => {
  let flag = true;
  if (process_app_parameters === undefined) return flag;
  for (let i = 0; i < process_app_parameters.length; i++) {
    if (process_app_parameters[i].key !== 'jsonpath_group' || !process_app_parameters[i].required) continue;
    if (!app_parameter.jsonpath_group) {
      flag = false;
      break;
    }
  }
  return flag;
};
