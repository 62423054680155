import { isReactElement } from 'shared/models/ReactElement';
import { isDateWithinRange } from 'shared/utils/is';
import {
  TableBodyType,
  TableHeaderType,
  TableBodyClickType,
  TableBodyMultipleValueType,
  TableBodyUrlType,
} from './type';
import { Search, SearchRequiredItem, isSearchType } from 'shared/models/Search';

// ---- TableのBodyの型判定関数群 ----
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTableBodyUrlType = (data: any): data is TableBodyUrlType =>
  data instanceof Object && typeof data.value === 'string' && typeof data.url === 'string';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTableBodyClickType = (data: any): data is TableBodyClickType =>
  data instanceof Object && typeof data.value === 'string' && data.onClick;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTableBodyMultipleValueType = (data: any): data is TableBodyMultipleValueType<any> =>
  data instanceof Object &&
  typeof data.value !== 'undefined' &&
  typeof data.available_value === 'string' &&
  (typeof data.displayable_value === 'string' || isReactElement(data.displayable_value));

// --------------------------------

/**
 * bodyの型に応じて、検索・ソートに利用可能な値を返却する
 * 対応外の型は空文字列を返却
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAvailableTableBodyValue = (body_data: any): string | number => {
  if (typeof body_data === 'string') {
    return body_data;
  } else if (typeof body_data === 'number') {
    return body_data;
  } else if (Array.isArray(body_data) && (typeof body_data[0] === 'string' || typeof body_data[0] === 'number')) {
    return body_data.join(' ');
  } else if (isTableBodyUrlType(body_data)) {
    return body_data.value;
  } else if (isTableBodyClickType(body_data)) {
    return body_data.value;
  } else if (isTableBodyMultipleValueType(body_data)) {
    return body_data.available_value;
  }
  return '';
};

/**
 * 検索方法に応じて、検索条件に合致するかを判断する
 * @param body
 * @param search
 * @returns boolean
 */
export const isSearchTargetBody = (body: TableBodyType, search: Search): boolean => {
  const { search_type, search_key, value, value2, perfect_match = false } = search;
  const body_data = body[search_key] ?? '';
  // 指定のbody_dataを参照可能な値(string)にして取得
  const body_data_value = String(getAvailableTableBodyValue(body_data));
  if (search_type === 'name') {
    // 検索値が空なら検索を行わずに全て返す
    if (!value) return true;
    const lowered_value = value.toLowerCase();
    const lowered_body_value: string = body_data_value.toLowerCase();
    /**
     * perfect_match = true -> 完全一致
     * perfect_match = false -> 部分一致
     */
    return perfect_match ? lowered_value === lowered_body_value : lowered_body_value.indexOf(lowered_value) !== -1;
  } else if (search_type === 'datetime') {
    // 検索値が空なら検索を行わずに全て返す
    if (!value && !value2) return true;
    return isDateWithinRange({ dt: body_data_value, start: value, end: value2 ?? '' });
  }
  return true;
};

// bodiesのデータからidの配列を生成する関数
export const getBodiesIds = (bodies: TableBodyType[]) => {
  const bodies_ids = bodies.map((body) => {
    return body.id;
  });
  return bodies_ids;
};

// 2つの配列を重複を消してマージする
export const mergeArray = (bodies1: TableBodyType[], bodies2: TableBodyType[]) => {
  const return_bodies: TableBodyType[] = [];
  const tmp_bodies = [...bodies1, ...bodies2];
  for (let i = 0; i < tmp_bodies.length; i++) {
    const id = tmp_bodies[i].id;
    if (getBodiesIds(return_bodies).indexOf(id) !== -1) continue;
    return_bodies.push(tmp_bodies[i]);
  }
  return return_bodies;
};

// ボディーに表示するデータを得る関数
export const getDisplayBody = (body: TableBodyType, ids: string[]) => {
  const return_body: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  } = {};
  // bodyの情報をheaderの並び順に変える && headerにないものはskip
  for (let i = 0; i < ids.length; i++) {
    const key = ids[i];
    return_body[key] = body[key] ?? '';
  }
  return return_body;
};

// a-Z順にソートする
export const getsortedDatas = (
  bodies: TableBodyType[],
  sort_key_to_reverse: { key: string; reverse: boolean } | null,
) => {
  if (sort_key_to_reverse === null) {
    return bodies;
  }
  const { key, reverse } = sort_key_to_reverse;
  /**
   * sort()は破壊的変更のため、スプレッド構文[...bodies]に対してsort()する
   */
  return [...bodies].sort((a, b) => {
    // bodyデータ(a, b)を比較可能な値として取得
    const a_value = getAvailableTableBodyValue(a[key]);
    const b_value = getAvailableTableBodyValue(b[key]);
    // 型が合わない場合は比較できないため1を返却
    if (typeof a_value !== typeof b_value) return 1;
    if (reverse) {
      return a_value < b_value ? -1 : 1;
    } else {
      return a_value < b_value ? 1 : -1;
    }
  });
};

interface CheckHeaderCheckedParams {
  selected_bodies: TableBodyType[];
  searched_bodies: TableBodyType[];
  bodies_per_page: number;
  selected_page_number: number;
}

// テーブルのヘッダーがチェックがどうか判断
export const checkHeaderChecked = ({
  searched_bodies,
  bodies_per_page,
  selected_bodies,
  selected_page_number,
}: CheckHeaderCheckedParams) => {
  const one_page_bodies = searched_bodies.slice(
    (selected_page_number - 1) * bodies_per_page,
    selected_page_number * bodies_per_page,
  );
  let required_true_lenth = one_page_bodies.length;
  for (let i = 0; i < one_page_bodies.length; i++) {
    if (
      selected_bodies.some((selected_body) => {
        return selected_body.id === one_page_bodies[i].id;
      })
    ) {
      required_true_lenth -= 1;
    }
  }
  return required_true_lenth <= 0 && one_page_bodies.length > 0 ? true : false;
};

// テーブルのページングテキストを取得
export const getTablePageNumberTop = (
  selected_page_number: number,
  bodies: TableBodyType[],
  body_display_number: number,
): string => {
  let return_text = '';
  const last_page = Math.ceil(bodies.length / body_display_number) === selected_page_number;
  let start_number = 1 + (selected_page_number - 1) * body_display_number;
  let end_number = last_page ? bodies.length : start_number - 1 + body_display_number;
  if (bodies.length <= 0) {
    start_number = 0;
    end_number = 0;
  }
  return_text = `${start_number}〜${end_number}`;
  return return_text;
};

export const getTablePageNumberBottom = (bodies: TableBodyType[]) => {
  return `${bodies.length}`;
};

export const getTablePageNumberEnd = (has_next?: boolean) => {
  const has_next_text = has_next ? '+' : '';
  return `件${has_next_text}`;
};

// BaseTableのTableHeaderType[] -> SearchRequiredItem[]
export const tableHeaderToSearchRequiredItems = (headers: TableHeaderType[]) => {
  const search_required_items: SearchRequiredItem[] = [];
  headers.forEach((header) => {
    if (header.search_props && isSearchType(header.search_props.type)) {
      search_required_items.push({
        key: header.id,
        name: header.label,
        type: header.search_props.type,
        default_display: header.search_props.default_display,
        is_api_search: header.search_props.is_api_search,
        required: header.search_props.required,
      });
    }
  });
  return search_required_items;
};
