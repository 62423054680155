import styles from 'shared/styles/styles';
import styled from 'styled-components';

export const SearchRow = styled.div`
  width: auto;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Header = styled.div`
  margin-bottom: ${styles.interval_narrow_margin};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  margin-top: ${styles.interval_margin};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const DynamicSearchParent = styled.div`
  width: calc(100% - 180px - 2 * ${styles.interval_x_narrow_margin} - 16px);
  margin-right: ${styles.interval_x_narrow_margin};
`;
