// -- basic library --
import React from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- type declaration --
interface Params {
  handleChangeClick1?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeClick2?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value1: string;
  value2: string;
  value2_disabled?: boolean;
  interval_text?: string;
  test_ids?: [string, string];
  placeholders?: [string, string];
  steps?: [number, number];
  types?: [string, string];
  mins?: [number | string | undefined, number | string | undefined];
  maxes?: [number | string | undefined, number | string | undefined];
  disabled?: boolean;
  value_texts?: [string, string];
  style?: React.CSSProperties;
}

// -- main component --

const InputDoubleBox: React.FC<Params> = (params) => {
  const {
    value1,
    value2,
    handleChangeClick1,
    handleChangeClick2,
    types,
    maxes,
    mins,
    steps,
    placeholders,
    value_texts,
    test_ids,
    interval_text = '〜',
    value2_disabled,
  } = params;
  // -- render part --
  return (
    <WholeArea>
      {value_texts ? (
        <TextArea>
          <ValueText>{value_texts[0] ?? ''}</ValueText>
          <IntervalText></IntervalText>
          <ValueText>{value_texts[1] ?? ''}</ValueText>
        </TextArea>
      ) : null}

      <InputArea>
        <InputBox
          placeholder={placeholders?.[0] ?? ''}
          type={types?.[0]}
          min={mins?.[0]}
          max={maxes?.[0]}
          step={steps?.[0]}
          data-testid={test_ids?.[0]}
          onChange={handleChangeClick1}
          value={value1}
          style={{ width: 'calc((100% - 40px) / 2)' }}
        />
        {!value2_disabled && (
          <>
            <IntervalText>{interval_text}</IntervalText>
            <InputBox
              placeholder={placeholders?.[1]}
              type={types?.[1]}
              min={mins?.[1]}
              max={maxes?.[1]}
              step={steps?.[1]}
              data-testid={test_ids?.[1]}
              onChange={handleChangeClick2}
              value={value2}
              style={{ width: 'calc((100% - 40px) / 2)' }}
            />
          </>
        )}
      </InputArea>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextArea = styled.div`
  width: 100%;
  height: 12px;
  margin-bottom: 3px;
  display: flex;
`;

const InputArea = styled.div`
  width: 100%;
  height: ${styles.input_component_height};
  display: flex;
  align-items: center;
`;

const IntervalText = styled.div`
  width: 20px;
  white-space: nowrap;
  text-align: center;
  margin: 20px 10px;
  font-size: ${styles.small_text_size};
  font-weight: ${styles.font_weight};
`;

const ValueText = styled.div`
  width: calc((100% - 40px) / 2);
  white-space: nowrap;
  font-size: 0.75em;
`;

// -- finally export part --

export default InputDoubleBox;
