import React from 'react';
import { ProgressBar, ProgressBarProps } from '@blueprintjs/core';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

/**
 * 進捗率などを表示したりするプログレスバー
 */
export interface PfProgressBarProps extends ProgressBarProps {
  width?: string;
  height?: string;
}
export default class PfProgressBar extends React.Component<PfProgressBarProps> {
  render() {
    const props: PfProgressBarProps = { ...this.props };
    const width: string = this.props.width || '50px';
    const height: string = this.props.height || '10px';
    // 最大を1、最小を0にする
    const value: number = this.props.value ? Math.min(1, Math.max(this.props.value, 0)) : 0;
    return <CustomProgressBar {...props} width={width} height={height} value={value} />;
  }
}

// -- styled components --
const CustomProgressBar = styled(ProgressBar)<{
  width: string;
  height: string;
}>`
  height: ${(p) => p.height};
  width: ${(p) => p.width};
  &.bp3-progress-bar .bp3-progress-meter {
    background-color: ${colors.component_main_color};
  }
`;
