// -- basic library --
import React, { useMemo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { usersPostAPI, RequestUsersPost } from 'admin/api/users';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace, isValidEmailFormat } from 'shared/utils/is';
import { useTenants } from 'admin/hooks/useTenants/useTenants';
import useObj from 'shared/hooks/useObj/useObj';
import RadioBox from 'shared/components/molecules/RadioBox';
import { TableauRole, tableau_role_radio_select_items } from 'shared/models/TableauRole';
import { Tenant } from 'admin/api/tenants';

// -- main component --

const UserCreate: React.FC = () => {
  // -- local states --
  const { tenants, tenants_record } = useTenants({});
  const { obj: form, updateObj: updateForm } = useObj<{
    user_name: string;
    mail_address: string;
    tenant_id: string;
    login_id: string;
    tableau_role: TableauRole;
  }>({ user_name: '', mail_address: '', tenant_id: '', login_id: '', tableau_role: 'Not-used' });
  const selected_tenant: Tenant | undefined = tenants_record[form.tenant_id];
  const use_tableau = useMemo(() => {
    return selected_tenant?.use_tableau;
  }, [selected_tenant]);

  const handleCancel = () => {
    history.push('/users');
  };

  const handleFinish = async () => {
    const { tenant_id, login_id, mail_address, user_name, tableau_role } = form;
    if (!isNotOnlySpace(tenant_id)) {
      AlertDialog.show('テナントは必須です');
      return;
    }
    if (!isNotOnlySpace(login_id)) {
      AlertDialog.show('ログインIDは必須です');
      return;
    }
    if (!isValidEmailFormat(mail_address)) {
      AlertDialog.show('メールアドレスが無効な型です。');
      return;
    }
    if (!isNotOnlySpace(user_name)) {
      AlertDialog.show('ユーザー名は必須です');
      return;
    }
    const user_post_data: RequestUsersPost = {
      tenant_id,
      login_id,
      mail_address,
      user_name,
      tableau_role: use_tableau ? tableau_role : undefined,
    };
    const res = await usersPostAPI(user_post_data);
    if (res.status === 200) {
      AlertDialog.show('ユーザーの追加に成功しました');
      history.push('/users');
    }
  };

  // -- render part --
  return (
    <PageWholeArea data-testid='UsersCreate'>
      <Breadcrumbs
        items={[
          { href: '/users', icon: IconNames.PERSON, text: 'ユーザー' },
          { icon: IconNames.CUBE_ADD, text: 'ユーザーの追加' },
        ]}
      />
      <Title text='ユーザーの追加' />
      <Content>
        <FormContainer title='テナント情報'>
          <InputComponent text='テナント' required>
            <SelectBox
              onChange={(e) => updateForm({ tenant_id: e.currentTarget.value })}
              value={form.tenant_id}
              datas={
                tenants?.map((d) => {
                  return {
                    name: d.tenant_id + ': ' + d.tenant_name,
                    value: d.tenant_id,
                  };
                }) ?? []
              }
              long
            />
          </InputComponent>
        </FormContainer>
        <FormContainer title='基本情報'>
          <InputComponent text='ログインID' required>
            <InputBox
              title='ログインID'
              placeholder='入力してください(必須)'
              value={form.login_id}
              minLength={1}
              onChange={(e) => updateForm({ login_id: e.currentTarget.value })}
            />
          </InputComponent>
          <InputComponent text='メールアドレス' required>
            <InputBox
              title='メールアドレス'
              placeholder='入力してください(必須)'
              value={form.mail_address}
              minLength={1}
              onChange={(e) => updateForm({ mail_address: e.currentTarget.value })}
            />
          </InputComponent>
          <InputComponent text='ユーザー名' required>
            <InputBox
              title='ユーザー名'
              placeholder='入力してください(必須)'
              value={form.user_name}
              minLength={1}
              onChange={(e) => updateForm({ user_name: e.currentTarget.value })}
            />
          </InputComponent>
          {use_tableau && (
            <InputComponent text='Tableau'>
              <RadioBox
                datas={tableau_role_radio_select_items}
                selectedValue={form.tableau_role ?? 'Not-used'}
                handleChangeClick={(value) => updateForm({ tableau_role: value as TableauRole })}
              />
            </InputComponent>
          )}
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} text='キャンセル' is_margin_right is_white />
          <RoundedButton onClick={handleFinish} text='登録' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default UserCreate;
