// -- basic library --
import React, { useState, useEffect } from 'react';

// -- external components --
import { Tooltip, Button, InputGroup } from '@blueprintjs/core';

// -- type declaration --
interface Params {
  placeholder: string;
  value: string;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// -- main component --

const SecurityBox: React.FC<Params> = (params) => {
  // -- local states --
  const [show, setShow] = useState(false);

  // -- integrated components --
  const toggleLockButton = (
    <Tooltip content={`${show ? 'Hide' : 'Show'}`}>
      <Button icon={show ? 'unlock' : 'lock'} intent="warning" minimal={true} onClick={() => setShow(!show)} />
    </Tooltip>
  );

  // -- onload function --
  useEffect(() => {
    setShow(false);
  }, [params.placeholder]); /* eslint-disable-line */

  // -- render part --
  return (
    <InputGroup
      placeholder={params.placeholder}
      rightElement={toggleLockButton}
      value={params.value}
      type={show ? 'text' : 'password'}
      onChange={params.onChange}
      data-testid={params.id}
    />
  );
};

// -- finally export part --

export default SecurityBox;
