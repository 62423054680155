/***
 * 請求機能画面にて画面操作当日の月日の取得
 * ***/ 
export const firstYearmonth = () => {
  const today = new Date();
  const year = String(today.getFullYear());
  // getMonthは0~11で返ってくるため+1する
  const month = ('00' + (today.getMonth() + 1)).slice(-2);
  const ym = year + month;
  return ym;
};

/***
 * 請求機能画面にて2023/4月以降の月日のリスト取得
 * ***/ 
export const yearmonthList = () => {
  const today = new Date()
  const year = String(today.getFullYear())
  // getMonthは0~11で返ってくるため+1する
  const month = ('00' + (today.getMonth() + 1)).slice(-2);
  const today_ym = year + month
  const yearmonth_list = ['202304']
  let defo_y = 2023
  let defo_m = 4
  let defo_ym = '202304'
  // 当日のyearmonthまでyearmonth_listに詰める
  while(today_ym != defo_ym){
    defo_m = defo_m + 1
    if(defo_m == 13){
      defo_m = 1
      defo_y = defo_y + 1
    }
    defo_ym = String(defo_y) + ('00' + defo_m).slice(-2);
    yearmonth_list.unshift(defo_ym)
  }
  return yearmonth_list
};

/***
 * 請求機能画面にて年月リストの画面表示変換
 * ***/ 
export const dateFormat = (yearmonth: string) => {
  return yearmonth.slice(0, 4) + '年' + yearmonth.slice(-2) + '月';
};