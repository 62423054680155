// 必須を示すアイコン（*記号）
import { memo, ReactNode } from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

const StyledRequiredIcon = styled.span`
  font-weight: normal;
  color: ${colors.red};
  margin-left: 4px;
  margin-top: 0px;
  font-size: ${styles.super_small_text_size};
  font-family: inherit;
`;

interface RequiredIconProps {
  children?: ReactNode;
}

export const RequiredIcon = memo(function RequiredIcon(props: RequiredIconProps) {
  return <StyledRequiredIcon title='この項目は必須です'>※{props.children}</StyledRequiredIcon>;
});

export default RequiredIcon;
