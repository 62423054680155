// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { tenantsPutAPI, tenantsIdGetAPI, Tenant, RequestTenantsIdPut } from 'admin/api/tenants';
import preloadGroup from 'admin/utils/preloadGroup';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styles from 'shared/styles/styles';
import { getSubDomain } from 'shared/utils/get';
import useObj from 'shared/hooks/useObj/useObj';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { tenantsIdPutValidator } from 'admin/utils/validators/tenants';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import TableauIdInputStatement from '../components/TableauIdInputStatement';
type Params = RouteComponentProps<{ tenant_id: string }>;

const initialTenantDomain = (tenant_domain: string) => {
  const sub_domain = getSubDomain();
  if (tenant_domain.endsWith(sub_domain)) {
    return tenant_domain.slice(0, -sub_domain.length);
  }
  return '';
};

/**
 * use_tableauがfalseかつ、initial_use_tableauがtrueの時はtableauユーザーが全て削除される旨をアラートする
 */
const needs_confirm = (use_tableau: boolean, initial_use_tableau?: boolean) => {
  return initial_use_tableau && !use_tableau;
};

// -- main component --

const TenantSet: React.FC<Params> = (params) => {
  // -- local states --
  const {
    obj: form,
    updateObj: updateForm,
    setObj: setForm,
  } = useObj<{
    tenant_name: string;
    tenant_domain: string;
    tenant_generator2_domain: string;
    use_tableau: boolean;
    initial_use_tableau?: boolean;
    tableau_id: string | null;
  }>({
    tenant_name: '',
    tenant_domain: '',
    tenant_generator2_domain: '',
    use_tableau: false,
    initial_use_tableau: undefined,
    tableau_id: null,
  });
  const [tenant, setTenant] = useState<Tenant | undefined>(undefined);
  const disabled_change_tableau_id = typeof tenant?.tableau_id === 'string';

  // -- handlers --

  const handleCancel = () => {
    history.push('/tenants');
  };

  const updateTableauIdentifier = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled_change_tableau_id) return;
    const { value } = e.currentTarget;
    if (!value) {
      updateForm({ tableau_id: null });
    } else {
      updateForm({ tableau_id: value });
    }
  };

  const loadTenantData = useCallback(async () => {
    const res = await tenantsIdGetAPI({ tenant_id: params.match.params.tenant_id });
    if (res.status === 200) {
      setTenant(res.data);
      setForm({
        tenant_name: res.data.tenant_name,
        tenant_domain: initialTenantDomain(res.data.tenant_domain),
        tenant_generator2_domain: res.data.tenant_generator2_domain ?? '',
        use_tableau: res.data.use_tableau,
        initial_use_tableau: res.data.use_tableau,
        tableau_id: res.data.tableau_id,
      });
    }
  }, [params.match.params.tenant_id, setForm]);

  const putApiFunc = async (request: RequestTenantsIdPut) => {
    const res = await tenantsPutAPI(request);
    if (res.status === 200) {
      AlertDialog.show('テナントの修正に成功しました');
      history.push('/tenants');
    }
  };

  const handleFinish = async (tenant_id: string) => {
    const { use_tableau, initial_use_tableau } = form;
    try {
      const request = tenantsIdPutValidator({
        ...form,
        tenant_id,
        disabled_change_tableau_id,
      });
      // confirmが必要な場合
      if (needs_confirm(use_tableau, initial_use_tableau)) {
        ConfirmDialog.show(
          '[確認]\ntableauを利用しない場合\n元々登録されている全tableauユーザーが削除されます。\nよろしいですか？',
          () => putApiFunc(request),
          undefined,
          undefined,
        );
        // confirmが必要でない場合
      } else {
        await putApiFunc(request);
      }
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
  };

  useEffect(() => {
    preloadGroup(loadTenantData());
  }, [loadTenantData]);

  // -- render part --
  return (
    <PageWholeArea data-testid='TenantSet'>
      <Breadcrumbs
        items={[
          { href: '/tenants', icon: IconNames.CONTROL, text: 'テナント' },
          {
            icon: IconNames.CUBE,
            text: tenant ? tenant.tenant_name : null,
          },
        ]}
      />
      <Title text='テナントの編集' />

      {tenant ? (
        <Content>
          <FormContainer title='基本情報'>
            <InputComponent text='テナントID'>
              <InputBox title='テナントID' value={tenant?.tenant_id ?? ''} disabled />
            </InputComponent>
            <InputComponent text='テナント名' required>
              <InputBox
                title='テナント名'
                placeholder='入力してください(必須)'
                value={form.tenant_name}
                minLength={1}
                onChange={(e) => updateForm({ tenant_name: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='テナントドメイン' required>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputBox
                  title='テナントドメイン'
                  placeholder='.と空白及び大文字入力はできません'
                  value={form.tenant_domain}
                  minLength={1}
                  onChange={(e) =>
                    updateForm({ tenant_domain: e.currentTarget.value.replace('.', '').trim().toLocaleLowerCase() })
                  }
                  style={{ width: 'calc(100% - 250px)' }}
                />
                <div style={{ width: '250px', marginLeft: styles.interval_x_narrow_margin }}>{getSubDomain()}</div>
              </div>
            </InputComponent>
            <InputComponent text='テナントGenerator2ドメイン'>
              <InputBox
                title='テナントGenerator2ドメイン'
                placeholder='入力してください(任意)'
                value={form.tenant_generator2_domain}
                onChange={(e) => updateForm({ tenant_generator2_domain: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='tableau'>
              <CheckBoxWithText
                text='利用する'
                checked={form.use_tableau}
                onClick={() => updateForm({ use_tableau: !form.use_tableau })}
              />
            </InputComponent>
            {form.use_tableau && (
              <InputComponent text='Tableau識別子' required>
                <InputBox
                  title='Tableau識別子'
                  placeholder=''
                  value={form.tableau_id ?? ''}
                  onChange={updateTableauIdentifier}
                  disabled={disabled_change_tableau_id}
                  maxLength={50}
                />
                <TableauIdInputStatement />
              </InputComponent>
            )}
          </FormContainer>
          <Footer>
            <RoundedButton onClick={handleCancel} text='戻る' is_margin_right is_white />
            <RoundedButton onClick={() => handleFinish(params.match.params.tenant_id)} text='更新' />
          </Footer>
        </Content>
      ) : (
        <Spinner />
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default TenantSet;
