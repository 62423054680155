// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
interface Params {
  bodies: TableDeviceType[];
  selected_bodies: TableDeviceType[];
  handleCheckClick: (datas: TableDeviceType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableDeviceType {
  id: string;
  device_id: TableBodyUrlType;
  device_name: string;
  tenant_id: string;
  device_type: string;
  device_group_name: string;
  subscriber_group_name: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'device_id',
    label: 'デバイスID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'device_name',
    label: 'デバイス名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'device_type',
    label: '種別',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'device_group_name',
    label: 'デバイスグループ名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'subscriber_group_name',
    label: 'SIMグループ名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'status',
    label: 'ステータス',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'created_at',
    label: '作成日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'updated_at',
    label: '更新日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'deleted_at',
    label: '削除日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
];

// -- main component --
const DeviceTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='devices'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default DeviceTable;
