import Axios from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { Query } from 'shared/models/Query';
import { getClient } from './base';

/*** エンティティ ***/

export interface CognitoSession {
  challenge_name: string;
  session: string;
  authentication_result: {
    access_token: string;
    expires_in: number;
    token_type: string;
    refresh_token: string;
  };
}

export const sample_cognito_session_1: CognitoSession = {
  challenge_name: 'string',
  session: 'session',
  authentication_result: {
    access_token: 'string',
    expires_in: 0,
    token_type: 'string',
    refresh_token: 'string',
  },
};

/*** [POST] /api/accounts/login ***/

export interface RequestAccountsLoginPost {
  login_id: string;
  password: string;
}

export const accountsLoginPostAPI = (params: RequestAccountsLoginPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/accounts/login`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<CognitoSession>(axios, path, query, form, method, sample_cognito_session_1);
};

/*** [POST] /api/accounts/refresh ***/

export interface RequestAccountsRefreshPost {
  refresh_token: string;
}

export const accountsRefreshPostAPI = (params: RequestAccountsRefreshPost) => {
  // クライアントを定義
  const axios = Axios.create({
    responseType: 'json',
  });

  // パス・メソッドを定義
  const path = `/api/accounts/refresh`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<CognitoSession>(axios, path, query, form, method, sample_cognito_session_1);
};

/*** [POST] /api/accounts/login/newpassword ***/

export interface RequestAccountsLoginNewpasswordPost {
  username: string; // これはUserテーブルのlogin_id
  new_password: string;
  session: string;
}

export const accountsLoginNewpasswordPostAPI = (params: RequestAccountsLoginNewpasswordPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/accounts/login/newpassword`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<CognitoSession>(axios, path, query, form, method, sample_cognito_session_1);
};

/*** [POST] /api/accounts/forgotpassword ***/

export interface RequestAccountsForgotpasswordPost {
  username: string; // これはUserテーブルのlogin_id
}

export const accountsForgotpasswordPostAPI = (params: RequestAccountsForgotpasswordPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/accounts/forgotpassword`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<null>(axios, path, query, form, method, null);
};

/*** [POST] /api/accounts/forgotpassword/confirm ***/

export interface RequestAccountsForgotpasswordConfirmPost {
  confirmation_code: string;
  username: string; // これはUserテーブルのlogin_id
  password: string;
}

export const accountsForgotpasswordConfirmPostAPI = (params: RequestAccountsForgotpasswordConfirmPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/accounts/forgotpassword/confirm`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<null>(axios, path, query, form, method, null);
};

/*** [PUT] /api/accounts/password ***/

export interface RequestAccountsPasswordPut {
  previous_password: string;
  next_password: string;
}

export const accountsPasswordPutAPI = (params: RequestAccountsPasswordPut) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/accounts/password`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<null>(axios, path, query, form, method, null);
};
