import React, { useState } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { parametersPostAPI, RequestParametersPost } from 'admin/api/parameters';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Title, Content, Breadcrumbs, Footer, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';

// -- main component --

const ParametersCreatePage: React.FC = () => {
  // -- local states --
  const [parameter_name, setParameterName] = useState<string>('');
  const [value, setValue] = useState<string>('');

  // -- handlers --

  const handleCancel = () => {
    history.push('/parameters');
  };

  const handleFinish = async () => {
    if (!isNotOnlySpace(parameter_name)) {
      AlertDialog.show('パラメータ名は必須です');
      return;
    }
    if (!isNotOnlySpace(value)) {
      AlertDialog.show('パラメータ値は必須です');
      return;
    }

    const parametersPostData: RequestParametersPost = {
      parameter_name: parameter_name,
      value: value,
    };
    const res = await parametersPostAPI(parametersPostData);
    if (res.status === 200) {
      AlertDialog.show('パラメータの追加に成功しました');
      history.push('/parameters');
    }
  };

  // -- render part --
  return (
    <PageWholeArea data-testid='ParametersCreate'>
      <Breadcrumbs
        items={[
          { href: '/parameters', icon: IconNames.VARIABLE, text: 'パラメータ' },
          { icon: IconNames.CUBE_ADD, text: 'パラメータの追加' },
        ]}
      />
      <Title text='パラメータの追加' />
      <Content>
        <FormContainer title='基本情報'>
          <InputComponent text='パラメータ名' required>
            <InputBox
              title='パラメータ名'
              placeholder='入力してください(必須)'
              value={parameter_name}
              onChange={(e) => setParameterName(e.currentTarget.value)}
              minLength={1}
            />
          </InputComponent>
          <InputComponent text='パラメータ値' required>
            <InputBox
              title='パラメータ値'
              placeholder='入力してください(必須)'
              value={value}
              onChange={(e) => setValue(e.currentTarget.value)}
              minLength={1}
            />
          </InputComponent>
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} text='キャンセル' is_white is_margin_right />
          <RoundedButton onClick={handleFinish} text='登録' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default ParametersCreatePage;
