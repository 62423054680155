import { memo } from 'react';
import { Spinner as OriginalSpinner } from '@blueprintjs/core';

/**
 * ロード中のスピナー
 */
export const Spinner = memo(function Spinner() {
  return <OriginalSpinner />;
});

export default Spinner;
