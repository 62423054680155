// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';

interface Params {
  bodies: TableNotificationType[];
  selected_bodies: TableNotificationType[];
  handleCheckClick: (datas: TableNotificationType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableNotificationType {
  id: string;
  notification_id: TableBodyUrlType;
  show_date: string;
  title: string;
  show_status: string;
  show_type: string;
  show_date_from: string;
  show_date_to: string;
  status: string;
  created_at: string;
}

// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'notification_id',
    label: 'お知らせID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'title',
    label: 'タイトル',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'show_status',
    label: 'ステータス',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'show_type',
    label: '表示区分',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'show_date',
    label: '表示日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'show_date_from',
    label: '表示開始日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'show_date_to',
    label: '表示終了日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'created_at',
    label: '作成日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
];

// -- main component --
const NotificatinoTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='Notifications'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default NotificatinoTable;
