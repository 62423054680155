export interface Search {
  search_key: string;
  search_name: string;
  search_type?: SearchType;
  // 検索値
  value: string;
  // 検索値が2つ必要な場合は利用する
  value2?: string;
  // 完全一致の場合はtrue
  perfect_match?: boolean;
  is_api_search?: boolean;
  // 検索の際に必須の項目
  required?: boolean;
}

export type SearchType = 'name' | 'datetime';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSearchType = (search_type: any): search_type is SearchType =>
  search_type === 'name' || search_type === 'datetime' || search_type === 'select';

export interface SearchRequiredItem {
  key: string;
  name: string;
  type: SearchType;
  // デフォルトで検索列を表示する
  default_display?: boolean;
  is_api_search?: boolean;
  required?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSearchRequiredItem = (data: any): data is SearchRequiredItem =>
  data instanceof Object && typeof data.key === 'string' && typeof data.name === 'string' && isSearchType(data.type);

export type SearchItemRecord = Record<string, SearchRequiredItem>;

export const hasValue2 = (search_type?: SearchType) => search_type === 'datetime';
const hasPerfectMatch = (search_type?: SearchType) => search_type === 'name';

export const searchRequiredItemTosearch = (search_required_item: SearchRequiredItem): Search => {
  return {
    search_key: search_required_item.key,
    search_name: search_required_item.name,
    search_type: search_required_item.type,
    value: '',
    value2: hasValue2(search_required_item.type) ? '' : undefined,
    perfect_match: search_required_item.is_api_search
      ? true
      : hasPerfectMatch(search_required_item.type)
      ? false
      : undefined,
    is_api_search: search_required_item.is_api_search,
    required: search_required_item.required,
  };
};
