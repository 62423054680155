// -- basic library --
import { memo } from 'react';
import OriginalLoader from 'react-loader-spinner';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

const AdminOverWrappedLoader = memo(function AdminOverWrappedLoader() {
  // -- render part --
  return <LoaderAnimation type='Oval' color={colors.dark_gray2} height={100} width={100} />;
});

// -- styled components --

const LoaderAnimation = styled(OriginalLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  opacity: 1;
  z-index: 9999; // 必ず一番上に来るように
`;

// -- finally export part --

export default AdminOverWrappedLoader;
