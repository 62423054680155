// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { useTenants } from 'admin/hooks/useTenants/useTenants';
import BillingTable, { TableBillingType } from './BillingTable';
import { CachedBillings } from 'admin/api/billingSummary';
import SelectBox from 'shared/components/atoms/SelectBox';
import { dateFormat, firstYearmonth, yearmonthList } from 'shared/utils/else/billingYearMonth';
import { formatter } from 'shared/utils/converter';
import { RouteComponentProps } from 'react-router-dom';

// -- external functions --
type Params = RouteComponentProps<{
  yearmonth?: string;
}>;

// -- main component --
const Billing: React.FC<Params> = (params) => {
  // -- local states --
  const { tenants_record } = useTenants({ status: 'ALL' });
  const [selected_bodies, setSelectedBodies] = useState<TableBillingType[]>([]);
  const [bodies, setBodies] = useState<TableBillingType[]>([]);
  const [yearmonth, setYearmonth] = useState<string>(params.match.params.yearmonth || firstYearmonth);
  const yearmonth_list: string[] = yearmonthList();

  // -- handlers --
  const handleClick = (datas: TableBillingType[]) => {
    setSelectedBodies(datas);
  };
  const loadTableBodies = useCallback(async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    const items = await new CachedBillings({ yearmonth: yearmonth }).get();
    // テーブル表示データへの変換
    const return_table_datas: TableBillingType[] = [];
    for (const item of items) {
      const tenant = tenants_record[item.tenant_id];
      const return_top_table_data: TableBillingType = {
        id: item.tenant_id,
        tenant_id: {
          value: item.tenant_id,
          url: `/billings/${yearmonth}/${item.tenant_id}/detail`,
          color: 'rgb(57, 131, 141)',
        },
        tenant_name: tenant?.tenant_name,
        demand_price_summary: formatter.format(item.demand_price_summary) + '円',
        yearmonth: yearmonth,
      };

      return_table_datas.push(return_top_table_data);
    }
    setBodies(return_table_datas);
  }, [tenants_record, yearmonth]);

  const handleCancelClick = () => {
    history.push('/billings');
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [loadTableBodies]);

  // -- render part --
  return (
    <PageWholeArea data-testid='Billings'>
      <TopArea>
        <Title text='請求照会画面' />
        <SelectBox
          onChange={(e) => setYearmonth(e.currentTarget.value)}
          value={yearmonth}
          datas={yearmonth_list.map((e) => {
            return {
              name: dateFormat(e),
              value: e,
            };
          })}
        />
      </TopArea>
      <BottomArea>
        {bodies ? (
          <BillingTable
            yearmonth={yearmonth}
            bodies={bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            loadTableBodies={loadTableBodies}
            setSelectedBodies={setSelectedBodies}
            handleCancelClick={handleCancelClick}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Billing;
