// -- basic library --
import React from 'react';

// -- external images --
import sidemenu_IDEA_logo from 'assets/sidemenu_IDEA_logo.png';
import history from 'shared/browserHistory';

// -- external functions --
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { resetPageNumbersFromSessionStorage } from 'shared/utils/else/sessionStorageFunctions';
import styled from 'styled-components';

export interface SideMenuItem {
  id: string;
  text: string;
  img_src: string;
  // 管理者ユーザー向けのページの場合true
  for_admin?: boolean;
  // `/{id}` 以外のURLにリンクしたい場合はこのフィールドにURLをセットします
  custom_url?: string;
}
interface PfSideMenuProps {
  default_id: string;
  side_menu_items: SideMenuItem[];
  is_admin_user: boolean;
}
interface PfSideMenuState {
  id: string;
  is_admin: boolean;
}
/**
 * 左サイドメニュー
 */
export default class PfSideMenu extends React.PureComponent<PfSideMenuProps, PfSideMenuState> {
  private historyUnlistenCallback?: { (): void; (): void };
  constructor(props: PfSideMenuProps) {
    super(props);
    this.state = {
      id: this.getId(),
      is_admin: this.props.is_admin_user,
    };
  }
  componentDidMount() {
    // アドレス欄のURLの書き換わりを監視するリスナーを登録
    this.historyUnlistenCallback = history.listen(this.onPopstateChanged);
  }
  componentWillUnmount() {
    if (this.historyUnlistenCallback) this.historyUnlistenCallback();
  }
  private onPopstateChanged = () => {
    this.setState({ id: this.getId() });
  };
  private onLogoClick = () => {
    const { default_id } = this.props;
    // メニューをクリックした時、テーブルのpageNumbers情報をリセットする。
    resetPageNumbersFromSessionStorage();
    history.push(`/${default_id}`);
  };
  private onMenuClick = (side_menu_item: SideMenuItem) => {
    // メニューをクリックした時、テーブルのpageNumbers情報をリセットする。
    resetPageNumbersFromSessionStorage();
    const url = side_menu_item.custom_url || `/${side_menu_item.id}`;
    history.push(url);
  };
  private getId() {
    // 現在のURLパスから機能カテゴリを取得します。
    const s = window.location.pathname.split('/');
    return s.length > 1 ? s[1] : '';
  }
  render() {
    const { side_menu_items } = this.props;
    const { is_admin } = this.state;
    return (
      <MenuContainer>
        <MenuHeader>
          <Logo src={sidemenu_IDEA_logo} onClick={this.onLogoClick} />
        </MenuHeader>
        <MenuWrapper>
          {side_menu_items.map((side_menu_item) => {
            if (side_menu_item.for_admin && !is_admin) return null;
            return (
              <MenuItem
                key={side_menu_item.id}
                id={side_menu_item.id}
                text={side_menu_item.text}
                img_src={side_menu_item.img_src}
                selected={this.state.id === side_menu_item.id ? true : false}
                onClick={() => this.onMenuClick(side_menu_item)}
              />
            );
          })}
        </MenuWrapper>
      </MenuContainer>
    );
  }
}

/**
 * メニューアイテム
 */
interface MenuItemProps {
  id: string;
  text: string;
  img_src: string;
  selected: boolean;
  onClick: (id: string) => void;
}
interface MenuItemState {}
class MenuItem extends React.PureComponent<MenuItemProps, MenuItemState> {
  render() {
    return (
      <StyledMenuItem
        onClick={() => this.props.onClick(this.props.id)}
        id={this.props.id}
        selected={this.props.selected}
        style={{ cursor: 'pointer' }}
      >
        <StyledMenuItemImg src={this.props.img_src} />
        <StyledMenuItemText>{this.props.text}</StyledMenuItemText>
      </StyledMenuItem>
    );
  }
}

const StyledMenuItem = styled.div<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  background-color: ${(params) => (params.selected ? colors.component_main_color : colors.black)};
  font-family: 'Noto Sans JP';
  font-weight: bold;
`;

const StyledMenuItemImg = styled.img`
  width: 28px;
  height: 28px;
`;

const StyledMenuItemText = styled.div`
  margin-top: 4px;
  font-size: ${styles.super_small_text_size};
  color: ${colors.component_text_color};
`;

const MenuContainer = styled.div`
  min-height: calc(100vh - ${styles.footer_height});
  height: calc(100% - ${styles.footer_height});
  width: ${styles.sidemenu_width};
  transition: width 0.3s, margin 0.3s;
  transition-timing-function: ease-out;
  background-color: ${colors.black};
  position: relative;
  overflow: auto;
`;

const MenuHeader = styled.div`
  height: ${styles.topmenu_height};
  widht: 100%;
  padding: 15px 0px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuWrapper = styled.div`
  height: calc(100% - ${styles.topmenu_height} - 30px * 2);
  width: 100%;
  box-shadow: none;
  padding: 0;
`;

const Logo = styled.img`
  width: auto;
  height: 100%;
`;
