// -- basic library --
import React from 'react';

// -- external components --
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';

// -- type declaration --

export interface TableTenantType {
  id: string;
  tenant_id: TableBodyUrlType;
  tenant_name: string;
  tenant_domain: string;
  tenant_generator2_domain: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface Params {
  bodies: TableTenantType[];
  selected_bodies: TableTenantType[];
  handleCheckClick: (datas: TableTenantType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'tenant_name',
    label: 'テナント名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'tenant_domain',
    label: 'テナントドメイン',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'tenant_generator2_domain',
    label: 'Genertor2ドメイン',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'status',
    label: 'ステータス',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'created_at',
    label: '作成日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'updated_at',
    label: '更新日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'deleted_at',
    label: '削除日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
];

// -- main component --
const TenantTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='streams'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default TenantTable;
