import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { modelsIdPutAPI, RequestModelsIdPut, modelsIdGetAPI, Model } from 'admin/api/models';
import preloadGroup from 'admin/utils/preloadGroup';
import { useDispatch } from 'react-redux';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';
import { toStringBoolean } from 'shared/models/StringBoolean';

// -- type declaration --
interface Params extends RouteComponentProps<{ model_id: string }> {}

// -- main component --

const ModelSet: React.FC<Params> = (params) => {
  // -- get external datas --

  // -- redux preparations --
  const dispatch = useDispatch();

  // -- local states --
  const [model, setModel] = useState<Model | null>(null);
  const [model_name, setModelName] = useState<string>('');
  const [use_crop_parameters, setUseCropParameters] = useState<boolean>(false);
  const [soracom_package_uri, setSoracompackageUri] = useState<string>('');

  // -- handlers --

  const handleCancel = () => {
    history.push('/models');
  };

  const getData = async (model_id: string) => {
    const res = await modelsIdGetAPI({ model_id: model_id });
    if (res.status === 200) {
      setModel(res.data);
      setModelName(res.data.model_name);
      setUseCropParameters(res.data.use_crop_parameters);
      setSoracompackageUri(res.data.soracom_package_uri);
    }
  };

  const handleFinish = async (model_id: string) => {
    if (!isNotOnlySpace(model_name)) {
      AlertDialog.show('モデル名は必須です');
      return;
    }
    if (!isNotOnlySpace(soracom_package_uri)) {
      AlertDialog.show('パケージURIは必須です');
      return;
    }

    const modelsIdPutData: RequestModelsIdPut = {
      model_id: model_id,
      model_name: model_name,
      use_crop_parameters: toStringBoolean(use_crop_parameters),
      soracom_package_uri: soracom_package_uri,
    };
    const res = await modelsIdPutAPI(modelsIdPutData);
    if (res.status === 200) {
      AlertDialog.show('モデルの編集に成功しました');
      history.push('/models');
    }
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(getData(params.match.params.model_id));
  }, [dispatch, params.match.params.model_id]);

  // -- render part --
  return (
    <PageWholeArea data-testid='ModelSet'>
      <Breadcrumbs
        items={[
          { href: '/models', icon: IconNames.DATA_LINEAGE, text: 'モデル' },
          {
            icon: IconNames.CUBE,
            text: model !== null ? model.model_name : null,
          },
        ]}
      />
      <Title text='モデルの編集' />

      {model ? (
        <>
          <Content>
            <FormContainer title='基本情報'>
              <InputComponent text='モデルID'>
                <InputBox title='モデルID' value={params.match.params.model_id} disabled />
              </InputComponent>
              <InputComponent text='モデル名' required>
                <InputBox
                  title='モデル名'
                  placeholder='入力してください(必須)'
                  value={model_name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModelName(e.currentTarget.value)}
                />
              </InputComponent>
            </FormContainer>
            <FormContainer title='クロップ情報'>
              <InputComponent text='クロップ設定'>
                <CheckBoxWithText
                  text='クロップ設定'
                  checked={use_crop_parameters}
                  onClick={() => setUseCropParameters(!use_crop_parameters)}
                />
              </InputComponent>
            </FormContainer>
            <FormContainer title='パッケージ情報'>
              <InputComponent text='パッケージURI' required>
                <InputBox
                  title='パッケージURI'
                  placeholder='入力してください(必須)'
                  value={soracom_package_uri}
                  onChange={(e) => setSoracompackageUri(e.currentTarget.value)}
                />
              </InputComponent>
            </FormContainer>
            <Footer>
              <RoundedButton onClick={handleCancel} text='戻る' is_margin_right is_white />
              <RoundedButton onClick={() => handleFinish(params.match.params.model_id)} text='更新' />
            </Footer>
          </Content>
        </>
      ) : (
        <Spinner />
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default ModelSet;
