import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { RequestStreamsGet, Stream, streamsGetAPI } from 'admin/api/streams';
import { Search } from 'shared/models/Search';
import { getSearchesFromSessionStorage } from 'shared/utils/else/sessionStorageFunctions';

const getInitialSearchesRecord = () => {
  const searches = getSearchesFromSessionStorage('streams', 'main');
  // Search[] -> Record<string, Search>
  const searches_record = searches ? keyBy(searches, (s) => s.search_key) : undefined;
  return searches_record;
};

/**
 * ページングを伴うストリーム一覧を取得するcustom hooks
 */
export const useStreamsWithPaging = ({ limit }: RequestStreamsGet) => {
  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);
  const [searches_record, setSearchesRecord] = useState<Record<string, Search> | undefined>(getInitialSearchesRecord());
  const [next_meta_data, setNextMetaData] = useState<{
    has_next: boolean;
    exclusive_start_stream_id: string;
    exclusive_start_tenant_id: string;
  }>({
    has_next: false,
    exclusive_start_stream_id: '',
    exclusive_start_tenant_id: '',
  });
  const streams_record: Record<string, Stream> = useMemo(() => {
    return streams ? keyBy(streams, (s) => s.stream_id) : {};
  }, [streams]);
  const loadStreams = useCallback(
    async (api_searches_record?: Record<string, Search>) => {
      if (api_searches_record !== undefined) {
        setSearchesRecord(api_searches_record);
      }
      const res = await streamsGetAPI({
        limit,
        tenant_id: api_searches_record?.['tenant_id']?.value ? api_searches_record['tenant_id'].value : undefined,
        stream_id: api_searches_record?.['stream_id']?.value ? api_searches_record['stream_id'].value : undefined,
      });
      if (res.status === 200) {
        setStreams(res.data.items);
        setNextMetaData({
          has_next: res.data.has_next,
          exclusive_start_stream_id: res.data.exclusive_start_stream_id ?? '',
          exclusive_start_tenant_id: res.data.exclusive_start_tenant_id ?? '',
        });
      }
    },
    [limit],
  );

  const addStreams = useCallback(async () => {
    const { has_next, exclusive_start_stream_id, exclusive_start_tenant_id } = next_meta_data;
    if (!has_next) return;
    const res = await streamsGetAPI({
      exclusive_start_stream_id,
      exclusive_start_tenant_id,
      limit,
      tenant_id: searches_record?.['tenant_id']?.value ? searches_record['tenant_id'].value : undefined,
      stream_id: searches_record?.['stream_id']?.value ? searches_record['stream_id'].value : undefined,
    });
    if (res.status !== 200) return;

    let new_streams = [];
    if (streams === undefined) {
      new_streams = [...res.data.items];
    } else {
      new_streams = [...streams, ...res.data.items];
    }
    setNextMetaData({
      has_next: res.data.has_next,
      exclusive_start_stream_id: res.data.exclusive_start_stream_id ?? '',
      exclusive_start_tenant_id: res.data.exclusive_start_tenant_id ?? '',
    });
    setStreams(new_streams);
  }, [streams, next_meta_data, limit, searches_record]);

  useEffect(() => {
    loadStreams(searches_record);
  }, [loadStreams, searches_record]);

  return useMemo(() => {
    return {
      streams,
      streams_record,
      next_meta_data,
      setStreams,
      loadStreams,
      addStreams,
    };
  }, [streams, setStreams, streams_record, next_meta_data, loadStreams, addStreams]);
};
