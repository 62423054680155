import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { PageWholeArea, Title, Content, Breadcrumbs } from 'shared/components/molecules/ContentsArea';
import InformationRegisterPanel from './InformationRegisterPanel';

// -- main component --

const ProcessesCreatePage = memo(function ProcessCreate() {
  // -- render part --
  return (
    <PageWholeArea data-testid='ProcessCreate'>
      <Breadcrumbs
        items={[
          { href: '/processes', icon: IconNames.ROUTE, text: 'プロセス' },
          { icon: IconNames.CUBE_ADD, text: 'プロセスの追加' },
        ]}
      />
      <Title text='プロセスの追加' />
      <Content>
        <InformationRegisterPanel />
      </Content>
    </PageWholeArea>
  );
});

// -- finally export part --

export default ProcessesCreatePage;
