// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import { CachedUsers, User, usersIdDeleteAPI } from 'admin/api/users';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import UserTable, { TableUserType } from './UserTable';
import { useTenants } from 'admin/hooks/useTenants/useTenants';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';

// -- external functions --

// -- main component --
const Users: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableUserType[]>([]);
  const [bodies, setBodies] = useState<TableUserType[] | undefined>(undefined);
  const { tenants_record } = useTenants({});

  // -- handlers --
  const handleClick = (datas: TableUserType[]) => {
    setSelectedBodies(datas);
  };
  const loadTableBodies = useCallback(async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    const items: User[] = await new CachedUsers({}).get();

    const return_table_datas: TableUserType[] = [];
    for (const item of items) {
      const tenant = tenants_record[item.tenant_id];
      const return_top_table_data: TableUserType = {
        id: item.user_id,
        user_id: {
          value: item.user_id,
          url: `/users/${item.user_id}/detail`,
          color: 'rgb(57, 131, 141)',
        },
        user_name: item.user_name,
        login_id: item.login_id,
        tenant_id: item.tenant_id,
        tenant_name: tenant?.tenant_name ?? 'Unknown',
        mail_address: item.mail_address,
        status: item.status,
        created_at: dateToYMDHMS(item.created_at),
        updated_at: dateToYMDHMS(item.updated_at),
        deleted_at: dateToYMDHMS(item.deleted_at ?? ''),
      };

      return_table_datas.push(return_top_table_data);
    }
    setBodies(return_table_datas);
  }, [tenants_record]);

  const handleCreateClick = () => {
    history.push(`/users/create`);
  };

  const handleOKClick = async (selected_datas: TableUserType[]) => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_datas.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return usersIdDeleteAPI({ user_id: sd.id });
        }),
      );
      if (has_deleted_flag) {
        AlertDialog.show(`状態が「DELETED」のデータは削除できませんでした`);
      }
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/users');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているユーザーを削除します。\n本当によろしいですか？',
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [loadTableBodies]);

  // -- render part --
  return (
    <PageWholeArea data-testid='Users'>
      <TopArea>
        <Title text='ユーザー' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規ユーザー追加'
          style={{ marginRight: styles.interval_narrow_margin }}
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {bodies !== undefined ? (
          <UserTable
            bodies={bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたユーザーを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Users;
