import React from 'react';
import { Process } from 'admin/api/processes';
import { ProcessFlow } from 'admin/api/processflows';
import { ProcessflowAppParameterDialogButton } from 'admin/dialogs/ProcessflowAppParameterDialog';
import InputBox from 'shared/components/atoms/InputBox';
import { CheckBoxArea, Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/atoms/PfTable';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import CheckBox from 'shared/components/molecules/CheckBox';
import { InputTableFooter } from 'shared/components/molecules/Table/InputBaseTable';
import { table_cell_button_width } from 'shared/styles/styles';
import { AppParameter } from 'shared/models/AppParameter';

interface ProcessflowsStepEditFormProps {
  steps: ProcessFlow['steps'];
  processes: Process[];
  onChange: (value: ProcessFlow['steps']) => void;
}
interface ProcessflowsStepEditFormState {
  checkes: true[]; // チェックボックスをチェックしている添字にtrueが入ります
}
/** プロセスフローのステップ表フォームです */
export default class ProcessflowsStepEditForm extends React.PureComponent<
  ProcessflowsStepEditFormProps,
  ProcessflowsStepEditFormState
> {
  constructor(props: ProcessflowsStepEditFormProps) {
    super(props);
    this.state = {
      checkes: [],
    };
  }
  /** stepsのプロセス変更された時に、入力ストリームを整合する**/
  private adjustInputStreamsWhenProcessChanged = (
    steps: ProcessFlow['steps'],
    step_number: number,
    processes: Process[],
  ): ProcessFlow['steps'] => {
    if (step_number + 1 >= steps.length) return steps;
    // 変更されたstep
    const changed_step = steps[step_number];
    const new_steps: ProcessFlow['steps'] = [...steps.slice(0, step_number + 1)];
    // 変更した番号(step_number)以降に影響が出るため、そこから始める
    for (let i = step_number + 1; i < steps.length; i++) {
      const step = steps[i];
      for (let j = 0; j < step.input_streams.length; j++) {
        let input_stream = step.input_streams[j];
        // 変更されたプロセスの入力ストリームを使用している場合、選択に不整合が生じていれば、初期値のinput_streamに戻す
        if (input_stream.startsWith(`INPUT(${changed_step.step_id},`)) {
          // 何番目の入力ストリームの部分を切り出す
          // INPUT(1,4)のような形をしているので、[, ~ )]の中の数値を抽出
          const id = input_stream.substring(`INPUT(${changed_step.step_id},`.length, input_stream.length - 1);
          // 変更したstepのprocess_idに該当するprocess
          const changed_step_process = processes.find((pr) => pr.process_id === changed_step.process_id);
          if (!changed_step_process) {
            // 該当プロセスがない時は、初期値に戻す
            input_stream = `INPUT(${step.step_id},${j})`;
          } else if (Number(id) >= Number(changed_step_process.input_stream_count)) {
            // 該当プロセスがあったとしても、入力ストリームの範囲を超えていれば初期値に戻す
            input_stream = `INPUT(${step.step_id},${j})`;
          }
        }
        // 変更されたプロセスの出力ストリームを使用している場合、選択に不整合が生じていれば、初期値のinput_streamに戻す
        if (input_stream.startsWith(`STEP(${changed_step.step_id},`)) {
          // 何番目の入力ストリームの部分を切り出す
          const id = input_stream.substring(`STEP(${changed_step.step_id},`.length, input_stream.length - 1);
          // 変更したstepのprocess_idに該当するprocess
          const changed_step_process = processes.find((pr) => pr.process_id === changed_step.process_id);
          if (!changed_step_process) {
            // 該当プロセスがない時は、初期値に戻す
            input_stream = `INPUT(${step.step_id},${j})`;
          } else if (Number(id) >= Number(changed_step_process.output_stream_count)) {
            // 該当プロセスがあったとしても、出力ストリームの範囲を超えていれば初期値に戻す
            input_stream = `INPUT(${step.step_id},${j})`;
          }
        }
        step.input_streams[j] = input_stream;
      }
      new_steps.push(step);
    }
    return new_steps;
  };

  /** stepsが削除された時に、入力ストリームを整合する**/
  private adjustInputStreamsWhenStepsDeleted = (steps: ProcessFlow['steps']): ProcessFlow['steps'] => {
    // 存在しているstep_idの配列
    const valid_step_ids: number[] = steps.map((st) => st.step_id);
    // 返却する新しいsteps
    const new_steps: ProcessFlow['steps'] = [];
    // 変更した番号(step_number)以降に影響が出るため、そこから始める
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      for (let j = 0; j < step.input_streams.length; j++) {
        // INPUT(step_id, index) or STEP(step_id, index)
        let input_stream = step.input_streams[j];
        // input_streamに記入されているstep_id
        let input_step_id: number | null = null;
        // INPUTから始まる
        if (input_stream.startsWith(`INPUT(`)) {
          const new_input_step_id = input_stream.substring(`INPUT(`.length, input_stream.indexOf(','));
          input_step_id = Number(new_input_step_id);
        } else if (input_stream.startsWith(`STEP(`)) {
          const new_input_step_id = input_stream.substring(`STEP(`.length, input_stream.indexOf(','));
          input_step_id = Number(new_input_step_id);
        }
        // 存在しないstepを参照している場合は、input_streamを初期に戻す
        if (input_step_id !== null && input_step_id !== step.step_id && !valid_step_ids.includes(input_step_id)) {
          input_stream = `INPUT(${step.step_id},${j})`;
        }
        step.input_streams[j] = input_stream;
      }
      new_steps.push(step);
    }
    return new_steps;
  };
  /** 行追加ボタンを押したときのイベントハンドラ */
  private handleAddClick = () => {
    const step_id = this.props.steps.length === 0 ? 1 : this.props.steps[this.props.steps.length - 1].step_id + 1;
    this.props.onChange([
      ...this.props.steps,
      {
        step_id: step_id,
        process_id: '',
        output_name: '',
        input_streams: [],
        integration_flag: false,
        app_parameter: {},
      },
    ]);
  };
  /** 選択行の削除ボタンを押したときのイベントハンドラ */
  private handleDeleteClick = () => {
    let new_steps: ProcessFlow['steps'] = [];
    for (let i = 0; i < this.props.steps.length; i++) {
      if (this.state.checkes[i] === undefined) {
        new_steps.push(this.props.steps[i]);
      }
    }
    new_steps = this.adjustInputStreamsWhenStepsDeleted(new_steps);
    this.props.onChange(new_steps);
    this.setState({ checkes: [] });
  };
  /** 行選択チェックボックスをクリックしたときのイベントハンドラ */
  private handleLineCheck = (i: number, checked: boolean) => {
    const new_checkes = [...this.state.checkes];
    if (checked) {
      new_checkes[i] = true;
    } else {
      delete new_checkes[i];
    }
    this.setState({ checkes: new_checkes });
  };
  /** 行のプロセスIDを変更した時のイベントハンドラ */
  private handleLineProcessIdChange = async (i: number, value: string) => {
    // 選択したプロセスの入力ストリーム
    const process = this.props.processes.filter((e) => e.process_id === value).shift();
    let new_steps = [...this.props.steps];
    new_steps[i] = {
      ...new_steps[i],
      process_id: value,
      input_streams: process
        ? process.input_stream_constraints.map((e, j) => `INPUT(${new_steps[i].step_id},${j})`)
        : [], //入力させるを初期値にする
    };
    new_steps = this.adjustInputStreamsWhenProcessChanged(new_steps, i, this.props.processes);
    this.props.onChange(new_steps);
  };
  /** 行の出力名を変更した時のイベントハンドラ */
  private handleLineOutputNameChange = async (i: number, value: string) => {
    const new_steps = [...this.props.steps];
    new_steps[i] = {
      ...new_steps[i],
      output_name: value,
    };
    this.props.onChange(new_steps);
  };
  /** 行の入力ストリームを変更した時のイベントハンドラ */
  private handleLineInputStreamsChange = (i: number, value: string[]) => {
    const new_steps = [...this.props.steps];

    new_steps[i] = { ...new_steps[i], input_streams: value };
    this.props.onChange(new_steps);
  };
  /** 行の統合チェックボックスをクリックしたときのイベントハンドラ */
  private handleLineIntegrationFlagChange = async (i: number, value: boolean) => {
    const new_steps = [...this.props.steps];
    new_steps[i] = {
      ...new_steps[i],
      integration_flag: value,
    };
    this.props.onChange(new_steps);
  };
  /** 行のアプリパラメータを変更した時のイベントハンドラ */
  private handleLineAppParameterChange = (i: number, value: AppParameter) => {
    const new_steps = [...this.props.steps];
    new_steps[i] = { ...new_steps[i], app_parameter: value };
    this.props.onChange(new_steps);
  };
  render() {
    return (
      <>
        <div>
          （入力ストリームの整合性はチェックしていませんから、指定時は各プロセスの入出力の制限を考慮してください）
        </div>
        <Table>
          <Thead>
            <Tr>
              <Th style={{ width: 35 }}></Th>
              <Th style={{ width: 40 }}>#</Th>
              <Th>プロセス</Th>
              <Th>出力名</Th>
              <Th>入力ストリーム</Th>
              <Th>パラメータ統合</Th>
              <Th style={{ width: table_cell_button_width }}>パラメータ</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.steps.map((step, i) => {
              return (
                <Tr style={{ textAlign: 'center' }} key={i}>
                  {/* テーブルヘッダー */}
                  <Td style={{ width: 35 }}>
                    <CheckBoxArea>
                      <CheckBox
                        checked={this.state.checkes[i]}
                        onClick={() => this.handleLineCheck(i, !this.state.checkes[i])}
                      />
                    </CheckBoxArea>
                  </Td>
                  <Td style={{ width: 40 }}>{step.step_id}</Td>
                  <Td>
                    <SelectBox
                      datas={this.props.processes.map((e) => {
                        return { name: e.process_name, value: e.process_id };
                      })}
                      value={step.process_id}
                      onChange={(e) => this.handleLineProcessIdChange(i, e.currentTarget.value)}
                    />
                  </Td>
                  <Td>
                    <InputBox
                      value={step.output_name}
                      onChange={(e) => this.handleLineOutputNameChange(i, e.currentTarget.value)}
                    />
                  </Td>
                  <Td>
                    <InputStreamsInput
                      step_id={step.step_id}
                      input_streams={step.input_streams}
                      steps={this.props.steps.filter((e) => e.step_id < step.step_id)}
                      processes={this.props.processes}
                      onChange={(value) => this.handleLineInputStreamsChange(i, value)}
                    />
                  </Td>
                  <Td>
                    <CheckBoxArea style={{ justifyContent: 'center' }}>
                      <CheckBox
                        checked={step.integration_flag}
                        onClick={() => this.handleLineIntegrationFlagChange(i, !step.integration_flag)}
                      />
                    </CheckBoxArea>
                  </Td>
                  <Td>
                    <ProcessflowAppParameterDialogButton
                      value={step.app_parameter}
                      process={this.props.processes.find((p) => p.process_id === step.process_id)}
                      onChange={(value) => this.handleLineAppParameterChange(i, value)}
                      useTableStylesButton
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <InputTableFooter>
          <RoundedButton text='削除' onClick={this.handleDeleteClick} is_white is_margin_right small />
          <RoundedButton text='追加' onClick={this.handleAddClick} small />
        </InputTableFooter>
      </>
    );
  }
}

/** ステップの入力ストリームの入力欄です */
interface InputStreamsInputProps {
  step_id: number;
  input_streams: ProcessFlow['steps'][number]['input_streams'];
  steps: ProcessFlow['steps'];
  processes: Process[];
  onChange: (value: ProcessFlow['steps'][number]['input_streams']) => void;
}
function InputStreamsInput(props: InputStreamsInputProps) {
  // 選択を変更したときのイベントハンドラ
  const handleChange = (i: number, value: string) => {
    const new_value = [...props.input_streams];
    new_value[i] = value;
    props.onChange(new_value);
  };
  // 選択の作成関数
  const getSelection = (idx: number) => {
    const selection = [{ name: '入力させる', value: `INPUT(${props.step_id},${idx})` }];

    props.steps.forEach((step) => {
      step.input_streams.forEach((s, j) => {
        if (s.startsWith('INPUT')) {
          selection.push({
            name: `INPUT(${step.step_id})の入力(${j + 1})と同じ`,
            value: `INPUT(${step.step_id},${j})`,
          });
        }
      });
    });
    props.steps.forEach((step) => {
      const p = props.processes.filter((e) => e.process_id === step.process_id).shift();
      if (p) {
        p.output_stream_constraints.forEach((c, j) => {
          selection.push({ name: `STEP(${step.step_id})の出力(${j + 1})`, value: `STEP(${step.step_id},${j})` });
        });
      }
    });
    return selection;
  };
  return (
    <>
      {props.input_streams.map((is, i) => (
        <>
          <SelectBox
            datas={getSelection(i)}
            value={is}
            default_text='null'
            onChange={(e) => handleChange(i, e.currentTarget.value)}
          />
          <br />
        </>
      ))}
    </>
  );
}
