// -- basic library --
import React from 'react';
import history from 'shared/browserHistory';

// -- external components --
import FunctionalText from 'shared/components/atoms/FunctionalText';
import { colors } from 'shared/styles/colors';
import { isTableBodyClickType, isTableBodyMultipleValueType, isTableBodyUrlType } from '../tableFunctions';
import { TableText } from '../tableStyles';

// -- type declaration --
interface DynamicTableBodyValue {
  row_index: number; // テーブルの何行目かを表す
  key_name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

// -- main component --
/**
 * tableのbodyにおいて任意のvalueの型に応じて、データを表示させるコンポーネント
 */
const DynamicTableBodyValue: React.FC<DynamicTableBodyValue> = (params) => {
  const { row_index, key_name } = params;
  const testid = `${row_index}-${key_name}`;
  // -- render part --
  if (isTableBodyUrlType(params.value)) {
    // params.valueがValueUrl型の場合は、リンク付きで返却する
    const { value, url, color = colors.component_main_color, newWindow } = params.value;
    const onClick = () => {
      if (newWindow) {
        window.open(url);
      } else {
        history.push(url);
      }
    };
    return (
      <FunctionalText
        title={value}
        text={value}
        onClick={onClick}
        color={color}
        style={{ fontSize: 'inherit' }}
        data-testid={testid}
      />
    );
  } else if (isTableBodyClickType(params.value)) {
    const { value, onClick, color = colors.component_main_color } = params.value;
    return (
      <FunctionalText
        title={value}
        text={value}
        onClick={onClick}
        color={color}
        style={{ fontSize: 'inherit' }}
        stop_propagation={true}
        data-testid={testid}
      />
    );
  } else if (isTableBodyMultipleValueType(params.value)) {
    const { displayable_value } = params.value;
    return typeof displayable_value === 'string' ? (
      <TableText title={displayable_value} data-testid={testid}>
        {displayable_value}
      </TableText>
    ) : (
      displayable_value
    );
  } else if (Array.isArray(params.value)) {
    return (
      <div>
        {params.value.map((v, index) => (
          <TableText key={index} title={v} data-testid={testid}>
            {v}
          </TableText>
        ))}
      </div>
    );
  } else if (typeof params.value === 'string' || typeof params.value === 'number') {
    return (
      <TableText title={String(params.value)} data-testid={testid}>
        {params.value}
      </TableText>
    );
  } else {
    return <span data-testid={testid}>{params.value}</span>;
  }
};

// -- finally export part --

export default DynamicTableBodyValue;
