import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import TextAreaInput from 'shared/components/atoms/TextAreaInput';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';

export type AppParameterDialogFields = {
  key: string;
  name: string;
  description: string;
  default_value: string;
  input_type: string;
  list_items: string;
  required: boolean;
  validate_regex: string;
  is_hidden: boolean;
};
interface AppParameterDialogProps {
  title: string;
  isOpen: boolean;
  isKeyFix: boolean;
  fields: AppParameterDialogFields;
  handleOkClose?: (event: React.MouseEvent<HTMLElement>, s: AppParameterDialogFields) => void;
  handleCancelClose?: () => void;
}
interface AppParameterDialogState {
  message: string;
  key: string;
  name: string;
  description: string;
  defaultValue: string;
  inputType: string;
  listItems: string;
  required: boolean;
  validateRegex: string;
  isHidden: boolean;
}
export default class AppParameterDialog extends React.PureComponent<AppParameterDialogProps, AppParameterDialogState> {
  constructor(props: AppParameterDialogProps) {
    super(props);
    this.state = {
      message: '',
      key: props.fields.key,
      name: props.fields.name,
      description: props.fields.description,
      defaultValue: props.fields.default_value,
      inputType: props.fields.input_type,
      listItems: props.fields.list_items,
      required: props.fields.required,
      validateRegex: props.fields.validate_regex,
      isHidden: props.fields.is_hidden,
    };
  }
  private handleOkClose = (e: React.MouseEvent<HTMLElement>) => {
    // 入力チェック
    if (this.state.key.trim() === '') {
      this.setState({ message: 'キーは入力必須です。' });
      return;
    }
    if (this.state.name.trim() === '') {
      this.setState({ message: '表示名は入力必須です。' });
      return;
    }
    if (this.props.handleOkClose) {
      this.props.handleOkClose(e, {
        key: this.state.key,
        name: this.state.name,
        description: this.state.description,
        default_value: this.state.defaultValue,
        input_type: this.state.inputType,
        list_items: this.state.listItems,
        required: this.state.required,
        validate_regex: this.state.validateRegex,
        is_hidden: this.state.isHidden,
      });
    }
  };
  render() {
    return (
      <PfDialog title={this.props.title} isOpen={this.props.isOpen} onClose={this.props.handleCancelClose}>
        {this.state.message !== '' && <Callout intent={Intent.DANGER}>{this.state.message}</Callout>}
        <InputComponent text='キー' required>
          <InputBox
            title='キー'
            placeholder=''
            onChange={(e) => this.setState({ key: e.currentTarget.value })}
            value={this.state.key}
            disabled={this.props.isKeyFix}
          />
        </InputComponent>
        <InputComponent text='表示名' required>
          <InputBox
            title='表示名'
            placeholder=''
            onChange={(e) => this.setState({ name: e.currentTarget.value })}
            value={this.state.name}
          />
        </InputComponent>
        <InputComponent text='説明文'>
          <InputBox
            title='説明文'
            placeholder=''
            onChange={(e) => this.setState({ description: e.currentTarget.value })}
            value={this.state.description}
          />
        </InputComponent>
        <InputComponent text='デフォルト値'>
          <InputBox
            title='デフォルト値'
            placeholder=''
            onChange={(e) => this.setState({ defaultValue: e.currentTarget.value })}
            value={this.state.defaultValue}
          />
        </InputComponent>
        <InputComponent text='入力タイプ'>
          <SelectBox
            onChange={(e) => this.setState({ inputType: e.currentTarget.value })}
            value={this.state.inputType}
            datas={[
              { name: 'テキスト', value: 'TEXT' },
              { name: '単一選択', value: 'SELECT' },
              { name: '複数選択', value: 'MULTISELECT' },
              { name: '検知ライン', value: 'CROSSLINE' },
              { name: '検知ライン2', value: 'CROSSLINE2' },
              { name: 'クロップ設定', value: 'CROP' },
              { name: 'ビデオエディタ', value: 'VIDEOEDITOR' },
              { name: 'CSV定義', value: 'CSVROWS' },
              { name: 'JSONパスグループ定義', value: 'JSONPATHGROUPS' },
              { name: 'Safie認証', value: 'SAFIEAUTH' },
              { name: '滞留エリア', value: 'STAYAREA' },
              { name: '射影変換対象エリア', value: 'TRANSFORMEDAREA1' },
              { name: '射影変換後エリア', value: 'TRANSFORMEDAREA2' },
              { name: '検知クラス', value: 'DETCLASS' },
            ]}
          />
        </InputComponent>
        <InputComponent text='入力できる値のリスト'>
          <TextAreaInput
            title='入力できる値のリスト'
            onChange={(e) => this.setState({ listItems: e.currentTarget.value })}
            value={this.state.listItems}
          />
        </InputComponent>

        <InputComponent text='必須'>
          <CheckBoxWithText
            text='必須'
            checked={this.state.required}
            onClick={() => this.setState({ required: !this.state.required })}
          />
        </InputComponent>

        <InputComponent text='チェック用正規表現'>
          <InputBox
            title='チェック用正規表現'
            placeholder=''
            onChange={(e) => this.setState({ validateRegex: e.currentTarget.value })}
            value={this.state.validateRegex}
          />
        </InputComponent>
        <InputComponent text='非表示'>
          <CheckBoxWithText
            text='非表示'
            checked={this.state.isHidden}
            onClick={() => this.setState({ isHidden: !this.state.isHidden })}
          />
        </InputComponent>
        <Footer>
          <RoundedButton onClick={this.props.handleCancelClose} text='キャンセル' is_margin_right is_white />
          <RoundedButton onClick={this.handleOkClose} text='設定' />
        </Footer>
      </PfDialog>
    );
  }
}
