import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
// slices
import authSlice from './slices/authSlice';
import systemSlice from './slices/systemSlice';

// (ここにslicesを追加)

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  system: systemSlice.reducer,
  // (ここにreducerを追加)
});

const setupStore = () => {
  const middlewares = [thunk];

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'], // 永続化したいstateを追加
    // blacklist: []
  };

  const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: middlewares,
  });
  return store;
};

export const store = setupStore();
export const persistor = persistStore(store);
