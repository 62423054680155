import sendAxios from 'shared/axios/sendAxios';
import { Query } from 'shared/models/Query';
import { getClient } from './base';
export interface TableauJwt {
  token: string;
}

/*** [GET] /api/tableau/jwt ***/

export const tableauJwtGetAPI = () => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/tableau/jwt`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<TableauJwt>(axios, path, query, form, method, {
    token: '',
  });
};
