// -- basic library --
import React from 'react';

// -- external components --
import { Popover as OriginalPopover, IPopoverProps } from '@blueprintjs/core';

// -- main component --
/**
 * ポップオーバー(ヘッダーで主に使用)
 */
interface PopoverProps { }
export default class Popover extends React.PureComponent<PopoverProps & IPopoverProps> {
  render() {
    return (
      <OriginalPopover {...this.props}>
        {this.props.children}
      </OriginalPopover>
    )
  }
}

