import React from 'react';
import { InputGroupProps } from '@blueprintjs/core';
import InputBox from 'shared/components/atoms/InputBox';

// JSONパスを入力するためのテキストボックス
interface JsonpathInputProps {
  value: string;
  onJsonpathChanged: (item: string) => void;
}
interface JsonpathInputState {
  jsonpath?: string[];
}
export default class JsonpathInput extends React.PureComponent<
  JsonpathInputProps & InputGroupProps,
  JsonpathInputState
> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  render() {
    const { onJsonpathChanged, ...remain_props } = this.props;
    return (
      <InputBox {...remain_props} inputRef={this.inputRef} onChange={(e) => onJsonpathChanged(e.currentTarget.value)} />
    );
  }
}
