import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { parametersNamePutAPI, RequestParametersNamePut, parametersNameGetAPI } from 'admin/api/parameters';
import preloadGroup from 'admin/utils/preloadGroup';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';

// -- type declaration --
type Params = RouteComponentProps<{ name: string }>;

// -- main component --

const ParametersDetailPage: React.FC<Params> = (params) => {
  // -- local states --;
  const [parameter_name, setParameterName] = useState<string>('');
  const [value, setValue] = useState<string>('');

  // -- handlers --
  const getInitialData = async (parameter_name: string) => {
    const res = await parametersNameGetAPI({ parameter_name: parameter_name });
    if (res.status === 200) {
      setParameterName(res.data.parameter_name);
      setValue(res.data.value);
    }
  };

  const handleCancel = () => {
    history.push('/parameters');
  };

  const handleFinish = async () => {
    if (!isNotOnlySpace(value)) {
      AlertDialog.show('パラメータ値は必須です');
      return;
    }
    const parametersNamePutData: RequestParametersNamePut = {
      parameter_name: parameter_name,
      value: value,
    };
    const res = await parametersNamePutAPI(parametersNamePutData);
    if (res.status === 200) {
      AlertDialog.show('パラメータの変更に成功しました');
      history.push('/parameters');
    }
  };

  // -- onload function --
  useEffect(() => {
    const parameter_name = decodeURI(params.match.params.name);

    preloadGroup(setParameterName(parameter_name), getInitialData(parameter_name));
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='ParametersSet'>
      <Breadcrumbs
        items={[
          { href: '/parameters', icon: IconNames.VARIABLE, text: 'パラメータ' },
          { icon: IconNames.CUBE, text: parameter_name },
        ]}
      />
      <Title text='パラメータの編集' />

      <Content>
        <FormContainer title='基本情報'>
          <InputComponent text='パラメータ名'>
            <InputBox title='パラメータ名' value={parameter_name} disabled />
          </InputComponent>
          <InputComponent text='パラメータ値' required>
            <InputBox
              title='パラメータ値'
              placeholder='入力してください(必須)'
              value={value}
              onChange={(e) => setValue(e.currentTarget.value)}
            />
          </InputComponent>
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} text='戻る' is_white is_margin_right />
          <RoundedButton onClick={handleFinish} text='更新' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default ParametersDetailPage;
