// -- basic library --
import React from 'react';

// -- redux library --
import { Process } from 'admin/api/processes';
import { Content } from 'shared/components/molecules/ContentsArea';
import InformationSetPanel from './InformationSetPanel';

// -- type declaration --
type Params = { process: Process };

// -- main component --

const ProcessSetPanel: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <Content>
      <InformationSetPanel process={params.process} />
    </Content>
  );
};

// -- finally export part --

export default ProcessSetPanel;
