import React, { useState } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { modelsPostAPI, RequestModelsPost } from 'admin/api/models';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';
import { toStringBoolean } from 'shared/models/StringBoolean';

// -- main component --

const ModelCreatePage: React.FC = () => {
  // -- local states --
  const [model_name, setModelName] = useState<string>('');
  const [use_crop_parameters, setUseCropParameters] = useState<boolean>(false);
  const [soracom_package_uri, setSoracompackageUri] = useState<string>('');

  // -- handlers --

  const handleCancel = () => {
    history.push('/models');
  };

  const handleFinish = async () => {
    if (!isNotOnlySpace(model_name)) {
      AlertDialog.show('モデル名は必須です');
      return;
    }
    if (!isNotOnlySpace(soracom_package_uri)) {
      AlertDialog.show('パッケージURIは必須です');
      return;
    }

    const modelsPostData: RequestModelsPost = {
      model_name: model_name,
      use_crop_parameters: toStringBoolean(use_crop_parameters),
      soracom_package_uri: soracom_package_uri,
    };
    const res = await modelsPostAPI(modelsPostData);
    if (res.status === 200) {
      AlertDialog.show('モデルの追加に成功しました');
      history.push('/models');
    }
  };

  // -- render part --
  return (
    <PageWholeArea data-testid='ModelCreate'>
      <Breadcrumbs
        items={[
          { href: '/models', icon: IconNames.DATA_LINEAGE, text: 'モデル' },
          { icon: IconNames.CUBE_ADD, text: 'モデルの追加' },
        ]}
      />
      <Title text='モデルの追加' />
      <Content>
        <FormContainer title='基本情報'>
          <InputComponent text='モデル名' required>
            <InputBox
              title='モデル名'
              placeholder='入力してください(必須)'
              value={model_name}
              onChange={(e) => setModelName(e.currentTarget.value)}
            />
          </InputComponent>
        </FormContainer>
        <FormContainer title='クロップ情報'>
          <InputComponent text='クロップ設定'>
            <CheckBoxWithText
              text='クロップ設定'
              checked={use_crop_parameters}
              onClick={() => setUseCropParameters(!use_crop_parameters)}
            />
          </InputComponent>
        </FormContainer>
        <FormContainer title='パッケージ情報'>
          <InputComponent text='パッケージURI' required>
            <InputBox
              title='パッケージURI'
              placeholder='入力してください(必須)'
              value={soracom_package_uri}
              onChange={(e) => setSoracompackageUri(e.currentTarget.value)}
            />
          </InputComponent>
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} text='キャンセル' is_white is_margin_right />
          <RoundedButton onClick={handleFinish} text='登録' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- finally export part --

export default ModelCreatePage;
