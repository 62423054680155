// -- basic library --
import React from 'react';
import SelectBox from '../atoms/SelectBox';

// -- type declaration --

// 昇順(0 -> 100) or 降順(100 -> 0)
export type ADOrder = 'ascending' | 'descending';

interface SelectBoxOrderProps {
  value: ADOrder;
  setValue: (value: ADOrder) => void;
  onFinished?: () => void;
}

// -- main component --

/***
 * 降順、昇順を選択するコンポーネント
 * ***/
const SelectBoxOrder = React.memo(function SelectBoxOrder(params: SelectBoxOrderProps) {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const new_order = e.currentTarget.value;
    if (new_order === 'ascending' || new_order === 'descending') {
      params.setValue(new_order);
      // 完了時の関数があれば実行
      if (params.onFinished) {
        params.onFinished();
      }
    }
  };

  // -- render part --
  return (
    <SelectBox
      {...params}
      default_text='null'
      data-testid='table-order-select-box'
      onChange={onChange}
      value={params.value}
      datas={[
        {
          name: '昇順',
          value: 'ascending',
        },
        {
          name: '降順',
          value: 'descending',
        },
      ]}
    />
  );
});

// -- finally export part --

export default SelectBoxOrder;
