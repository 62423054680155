import { Point, SizeWH, TrimedImage } from 'shared/models';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import { DateString, dateStringDay, dateStringMonth, dateStringYear, toDateString } from 'shared/models/DateString';
import { isTimeString, TimeString, timeStringHour, timeStringMinute, timeStringSecond } from 'shared/models/TimeString';
import { isBlob } from '../is';
import { toStringBoolean } from 'shared/models/StringBoolean';
import { isCommonAPIRequestType } from 'shared/models/CommonAPIType';

/**
 * T[] -> Record<string, T>に変換する関数
 * @param arr: T[]
 * @returns arrをRecord<key: T>の形に変換したデータ
 */
export const keyBy = <T>(values: T[], toKey: (t: T) => string): Record<string, T> =>
  values.reduce((prev, cur, _1, _2, k = toKey(cur)) => ((prev[k] = cur), prev), {} as Record<string, T>);

export const convertAutoReloadTime = (time: string): number => {
  let return_time = Infinity;
  if (time === '10sec') {
    return_time = 10000;
  }
  if (time === '1min') {
    return_time = 60000;
  }
  if (time === '2min') {
    return_time = 120000;
  }
  if (time === '5min') {
    return_time = 300000;
  }
  if (time === '10min') {
    return_time = 600000;
  }
  return return_time;
};

export const toNaNOrNumber = (str: string | undefined, to_NaN_where_undef = true) => {
  if (to_NaN_where_undef) {
    if (str === undefined || str.length <= 0) {
      return NaN;
    }
  }
  return Number(str);
};

/***
 * centerが中心座標(相対座標の中心点)
 * ***/
export const scaleChange = (coor: Point, center: Point, length: number): Point => {
  // ２点の直線の傾き(角度)
  const rad = Math.atan2(coor.y - center.y, coor.x - center.x);
  const newX = length * Math.cos(rad) + center.x;
  const newY = length * Math.sin(rad) + center.y;
  return {
    x: newX,
    y: newY,
  };
};

/** DateString と TimeString からUnix時刻を算出します。
 * isEnd を指定すると、59秒をセットします。
 */
export const dateTimeStringToFeatureDate = (date?: DateString | null, time?: TimeString | null, isEnd?: boolean) => {
  const dt = new Date();
  // なければ今日のdate
  const _date = date ?? toDateString('');
  dt.setFullYear(dateStringYear(_date));
  dt.setMonth(dateStringMonth(_date) - 1);
  dt.setDate(dateStringDay(_date));
  if (isTimeString(time)) {
    dt.setHours(timeStringHour(time));
    dt.setMinutes(timeStringMinute(time));
    if (isEnd) {
      dt.setSeconds(59);
    } else {
      dt.setSeconds(timeStringSecond(time));
    }
  }
  const initial = ~~(dt.getTime() / 1000);

  return new FeatureDatetime(initial);
};

export const toFormatBytes = (bytes: number, decimals: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const trimedImageToSize = (trimed_image: TrimedImage): SizeWH => {
  const rotate = trimed_image.rotate || 0;
  // imageのサイズ
  const size: SizeWH =
    trimed_image.size && rotate % 2 === 1
      ? trimed_image.size
      : trimed_image.size || {
          width: 580 * (16 / 9),
          height: 580,
        };
  return size;
};

/***
 * 2次元の配列を1次元の配列に変換する
 * ***/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arr2DTo1D = <T = any>(arr2D: T[][]): T[] => {
  const arr1D = arr2D.reduce((acc, cur) => {
    return [...acc, ...cur];
  });
  return arr1D;
};

export const toRequestBool = (bool?: boolean) => (bool ? 'True' : undefined);

export const streamDataTypeToMedia = (data_type: string, data_number_type: string) => {
  let flag = 'else';
  if (data_number_type === 'TIMESTAMP') {
    if (data_type === 'IMAGE' || data_type === 'VIDEO') {
      flag = 'video';
    } else if (data_type === 'METRIC') {
      flag = 'metric';
    }
  }
  if (data_type === 'IMAGE' || data_type === 'VIDEO') {
    if (data_number_type === 'SEQUENCE' || data_number_type === 'USER_SPECIFIC') {
      flag = 'thumbnail';
    }
  }
  if (data_type === 'METRIC') {
    flag = 'metric';
  }
  return flag;
};
/***
 * 請求機能画面にて、取得したcsvを請求csvと内訳csvデータに分割する
 * ***/

interface Csv {
  csv_index: number; // 元のcsvのindexを表す。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  csv: Record<string, any>; // さまざまな属性が存在する。({列: 値}の関係)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const billingClaimGet = (billingCsv: any) => {
  const claim_csv: Csv[] = [];
  const claim: string[] = [];
  for (const d of billingCsv) {
    const csv_data = d.csv;
    if (csv_data[0] == '請求金額合計 (税別)' || csv_data[0] == '消費税' || csv_data[0] == '請求金額合計 (税込)') {
      csv_data[3] = formatter.format(Number(csv_data[3])) + '円';
      claim.push(csv_data[3]);
    }
  }

  const claim_0: Csv = {
    csv_index: 0,
    csv: ['請求金額合計 (税別)', '消費税', '請求金額合計 (税込)'],
  };
  const claim_1: Csv = {
    csv_index: 1,
    csv: claim,
  };

  claim_csv[0] = { ...claim_0 };
  claim_csv[1] = { ...claim_1 };
  return claim_csv;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const billingBreakdownGet = (billingCsv: any) => {
  const breakdown_csv: Csv[] = [];
  let i = 1;
  for (const d of billingCsv) {
    const csv_data = d.csv;
    if (
      csv_data[0] != '月額基本料' &&
      csv_data[0] != '請求金額合計 (税別)' &&
      csv_data[0] != '消費税' &&
      csv_data[0] != '請求金額合計 (税込)'
    ) {
      const breakdown_data: Csv = {
        csv_index: i,
        csv: csv_data,
      };
      breakdown_csv[i] = { ...breakdown_data };
      i += 1;
    } else if (csv_data[0] == '月額基本料') {
      const header = [csv_data[0], '', '', ''];
      const breakdown_header: Csv = {
        csv_index: i,
        csv: header,
      };
      breakdown_csv[i] = { ...breakdown_header };
      i += 1;
      const data = ['', '', '', csv_data[3]];
      const breakdown_data: Csv = {
        csv_index: i,
        csv: data,
      };
      breakdown_csv[i] = { ...breakdown_data };
      i += 1;
    }
  }

  return breakdown_csv;
};

/***
 * 請求機能画面にて、取得した内訳csvを出力用データに変換する
 * ***/
// 金額表示フォーマット
export const formatter = new Intl.NumberFormat('ja-JP');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const billingDisplaySet = (billingCsv: any) => {
  const breakdown_data: string[] = [];
  billingCsv.forEach((cd: string, y: number) => {
    // 単価、請求金額の表示に円をつける
    if ((y > 1 && Number(cd)) || cd == '0') {
      cd = formatter.format(Number(cd)) + '円';
    }
    // 数量、単価の末尾に計算単位の追加
    // 全角スペースは表示上円と末尾を揃えるため
    if (billingCsv[0].slice(0, 6) == 'ファイル処理') {
      if (y == 1) {
        if (Number(cd) >= 60) {
          const hour = Math.floor(Number(cd) / 60);
          const min = Number(cd) - hour * 60;
          cd = String(hour) + '時間' + String(min) + '分';
        } else {
          cd = cd + '分';
        }
      } else if (y == 2) {
        cd = cd + '　/ 時間';
      }
    } else if (billingCsv[0].slice(0, 9) == 'チャンネルプロセス') {
      if (y == 1) {
        cd = cd + 'ヶ月';
      } else if (y == 2) {
        cd = cd + '　/ 　月';
      }
    } else if (
      billingCsv[0] == 'ダッシュボード' ||
      billingCsv[0] == 'イベント監視' ||
      billingCsv[0].slice(0, 5) == 'データ保管'
    ) {
      if (y == 1) {
        cd = cd + '件';
      } else if (y == 2) {
        cd = cd + '　/ 　件';
      }
    }
    breakdown_data.push(cd);
  });
  return breakdown_data;
};

/**
 * 整形してformDataへ追加する(もしくは追加しない)関数
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addFormData = (args: { form: FormData; key: string; value: any }) => {
  const { form, key, value } = args;
  if (isCommonAPIRequestType(key)) return;
  if (typeof value === 'string' || typeof value === 'number') {
    form.append(key, String(value));
  } else if (typeof value === 'boolean') {
    form.append(key, toStringBoolean(value));
  } else if (isBlob(value)) {
    form.append(key, value);
  }
};
