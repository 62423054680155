import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import BaseIcon, { BaseIconProps } from './BaseIcon';

/**
 * Informationを表すアイコンです。
 */

export const InformationIcon = memo(function InformationIcon(props: Partial<BaseIconProps>) {
  return <BaseIcon {...props} icon={IconNames.INFO_SIGN} />;
});
