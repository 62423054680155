import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { PageWholeArea, Title, Breadcrumbs, Content, Footer } from 'shared/components/molecules/ContentsArea';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import { isNotOnlySpace } from 'shared/utils/is';
import { edgeDevicesKeyGetAPI, edgeDevicesKeyPutAPI } from 'admin/api/edgeDevices';
import { useTenants } from 'admin/hooks/useTenants/useTenants';
import history from 'shared/browserHistory';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import InputComponent from 'shared/components/molecules/InputComponent';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';

// -- main component --
type Params = RouteComponentProps<{ edge_device_key: string }>;

const DevicesDetailPage: React.FC<Params> = (params) => {
  // -- local states --
  const [tenant_id, setTenantId] = useState<string>('');
  const [edge_device_name, setEdgeDeviceName] = useState<string>('');
  const { tenants } = useTenants({});

  const getEdgedevicesData = async (edge_device_key: string) => {
    const res = await edgeDevicesKeyGetAPI({
      edge_device_key: edge_device_key,
    });
    if (res.status === 200) {
      setEdgeDeviceName(res.data.heartbeat_edge_device_name);
      setTenantId(res.data.tenant_id);
    }
  };

  const onCancelClick = () => {
    history.push('/edgeDevices');
  };

  const onUpdateClick = () => {
    if (!isNotOnlySpace(edge_device_name)) {
      AlertDialog.show('エッジデバイス名(顧客から見える情報)は必須です');
      return;
    }
    edgeDevicesKeyPutAPI({
      edge_device_key: params.match.params.edge_device_key,
      tenant_id: tenant_id || '',
      heartbeat_edge_device_name: edge_device_name,
    }).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('デバイスの更新に成功しました。');
        history.push('/edgeDevices');
      }
    });
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // エッジデバイス情報の取得
      await getEdgedevicesData(params.match.params.edge_device_key);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='EdgeDevicesDetail'>
      <Breadcrumbs
        items={[
          { href: '/edgeDevices', icon: IconNames.CONTROL, text: 'エッジデバイス' },
          { icon: IconNames.CUBE_ADD, text: 'エッジデバイス詳細' },
        ]}
      />
      <Title text='エッジデバイス詳細' />
      <Content>
        <FormContainer title='基本情報'>
          <InputComponent text='エッジデバイスキー'>
            <div>{params.match.params.edge_device_key}</div>
          </InputComponent>
          <InputComponent text='テナント'>
            <SelectBox
              onChange={(e) => setTenantId(e.currentTarget.value)}
              value={tenant_id}
              datas={
                tenants?.map((d) => {
                  return {
                    name: d.tenant_name,
                    value: d.tenant_id,
                  };
                }) ?? []
              }
              long
            />
          </InputComponent>
          <InputComponent text='エッジデバイス名' required>
            <InputBox
              title='エッジデバイス名'
              placeholder={edge_device_name}
              value={edge_device_name}
              minLength={1}
              onChange={(e) => setEdgeDeviceName(e.currentTarget.value)}
            />
          </InputComponent>
        </FormContainer>
        <Footer>
          <RoundedButton onClick={onCancelClick} text='戻る' is_margin_right is_white />
          <RoundedButton onClick={onUpdateClick} text='更新' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --
// -- finally export part --

export default DevicesDetailPage;
