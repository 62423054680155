import { useCallback, useMemo, useState } from 'react';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import { IconNames } from '@blueprintjs/icons';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import styles from 'shared/styles/styles';
import { DynamicSearch } from './DynamicSearch';
import { DynamicSearchParent, Header, SearchRow, Footer } from './components';
import { DeleteIcon } from 'shared/components/atoms/Icons';
import { keyBy } from 'shared/utils/converter';
import {
  Search,
  SearchRequiredItem,
  SearchItemRecord,
  isSearchRequiredItem,
  searchRequiredItemTosearch,
  isSearchType,
} from 'shared/models/Search';
import { useSearches } from './useSearches';
import AlertDialog from '../../AlertDialog';
import RequiredIcon from 'shared/components/atoms/RequiredIcon';
import { TitleSmall } from '../../ContentsArea';
import FunctionalText from 'shared/components/atoms/FunctionalText';

const validate = (api_searches: Search[]) => {
  let required_empty_flag = false;
  let option_exist_flag = false;
  for (const search of api_searches) {
    if (search.required && !search.value) {
      required_empty_flag = true;
    } else if (!search.required && search.value) {
      option_exist_flag = true;
    }
  }
  if (required_empty_flag && option_exist_flag) {
    throw new Error(`メインにおいて、必須項目を入力しない場合は、必須でない項目も入力できません`);
  }
};

const initialSearches = (search_required_items: SearchRequiredItem[]) => {
  const new_searches: Search[] = [];
  search_required_items.forEach((sri) => {
    if (sri.default_display) {
      new_searches.push(searchRequiredItemTosearch(sri));
    }
  });
  return new_searches;
};

const initialApiSearches = (search_required_items: SearchRequiredItem[]) => {
  const new_searches: Search[] = [];
  search_required_items.forEach((sri) => {
    if (sri.is_api_search) {
      new_searches.push(searchRequiredItemTosearch(sri));
    }
  });
  return new_searches;
};

interface SearchPanelProps {
  search_required_items: SearchRequiredItem[];
  onSearch(searches: Search[]): void;
  onAPISearch?(api_searches_record: Record<string, Search>): void;
  table_name: string;
  onDownloadClick?: (searches: Search[]) => void;  // 「ダウンロード」ボタンのイベントハンドラ
}
export default function SearchPanel({ search_required_items, onSearch, onAPISearch, table_name, onDownloadClick }: SearchPanelProps) {
  // API検索用のsearches
  const {
    searches: api_searches,
    searches_record: api_searches_record,
    updateSearch: updateApiSearch,
    initializedSearches: initializedApiSearches,
  } = useSearches(initialApiSearches(search_required_items), table_name, 'main');
  // ローカル検索用のsearches
  const { searches, addSearch, deleteSearch, updateSearch, initializedSearches } = useSearches(
    initialSearches(search_required_items),
    table_name,
    'filter',
  );
  const [prev_api_searches_record, setPrevApiSearchesRecord] = useState<Record<string, Search>>({});
  // 前回のapi_searchesと現在のapi_searchesを比較し、違いがある場合はtrue
  const has_api_differences = useMemo(() => {
    let diff_flag = false;
    for (const api_search of api_searches) {
      const { search_key, value, value2 } = api_search;
      if (
        value !== prev_api_searches_record[search_key]?.value ||
        value2 !== prev_api_searches_record[search_key]?.value2
      ) {
        diff_flag = true;
        break;
      }
    }
    return diff_flag;
  }, [api_searches, prev_api_searches_record]);
  const search_required_item_record = useMemo(() => {
    const new_search_item_record: SearchItemRecord = keyBy(search_required_items, (t) => t.key);
    return new_search_item_record;
  }, [search_required_items]);

  // searchesのindex番目のsearch_keyを更新する
  const onChangeSearchesOfKey = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.currentTarget.value;
      const search_required_item = search_required_item_record[value];
      if (isSearchRequiredItem(search_required_item)) {
        updateSearch(index, searchRequiredItemTosearch(search_required_item));
      } else if (!value) {
        // valueが空文字列の時は初期状態に戻す
        updateSearch(index, {
          search_key: '',
          value: '',
          search_name: '',
        });
      }
    },
    [search_required_item_record, updateSearch],
  );

  const onCustomSearch = useCallback(() => {
    // バリデーション(現状API検索の部分のみ)
    try {
      validate(api_searches);
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
        return;
      }
    }

    // api_searchesのvalueが変わっている場合はAPI検索を行う
    if (has_api_differences) {
      onAPISearch?.(api_searches_record);
      setPrevApiSearchesRecord(api_searches_record);
    }
    onSearch(searches);
  }, [api_searches_record, api_searches, searches, has_api_differences, onAPISearch, onSearch]);

  const handleDownloadClick = useCallback(() => {
    if (onDownloadClick) {
      onDownloadClick(searches);
    }
  }, [searches, onDownloadClick]);

  /**
   * SelectBoxで利用するdata
   * */
  const local_select_box_data: SelectData<string>[] = useMemo(() => {
    const items = search_required_items.filter((search_required_item) => {
      return !search_required_item.is_api_search;
    });
    return items.map((search_required_item) => {
      return {
        name: search_required_item.name,
        value: search_required_item.key,
      };
    });
  }, [search_required_items]);
  return (
    <div style={{ padding: 10 }}>
      {api_searches.length > 0 && (
        <div style={{ marginBottom: 30 }}>
          <Header>
            <TitleSmall text='メイン' />
            <div style={{ display: 'flex' }}>
              <RoundedButton text='元に戻す' onClick={initializedApiSearches} small={true} is_white={true} />
            </div>
          </Header>
          {api_searches.map((api_search, i) => {
            return (
              <SearchRow key={i}>
                <span
                  style={{
                    width: 180,
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: styles.interval_x_narrow_margin,
                  }}
                >
                  {api_search.search_name}
                  {api_search.required && <RequiredIcon />}
                </span>

                <DynamicSearchParent>
                  {isSearchType(api_search.search_type) && (
                    <DynamicSearch
                      search={api_search}
                      setSearch={(new_search) => updateApiSearch(i, new_search)}
                      index={i}
                    />
                  )}
                </DynamicSearchParent>
              </SearchRow>
            );
          })}
        </div>
      )}
      <Header>
        <TitleSmall text='フィルター' />
        <div style={{ display: 'flex' }}>
          <RoundedButton text='追加' onClick={addSearch} small={true} is_margin_right={true} />
          <RoundedButton text='元に戻す' onClick={initializedSearches} small={true} is_white={true} />
        </div>
      </Header>
      {searches.map((search, i) => {
        return (
          <SearchRow key={i}>
            <SelectBox
              style={{ marginRight: styles.interval_x_narrow_margin, width: 180 }}
              onChange={onChangeSearchesOfKey(i)}
              value={search.search_key}
              datas={local_select_box_data}
              data-testid={`${i}-search-select`}
            />
            <DynamicSearchParent>
              {isSearchType(search.search_type) && (
                <DynamicSearch search={search} setSearch={(new_search) => updateSearch(i, new_search)} index={i} />
              )}
            </DynamicSearchParent>

            <DeleteIcon onClick={() => deleteSearch(i)} iconSize={16} />
          </SearchRow>
        );
      })}
      <Footer style={{position: 'relative'}}>
        <RoundedButton onClick={onCustomSearch} text='検索する' iconProps={{ icon: IconNames.SEARCH }} />
        {onDownloadClick && 
          <div style={{position: 'absolute', right: 0, bottom: 0}}>
            <FunctionalText onClick={handleDownloadClick} text='＞ダウンロードする' />
          </div>
        }
      </Footer>
    </div>
  );
}
