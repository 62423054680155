// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, TopArea, BottomArea, Footer } from 'shared/components/molecules/ContentsArea';
import { TableBillingFileProcessesType } from './BillingFileProcessTable';
import { RouteComponentProps } from 'react-router-dom';
import { BillingProcessType } from 'shared/models/BillingProcessType';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import BillingFileProcessTable from './BillingFileProcessTable';
import { useBillingcollects } from 'admin/hooks/useBillingcollects/useBillingcollects';

// -- external functions --
type Params = RouteComponentProps<{
  yearmonth: string;
  tenant_id: string;
  component_name: string;
  process_type: BillingProcessType;
}>;

// -- main component --
const BillingsFileProcessDetail: React.FC<Params> = (params) => {
  // -- local states --
  const { tenant_id, yearmonth, component_name, process_type } = params.match.params;
  const { billing_collects } = useBillingcollects({
    yearmonth,
    tenant_id,
    component_name,
    process_type,
  });
  const [bodies, setBodies] = useState<TableBillingFileProcessesType[]>([]);
  const title =
    process_type === 'DETECTION'
      ? 'ファイル処理詳細 (検知)'
      : process_type === 'COUNT'
      ? 'ファイル処理詳細 (カウント)'
      : 'ファイル処理詳細 (滞留)';

  // -- handlers --
  const loadTableBodies = useCallback(() => {
    const return_table_datas: TableBillingFileProcessesType[] = [];
    billing_collects?.map((billing_collect) => {
      const return_table_data: TableBillingFileProcessesType = {
        id: billing_collect.component_id,
        process_flow_name: billing_collect.process_flow_name,
        process_flow: billing_collect.process_flow,
        folder_name: billing_collect.folder_name,
        stream_data_number: billing_collect.stream_data_number,
        file_name: billing_collect.file_name,
        file_time: billing_collect.file_time + '分',
        file_fps: billing_collect.file_fps,
        amount: billing_collect.amount + '分',
        demand_price: billing_collect.demand_price + '円',
        create_date: billing_collect.created_at,
      };
      return_table_datas.push(return_table_data);
    });
    setBodies(return_table_datas);
  }, [billing_collects]);

  const handleCancelClick = () => {
    history.push(`/billings/${yearmonth}/${tenant_id}/detail`);
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [loadTableBodies]);

  // -- render part --
  return (
    <PageWholeArea data-testid='Billings'>
      <TopArea>
        <Title text={title} />
      </TopArea>
      <BottomArea>{bodies ? <BillingFileProcessTable bodies={bodies} /> : <Spinner />}</BottomArea>
      <Footer>
        <RoundedButton onClick={handleCancelClick} is_margin_right is_white text='戻る' />
      </Footer>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default BillingsFileProcessDetail;
