import { Icon, IconProps } from '@blueprintjs/core';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

const hover_style = `
cursor: pointer;
&:hover {
    opacity: ${styles.opacity_hover};
}`;

export interface BaseIconProps extends IconProps {
  disabled_hover?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
/**
 * blueprint系の基本Icon
 */
export const BaseIcon = styled((props: BaseIconProps) => <Icon {...props} />)`
  cursor: pointer;
  ${({ disabled_hover }) => !disabled_hover && hover_style}
`;

export default BaseIcon;
