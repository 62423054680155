// -- basic library --
import React from 'react';
import styled from 'styled-components';
import { DownIcon, UpIcon } from 'shared/components/atoms/Icons';
import { colors } from 'shared/styles/colors';
// -- type declaration --
interface Params {
  handleClick1: () => void;
  handleClick2: () => void;
  selected1: boolean;
  selected2: boolean;
}

// -- main component --

const SortIconForTable = React.memo(
  function SortIconForTable(params: Params) {
    // -- preparations --

    // -- render part --
    return (
      <WholeAreaForSortIcon style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fill: 'red' }}>
        <UpIcon
          onClick={params.handleClick1}
          style={{ color: params.selected1 ? colors.component_main_color : undefined }}
        />
        <DownIcon
          onClick={params.handleClick2}
          style={{ color: params.selected2 ? colors.component_main_color : undefined }}
        />
      </WholeAreaForSortIcon>
    );
  },
  (prev_props, next_props) =>
    prev_props.handleClick1 == next_props.handleClick1 && prev_props.handleClick2 === next_props.handleClick2,
);

// -- styled components --
const WholeAreaForSortIcon = styled.div`
  width: 10px;
  height: auto;
  margin: 0px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// -- finally export part --

export default SortIconForTable;
