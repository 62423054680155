import sendAxios from 'shared/axios/sendAxios';
import { Query } from 'shared/models/Query';
import { getClient } from './base';

export interface SoracomDevice {
  soracom_device_id: string;
  soracom_device_name: string;
  soracom_device_group_id: string;
}

export interface SoracomGroup {
  soracom_group_id: string;
  soracom_group_name: string;
}

const soracom_device1: SoracomDevice = {
  soracom_device_id: '000000001',
  soracom_device_name: 'a1',
  soracom_device_group_id: '000000001',
};

const soracom_group1: SoracomGroup = {
  soracom_group_id: 'a8wbrauvb',
  soracom_group_name: 'soracom-group-name1',
};

/*** [GET] /api/soracom/devices ***/

export interface RequestSoracomDevicesGet {}

export const soracomDevicesGetAPI = (params: RequestSoracomDevicesGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/soracom/devices`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<SoracomDevice[]>(axios, path, query, form, method, [soracom_device1]);
};

/*** [GET] /api/soracom/groups ***/

export interface RequestSoracomGroupsGet {}

export const soracomGroupsGetAPI = (params: RequestSoracomGroupsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/soracom/groups`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<SoracomGroup[]>(axios, path, query, form, method, [soracom_group1]);
};
