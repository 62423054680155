import { RequestTenantsIdPut, RequestTenantsPost } from 'admin/api/tenants';
import { getSubDomain } from 'shared/utils/get';
import { isNotOnlySpace } from 'shared/utils/is';

export const tableauIdValidator = (tableau_id: string | null) => {
  if (typeof tableau_id !== 'string') {
    throw new Error('tableau識別子を入力してください');
  }
  if (tableau_id.length > 50) {
    throw new Error('tableau識別子は50字以下です');
  }
  const regex = /^[A-Za-z0-9_]+$/;
  if (!regex.test(tableau_id)) {
    throw new Error('tableau識別子は大文字、小文字、数字、アンダースコア(_)のみ許されています');
  }
  return tableau_id;
};

export const tableauValidator = (args: { use_tableau: boolean; tableau_id: string | null }) => {
  const { use_tableau, tableau_id } = args;
  if (use_tableau) {
    try {
      const validated_tableau_id = tableauIdValidator(tableau_id);
      return validated_tableau_id;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  }
  return undefined;
};

export interface TableauPostValidatorProps {
  tenant_name: string;
  tenant_domain: string;
  tenant_generator2_domain: string;
  use_tableau: boolean;
  tableau_id: string | null;
}

export const tenantsPostValidator = (args: TableauPostValidatorProps) => {
  const { tenant_name, tenant_domain, tenant_generator2_domain, use_tableau, tableau_id } = args;
  const request: RequestTenantsPost = {
    tenant_name,
    tenant_domain: '',
    tenant_generator2_domain,
    use_tableau,
    tableau_id: undefined,
  };
  if (!isNotOnlySpace(tenant_name)) {
    throw new Error('テナント名は必須です');
  }
  if (!isNotOnlySpace(tenant_domain)) {
    throw new Error('テナントドメインは必須です');
  }
  // input tenant_domain + sub_domain
  request.tenant_domain = tenant_domain + getSubDomain();
  if (use_tableau) {
    try {
      const validated_tableau_id = tableauIdValidator(tableau_id);
      request.tableau_id = validated_tableau_id;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  }
  return request;
};

export interface TableauIdPutValidatorProps {
  tenant_id: string;
  tenant_name: string;
  tenant_domain: string;
  tenant_generator2_domain: string;
  use_tableau: boolean;
  tableau_id: string | null;
  disabled_change_tableau_id: boolean;
}

export const tenantsIdPutValidator = (args: TableauIdPutValidatorProps) => {
  const {
    tenant_id,
    tenant_name,
    tenant_domain,
    tenant_generator2_domain,
    use_tableau,
    tableau_id,
    disabled_change_tableau_id,
  } = args;
  const request: RequestTenantsIdPut = {
    tenant_id,
    tenant_name,
    tenant_domain: '',
    tenant_generator2_domain,
    use_tableau,
    tableau_id: undefined,
  };
  if (!isNotOnlySpace(tenant_name)) {
    throw new Error('テナント名は必須です');
  }
  if (!isNotOnlySpace(tenant_domain)) {
    throw new Error('テナントドメインは必須です');
  }
  // input tenant_domain + sub_domain
  request.tenant_domain = tenant_domain + getSubDomain();
  if (!disabled_change_tableau_id && use_tableau) {
    try {
      const validated_tableau_id = tableauIdValidator(tableau_id);
      request.tableau_id = validated_tableau_id;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  }
  return request;
};
