// -- basic library --
import React from 'react';

// -- external components --
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { ISO8601 } from 'shared/models/ISO8601';

// -- external types --

// -- external functions --

// -- type declaration --

interface Params {
  bodies: TableEdgeDeviceType[];
  selected_bodies: TableEdgeDeviceType[];
  handleCheckClick: (datas: TableEdgeDeviceType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableEdgeDeviceType {
  id: string;
  edge_device_key: TableBodyUrlType;
  key_type: string;
  tenant_id: string;
  heartbeat_edge_device_name: string;
  heartbeat_ip: string;
  heartbeat_at: string;
  latlon: TableBodyUrlType | string;
  latlon_at: ISO8601 | string;
  edge_device_status: string;
  status: string;
}

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'edge_device_key',
    label: 'エッジデバイスキー',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'key_type',
    label: 'キータイプ',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'heartbeat_edge_device_name',
    label: 'エッジデバイス名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'heartbeat_ip',
    label: 'ハートビートIP',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'edge_device_status',
    label: 'ステータス',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'heartbeat_at',
    label: 'ハートビート時刻',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'latlon',
    label: 'デバイス位置情報',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'latlon_at',
    label: '位置情報収集時刻',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
];

// -- main component --
const EdgeDeviceTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='edge_devices'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default EdgeDeviceTable;
