import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { ListingVisibility } from 'shared/models/ListingVisibility';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { StreamDataNumberType } from 'shared/models/StreamDataNumberType';
import { StreamDataType } from 'shared/models/StreamDataType';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';
import { getClient } from './base';

/*** エンティティ ***/

export interface Stream {
  tenant_id: string;
  stream_id: string;
  stream_name: string;
  status: StatusType;
  data_number_type: StreamDataNumberType;
  data_type: StreamDataType;
  data_store_period: number;
  total_data_count: number;
  total_data_size: number;
  total_package_size: number;
  metrics_jsonpath_templates?: { name: string; jsonpath: string }[];
  device_id: string;
  device_data_category: string;
  device_graphs?: StreamDeviceGraph[];
  input_channel_event_conditions: string[];
  input_channel_processes: string[];
  jsonpath_group_id: string[];
  channel_id?: string;
  output_channel_process: string | null;
  streaming_endpoint: string | null;
  is_published: boolean;
  publish_id: string | null;
  edgebox_key: string | null;
  estimated_live_delay: number | null;
  listing_visibility: ListingVisibility;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface StreamsWithPaging {
  items: Stream[];
  has_next: boolean;
  exclusive_start_stream_id?: string;
  exclusive_start_tenant_id?: string;
}

const sample_stream_1: Stream = {
  tenant_id: '00000001',
  stream_id: '00000001',
  stream_name: 'ストリーム1',
  status: 'ACTIVE',
  data_number_type: 'USER_SPECIFIC',
  data_type: 'METRIC',
  data_store_period: 0,
  total_data_count: 3,
  total_data_size: 1701000000,
  total_package_size: -1,
  device_id: '00000001',
  device_data_category: 'count_data',
  device_graphs: [
    {
      id: 'LINE0_LR_GENDER_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'LR',
      target: 'GENDER',
    },
    {
      id: 'LINE0_RL_GENERATION_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'RL',
      target: 'GENERATION',
    },
    {
      id: 'LINE1_LR_TOTAL_BAR',
      graph_type: 'BAR',
      line_number: 0,
      direction: 'LR',
      target: 'TOTAL',
    },
  ],
  input_channel_event_conditions: [],
  input_channel_processes: [],
  jsonpath_group_id: [],
  channel_id: '00000001',
  output_channel_process: null,
  streaming_endpoint: null,
  edgebox_key: null,
  is_published: false,
  publish_id: null,
  estimated_live_delay: null,
  listing_visibility: 'INPUT',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_stream_2: Stream = {
  tenant_id: '00000001',
  stream_id: '00000002',
  stream_name: 'ストリーム2',
  status: 'ACTIVE',
  data_number_type: 'SEQUENCE',
  data_type: 'VIDEO',
  data_store_period: 0,
  total_data_count: 3,
  total_data_size: 1701000000,
  total_package_size: 2,
  device_id: '00000001',
  device_data_category: 'string',
  device_graphs: [
    {
      id: 'LINE0_LR_GENDER_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'LR',
      target: 'GENDER',
    },
    {
      id: 'LINE0_RL_GENERATION_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'RL',
      target: 'GENERATION',
    },
    {
      id: 'LINE1_LR_TOTAL_BAR',
      graph_type: 'BAR',
      line_number: 0,
      direction: 'LR',
      target: 'TOTAL',
    },
  ],
  input_channel_event_conditions: ['00000001', '00000002'],
  input_channel_processes: ['00000001', '00000002'],
  jsonpath_group_id: ['00000001', '00000002'],
  channel_id: '00000002',
  output_channel_process: '00000001',
  streaming_endpoint: null,
  edgebox_key: null,
  is_published: false,
  publish_id: null,
  estimated_live_delay: null,
  listing_visibility: 'INPUT',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_stream_3: Stream = {
  tenant_id: '00000001',
  stream_id: '00000003',
  stream_name: 'ストリーム3',
  status: 'ACTIVE',
  data_number_type: 'TIMESTAMP',
  data_type: 'METRIC',
  data_store_period: 0,
  total_data_count: 3,
  total_data_size: 1701000000,
  total_package_size: 1,
  device_id: '00000001',
  device_data_category: 'string',
  device_graphs: [
    {
      id: 'LINE0_LR_GENDER_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'LR',
      target: 'GENDER',
    },
    {
      id: 'LINE0_RL_GENERATION_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'RL',
      target: 'GENERATION',
    },
    {
      id: 'LINE1_LR_TOTAL_BAR',
      graph_type: 'BAR',
      line_number: 0,
      direction: 'LR',
      target: 'TOTAL',
    },
  ],
  input_channel_event_conditions: ['00000001', '00000002'],
  input_channel_processes: ['00000001', '00000002'],
  jsonpath_group_id: ['00000001', '00000002'],
  channel_id: '',
  output_channel_process: '00000001',
  streaming_endpoint: null,
  edgebox_key: null,
  is_published: false,
  publish_id: null,
  estimated_live_delay: null,
  listing_visibility: 'INPUT',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** Caching mechanism ***/

export class CachedStreams {
  private searched = false;
  private cache: Stream[] = [];
  private params: RequestStreamsGet;
  constructor(params: RequestStreamsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk_tenant_id: string | undefined = undefined;
      let esk_stream_id: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<StreamsWithPaging> = await streamsGetAPI({
          ...this.params,
          exclusive_start_stream_id: esk_stream_id,
          exclusive_start_tenant_id: esk_tenant_id,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next) {
            break;
          } else {
            esk_tenant_id = res.data.exclusive_start_tenant_id;
            esk_stream_id = res.data.exclusive_start_stream_id;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/streams ***/

export interface RequestStreamsGet {
  exclusive_start_stream_id?: string;
  exclusive_start_tenant_id?: string;
  limit?: number;
  tenant_id?: string;
  stream_id?: string;
}

export const streamsGetAPI = (params: RequestStreamsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/streams`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<StreamsWithPaging>(axios, path, query, form, method, {
    items: [sample_stream_1, sample_stream_2, sample_stream_3],
    has_next: false,
  });
};

/*** [GET] /api/streams/{stream_id} ***/

// export interface RequestStreamsIdGet extends CommonAPIRequestType {
export interface RequestStreamsIdGet {
  stream_id: string;
  tenant_id: string;
}

export const streamsIdGetAPI = (params: RequestStreamsIdGet) => {
  const { stream_id, tenant_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    tenant_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Stream>(axios, path, query, form, method, sample_stream_3);
};
export interface RequestStreamsIdPut {
  stream_id: string;
  tenant_id: string;
  data_store_period: number;
}

export const streamsIdPutAPI = (params: RequestStreamsIdPut) => {
  const { stream_id, tenant_id, data_store_period } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries({ tenant_id, data_store_period })) {
    form.append(key, String(value));
  }
  // 送信
  return sendAxios<Stream>(axios, path, query, form, method, sample_stream_3);
};
