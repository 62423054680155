import { systemAdminActions } from 'admin/redux/slices/systemSlice';
import { store } from 'admin/redux/store';

/**
 * [管理者画面]非同期関数を並列に実行するラッパー
 * @param promises
 * @returns
 */
export const preloadGroup = async (...promises: (Promise<void> | void)[]): Promise<void> => {
  store.dispatch(systemAdminActions.preloading(true));
  Promise.all(promises).then(() => {
    store.dispatch(systemAdminActions.preloading(false));
  });
};

export default preloadGroup;
