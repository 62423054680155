import React, { useCallback, useMemo, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import DashboardTable, { TableDashboardType } from './DashboardTable';
import { useTenants } from 'admin/hooks/useTenants/useTenants';
import { dashboardsIdPutAPI } from 'admin/api/dashboards';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { table_cell_toggle_button_style } from 'shared/styles/styles';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { toStringBoolean } from 'shared/models/StringBoolean';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { useDashboards } from 'admin/hooks/useDashboards/useDashboards';

/** ダッシュボード管理のトップ画面です */
// -- main component --
const Dashboard: React.FC = () => {
  // -- local states --
  const { tenants_record } = useTenants({ status: 'ALL' });
  // dashboardsの一覧とdashboardsをロードする関数
  const { dashboards, loadDashboards } = useDashboards();
  const [selected_bodies, setSelectedBodies] = useState<TableDashboardType[]>([]);

  const handleOKClick = useCallback(
    async (params: { tenant_id: string; dashboard_id: string; is_billing: boolean }) => {
      // 今のstatusの反転したbooleanを送信する
      const res = await dashboardsIdPutAPI({
        tenant_id: params.tenant_id,
        dashboard_id: params.dashboard_id,
        is_billing: toStringBoolean(!params.is_billing),
      });
      if (res.status === 200) {
        AlertDialog.show('課金情報の更新に成功しました。');
      }
      await loadDashboards();
    },
    [loadDashboards],
  );

  // テーブルの状態でトグルボタンを押した時の関数
  const onChangeStatus = useCallback(
    async (params: {
      tenant_id: string;
      tenant_name: string;
      dashboard_id: string;
      dashboard_name: string;
      is_billing: boolean;
    }) => {
      ConfirmDialog.show(
        <div>
          [確認]
          <br />
          {params.tenant_name}の{params.dashboard_name}の課金情報を変更します。
          <br />
          本当によろしいですか？
        </div>,
        () =>
          handleOKClick({
            tenant_id: params.tenant_id,
            dashboard_id: params.dashboard_id,
            is_billing: params.is_billing,
          }),
        undefined,
        undefined,
      );
    },
    [handleOKClick],
  );

  const bodies = useMemo(() => {
    return dashboards?.map((item) => {
      const tenant_name = tenants_record[item.tenant_id]?.tenant_name ?? '';
      return {
        id: item.dashboard_id,
        tenant_id: item.tenant_id,
        tenant_name: tenant_name,
        dashboard_id: item.dashboard_id,
        dashboard_name: item.dashboard_name,
        created_at: dateToYMDHMS(item.created_at),
        is_billing: {
          value: item.is_billing ? 'ON' : 'OFF',
          available_value: item.is_billing ? 'ON' : 'OFF',
          displayable_value: (
            <ToggleButton
              stop_propagation={true}
              onClick={() => {
                onChangeStatus({
                  tenant_id: item.tenant_id,
                  tenant_name: tenant_name,
                  dashboard_id: item.dashboard_id,
                  dashboard_name: item.dashboard_name,
                  is_billing: item.is_billing,
                });
              }}
              checked={item.is_billing}
              style={table_cell_toggle_button_style}
            />
          ),
        },
      };
    });
  }, [dashboards, tenants_record, onChangeStatus]);

  const handleClick = (datas: TableDashboardType[]) => {
    setSelectedBodies(datas);
  };

  return (
    <PageWholeArea>
      <TopArea>
        <Title text='ダッシュボード一覧' />
      </TopArea>
      <BottomArea>
        {bodies !== undefined ? (
          <DashboardTable bodies={bodies} selected_bodies={selected_bodies} handleCheckClick={handleClick} />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Dashboard;
