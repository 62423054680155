import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import BaseIcon, { BaseIconProps } from './BaseIcon';

/**
 * 星を表すアイコンです。
 */
interface StarIconProps extends Partial<BaseIconProps> {
  fill?: boolean;
}
export const StarIcon = memo(function StarIcon(props: StarIconProps) {
  return <BaseIcon {...props} icon={props.fill ? IconNames.STAR : IconNames.STAR_EMPTY} />;
});
