/**
 * API通信するタイプ
 * multipleTransmission: 連続でAPI送信を行う時
 * changeableOneTransmission: DBの変更が生じる1度のAPI送信を行う時(例：POST, PUT, DELETE)
 */
export type APISendType = 'multipleTransmission' | 'changeableOneTransmission';
/** 全APIの共通interface */
export interface CommonAPIRequestType {
  /** ロード開始を無効化する(デフォルトtrue)*/
  disabled_load?: boolean;
  /** エラーメッセージを無効化する(デフォルトfalse) */
  disabled_error_message?: boolean;
  /** ロード処理を終了する(デフォルトtrue) */
  ended_load?: boolean;
  /** API通信するタイプ(ロードパターンが変わる) */
  api_send_type?: APISendType;
}

/* Common APIの名称が存在するRecord **/
export const commonAPIRequestTypeNameRecord: Record<string, number> = {
  disabled_load: 1,
  disabled_error_message: 1,
  ended_load: 1,
  load_type: 1,
};

/** 文字列がCommonAPIRequestTypeの時はtrue */
export const isCommonAPIRequestType = (str: string): boolean => str in commonAPIRequestTypeNameRecord;

/**
 * 連続API通信する際のローダー値
 */
export const loaderForMultipleTransmission: CommonAPIRequestType = {
  disabled_load: true,
  disabled_error_message: false,
  ended_load: false,
  api_send_type: 'multipleTransmission',
} as const;

/**
 * API通信後にロード処理するローダー値
 */
export const loaderForChangeableOneTransmission: CommonAPIRequestType = {
  disabled_load: false,
  disabled_error_message: false,
  ended_load: true,
  api_send_type: 'changeableOneTransmission',
} as const;

/**
 * 条件に応じてAPIリクエスト用のparamsを返却する
 * @param params T & CommonAPIRequestType
 * @returns T & CommonAPIRequestType
 */
export const toAPIRequestParams = <T,>(params: T & CommonAPIRequestType): T & CommonAPIRequestType => {
  const { disabled_load, disabled_error_message, ended_load, api_send_type } = params;
  switch (api_send_type) {
    case 'multipleTransmission':
      return {
        ...params,
        // 左がundefinedの場合に右側の値を返却
        disabled_load: disabled_load ?? loaderForMultipleTransmission.disabled_load,
        disabled_error_message: disabled_error_message ?? loaderForMultipleTransmission.disabled_error_message,
        ended_load: ended_load ?? loaderForMultipleTransmission.ended_load,
        api_send_type: loaderForMultipleTransmission.api_send_type,
      };
    case 'changeableOneTransmission':
      return {
        ...params,
        // 左がundefinedの場合に右側の値を返却
        disabled_load: disabled_load ?? loaderForChangeableOneTransmission.disabled_load,
        disabled_error_message: disabled_error_message ?? loaderForChangeableOneTransmission.disabled_error_message,
        ended_load: ended_load ?? loaderForChangeableOneTransmission.ended_load,
        api_send_type: loaderForChangeableOneTransmission.api_send_type,
      };
    default:
      return { ...params, api_send_type: undefined };
  }
};
