import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getPageNumberFromSessionStorage,
  getSearchesFromSessionStorage,
  getSortInfoFromSessionStorage,
  setPageNumbersFromSessionStorage,
  setSortInfoFromSessionStorage,
} from 'shared/utils/else/sessionStorageFunctions';
import { getBodiesIds, isSearchTargetBody, mergeArray } from './tableFunctions';
import { BaseTableProps, TableBodyType } from './type';
import { Search } from 'shared/models/Search';

/**
 * BaseTableで利用するhooks+関数
 */
export const useTable = ({
  hide_toparea,
  bodies_per_page: bpp,
  // テーブル名(存在しなければ'anonymous')
  table_name = 'anonymous',
  bodies,
  headers,
  checkbox_abridgement,
  handleCheckClick,
  selected_bodies,
  id_abridgement,
}: BaseTableProps) => {
  // -- local states --
  const [body_checkboxes, setBodyCheckBoxes] = useState<{
    [key: string]: boolean;
  }>({});
  const [sort_info, setSortInfo] = useState<{
    key: string;
    reverse: boolean;
  } | null>(getSortInfoFromSessionStorage(table_name));
  const [selected_page_number, setSelectedPageNumber] = useState<number>(1);
  // 検索後のbodies
  const [searched_bodies, setSearchedBodies] = useState<TableBodyType[]>(bodies);
  const [searches, setSearches] = useState<Search[]>(getSearchesFromSessionStorage(table_name, 'filter') ?? []);

  // -- functions --
  const bodies_per_page = useMemo(() => {
    return hide_toparea ? Number.MAX_SAFE_INTEGER : bpp ?? 10;
  }, [hide_toparea, bpp]);

  // テーブルのページが更新された時に安全に反映する関数
  const handleTablePageChange = useCallback(
    (page_number: number) => {
      let limited_page_number = page_number;
      // テーブルのbodiesが空の時は最大最小の制限を行わない
      if (bodies.length > 0) {
        const max_page_number = Math.ceil(bodies.length / bodies_per_page);
        // 最大をデータの長さ分にする
        limited_page_number = Math.min(limited_page_number, max_page_number);
        // 最小を1にする
        limited_page_number = Math.max(limited_page_number, 1);
      }
      setSelectedPageNumber(limited_page_number);
      // window.sessionStorageの指定のtable_nameのpage_numberを更新する。
      setPageNumbersFromSessionStorage(table_name, limited_page_number);
    },
    [table_name, bodies, bodies_per_page],
  );

  // ヘッダーの表示するデータのidを取得
  const header_ids = useMemo(() => {
    const return_ids: string[] = [];
    headers.forEach((header) => {
      if (id_abridgement && header.id === 'id') return;
      return_ids.push(header.id);
    });

    return return_ids;
  }, [headers, id_abridgement]);

  // 渡されたselected_datasのidに基づいてチェックボックスのチェック状態を変える
  const updateBodyCheckboxes = useCallback(
    (selected_bodies: TableBodyType[]) => {
      //1ページ内のbodies
      const one_page_bodies = searched_bodies.slice(
        (selected_page_number - 1) * bodies_per_page,
        selected_page_number * bodies_per_page,
      );
      const new_body_checkboxes: { [key: string]: boolean } = {};
      one_page_bodies.forEach((opb) => {
        const id = opb.id;
        new_body_checkboxes[id] = false;
      });
      selected_bodies.forEach((sb) => {
        const id = sb.id;
        new_body_checkboxes[id] = true;
      });
      setBodyCheckBoxes(new_body_checkboxes);
    },
    [searched_bodies, bodies_per_page, selected_page_number],
  );

  // テーブルをクリックした時の挙動
  const handleTableTrClick = useCallback(
    (body: TableBodyType, selected_bodies: TableBodyType[]) => {
      // チェックボックスの動きを省略する
      if (checkbox_abridgement) {
        return;
      }
      const id = body.id;
      const selected_bodies_ids = getBodiesIds(selected_bodies);
      const selected_index = selected_bodies_ids.indexOf(id);
      let new_selected_bodies: TableBodyType[] = [];

      if (selected_index === -1) {
        new_selected_bodies = new_selected_bodies.concat(selected_bodies, body);
      } else if (selected_index === 0) {
        new_selected_bodies = new_selected_bodies.concat(selected_bodies.slice(1));
      } else if (selected_index === selected_bodies_ids.length - 1) {
        new_selected_bodies = new_selected_bodies.concat(selected_bodies.slice(0, -1));
      } else if (selected_index > 0) {
        new_selected_bodies = new_selected_bodies.concat(
          selected_bodies.slice(0, selected_index),
          selected_bodies.slice(selected_index + 1),
        );
      }
      handleCheckClick(new_selected_bodies);
      updateBodyCheckboxes(new_selected_bodies);
    },
    [checkbox_abridgement, handleCheckClick, updateBodyCheckboxes],
  );

  // ヘッダーのチェックボックスの動き
  const handleTableHeaderCheckboxClick = useCallback(
    (checked: boolean, selected_bodies: TableBodyType[]) => {
      // チェックボックスの動きを省略する
      if (checkbox_abridgement) {
        return;
      }
      // 1ページのbodies
      const one_page_bodies = searched_bodies.slice(
        (selected_page_number - 1) * bodies_per_page,
        selected_page_number * bodies_per_page,
      );
      if (!checked) {
        const merged_bodies = mergeArray(selected_bodies, one_page_bodies);
        handleCheckClick(merged_bodies);
        updateBodyCheckboxes(merged_bodies);
        return;
      }
      const new_selected_bodies: TableBodyType[] = [];
      const bodies_ids = getBodiesIds(one_page_bodies);
      for (let i = 0; i < selected_bodies.length; i++) {
        const selected_id_index = bodies_ids.indexOf(selected_bodies[i].id);
        if (selected_id_index !== -1) continue;
        new_selected_bodies.push(selected_bodies[i]);
      }
      handleCheckClick(new_selected_bodies);
      updateBodyCheckboxes(new_selected_bodies);
    },
    [
      checkbox_abridgement,
      bodies_per_page,
      handleCheckClick,
      searched_bodies,
      selected_page_number,
      updateBodyCheckboxes,
    ],
  );
  // 検索を実行する
  const onSearch = useCallback(
    (searches: Search[], disabled_move_page?: boolean) => {
      setSearches(searches);
      // ローカル検索でフロントのデータの中から検索する
      const searched_bodies = bodies.filter((body) => {
        let is_target = true;
        searches.forEach((search) => {
          is_target = is_target && isSearchTargetBody(body, search);
        });
        return is_target;
      });
      // searched_bodiesを更新
      setSearchedBodies(searched_bodies);

      if (!disabled_move_page) {
        // 検索後のページ番号を1に戻す
        handleTablePageChange(1);
      }

      // searched_bodiesのidのリスト
      const sids = getBodiesIds(searched_bodies);
      // sids内に存在するものだけselected_bodiesに挿入する
      const new_selected_bodies = selected_bodies.filter((body) => {
        return sids.includes(body.id);
      });
      // selected_bodiesとチェックを更新する
      updateBodyCheckboxes(new_selected_bodies);
      handleCheckClick(new_selected_bodies);
    },
    [bodies, handleCheckClick, handleTablePageChange, updateBodyCheckboxes, selected_bodies],
  );

  // ソートアイコンを押した時の挙動
  const handleSortClick = useCallback(
    (sort_key: string, reverse: boolean) => {
      const new_sort_key_to_reverse = { key: sort_key, reverse: reverse };
      if (new_sort_key_to_reverse.key === sort_info?.key && new_sort_key_to_reverse.reverse === sort_info.reverse) {
        setSortInfo(null);
        setSortInfoFromSessionStorage(table_name, null);
      } else {
        setSortInfo(new_sort_key_to_reverse);
        setSortInfoFromSessionStorage(table_name, new_sort_key_to_reverse);
      }
    },
    [table_name, sort_info],
  );

  useEffect(() => {
    onSearch(searches, true);
    // bodiesが変化した時だけonSearchを発火させたいので、他の依存はdisabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodies]);

  useEffect(() => {
    // テーブルのpageNumber
    const page_number: number = getPageNumberFromSessionStorage(table_name);
    handleTablePageChange(page_number);
  }, [handleTablePageChange, table_name]);

  useEffect(() => {
    updateBodyCheckboxes(selected_bodies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_bodies]);

  return useMemo(() => {
    return {
      // state
      searched_bodies,
      selected_page_number,
      body_checkboxes,
      sort_info,
      // 関数群
      header_ids,
      bodies_per_page,
      handleSortClick,
      onSearch,
      handleTableHeaderCheckboxClick,
      handleTableTrClick,
      handleTablePageChange,
    };
  }, [
    searched_bodies,
    selected_page_number,
    body_checkboxes,
    sort_info,
    header_ids,
    bodies_per_page,
    handleSortClick,
    onSearch,
    handleTableHeaderCheckboxClick,
    handleTableTrClick,
    handleTablePageChange,
  ]);
};
