import { assertNever } from 'shared/utils/else/assertNever';

export type StreamDataType = 'IMAGE' | 'VIDEO' | 'AUDIO' | 'METRIC' | 'PDF';

export const streamDataTypeToJP = (stream_data_type: StreamDataType) => {
  switch (stream_data_type) {
    case 'IMAGE':
      return '画像';
    case 'VIDEO':
      return 'ビデオ';
    case 'AUDIO':
      return '音声';
    case 'METRIC':
      return 'メトリック';
    case 'PDF':
      return 'ファイル';
    default:
      assertNever(stream_data_type);
      return '';
  }
};

export const streamDataTypeToMime = (stream_data_type: StreamDataType) => {
  switch (stream_data_type) {
    case 'IMAGE':
      return 'image/*';
    case 'VIDEO':
      return 'video/*';
    case 'AUDIO':
      return 'audio/*';
    case 'METRIC':
      return 'application/json';
    case 'PDF':
      return 'application/pdf';
    default:
      assertNever(stream_data_type);
      return '';
  }
};
