// -- basic library --
import { memo, useCallback } from 'react';
import { authActions } from 'admin/redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import PfHeader, { HeaderMenuItem } from 'shared/components/molecules/PfHeader';
import { getLoginId } from 'shared/utils/else/localStorage';

const header_menu_items: HeaderMenuItem[] = [
  {
    id: 'users',
    text: '>ユーザー管理',
  },
];

// -- main component --
const PfAdminHeader = memo(function PfAdminHeader() {
  // -- redux preparations --
  const dispatch = useDispatch();
  const login_id = getLoginId();

  const signOut = useCallback(() => {
    dispatch(authActions.signOut());
  }, [dispatch]);

  // -- render part --
  return <PfHeader header_menu_items={header_menu_items} signOut={signOut} is_admin_user={true} login_id={login_id} />;
});
export default PfAdminHeader;
