import { Card, Elevation, CardProps } from '@blueprintjs/core';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';
import { PinIcon } from './Icons';
import PfMenu from './PfMenu';

/**
 * 角に丸みをつけたカードコンテナ
 */
interface RoundedCardProps extends CardProps {
  badge_number?: number;
  menu?: JSX.Element;
  pinned?: boolean;
}
export default function RoundedCard(props: RoundedCardProps) {
  return (
    <OriginalCard elevation={Elevation.TWO} {...props}>
      {props.children}
      <Badge badge_number={props.badge_number ? props.badge_number : -1}>
        {props.badge_number && props.badge_number >= 100 ? '99+' : props.badge_number}
      </Badge>
      {props.pinned && (
        <PinIcon
          disabled_hover={true}
          style={{
            position: 'absolute',
            top: -15,
            right: -15,
          }}
        />
      )}
      <PfMenu
        menu={props.menu}
        iconPositionProps={{
          style: {
            position: 'absolute',
            top: 10,
            right: 10,
          },
        }}
      />
    </OriginalCard>
  );
}

const OriginalCard = styled(Card)`
  position: relative;
  border-radius: 15px;
`;

const Badge = styled.span<{
  badge_number: number;
}>`
  display: ${(params) => (params.badge_number <= 0 ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  position: absolute;
  content: attr(${(params) => (params.badge_number > 100 ? '99+' : params.badge_number)});
  min-width: 50px;
  height: 50px;
  box-sizing: border-box;
  padding: 4px;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.white};
  background-color: ${colors.icon_dangerous_color};
  border: 1px solid #fff;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  z-index: 1;
`;
