import { assertNever } from 'shared/utils/else/assertNever';

export type UserType = 'ADMIN' | 'GENERAL';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUserType = (data: any): data is UserType => data === 'ADMIN' || data === 'GENERAL';

export const userTypeToJP = (user_type: UserType) => {
  switch (user_type) {
    case 'ADMIN':
      return '管理者';
    case 'GENERAL':
      return '一般';
    default:
      assertNever(user_type);
      return '';
  }
};
