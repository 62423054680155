// -- basic library --
import React, { useState } from 'react';
import history from 'shared/browserHistory';
// -- redux library --

// -- http connection library --
import CSVTable from './CSVTable';
import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import SelectBox from 'shared/components/atoms/SelectBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { RouteComponentProps } from 'react-router-dom';
import { BillingSummariesDataDownloadGetAPI } from 'admin/api/billingSummary';
import { dateFormat, yearmonthList } from 'shared/utils/else/billingYearMonth';
import { styles } from 'shared/styles/styles';
import styled from 'styled-components';
type Params = RouteComponentProps<{ tenant_id: string; yearmonth: string }>;

// -- external functions --

const downloadCsv = async (yearmonth: string, tenant_id: string) => {
  const res = BillingSummariesDataDownloadGetAPI({
    yearmonth: yearmonth,
    tenant_id: tenant_id,
  });
  return res;
};


// -- main component --
const BillingCsv: React.FC<Params> = (params) => {
  const [yearmonth, setYearmonth] = useState<string>(params.match.params.yearmonth);
  const yearmonth_list: string[] = yearmonthList();
  const tenant_id = params.match.params.tenant_id;
  
  const handleCancel = () => {
    history.push(`/billings/${yearmonth}`);
  };
  
  // -- render part --
  return (
    <PageWholeArea data-testid='Billings'>
      <TopArea>
        <Title text='請求照会画面' />
        <SelectBox
          onChange={(e) => setYearmonth(e.currentTarget.value)}
          value={yearmonth}
          datas={yearmonth_list.map((e) => {
            return {
              name: dateFormat(e),
              value: e,
            };
          })}
        />
      </TopArea>
      <CSVTable yearmonth={yearmonth} tenant_id={tenant_id} />
      <BottomArea
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <RoundedButton
          text='CSVダウンロード'
          onClick={() => downloadCsv(yearmonth, tenant_id)}
          style={{
            fontSize: '0.8em',
          }}
        />
      </BottomArea>
      <Footer>
        <RoundedButton onClick={handleCancel} is_margin_right is_white text='戻る' />
      </Footer>
    </PageWholeArea>
  );
};

// -- styled components --
const TopArea = styled.div`
  width: 50%;
  height: 10%;
  display: flex;
  align-items: center;
  margin-bottom: ${styles.interval_margin};
`;

const BottomArea = styled.div`
  width: 50%;
  height: auto;
  display: block;
`;

const Footer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;
// -- finally export part --

export default BillingCsv;
