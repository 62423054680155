import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { BaseTableProps, TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';

export interface TableStreamType {
  id: string;
  stream_id: TableBodyUrlType;
  stream_name: string;
  tenant_id: string;
  tenant_name: string;
  channel_id?: string;
  stream_type: string;
  data_number_type: string;
  data_type: string;
  retention_period: string;
}

interface Params extends Omit<BaseTableProps, 'headers' | 'table_name'> {
  bodies: TableStreamType[];
  selected_bodies: TableStreamType[];
  handleCheckClick: (datas: TableStreamType[]) => void;
}

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
      is_api_search: true,
      required: true,
    },
  },
  {
    id: 'stream_id',
    label: 'ストリームID',
    sortable: true,
    search_props: {
      type: 'name',
      is_api_search: true,
    },
  },
  {
    id: 'stream_name',
    label: 'ストリーム名',
    sortable: true,
    search_props: {
      default_display: true,
      type: 'name',
    },
  },
  {
    id: 'tenant_name',
    label: 'テナント名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'stream_type',
    label: 'ストリームタイプ',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'data_number_type',
    label: 'データ番号の基準',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'data_type',
    label: 'データの種類',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'retention_period',
    label: '保存期間',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'channel_id',
    label: 'チャンネルID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const StreamTable: React.FC<Params> = (params) => {
  // -- render part --
  return <BaseTable {...params} headers={headers} table_name='streams' />;
};

// -- styled components --

// -- finally export part --
export default StreamTable;
