import { SelectData } from 'shared/components/atoms/SelectBox';
import { assertNever } from 'shared/utils/else/assertNever';

/** ストリームのデータ番号の基準 */
export type StreamDataNumberType = 'TIMESTAMP' | 'SEQUENCE' | 'USER_SPECIFIC';
export const DataNumberTypeTypes: StreamDataNumberType[] = ['TIMESTAMP', 'SEQUENCE', 'USER_SPECIFIC'];
export function convertDataNumberType(s?: string) {
  for (const v of DataNumberTypeTypes) if (v === s) return s;
  return DataNumberTypeTypes[0];
}

// ストリームのdata_number_type=TIMESTAMPの時True
export const isTimeStamp = (data_number_type: string | StreamDataNumberType) => {
  return data_number_type === 'TIMESTAMP';
};

export const streamDataNumberTypeToJP = (stream_data_number_type: StreamDataNumberType) => {
  switch (stream_data_number_type) {
    case 'TIMESTAMP':
      return 'タイムスタンプ';
    case 'SEQUENCE':
      return '連続した番号';
    case 'USER_SPECIFIC':
      return 'ユーザー指定';
    default:
      assertNever(stream_data_number_type);
      return '';
  }
};

export const select_stream_data_number_types: SelectData<string>[] = [
  { name: streamDataNumberTypeToJP('TIMESTAMP'), value: 'TIMESTAMP' },
  { name: streamDataNumberTypeToJP('SEQUENCE'), value: 'SEQUENCE' },
  { name: streamDataNumberTypeToJP('USER_SPECIFIC'), value: 'USER_SPECIFIC' },
];
