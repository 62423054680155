// -- basic library --
import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { BottomArea, Content } from 'shared/components/molecules/ContentsArea';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { dateToYMDHMS } from 'shared/utils/converter/date';

interface Params {
  bodies: TableBillingFileProcessesType[];
  // loadTableBodies: () => void;
}

export interface TableBillingFileProcessesType {
  id: string;
  process_flow_name: string;
  process_flow: string;
  folder_name: string;
  stream_data_number: number | undefined;
  file_name: string;
  file_time: string;
  file_fps: number;
  amount: string;
  demand_price: string;
  create_date: string;
}

export type TableBillingDataDownloadPackageType = {
  id: string;
  tenant_id: TableBodyUrlType;
  tenant_name: string;
  demand_price_summary: string;
  button_element: JSX.Element;
};

// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'process_flow_name',
    label: 'プロセスフロー名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'process_flow',
    label: 'プロセスフロー',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'folder_name',
    label: 'フォルダ名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'stream_data_number',
    label: 'ストリームデータ番号',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'file_name',
    label: '動画名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'file_time',
    label: '動画時間',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'file_fps',
    label: '指定FPS',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'amount',
    label: '推論実行時間',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'demand_price',
    label: '請求金額',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'create_date',
    label: '実施日',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const BillingFileProcessTable: React.FC<Params> = (params) => {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableBillingFileProcessesType[] | undefined>(undefined);

  const loadData = async () => {
    const items = params.bodies;
    const return_table_datas: TableBillingFileProcessesType[] = [];
    for (let i = 0; i < items.length; i++) {
      const return_table_data: TableBillingFileProcessesType = {
        id: items[i].id,
        process_flow_name: items[i].process_flow_name,
        process_flow: items[i].process_flow,
        folder_name: items[i].folder_name,
        stream_data_number: items[i].stream_data_number,
        file_name: items[i].file_name,
        file_time: items[i].file_time,
        file_fps: items[i].file_fps,
        amount: items[i].amount,
        demand_price: items[i].demand_price,
        create_date: dateToYMDHMS(items[i].create_date),
      };
      return_table_datas.push(return_table_data);
    }
    setTableBodies(return_table_datas);
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await loadData();
    })();
  }, [params.bodies]); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      <BottomArea>
        {table_bodies ? (
          <BaseTable
            checkbox_abridgement={true}
            bodies={table_bodies}
            headers={headers}
            table_name='billingFileProcesses'
            handleCheckClick={() => {}}
            selected_bodies={[]}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </Content>
  );
};

// -- styled components --

// -- finally export part --
export default BillingFileProcessTable;
