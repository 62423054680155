/***
 * window.sessionStrageを操作する関数
 * ***/

import { Search } from 'shared/models/Search';

/***
 * sessionStorageのデータを安全に取得する関数
 * ***/
export const getSessionStorage = (key: string) => {
  const sessionStorage = window.sessionStorage;
  const ss = sessionStorage.getItem(key);
  return ss || '';
};

/***
 * 任意テーブルのpage_numberを安全に取得する関数
 * ***/
export const getPageNumberFromSessionStorage = (table_name: string, default_num = 1) => {
  const pn = getSessionStorage('page_numbers');
  let pn_json: Record<string, number> = {};
  if (pn) {
    pn_json = JSON.parse(pn);
  }
  const table_pn = pn_json[table_name];
  // 宣言がない時はdefault_num
  const page_number = table_pn ?? default_num;
  return page_number;
};

/***
 * テーブルのpage_numbersを安全に更新する関数
 * ***/
export const setPageNumbersFromSessionStorage = (table_name: string, page_number: number) => {
  // window.sessionStrageのpage_numbersは
  // {[table_name: string]: number}
  // のJSONで保持します(正確にはJSONのstringify)
  const pn = getSessionStorage('page_numbers');
  let pn_json: Record<string, number> = {};
  if (pn) {
    pn_json = JSON.parse(pn);
  }
  pn_json[table_name] = page_number;
  window.sessionStorage.setItem('page_numbers', JSON.stringify(pn_json));
};

/***
 * テーブルのpage_numbersをリセットする。
 * ***/
export const resetPageNumbersFromSessionStorage = () => {
  window.sessionStorage.removeItem('page_numbers');
};

export const getSortInfoFromSessionStorage = (table_name: string, default_sort_info = null) => {
  const si = getSessionStorage('sort_info');
  let si_json: Record<
    string,
    {
      key: string;
      reverse: boolean;
    } | null
  > = {};
  if (si) {
    si_json = JSON.parse(si);
  }
  const table_si = si_json[table_name];
  // 宣言がない時はdefault_sort_info
  const sort_info = table_si ?? default_sort_info;
  return sort_info;
};

export const setSortInfoFromSessionStorage = (
  table_name: string,
  sort_info: {
    key: string;
    reverse: boolean;
  } | null,
) => {
  // window.sessionStrageのsort_infoは
  //{[table_name: string]: Search[]}
  // のJSONで保持します(正確にはJSONのstringify)
  const si = getSessionStorage('sort_info');
  let si_json: Record<
    string,
    {
      key: string;
      reverse: boolean;
    } | null
  > = {};
  if (si) {
    si_json = JSON.parse(si);
  }
  si_json[table_name] = sort_info;
  window.sessionStorage.setItem('sort_info', JSON.stringify(si_json));
};

export const getSearchesFromSessionStorage = (table_name: string, search_type: string) => {
  const ns = getSessionStorage('searches');
  let ns_json: Record<string, Record<string, Search[] | undefined>> = {};
  if (ns) {
    ns_json = JSON.parse(ns);
  }
  const table_ns = ns_json[table_name]?.[search_type];

  // 宣言がない時はdefault_sort_info
  const new_searches = table_ns;
  return new_searches;
};

export const setSearchesFromSessionStorage = (table_name: string, search_type: string, new_seraches: Search[]) => {
  // window.sessionStrageのsearchesは
  //{[table_name: string]: Search[]}
  // のJSONで保持します(正確にはJSONのstringify)
  const ns = getSessionStorage('searches');
  let ns_json: Record<string, Record<string, Search[]>> = {};
  if (ns) {
    ns_json = JSON.parse(ns);
  }
  ns_json[table_name] = { ...ns_json[table_name], [search_type]: new_seraches };
  window.sessionStorage.setItem('searches', JSON.stringify(ns_json));
};

/***
 * 新着情報を安全に取得する関数
 * ***/
export const getNotificationSessionStorage = () => {
  const notification = getSessionStorage('notification');
  return notification;
};

/***
 * 新着情報を安全に更新する関数
 * ***/
export const setNotificationSessionStorage = (notification: string) => {
  window.sessionStorage.setItem('notification', notification);
};

/***
 * 新着情報をリセットする関数
 * ***/
export const resetNotificationSessionStorage = () => {
  window.sessionStorage.removeItem('notification');
};

/**
 * API通信を行うためのアクセスキー
 */
export const setAccessKey = (access_key?: string) => {
  if (typeof access_key !== 'string') return;
  sessionStorage.setItem('access_key', access_key);
};

export const getAccessKey = () => {
  const access_key = sessionStorage.getItem('access_key') ?? '';
  return access_key;
};
/**
 * access_keyが切れた時に、再度認証をするためのトークン
 */
export const setRefreshToken = (refresh_token?: string) => {
  if (typeof refresh_token !== 'string') return;
  sessionStorage.setItem('refresh_token', refresh_token);
};

export const getRefreshToken = () => {
  const refresh_token = sessionStorage.getItem('refresh_token') ?? '';
  return refresh_token;
};
