import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setLoginId } from 'shared/utils/else/localStorage';
import { setAccessKey, setRefreshToken } from 'shared/utils/else/sessionStorageFunctions';
import RootState from '../rootState';

// state の型
export interface ReduxAuthState {
  is_logged_in: boolean;
  session: string;
}

// state の初期値
const initialState: ReduxAuthState = {
  is_logged_in: false,
  session: '',
};

// actions と reducers の定義
const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    signInFirst: (
      state,
      action: PayloadAction<{
        session: ReduxAuthState['session'];
        login_id: string;
      }>,
    ): void => {
      state.session = action.payload.session;
      // login_idはlocal storageに別途保存する
      setLoginId(action.payload.login_id);
    },
    signIn: (
      state,
      action: PayloadAction<{
        access_key: string;
        refresh_token?: string;
        session: ReduxAuthState['session'];
        login_id: string;
      }>,
    ): void => {
      const { access_key, session, refresh_token, login_id } = action.payload;
      state.session = session;
      state.is_logged_in = true;
      // access_keyとrefresh_tokenはsession storageに別途保存する
      setAccessKey(access_key);
      setRefreshToken(refresh_token);
      // login_idはlocal storageに別途保存する
      setLoginId(login_id);
    },
    signOut: (state): void => {
      state.is_logged_in = false;
      // access_keyとrefresh_tokenはsession storageに別途保存する
      setAccessKey('');
      setRefreshToken('');
      // login_idはlocal storageに別途保存する
      setLoginId('');
    },
    refreshToken: (state, action: PayloadAction<{ access_key: string }>) => {
      setAccessKey(action.payload.access_key);
    },
  },
});

export default slice;
export const authSelector = (state: RootState): ReduxAuthState => state.auth;
export const authActions = slice.actions;
