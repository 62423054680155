// -- basic library --
import React from 'react';
import { RoundedButtonProps } from 'shared/components/atoms/RoundedButton';
import { InputBaseTable } from 'shared/components/molecules/Table/InputBaseTable';
import { TableBodyClickType, TableHeaderType } from 'shared/components/molecules/Table/type';

interface Params {
  bodies: TableAppParameterType[];
  selected_bodies: TableAppParameterType[];
  handleCheckClick: (datas: TableAppParameterType[]) => void;
  buttons?: RoundedButtonProps[];
}

export interface TableAppParameterType {
  id: string;
  key: TableBodyClickType;
  name: string;
  description: string;
  default_value: string;
  input_type: string;
  list_items: string;
  required: string;
  validate_regex: string;
  is_hidden: string;
}

const headers: TableHeaderType[] = [
  {
    id: 'key',
    label: 'キー',
  },
  {
    id: 'name',
    label: '表示名',
  },
  {
    id: 'description',
    label: '説明',
  },
  {
    id: 'default_value',
    label: 'デフォルト値',
  },
  {
    id: 'input_type',
    label: '入力タイプ',
  },
  {
    id: 'list_items',
    label: '入力できる値のリスト',
  },
  {
    id: 'required',
    label: '必須',
  },
  {
    id: 'validate_regex',
    label: '正規表現',
  },
  {
    id: 'is_hidden',
    label: '非表示',
  },
];

// -- main component --
const AppParameterTable: React.FC<Params> = (params) => {
  // -- render part --
  return <InputBaseTable {...params} table_name='appParameter' headers={headers} />;
};

// -- styled components --

// -- finally export part --
export default AppParameterTable;
