import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import RootState from '../rootState';

// state
export interface ReduxSystemAdminState {
  loading: {
    isActive: boolean;
    preloading: boolean;
  };
}

// state の初期値
const systemAdminInitialState: ReduxSystemAdminState = {
  loading: {
    isActive: false,
    preloading: false,
  },
};

// actions と reducers の定義
const slice = createSlice({
  name: 'system',
  initialState: systemAdminInitialState,
  reducers: {
    loading: (state, action: PayloadAction<ReduxSystemAdminState['loading']['isActive']>) => {
      state.loading.isActive = action.payload;
    },
    preloading: (state, action: PayloadAction<ReduxSystemAdminState['loading']['preloading']>) => {
      state.loading.preloading = action.payload;
    },
  },
});

export default slice;
export const systemAdminSelector = (state: RootState): ReduxSystemAdminState => state.system;
export const systemAdminActions = slice.actions;
