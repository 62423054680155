// -- basic library --
import React, { useEffect, useState } from 'react';
import { processesIdDeleteAPI, processesIdInfoGetAPI } from 'admin/api/processes';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { isNotSelected, isSelected } from 'shared/utils/is';
import ProcessTable from './ProcessTable';
import { TableProcessType } from './ProcessTable';
import { useProcesses } from 'admin/hooks/useProcesses/useProcesses';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';
import { colors } from 'shared/styles/colors';

// -- main component --
const ProcessesTopPage: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableProcessType[]>([]);
  const { processes, loadProcesses } = useProcesses({});
  const [table_bodies, setTableBodies] = useState<TableProcessType[] | undefined>(undefined);
  const handleGetInfo = async (table_bodies: TableProcessType[] | undefined) => {
    if (!table_bodies) return;
    const new_table_bodies_promises = table_bodies.map(async (table_body) => {
      // tenancyが共有の場合、ESCタスク数(実行中タスク及び目標タスク数)、SQSキュー長(リクエスト総数)を取得
      if (table_body.running_task_count === 'Loading…') {
        const res = await processesIdInfoGetAPI({
          process_id: table_body.id,
        });
        if (res.status === 200) {
          return {
            ...table_body,
            running_task_count: String(res.data.running_task) + '台',
            max_task_count: String(table_body.max_task || 0) + '台',
            sqs_queue_messages: getDesiredTaskCount(
              res.data.sqs_queue_messages,
              res.data.running_task,
              table_body.max_task || 0,
            ),
          };
        }
      }
      return table_body;
    });
    const new_table_bodies = await Promise.all(new_table_bodies_promises as Promise<TableProcessType>[]);
    setTableBodies(new_table_bodies);
  };

  const getDesiredTaskCount = (sqs_queue_messages: number, running_task: number, max_task: number) => {
    const text = String(sqs_queue_messages) + '件';
    if (running_task === max_task && sqs_queue_messages > 0) {
      return {
        value: text,
        available_value: text,
        displayable_value: <p style={{ color: colors.red }}>{text}</p>,
      };
    } else {
      return {
        value: text,
        available_value: text,
        displayable_value: <p style={{ color: colors.dark_gray }}>{text}</p>,
      };
    }
  };

  const desiredTaskCheck = (tenancy: string) => {
    let text = '';
    if (tenancy !== 'Stateful') text = 'Loading…';
    return {
      value: text,
      available_value: text,
      displayable_value: <p style={{ color: colors.dark_gray }}>{text}</p>,
    };
  };

  useEffect(() => {
    const new_table_bodies: TableProcessType[] | undefined = processes?.map((process) => {
      return {
        id: process.process_id,
        process_name: {
          value: process.process_name,
          url: `/processes/${process.process_id}/set`,
          color: 'rgb(57, 131, 141)',
        },
        running_task_count: process.processing_tenancy === 'Stateful' ? '' : 'Loading…',
        // 色を変えて表示するため関数で取得
        max_task_count: process.processing_tenancy === 'Stateful' ? '' : 'Loading…',
        sqs_queue_messages: desiredTaskCheck(process.processing_tenancy),
        order: process.order,
        max_task: process.ecs_parameter?.max_tasks,
      };
    });
    setTableBodies(new_table_bodies);
    // 非同期でECSタスク数、SQSキュー長を取得
    handleGetInfo(new_table_bodies);
  }, [processes]); /* eslint-disable-line */
  // -- handlers --
  const handleClick = (datas: TableProcessType[]) => {
    setSelectedBodies(datas);
  };

  const handleCreateClick = () => {
    history.push(`/processes/create`);
  };

  const handleOKClick = async (selected_datas: TableProcessType[]) => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_datas.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return processesIdDeleteAPI({ process_id: sd.id });
        }),
      );
      if (has_deleted_flag) {
        AlertDialog.show(`状態が「DELETED」のデータは削除できませんでした`);
      }
      await loadProcesses();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/processes');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているプロセスを削除します。\n本当によろしいですか？',
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };
  // -- render part --
  return (
    <PageWholeArea data-testid='Process'>
      <TopArea>
        <Title text='プロセス' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規プロセス追加'
          is_margin_right
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {table_bodies ? (
          <ProcessTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたプロセスを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default ProcessesTopPage;
