// -- styled components --

import styles from 'shared/styles/styles';
import { pc, sp, tab } from 'shared/styles/media';
import styled from 'styled-components';

export const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

export const TableArea = styled.div`
  max-width: 100%;
  width: 100%;
  height: calc(100% - 25px - 25px - ${styles.interval_narrow_margin} * 2);
  border-radius: ${styles.table_radius_px};
  max-height: 500px;
  overflow: scroll;
`;

export const SubTopArea = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${styles.interval_x_narrow_margin};
`;

export const TopArea = styled.div`
  display: flex;
  ${pc`
    justify-content: space-between;
  `}
  ${tab`
    justify-content: flex-end;
    flex-wrap: wrap;
  `}
  ${sp`
    justify-content: flex-end;
    flex-wrap: wrap;
  `}
  align-items: center;
  width: 100%;
  margin-bottom: ${styles.interval_narrow_margin};
`;

export const FooterArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 25px;
  margin-top: ${styles.interval_narrow_margin};
`;

export const ThChildrensArea = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionFunctionArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

export const PageNumberText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
  min-width: 150px;
  height: 100%;
  margin-right: ${styles.interval_narrow_margin};
`;

export const TableText = styled.div`
  align-items: center;
  font-family: inherit;
  color: inherit;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
