import { isBetweenRange, isDivisor } from 'shared/utils/is';

/** ストリームの保存期間の単位 */
// DAY=86400 * sec, HOUR=3600 * sec, MINUTE=60 * sec, UNSPECIFIED=-1
export type DATA_STORE_PERIOD_UNIT = 'DAY' | 'HOUR' | 'MINUTE' | 'UNSPECIFIED';
export const DataStorePeriodUnitTypes: DATA_STORE_PERIOD_UNIT[] = ['DAY', 'HOUR', 'MINUTE', 'UNSPECIFIED'];

export interface DataStorePeriodType {
  data_store_period: number; // number -1可能
  unit: DATA_STORE_PERIOD_UNIT;
}

// data_store_period.unitがUNSPECIFIEDかどうか
export const isUnspecified = (unit: string) => {
  return unit === 'UNSPECIFIED';
};

export const isValidDataStorePeriod = (data_store_period: DataStorePeriodType) => {
  // データの保存期間(number)
  const dsp = Number(data_store_period.data_store_period);
  // 整数チェック
  const flag1 = isDivisor(1, dsp);
  // 範囲チェック(unitがUNSPECIFEDの時だけ-1)
  const flag2 = isBetweenRange(dsp, 1, 9999) || (dsp === -1 && isUnspecified(data_store_period.unit));
  return flag1 && flag2;
};

// unitを考慮したdata_store_periodを返却する。
export const getDataStorePeriod = (unit: string, data_store_period: number | string): number => {
  const dsp = Number(data_store_period);
  const unitsec = unitToSecond(unit);
  return unitsec === -1 || isNaN(dsp) ? -1 : unitsec * dsp;
};

export function convertDataStorePeriodUnitType(s?: string) {
  for (const v of DataStorePeriodUnitTypes) if (v === s) return s;
  // 該当しない時はUNSPECIFIED
  return 'UNSPECIFIED';
}

export const translateDataStorePeriodToJp = (data_store_period: DataStorePeriodType) => {
  const { unit, data_store_period: dsp } = data_store_period;
  if (dsp === -1) {
    return '無期限';
  } else if (unit === 'DAY') {
    return dsp + '日';
  } else if (unit === 'HOUR') {
    return dsp + '時間';
  } else if (unit === 'MINUTE') {
    return dsp + '分';
  } else {
    return '該当なし';
  }
};

// unitを数値に変換する
export const unitToSecond = (unit: string) => {
  if (unit === 'DAY') {
    return 86400;
  } else if (unit === 'HOUR') {
    return 3600;
  } else if (unit === 'MINUTE') {
    return 60;
  } else {
    return -1;
  }
};

// data_store_period[秒数]から、DataStorePeriodTypeを返却する
export const secondsToDataStorePeriod = (sec: number): DataStorePeriodType => {
  let dsp = -1;
  let unit: DATA_STORE_PERIOD_UNIT = 'UNSPECIFIED';
  // APIからの返却データは秒数
  // 86400秒(1日)の倍数
  if (sec % 86400 === 0) {
    // 商が欲しい、少数切り捨て
    dsp = ~~(sec / 86400);
    unit = 'DAY';
  } else if (sec % 3600 === 0) {
    // 3600秒(1時間)の倍数
    // 商が欲しい、少数切り捨て
    dsp = ~~(sec / 3600);
    unit = 'HOUR';
  } else if (sec % 60 === 0) {
    // 60秒(1分)の倍数
    // 商が欲しい、少数切り捨て
    dsp = ~~(sec / 60);
    unit = 'MINUTE';
  } else {
    // 商が欲しい、少数切り捨て
    dsp = ~~sec;
    unit = 'UNSPECIFIED';
  }
  return {
    data_store_period: dsp,
    unit: unit,
  };
};
