// -- basic library --
import React from 'react';
import RoundedSelect from 'shared/components/atoms/RoundedSelect';
import { colors } from 'shared/styles/colors';

// -- type declaration --

export interface SelectData<V = string> {
  name: string;
  value: V;
  sub_value?: V;
}

export interface SelectBoxProps<V = string> {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  'data-testid'?: string;
  value: string;
  datas: SelectData<V>[];
  default_text?: string;
  default_text_value?: string;
  style?: React.CSSProperties;
  long?: boolean;
}

// -- main component --

const SelectBox: React.FC<SelectBoxProps> = (params) => {
  // -- preparations --
  const default_text = params.default_text || 'ー選択してくださいー';
  const default_text_value = params.default_text_value || '';

  // -- render part --
  return (
    <RoundedSelect
      onChange={params.onChange}
      data-testid={params['data-testid']}
      value={params.value}
      style={params.style}
      long={params.long}
    >
      {default_text === 'null' ? null : (
        <option value={default_text_value} color={colors.placeholder_color}>
          {default_text}
        </option>
      )}
      {params.datas.map((data, index) => (
        <option value={data.value} key={index}>
          {data.name}
        </option>
      ))}
    </RoundedSelect>
  );
};

// -- finally export part --

export default SelectBox;
