// -- basic library --
import { AccordionContainer } from 'shared/components/molecules/AccordionContainer';
import styled from 'styled-components';
import SearchPanel from './SearchPanel';
import { SearchRequiredItem, Search } from 'shared/models/Search';

/***
 * テーブル内の検索コンポーネント
 *
 * ***/
interface TableSearchProps {
  search_required_items: SearchRequiredItem[];
  onSearch(searches: Search[]): void;
  onAPISearch?(api_searches_record: Record<string, Search>): void;
  table_name: string;
  onDownloadClick?: (searches: Search[]) => void;  // 「ダウンロード」ボタンのイベントハンドラ
}

function TableSearch({ search_required_items, onSearch, onAPISearch, table_name, onDownloadClick }: TableSearchProps) {
  return (
    <ParentDiv>
      <AccordionContainer title='検索'>
        <SearchPanel
          search_required_items={search_required_items}
          onSearch={onSearch}
          onAPISearch={onAPISearch}
          table_name={table_name}
          onDownloadClick={onDownloadClick}
        />
      </AccordionContainer>
    </ParentDiv>
  );
}

export default TableSearch;

// -- styled components --

const ParentDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;
