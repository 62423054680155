import { processesIdPutAPIFormAppender, processesPostAPIFormAppender } from 'admin/utils/formAppender';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { ECSCpuArch } from 'shared/models/process/ECSCpuArch';
import { ProcessingTenancy } from 'shared/models/process/ProcessingTenancy';
import { RuntimeType } from 'shared/models/process/RuntimeType';
import { ProcessType } from 'shared/models/ProcessType';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { StringBoolean } from 'shared/models/StringBoolean';
import { getClient } from './base';

/*** エンティティ ***/

/**
 * EC2用のプロセスパラメータ
 * 現在利用していません
 * @deprecated
 */
export interface ProcessEc2parameter {
  instance_type: string;
  image_id: string;
  user_data: string;
}
export interface ProcessEcsparameter {
  image: string;
  cpu: number;
  memory: number;
  gpu: number;
  compatibilities: string[];
  capacity_providers: string[];
  cpu_arch: ECSCpuArch;
  vpc: string;
  security_group_name: string;
  max_tasks: number;
}

export interface ProcessAppParameter {
  key: string;
  name: string;
  description: string;
  default_value: string;
  input_type: string;
  // 改行で複数指定可能
  list_items: string;
  required: boolean;
  validate_regex: string;
  is_hidden: boolean;
}

export interface ProcessHeartbeats {
  key: string;
  name: string;
  description: string;
  default_schedule: string;
  additionals: string;
  required: boolean;
  is_hidden: boolean;
}

export interface InputStreamConstraint {
  // StreamDataTypeを,で区切って複数指定可能
  data_type: string;
  // StreamDataNumberTypeを,で区切って複数指定可能
  data_number_type: string;
  max_repeats: number;
  no_message: boolean;
}

export interface OutputStreamConstraint {
  // StreamDataTypeを,で区切って複数指定可能
  data_type: string;
  // StreamDataNumberTypeを,で区切って複数指定可能
  data_number_type: string;
  repeat: boolean;
}

export interface Process {
  tenant_id: string; // ','で区切った文字列が返される
  process_id: string;
  process_name: string;
  status: StatusType;
  input_stream_count: string;
  input_stream_constraints: InputStreamConstraint[];
  input_sync: boolean;
  output_stream_count: string;
  output_stream_data_types: string[];
  output_stream_constraints: OutputStreamConstraint[];
  app_parameters: ProcessAppParameter[];
  heartbeats: ProcessHeartbeats[];
  app_module: string;
  app_class: string;
  processing_tenancy: ProcessingTenancy;
  runtime_type: RuntimeType;
  // 現在のプロセスでは利用していません
  // ec2_parameter?: ProcessEc2parameter;
  ecs_parameter?: ProcessEcsparameter;
  processing_task_arns: string[];
  ui_visible: boolean;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  is_billing: boolean;
  process_type: ProcessType;
  unit_price: number;
  memo: string | null;
  order: number | null;
}

export interface ProcessesWithPaging {
  items: Process[];
  has_next: boolean;
}

const sample_process_1: Process = {
  tenant_id: '00000001',
  process_id: '00000001',
  process_name: 'process1',
  status: 'DELETED',
  input_stream_count: '0',
  output_stream_count: '1',
  output_stream_data_types: ['VIDEO'],
  app_module: 'string',
  app_class: 'string',
  runtime_type: 'ECS',
  app_parameters: [
    {
      key: 'CROSSLINE',
      name: 'CROSSLINE',
      description: 'CROSSLINE',
      default_value: '',
      input_type: 'CROSSLINE',
      list_items: '',
      required: false,
      validate_regex: '',
      is_hidden: false,
    },
  ],
  heartbeats: [
    {
      key: 'h',
      name: 'h',
      description: 'aaa',
      default_schedule: '',
      additionals: '',
      required: false,
      is_hidden: false,
    },
  ],
  input_stream_constraints: [],
  input_sync: false,
  output_stream_constraints: [
    {
      data_type: 'VIDEO', // 複数カンマ区切り
      data_number_type: 'TIMESTAMP',
      repeat: false,
    },
  ],
  processing_tenancy: 'Stateful',
  processing_task_arns: [],
  ui_visible: true,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  is_billing: true,
  process_type: 'COUNT',
  unit_price: 20000,
  memo: 'memo',
  order: 0,
};

const sample_process_2: Process = {
  tenant_id: '00000001',
  process_id: '00000002',
  process_name: 'process2',
  status: 'ACTIVE',
  input_stream_count: '0',
  input_stream_constraints: [],
  input_sync: false,
  output_stream_count: '1',
  output_stream_data_types: ['VIDEO'],
  output_stream_constraints: [],
  processing_tenancy: 'Stateful',
  app_parameters: [
    {
      key: 'CSVROWS',
      name: 'CSVROWS',
      description: 'CSVROWS',
      default_value: '',
      input_type: 'CSVROWS',
      list_items: '',
      required: false,
      validate_regex: '',
      is_hidden: false,
    },
  ],
  heartbeats: [],
  app_module: 'string',
  app_class: 'string',
  runtime_type: 'ECS',
  processing_task_arns: [],
  ui_visible: true,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  is_billing: true,
  process_type: 'COUNT',
  unit_price: 20000,
  memo: 'memo2',
  order: 10,
};

export interface ProcessesIdInfo {
  running_task: number;
  desired_task: number;
  sqs_queue_messages: number;
}

const sample_process_info_1: ProcessesIdInfo = {
  running_task: 3,
  desired_task: 10,
  sqs_queue_messages: 1,
};

/*** Caching mechanism (for admin) ***/

export interface CachedProcessesProps {
  tenant_id?: string; // 絞り込むテナントID (all は必ず含まれます)(','で複数指定)
  filter_method?: 'AND' | 'OR'; // AND条件 OR条件のどちらで探索するか
}
export class CachedProcesses {
  private searched = false;
  private cache: Process[] = [];
  private tenant_id?: string;
  private filter_method?: 'AND' | 'OR';
  constructor(params: CachedProcessesProps) {
    this.tenant_id = params.tenant_id;
    this.filter_method = params.filter_method || 'AND';
  }
  async get() {
    if (!this.searched) {
      const res = await processesGetAPI({
        tenant_id: this.tenant_id,
        filter_method: this.filter_method,
      });
      if (res.status === 200) {
        const processes = res.data.items;
        this.cache = processes;
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/processes ***/

export interface RequestProcessesGet {
  filter_method?: 'AND' | 'OR'; // AND条件 OR条件のどちらで探索するか
  tenant_id?: string; // ','区切りで複数指定可能
}

export const processesGetAPI = (params: RequestProcessesGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ProcessesWithPaging>(axios, path, query, form, method, {
    items: [sample_process_1, sample_process_2],
    has_next: false,
  });
};

/*** [POST] /api/processes ***/

export interface RequestProcessesPost {
  tenant_id: string; // カンマ区切りで複数指定可能
  process_name: string;
  input_sync: StringBoolean;
  input_stream_constraints: InputStreamConstraint[];
  output_stream_constraints: OutputStreamConstraint[];
  app_parameters?: ProcessAppParameter[];
  heartbeats?: ProcessHeartbeats[];
  processing_tenancy: ProcessingTenancy;
  app_module: string;
  app_class: string;
  runtime_type: RuntimeType;
  // ec2_instance_type?: string;
  // ec2_image_id?: string;
  // ec2_user_data?: string;
  ecs_image?: string;
  ecs_cpu?: number;
  ecs_cpu_arch?: ECSCpuArch;
  ecs_memory?: number;
  ecs_gpu?: number;
  ecs_compatibilities?: string;
  ecs_capacity_providers?: string;
  ecs_max_tasks?: number;
  ecs_vpc?: string;
  ecs_security_group_name?: string;
  ui_visible: StringBoolean;
  is_billing: StringBoolean;
  process_type?: string;
  unit_price: number;
  memo?: string;
  order?: number;
}

export const processesPostAPI = (params: RequestProcessesPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // formにパラメータを追加
  processesPostAPIFormAppender(form, params);

  // 送信
  return sendAxios<Process>(axios, path, query, form, method, sample_process_1);
};

/*** [GET] /api/processes/{id} ***/

export interface RequestProcessesIdGet {
  process_id: string;
}

export const processesIdGetAPI = (params: RequestProcessesIdGet) => {
  const { process_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Process>(axios, path, query, form, method, sample_process_1);
};

/*** [GET] /api/processes/{id}/info ***/

export interface RequestProcessesIdInfoGet {
  process_id: string;
}

export const processesIdInfoGetAPI = (params: RequestProcessesIdInfoGet) => {
  const { process_id } = params;
  // クライアントを定義
  const axios = getClient('json', { disabled_load: true });

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}/info`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ProcessesIdInfo>(axios, path, query, form, method, sample_process_info_1);
};

/*** [PUT] /api/processes/{id} ***/

export interface RequestProcessesIdPut {
  tenant_id: string;
  process_id: string;
  process_name: string;
  input_sync: StringBoolean;
  app_module?: string;
  app_class?: string;
  app_parameters?: ProcessAppParameter[];
  heartbeats?: ProcessHeartbeats[];
  runtime_type?: RuntimeType;
  // ec2_instance_type?: string;
  // ec2_image_id?: string;
  // ec2_user_data?: string;
  ecs_image?: string;
  ecs_cpu?: number;
  ecs_cpu_arch?: ECSCpuArch;
  ecs_memory?: number;
  ecs_gpu?: number;
  ecs_compatibilities?: string;
  ecs_capacity_providers?: string;
  ecs_max_tasks?: number;
  ecs_vpc?: string;
  ecs_security_group_name?: string;
  ui_visible: string;
  is_billing: StringBoolean;
  process_type?: string;
  unit_price: number;
  memo?: string;
  order?: number;
}

export const processesIdPutAPI = (params: RequestProcessesIdPut) => {
  const { process_id, ...form_params } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  processesIdPutAPIFormAppender(form, form_params);

  // 送信
  return sendAxios<Process>(axios, path, query, form, method, sample_process_1);
};

/*** [DELETE] /api/processes/{id} ***/

export interface RequestProcessesIdDelete {
  process_id: string;
}

export const processesIdDeleteAPI = (params: RequestProcessesIdDelete) => {
  const { process_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Process>(axios, path, query, form, method, sample_process_1);
};

/*** [POST] /api/processes/{id}/run ***/

export interface RequestProcessesIdRunPost {
  process_id: string;
}

export function processesIdRunPostAPI(params: RequestProcessesIdRunPost) {
  const { process_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}/run`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Process>(axios, path, query, form, method, sample_process_1);
}

/*** [POST] /api/processes/{id}/run2 ***/

export interface RequestProcessesIdRun2Post {
  process_id: string;
  tenant_id: string;
  channel_id: string;
  channel_process_number: number;
}

export function processesIdRun2PostAPI(params: RequestProcessesIdRun2Post) {
  const { process_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}/run2`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Process>(axios, path, query, form, method, sample_process_1);
}

/*** [GET] /api/processes/{id}/tasks ***/

export interface RequestProcessesIdTasksGet {
  process_id: string;
}
export interface ResponseProcessesIdTasksGet {
  tasks: {
    task_arn: string;
    last_status: string;
  }[];
}
export const processesIdTasksGetAPI = (params: RequestProcessesIdTasksGet) => {
  const { process_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}/tasks`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ResponseProcessesIdTasksGet>(axios, path, query, form, method, { tasks: [] });
};

/*** [GET] /api/processes/{id}/tasks ***/

export interface RequestProcessesIdTasks2Get {
  process_id: string;
}
export interface ResponseProcessesIdTasks2Get {
  tasks: {
    tenant_id: string;
    channel_id: string;
    channel_process_number: number;
    task_arn: string;
    last_status: string;
    is_oneshot: boolean;
    is_term: boolean;
  }[];
}
export const processesIdTasks2GetAPI = (params: RequestProcessesIdTasks2Get) => {
  const { process_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processes/${process_id}/tasks2`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ResponseProcessesIdTasks2Get>(axios, path, query, form, method, { tasks: [] });
};
