// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { ExportCsvStatistics } from 'shared/models/ExportCsvStatistics';

interface Params {
  bodies: TableJsonpathGroupType[];
  selected_bodies: TableJsonpathGroupType[];
  handleCheckClick: (datas: TableJsonpathGroupType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableJsonpathGroupType {
  id: string;
  jsonpath_group_id: TableBodyUrlType;
  jsonpath_group_name: string;
  category: string;
  display: string;
  export_csv_statistics: ExportCsvStatistics;
  memo: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'jsonpath_group_id',
    label: 'JSONパスグループID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'jsonpath_group_name',
    label: 'JSONパスグループ名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'display',
    label: '対象画面',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'category',
    label: 'カテゴリ',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'memo',
    label: 'メモ',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const JsonpathGroupTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='jsonpath_group'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default JsonpathGroupTable;
