import React from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import Popover from './Popover';
/***
 * メニュの骨組みコンポーネント
 * propsのmenuの中に具体的なmenuコンポーネントを受け渡す
 * ***/

export interface PfMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  menu?: JSX.Element;
  icon?: IconName;
  iconPositionProps?: React.HTMLAttributes<HTMLDivElement>;
}

export interface PfMenuState {}

export default class PfMenu extends React.PureComponent<PfMenuProps, PfMenuState> {
  render() {
    return (
      <>
        {this.props.menu && (
          <IconPosition
            onClick={(e) => {
              e.stopPropagation();
            }}
            {...this.props.iconPositionProps}
          >
            <Popover placement='bottom-start' modifiers={{ arrow: { enabled: false } }} content={this.props.menu}>
              <MenuIcon icon={this.props.icon || IconNames.MENU} />
            </Popover>
          </IconPosition>
        )}
      </>
    );
  }
}

const IconPosition = styled.div``;

const MenuIcon = styled(Icon)`
  cursor: pointer;
  &:hover {
    opacity: ${styles.opacity_hover};
  }
`;
