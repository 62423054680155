import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from './base';

/*** エンティティ ***/

export interface Model {
  tenant_id: string;
  model_id: string;
  model_name: string;
  use_crop_parameters: boolean;
  app_s3_key: string;
  app_filename: string;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  soracom_package_uri: string;
}

export interface ModelsWithPaging {
  items: Model[];
  has_next: boolean;
}

const sample_model_1: Model = {
  tenant_id: '00000001',
  model_id: '00000001',
  model_name: 'model1',
  use_crop_parameters: true,
  app_s3_key: 'app_s3_key',
  app_filename: 'sample.tar.gz',
  status: 'DELETED',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  soracom_package_uri: 'string',
};

const sample_model_2: Model = {
  tenant_id: '00000001',
  model_id: '00000002',
  model_name: 'model1',
  use_crop_parameters: true,
  app_s3_key: 'app_s3_key',
  app_filename: 'sample.tar.gz',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  soracom_package_uri: 'string',
};

/*** Caching mechanism ***/

export class CachedModels {
  private searched = false;
  private cache: Model[] = [];
  private params: RequestModelsGet;
  constructor(params: RequestModelsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<ModelsWithPaging> = await modelsGetAPI({
          ...this.params,
          exclusive_start_model_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.model_id).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/models ***/

export interface RequestModelsGet {
  status?: StatusType;
  exclusive_start_model_id?: string;
}

export const modelsGetAPI = (params: RequestModelsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/models`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ModelsWithPaging>(axios, path, query, form, method, {
    items: [sample_model_1, sample_model_2],
    has_next: false,
  });
};

/*** [POST] /api/models ***/

export interface RequestModelsPost {
  model_name: string;
  use_crop_parameters: string;
  soracom_package_uri: string;
}

export const modelsPostAPI = (params: RequestModelsPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/models`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Model>(axios, path, query, form, method, sample_model_1);
};

/*** [GET] /api/models/{id} ***/

export interface RequestModelsIdGet {
  model_id: string;
}

export const modelsIdGetAPI = (params: RequestModelsIdGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/models/${params.model_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Model>(axios, path, query, form, method, sample_model_1);
};

/*** [PUT] /api/models/{id} ***/

export interface RequestModelsIdPut {
  model_id: string;
  model_name: string;
  use_crop_parameters: string;
  soracom_package_uri: string;
}

export const modelsIdPutAPI = (params: RequestModelsIdPut) => {
  const { model_id, ...form_params } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/models/${model_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(form_params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Model>(axios, path, query, form, method, sample_model_1);
};

/*** [DELETE] /api/models/{id} ***/

export interface RequestModelsIdDelete {
  model_id: string;
}

export const modelsIdDeleteAPI = (params: RequestModelsIdDelete) => {
  const { model_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/models/${model_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Model>(axios, path, query, form, method, sample_model_1);
};
