import React from 'react';
import { ProcessFlow, processflowsGetAPI, processflowsIdDeleteAPI } from 'admin/api/processflows';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import { isNotSelected, isSelected } from 'shared/utils/is';
import ProcessFlowTable, { TableProcessFlowType } from './ProcessFlowTable';
import { convertProcessType } from 'shared/models/ProcessType';

interface ProcessFlowsTopPageProps {}
interface ProcessFlowsTopPageState {
  process_flows: ProcessFlow[];
  table_process_flows?: TableProcessFlowType[];
  table_process_flows_selected: TableProcessFlowType[];
}
/** プロセスフローのトップ画面です */
export default class ProcessFlowsTopPage extends React.PureComponent<
  ProcessFlowsTopPageProps,
  ProcessFlowsTopPageState
> {
  constructor(props: ProcessFlowsTopPageProps) {
    super(props);
    this.state = {
      process_flows: [],
      table_process_flows: undefined,
      table_process_flows_selected: [],
    };
  }
  componentDidMount() {
    this.reload();
  }
  private reload() {
    processflowsGetAPI({}).then((res) => {
      if (res.status === 200) {
        const tableProcessFlows: TableProcessFlowType[] = res.data.items.map((e) => {
          return {
            id: e.process_flow_id,
            process_flow_id: {
              value: e.process_flow_id,
              url: `/processflows/${e.process_flow_id}`,
              color: 'rgb(57, 131, 141)',
            },
            tenant_id: e.tenant_id,
            process_flow_name: e.process_flow_name,
            process_type: convertProcessType(e.process_type, true),
            memo: e.memo ?? '',
            order: e.order,
          };
        });
        this.setState({
          process_flows: res.data.items,
          table_process_flows: tableProcessFlows,
          table_process_flows_selected: [],
        });
      }
    });
  }
  private handleClick = (data: TableProcessFlowType[]) => {
    this.setState({ table_process_flows_selected: data });
  };
  private handleCreateClick = () => {
    history.push(`/processflows/create`);
  };
  private handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているプロセスフローを削除します。\n本当によろしいですか？',
      () => this.handleOKClick(this.state.table_process_flows_selected),
      undefined,
      undefined,
    );
  };
  private handleOKClick = async (selected_datas: TableProcessFlowType[]) => {
    for (let i = 0; i < selected_datas.length; i++) {
      await processflowsIdDeleteAPI({
        process_flow_id: selected_datas[i].id,
      });
    }
    this.reload();
  };
  render() {
    return (
      <PageWholeArea>
        <TopArea>
          <Title text='プロセスフロー' />
          <RoundedButton
            onClick={this.handleCreateClick}
            text='新規プロセスフロー追加'
            is_margin_right
            disabled={isSelected(this.state.table_process_flows_selected.length)}
          />
        </TopArea>
        <BottomArea>
          {this.state.table_process_flows !== undefined ? (
            <ProcessFlowTable
              bodies={this.state.table_process_flows}
              selected_bodies={this.state.table_process_flows_selected}
              handleCheckClick={this.handleClick}
              footer_option={{
                text: '＞チェックしたプロセスフローを削除する',
                handleClick: this.handleDeleteClick,
                disabled: isNotSelected(this.state.table_process_flows_selected.length),
              }}
            />
          ) : (
            <Spinner />
          )}
        </BottomArea>
      </PageWholeArea>
    );
  }
}
