import React from 'react';
import { useCallback } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import { CheckBoxWithText } from 'shared/components/molecules/CheckBoxWithText';
import InputDoubleBox from 'shared/components/molecules/InputDoubleBox';
import styles from 'shared/styles/styles';
import { assertNever } from 'shared/utils/else/assertNever';
import styled from 'styled-components';
import { Search } from 'shared/models/Search';

/**
 * search_typeに応じた検索コンポーネントを返却する
 */
export interface DynamicSearchProps {
  search: Search;
  setSearch(search: Search): void;
  index?: number;
}
export const DynamicSearch: React.FC<DynamicSearchProps> = ({ search, setSearch, index }) => {
  const { value, value2 = '', search_type, perfect_match = false } = search;
  const onValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
      const new_search = { ...search };
      new_search.value = e.currentTarget.value;
      setSearch(new_search);
    },
    [search, setSearch],
  );

  const onValue2Change = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const new_search = { ...search };
      new_search.value2 = e.currentTarget.value;
      setSearch(new_search);
    },
    [search, setSearch],
  );

  const onPerfectMatchSwitch = useCallback(() => {
    setSearch({ ...search, perfect_match: !perfect_match });
  }, [search, setSearch, perfect_match]);
  switch (search_type) {
    case 'name':
      return (
        <ParentNameSearchTypeContent>
          <InputBox value={value} onChange={onValueChange} />
          {search.is_api_search ? (
            <div
              style={{
                fontSize: 'smaller',
                whiteSpace: 'nowrap',
                marginLeft: styles.interval_x_narrow_margin,
                width: 100,
              }}
            >
              完全一致
            </div>
          ) : (
            <CheckBoxWithText
              checked={perfect_match}
              onClick={onPerfectMatchSwitch}
              text='完全一致'
              style={{ width: 100, marginLeft: styles.interval_x_narrow_margin }}
              data-testid={`${index}-perfect-match`}
              disabled={search.is_api_search}
            />
          )}
        </ParentNameSearchTypeContent>
      );
    case 'datetime':
      return (
        <InputDoubleBox
          value1={value}
          value2={value2}
          handleChangeClick1={onValueChange}
          handleChangeClick2={onValue2Change}
          types={['datetime-local', 'datetime-local']}
        />
      );
    // case 'select':
    //   return <SelectBox value={value} onChange={onValueChange} datas={search?.select_options ?? []} />;
    case undefined:
      return null;
    default:
      assertNever(search_type);
      return null;
  }
};

const ParentNameSearchTypeContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
