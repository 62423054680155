// -- basic library --
import React, { SyntheticEvent } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { IItemRendererProps, MultiSelect } from '@blueprintjs/select';
import { SelectData } from 'shared/components/atoms/SelectBox';

// -- type declaration --

interface Params<T> {
  items: T[];
  selectedItems: T[] | undefined;
  onItemSelect: (item: T, event?: SyntheticEvent<HTMLElement, Event> | undefined) => void;
  onRemove: (value: T, index: number | undefined) => void;
}

// -- main component --

/***
 * 複数選択が可能な入力コンポーネント
 *
 * ***/
const MultiSelectBox: React.FC<Params<SelectData>> = (params) => {
  // -- preparations --
  const default_item_renderer = (item: SelectData, itemProps: IItemRendererProps) => {
    return <MenuItem onClick={itemProps.handleClick} key={item.value} text={item.name} />;
  };
  const default_tag_renderer = (item: SelectData) => {
    return <div>{item.name}</div>;
  };

  // -- render part --
  return (
    <MultiSelect
      onItemSelect={params.onItemSelect}
      selectedItems={params.selectedItems}
      tagRenderer={default_tag_renderer}
      items={params.items}
      itemsEqual={(a, b) => a.value === b.value}
      itemRenderer={default_item_renderer}
      placeholder='複数選択可能...'
      onRemove={params.onRemove}
    />
  );
};

// -- finally export part --

export default MultiSelectBox;
