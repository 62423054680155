// インスタンス情報の入力フォーム
import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import { awsEcrreptagsGetAPI, awsEcsclusterGetAPI, awsVpcsGetAPI, awsVpcsGetAPIResponse } from 'admin/api/aws';
import { ProcessEcsparameter } from 'admin/api/processes';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import RadioBox from 'shared/components/molecules/RadioBox';
import SelectBox from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace, isValidNumber } from 'shared/utils/is';
import { isRuntimeType, RuntimeType } from 'shared/models/process/RuntimeType';
import { isECSCpuArch } from 'shared/models/process/ECSCpuArch';

/**
 * ecsパラメータの互換性入力されているかを確認する関数
 * @param compatibilities 互換性のリスト
 * @returns boolean
 */
const isValidCompatibilities = (compatibilities: string[]) => {
  return compatibilities.length > 0 && compatibilities[0];
};

interface InstanceFormProps {
  edit_mode?: boolean;
  processing_tenancy: string;
  runtimeType: RuntimeType;
  ecsParameter: ProcessEcsparameter;
  onChange: (runtimeType: RuntimeType, ecsParameter: ProcessEcsparameter) => void;
}
interface InstanceFormState {
  image_tags: string[];
  vpcs: awsVpcsGetAPIResponse['vpcs'];
  capacity_providers: string[];
}
export default class InstanceForm extends React.PureComponent<InstanceFormProps, InstanceFormState> {
  constructor(props: InstanceFormProps) {
    super(props);
    this.state = {
      image_tags: [],
      vpcs: [],
      capacity_providers: [],
    };
  }
  componentDidMount() {
    // イメージタグの取得
    awsEcrreptagsGetAPI().then((res) => {
      if (res.status === 200) {
        this.setState({ image_tags: res.data.tags });
      }
    });
    // VPCの取得
    awsVpcsGetAPI().then((res) => {
      if (res.status === 200) {
        this.setState({ vpcs: res.data.vpcs });
      }
    });
    // キャパシティプロバイダーの取得
    awsEcsclusterGetAPI().then((e) => {
      if (e.status === 200) {
        this.setState({ capacity_providers: e.data.capacity_providers });
      }
    });
  }
  private handleRuntimeTypeChange = (value: string) => {
    if (isRuntimeType(value)) {
      this.props.onChange(value, this.props.ecsParameter);
    }
  };
  private handleEcsParameterItemChange = (props: object) => {
    this.props.onChange(this.props.runtimeType, { ...this.props.ecsParameter, ...props });
  };
  /**
   * 簡易的な入力チェック
   * [TODO] エラーメッセージを詳細に出したチェックをしたい
   * */
  static validate = (runtimeType: string, ecsParameter: ProcessEcsparameter) => {
    if (runtimeType === 'ECS') {
      return (
        isNotOnlySpace(ecsParameter.image) &&
        isNotOnlySpace(ecsParameter.security_group_name) &&
        isValidNumber({
          num: ecsParameter.cpu,
          integer: true,
          positive: true,
          allow0: true,
        }) &&
        isValidNumber({
          num: ecsParameter.memory,
          integer: true,
          positive: true,
          allow0: true,
        }) &&
        isValidNumber({
          num: ecsParameter.gpu,
          integer: true,
          positive: true,
          allow0: true,
        }) &&
        isValidNumber({
          num: ecsParameter.max_tasks,
          integer: true,
          positive: true,
          allow0: true,
        }) &&
        isECSCpuArch(ecsParameter.cpu_arch) &&
        isValidCompatibilities(ecsParameter.compatibilities) &&
        isNotOnlySpace(ecsParameter.vpc) &&
        isNotOnlySpace(ecsParameter.security_group_name)
      );
    } else {
      return true;
    }
  };
  render() {
    return (
      <FormContainer title='インスタンス情報' description='プロセスを実行するインスタンス'>
        <InputComponent text='実行種別' required>
          <RadioBox
            datas={[{ name: 'ECS', value: 'ECS' }]}
            selectedValue={this.props.runtimeType}
            handleChangeClick={(value) => this.handleRuntimeTypeChange(value)}
          />
        </InputComponent>

        {this.props.runtimeType === 'ECS' && (
          <>
            <Callout intent={Intent.DANGER} title='ECSインスタンスが起動されます'>
              <p>実行種別に ECS を選択した場合、チャンネルプロセスを作成するとECSインスタンスが起動されます。</p>
            </Callout>
            <br />
            <InputComponent text='イメージタグ' required>
              <SelectBox
                onChange={(e) => this.handleEcsParameterItemChange({ image: e.currentTarget.value })}
                value={this.props.ecsParameter.image}
                datas={this.state.image_tags.map((e) => {
                  return { name: e, value: e };
                })}
              />
            </InputComponent>
            <InputComponent text='CPUユニット' required>
              <InputNumberBox
                value={this.props.ecsParameter.cpu}
                onChange={(value) => this.handleEcsParameterItemChange({ cpu: value })}
              />
            </InputComponent>
            <InputComponent text='メモリ' required>
              <InputNumberBox
                value={this.props.ecsParameter.memory}
                onChange={(value) => this.handleEcsParameterItemChange({ memory: value })}
              />
            </InputComponent>
            <InputComponent text='GPUユニット' required>
              <InputNumberBox
                value={this.props.ecsParameter.gpu}
                onChange={(value) => this.handleEcsParameterItemChange({ gpu: value })}
              />
            </InputComponent>
            <InputComponent text='CPUアーキテクチャ' required>
              <SelectBox
                onChange={(e) => this.handleEcsParameterItemChange({ cpu_arch: e.currentTarget.value })}
                value={this.props.ecsParameter.cpu_arch}
                datas={[
                  { name: 'X86_64', value: 'X86_64' },
                  { name: 'ARM64', value: 'ARM64' },
                ]}
              />
            </InputComponent>
            <InputComponent text='互換性' required>
              {this.props.edit_mode &&
              this.props.processing_tenancy === 'Stateless' &&
              this.props.ecsParameter.compatibilities?.join() === 'EC2' &&
              this.props.ecsParameter.capacity_providers.length > 0 ? (
                <>{this.props.ecsParameter.compatibilities?.join()}</>
              ) : (
                <SelectBox
                  onChange={(e) =>
                    this.handleEcsParameterItemChange({ compatibilities: e.currentTarget.value.split(',') })
                  }
                  value={this.props.ecsParameter.compatibilities?.join() || ''}
                  datas={[
                    { name: 'FARGATE', value: 'FARGATE' },
                    { name: 'EC2', value: 'EC2' },
                  ]}
                />
              )}
            </InputComponent>
            {this.props.ecsParameter.compatibilities?.join() === 'EC2' && (
              <InputComponent text='キャパシティプロバイダー'>
                <SelectBox
                  default_text={
                    this.props.edit_mode &&
                    this.props.processing_tenancy === 'Stateless' &&
                    this.props.ecsParameter.capacity_providers.length > 0
                      ? 'null'
                      : undefined
                  }
                  onChange={(e) => this.handleEcsParameterItemChange({ capacity_providers: [e.currentTarget.value] })}
                  value={this.props.ecsParameter.capacity_providers.find((e) => e !== undefined) || ''}
                  datas={this.state.capacity_providers.map((e) => {
                    return { name: e, value: e };
                  })}
                />
                {this.props.processing_tenancy === 'Stateless' && (
                  <>
                    <br />
                    共有型プロセスのキャパシティプロバイダーは一度選択すると、その後に解除することはできません。互換性をFARGATEに変更することもできません。
                  </>
                )}
              </InputComponent>
            )}
            <InputComponent text='VPC' required>
              <SelectBox
                onChange={(e) => this.handleEcsParameterItemChange({ vpc: e.currentTarget.value })}
                value={this.props.ecsParameter.vpc}
                datas={this.state.vpcs.map((e) => {
                  return { name: e.vpcId + ' | ' + e.name, value: e.vpcId };
                })}
              />
            </InputComponent>
            <InputComponent text='セキュリティグループ' required>
              <SelectBox
                onChange={(e) => this.handleEcsParameterItemChange({ security_group_name: e.currentTarget.value })}
                value={this.props.ecsParameter.security_group_name}
                datas={
                  this.state.vpcs
                    .find((e) => e.vpcId === this.props.ecsParameter.vpc)
                    ?.securityGroups.map((e) => {
                      return { name: e.groupId + ' | ' + e.name, value: e.groupId };
                    }) || []
                }
              />
            </InputComponent>
            <InputComponent text='(共有型プロセスのみ) オートスケールの最大タスク数' required>
              <InputNumberBox
                value={this.props.ecsParameter.max_tasks}
                onChange={(value) => this.handleEcsParameterItemChange({ max_tasks: value })}
              />
            </InputComponent>
          </>
        )}
      </FormContainer>
    );
  }
}
