import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- type declaration --
export interface CheckBoxProps extends Omit<React.InputHTMLAttributes<HTMLLabelElement>, 'onClick'> {
  checked: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  'data-testid'?: string;
}

export const CheckBox = ({ checked, onClick, style, disabled, 'data-testid': testid }: CheckBoxProps) => {
  return (
    <ParentLabel onClick={onClick} style={style} data-testid={testid}>
      <Label htmlFor='checkbox' checked={checked} disabled={disabled}>
        <InputCheckbox id='checkbox' type='checkbox' checked={checked} disabled />
      </Label>
    </ParentLabel>
  );
};

export default CheckBox;

// -- styled components --
const ParentLabel = styled.div`
  display: inline-block;
`;
const InputCheckbox = styled.input`
  display: none;
`;

// labelのafter時のstyle
const label_after_styles = `
{
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 14px;
  height: 10px;
  top: 2px;
  left: 3px;
  transform: rotate(-45deg);
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-color: ${colors.white};
}`;

const Label = styled.label<{
  checked: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;
  width: 20px;
  font-size: ${styles.small_text_size};
  &::before {
    content: '';
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    border: 1px solid;
    border-radius: 3px;
    border-color: ${colors.component_small_border_color};
    background-color: ${(params) => {
      // disabledなら問答無用でdisabledカラー
      if (params.disabled) {
        return colors.disabled_background_color;
      }
      // disabledではないならcheckedに応じて色を返す
      return params.checked ? colors.component_main_color : colors.white;
    }};
  }
  &::after {
    ${(params) => (params.checked ? label_after_styles : null)}
  }
`;
