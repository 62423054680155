// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { TableNotificationType } from './NotificationTable';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import NotificationTable from './NotificationTable';
import { isNotSelected, isSelected } from 'shared/utils/is';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { CachedNotifications, notificationsIdDeleteAPI } from 'admin/api/notifications';
import { userTypeToJP } from 'shared/models/UserType';
import { dateToYMD, dateToYMDHMS } from 'shared/utils/converter/date';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';
import { showStatusToJP } from 'shared/models/ShowStatus';
// -- external functions --

// -- main component --
const Notification: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableNotificationType[]>([]);
  const [bodies, setBodies] = useState<TableNotificationType[]>([]);

  // -- handlers --
  const handleClick = (datas: TableNotificationType[]) => {
    setSelectedBodies(datas);
  };
  const loadTableBodies = useCallback(async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    const items = await new CachedNotifications({}).get();
    // テーブル表示データへの変換
    const return_table_datas: TableNotificationType[] = [];
    for (const item of items) {
      const return_top_table_data: TableNotificationType = {
        id: item.notification_id,
        notification_id: {
          value: item.notification_id,
          url: `/notifications/${item.notification_id}/detail`,
          color: 'rgb(57, 131, 141)',
        },
        title: item.title,
        show_status: item.is_draft ? '下書き' : showStatusToJP(item.show_status),
        show_type: userTypeToJP(item.show_type),
        show_date: dateToYMD(item.show_date),
        show_date_from: dateToYMD(item.show_date_from),
        show_date_to: dateToYMD(item.show_date_to),
        status: item.status,
        created_at: dateToYMDHMS(item.created_at),
      };

      return_table_datas.push(return_top_table_data);
    }
    setBodies(return_table_datas);
  }, []);

  const handleCreateClick = () => {
    history.push(`/notifications/create`);
  };

  const handleOKClick = async () => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_bodies.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return notificationsIdDeleteAPI({ notification_id: sd.id });
        }),
      );
      if (has_deleted_flag) {
        AlertDialog.show(`状態が「DELETED」のデータは削除できませんでした`);
      }
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/notifications');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているお知らせを削除します。\n本当によろしいですか？',
      () => handleOKClick(),
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [loadTableBodies]);

  // -- render part --
  return (
    <PageWholeArea data-testid='Notifications'>
      <TopArea>
        <Title text='お知らせ一覧' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規お知らせ追加'
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {bodies ? (
          <NotificationTable
            bodies={bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたお知らせを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Notification;
