// -- basic library --
import React, { ReactNode, useCallback, useState } from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { BaseIcon } from 'shared/components/atoms/Icons';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

/**
 * アコーディオンコンテナ
 */
export interface AccordionContainerProps {
  title?: string;
  description?: string;
  children?: ReactNode;
  default_is_open?: boolean;
}
export const AccordionContainer: React.FC<AccordionContainerProps> = ({
  title,
  description,
  default_is_open = false,
  children,
}) => {
  const [is_open, setIsOpen] = useState<boolean>(default_is_open);
  const onSwitch = useCallback(() => {
    setIsOpen((current) => !current);
  }, []);
  return (
    <Card style={{ width: '100%', padding: is_open ? '5px 5px 8px' : 5 }} elevation={Elevation.ONE}>
      <Header is_open={is_open}>
        <BaseIcon
          data-testid={is_open ? 'accordion-is-opened' : 'accordion-is-closed'}
          icon={is_open ? IconNames.CARET_DOWN : IconNames.CARET_RIGHT}
          onClick={onSwitch}
          iconSize={24}
          style={{ marginRight: styles.interval_x_narrow_margin }}
        />
        <h3 style={{ margin: 0 }}>{title}</h3>
        {description && <p style={{ margin: '5px 0 0 0' }}>{description}</p>}
      </Header>
      {is_open && <Body>{children}</Body>}
    </Card>
  );
};
interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  is_open?: boolean;
}
const Header = styled(({ is_open: _, ...props }: HeaderProps) => <div {...props} />)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: inherit;
  ${({ is_open }) => `
    border-bottom: ${is_open ? '1px solid #10161a26' : 'none'};
  `}
`;

const Body = styled.div`
  width: 100%;
  height: auto;
  margin-top: ${styles.interval_narrow_margin};
`;
