// -- basic library --
import React, { useEffect, useState } from 'react';
import { CachedModels, Model, modelsIdDeleteAPI } from 'admin/api/models';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';

import styles from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import ModelTable, { TableModelType } from './ModelTable';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';

// -- main component --
const Models: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableModelType[]>([]);
  const [bodies, setBodies] = useState<TableModelType[] | undefined>(undefined);

  // -- handlers --
  const handleClick = (datas: TableModelType[]) => {
    setSelectedBodies(datas);
  };

  const loadTableBodies = async () => {
    const items: Model[] = await new CachedModels({}).get();

    const return_table_datas: TableModelType[] = [];
    for (let i = 0; i < items.length; i++) {
      const returnTableData: TableModelType = {
        id: items[i].model_id,
        model_id: {
          value: items[i].model_id,
          url: `/models/${items[i].model_id}/set`,
          color: 'rgb(57, 131, 141)',
        },
        tenant_id: items[i].tenant_id,
        model_name: items[i].model_name,
        status: items[i].status,
      };

      return_table_datas.push(returnTableData);
    }
    setBodies(return_table_datas);
  };

  const handleCreateClick = () => {
    history.push(`/models/create`);
  };

  const handleOKClick = async (selected_datas: TableModelType[]) => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_datas.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return modelsIdDeleteAPI({ model_id: sd.id });
        }),
      );
      AlertDialog.show(
        `モデルの削除に成功しました\n${has_deleted_flag ? '※状態が「DELETED」のデータは削除できませんでした' : ''}`,
      );
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/models');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているモデルを削除します。\n本当によろしいですか？',
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='Model'>
      <TopArea>
        <Title text='モデル' />
        <RoundedButton
          onClick={handleCreateClick}
          text='新規モデル追加'
          style={{ marginRight: styles.interval_narrow_margin }}
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {bodies !== undefined ? (
          <ModelTable
            bodies={bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたモデルを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Models;
