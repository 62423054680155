import React from 'react';
import {
  Process,
  processesIdRun2PostAPI,
  processesIdTasks2GetAPI,
  ResponseProcessesIdTasks2Get,
} from 'admin/api/processes';
import { Table, Td, Th, Tr } from 'shared/components/atoms/PfTable';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content } from 'shared/components/molecules/ContentsArea';
import { table_cell_button_style } from 'shared/styles/styles';

interface ProcessesIdECSInstancesPanelProps {
  process: Process;
}
interface ProcessesIdECSInstancesPanelState {
  tasks?: ResponseProcessesIdTasks2Get['tasks'];
}
/**
 * ECSインスタンスの起動ができる画面
 * 専有のみ利用可能
 */
export default class ProcessesIdECSInstancesPanel extends React.PureComponent<
  ProcessesIdECSInstancesPanelProps,
  ProcessesIdECSInstancesPanelState
> {
  constructor(props: ProcessesIdECSInstancesPanelProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // タスク情報の取得
    processesIdTasks2GetAPI({ process_id: this.props.process.process_id }).then((res) => {
      if (res.status === 200) {
        this.setState({ tasks: res.data.tasks });
      }
    });
  }
  getTaskStatus = (task_arn: string) => {
    if (this.state.tasks === undefined) {
      return 'Loading...';
    }
    if (task_arn === '') {
      return 'No task';
    }
    for (const task of this.state.tasks) {
      if (task.task_arn === task_arn) {
        return task.last_status;
      }
    }
    return 'Unknown';
  };
  handleRunInstanceButton = (tenant_id: string, channel_id: string, channel_process_number: number) => {
    ConfirmDialog.show(
      '本当にインスタンスを起動して良いですか？\n（処理中のチャンネルプロセスに追加起動するとデータ処理が矛盾するリスクがあります）',
      () => {
        processesIdRun2PostAPI({
          process_id: this.props.process.process_id,
          tenant_id: tenant_id,
          channel_id: channel_id,
          channel_process_number: channel_process_number,
        }).then((res) => {
          if (res.status === 200) {
            AlertDialog.show('インスタンスの起動リクエストが送信されました。');
          }
        });
      },
    );
  };
  render() {
    return (
      <Content>
        <div>
          <Table>
            <Tr>
              <Th>テナントID</Th>
              <Th>チャンネルID</Th>
              <Th>チャンネルプロセス番号</Th>
              <Th>ワンショット</Th>
              <Th>期間設定</Th>
              <Th>タスクID</Th>
              <Th>タスクの状態</Th>
              <Th>タスクの起動</Th>
            </Tr>
            {this.state.tasks ? (
              this.state.tasks.map((e, i) => {
                const s = e.task_arn.split('/');
                return (
                  <Tr key={i}>
                    <Td>{e.tenant_id}</Td>
                    <Td>{e.channel_id}</Td>
                    <Td>{e.channel_process_number}</Td>
                    <Td>{e.is_oneshot ? '○' : ''}</Td>
                    <Td>{e.is_term ? '○' : ''}</Td>
                    <Td>
                      <a
                        href={
                          'https://ap-northeast-1.console.aws.amazon.com/ecs/home?region=ap-northeast-1#/clusters/' +
                          s[1] +
                          '/tasks/' +
                          s[2] +
                          '/details'
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {s[2]}
                      </a>
                    </Td>
                    <Td>{this.getTaskStatus(e.task_arn)}</Td>
                    <Td>
                      <RoundedButton
                        text='起動'
                        style={table_cell_button_style}
                        onClick={() =>
                          this.handleRunInstanceButton(e.tenant_id, e.channel_id, e.channel_process_number)
                        }
                      />
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Spinner />
            )}
          </Table>
        </div>
      </Content>
    );
  }
}
