// -- redux library --
import { accountsRefreshPostAPI } from 'admin/api/accounts';
import { authActions } from 'admin/redux/slices/authSlice';
import { systemAdminActions } from 'admin/redux/slices/systemSlice';
import { store } from 'admin/redux/store';
import axios from 'axios';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { AxiosErrorType, AxiosRequestType, AxiosResponseType } from 'shared/models/AxiosType';
import { getRefreshToken } from 'shared/utils/else/sessionStorageFunctions';

// -- ready handler --
const onReady = (request: AxiosRequestType, disabled_load = false) => {
  // load処理を無効化できる
  if (!disabled_load) {
    store.dispatch(systemAdminActions.loading(true));
  }
  return request;
};

// -- success handler --
const onSuccess = (response: AxiosResponseType, ended_load = true) => {
  if (ended_load) {
    store.dispatch(systemAdminActions.loading(false));
  }
  return response;
};

// -- error handler --
const onError = (err: AxiosErrorType, disabled_error_message = false) => {
  if (!err) {
    store.dispatch(systemAdminActions.loading(false));
    return;
  }
  // エラーメッセージをスキップできる
  if (disabled_error_message) {
    store.dispatch(systemAdminActions.loading(false));
    return err.response;
  }
  store.dispatch(systemAdminActions.loading(false));
  switch (err.response.status) {
    case 400: // 入力データに誤りがある場合
      AlertDialog.show(err.response.data.message ?? '入力データに誤りがあります');
      break;

    case 401: // ユーザーが認証されていない場合
      return accountsRefreshPostAPI({
        refresh_token: getRefreshToken(),
      })
        .then((res) => {
          store.dispatch(authActions.refreshToken({ access_key: res.data.authentication_result.access_token }));

          // 再度リクエスト
          const config = err.config;
          if (typeof config.data === 'string') {
            config.data = JSON.parse(config.data);
          }
          config.headers['x-api-key'] = res.data.authentication_result.access_token;

          return axios.request(config);
        })
        .catch(() => {
          AlertDialog.show('ユーザーが認証されませんでした。再度ログインをしてください。');
          store.dispatch(authActions.signOut());
          return err.response;
        });

    case 404: // 指定したリソースがサーバーに存在しない場合
      AlertDialog.show('指定したリソースはサーバーに存在しません。');
      break;

    case 405: // 指定したリソースのメソッドが許可されていない場合
      AlertDialog.show('指定したリソースのメソッドが許可されていません');
      break;

    case 500: // サーバー内でエラーが発生した場合
      AlertDialog.show('サーバー内で予期せぬエラーが発生しました。');
      break;

    case 502: // サーバーとの通信でエラーが発生した場合
      AlertDialog.show('通信エラーが発生しました。');
      break;

    case 503: // サーバーがアクセス過多で一時的に利用できない時
      AlertDialog.show('アクセス過多によりサービスが一時的に利用できません。数分待ってから再度実行してください。');
      break;

    case 504: // サーバーとの通信がタイムアウトした時
      AlertDialog.show('通信がタイムアウトしました。');
      break;

    default:
      // 予期していないエラー
      AlertDialog.show('予期せぬエラーが発生しました。');
      break;
  }
  return err.response;
};

// -- export part --
export { onReady, onSuccess, onError };
