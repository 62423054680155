import { SelectData } from 'shared/components/atoms/SelectBox';
import { assertNever } from 'shared/utils/else/assertNever';

export type JsonpathGroupCategory = 'AI' | 'COUNTER' | 'COUNTERBOX' | 'CLIENTBOX' | 'PROCESSFLOW' | 'OTHER';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isJsonpathGroupCategory = (data: any): data is JsonpathGroupCategory =>
  data === 'AI' ||
  data === 'COUNTER' ||
  data === 'COUNTERBOX' ||
  data === 'CLIENTBOX' ||
  data === 'PROCESSFLOW' ||
  data === 'OTHER';

export const jsonpathGroupCategoryToText = (jsonpath_group_category: JsonpathGroupCategory) => {
  switch (jsonpath_group_category) {
    case 'AI':
      return 'AI解析';
    case 'COUNTER':
      return 'counter';
    case 'COUNTERBOX':
      return 'counterBox';
    case 'CLIENTBOX':
      return 'counterBox';
    case 'PROCESSFLOW':
      return 'ファイル推論';
    case 'OTHER':
      return 'その他';
    default:
      assertNever(jsonpath_group_category);
      return '';
  }
};

export const jsonpath_group_category_select_data: SelectData[] = [
  { name: 'AI解析', value: 'AI' },
  { name: 'counter', value: 'COUNTER' },
  { name: 'counterBox', value: 'COUNTERBOX' },
  { name: 'ファイル推論', value: 'PROCESSFLOW' },
  { name: 'その他', value: 'OTHER' },
];
