import { assertNever } from 'shared/utils/else/assertNever';

export type StringBoolean = 'True' | 'False';

export const toStringBoolean = (data: boolean | string): StringBoolean => {
  switch (typeof data) {
    case 'string':
      return data.toUpperCase() === 'TRUE' ? 'True' : 'False';
    case 'boolean':
      return data ? 'True' : 'False';
    default:
      assertNever(data);
      return 'False';
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStringBoolean = (data: any): data is StringBoolean => data === 'True' || data === 'False';

export const stringToBoolean = (str: string): boolean => str.toUpperCase() === 'TRUE';
