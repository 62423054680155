import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StringBoolean } from 'shared/models/StringBoolean';
import { getClient } from './base';

/*** エンティティ ***/

export interface Dashboard {
  tenant_id: string;
  dashboard_id: string;
  dashboard_name: string;
  status: string;
  is_billing: boolean;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601;
}

export interface DashboardWithPaging {
  items: Dashboard[];
  has_next: boolean;
}

const sample_dashboard_1: Dashboard = {
  tenant_id: '00000001',
  dashboard_id: '00000001',
  dashboard_name: 'ダッシュボード01',
  status: 'ACTIVE',
  is_billing: true,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** [GET] /api/dashboards ***/

export interface RequestDashboardsGet {}
export const dashboardsGetAPI = (params: RequestDashboardsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/dashboards`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<DashboardWithPaging>(axios, path, query, form, method, {
    items: [sample_dashboard_1],
    has_next: false,
  });
};

/*** [PUT] /api/dashboards/{dashboard_id} ***/

export interface RequestDashboardsIdPut {
  tenant_id: string;
  dashboard_id: string;
  is_billing: StringBoolean;
}

export const dashboardsIdPutAPI = (params: RequestDashboardsIdPut) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/dashboards/${params.dashboard_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Dashboard>(axios, path, query, form, method, sample_dashboard_1);
};
