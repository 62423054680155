import React from 'react';
import { Tab, TabId, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Process, processesIdGetAPI } from 'admin/api/processes';
import history from 'shared/browserHistory';
import { Breadcrumbs, Content, Title, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import ProcessesIdECSInstancesPanel from './ECSInstances';
import ProcessSetPanel from './Set';

interface ProcessesIdPageProps {
  process_id: string;
  tab_id?: string;
}
interface ProcessesIdPageState {
  process?: Process;
  tab_id: string;
}
export default class ProcessesDetailPage extends React.PureComponent<ProcessesIdPageProps, ProcessesIdPageState> {
  constructor(props: ProcessesIdPageProps) {
    super(props);
    this.state = {
      tab_id: props.tab_id || 'set',
    };
  }
  componentDidMount() {
    // プロセスの検索
    processesIdGetAPI({ process_id: this.props.process_id }).then((res) => {
      if (res.status === 200) {
        this.setState({ process: res.data });
      }
    });
  }
  handleTabChange = (newTabId: TabId) => {
    history.push(`/processes/${this.props.process_id}/${newTabId}`);
    this.setState({ tab_id: String(newTabId) });
  };
  render() {
    const { process } = this.state;
    const is_ec2_instance = process?.processing_tenancy === 'Stateful' && process?.runtime_type === 'ECS';
    return (
      <PageWholeArea>
        <Breadcrumbs
          items={[
            { href: '/processes', icon: IconNames.ROUTE, text: 'プロセス' },
            {
              icon: IconNames.CUBE,
              text: this.state.process ? this.state.process.process_name : null,
            },
          ]}
        />
        {this.state.process ? (
          <>
            <Title text={this.state.process.process_name} />
            <Content>
              <Tabs selectedTabId={this.state.tab_id} onChange={this.handleTabChange}>
                <Tab id='set' title='プロセスの編集' />
                {is_ec2_instance && <Tab id='ecs-instances' title='チャンネルプロセスのインスタンス' />}
              </Tabs>
            </Content>
            {this.state.tab_id === 'set' ? <ProcessSetPanel process={this.state.process} /> : null}
            {this.state.tab_id === 'ecs-instances' && is_ec2_instance && (
              <ProcessesIdECSInstancesPanel process={this.state.process} />
            )}
          </>
        ) : null}
      </PageWholeArea>
    );
  }
}
