import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Tab } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { devicesIdGetAPI } from 'admin/api/device';
import { Device } from 'admin/api/device';
import { systemAdminSelector } from 'admin/redux/slices/systemSlice';
import preloadGroup from 'admin/utils/preloadGroup';
import { useSelector } from 'react-redux';
import { PageWholeArea, Title, Breadcrumbs, CategoryTabs } from 'shared/components/molecules/ContentsArea';
import DeviceIdEditPanel from './DeviceIdEditPanel';
import DevicesBasicInformation from './DevicesIdDetailBasicInformation';

// -- main component --

const DevicesDetailPage: React.FC<
  RouteComponentProps<{
    device_id: string;
    panel_id?: string;
  }>
> = (params) => {
  const system_state = useSelector(systemAdminSelector);

  const [device_data, setDeviceData] = useState<Device | null>(null);

  const getDevicesData = async (id: string) => {
    const res = await devicesIdGetAPI({
      id: id,
    });
    if (res.status === 200) {
      setDeviceData(res.data);
    }
  };

  const handleChangeDevice = () => {
    devicesIdGetAPI({ id: params.match.params.device_id }).then((res) => {
      if (res.status === 200) setDeviceData(res.data);
    });
  };

  // -- onload function --
  useEffect(() => {
    const stime = new Date();
    stime.setHours(0);
    stime.setMinutes(0);
    stime.setSeconds(0);
    const etime = new Date();
    etime.setHours(23);
    etime.setMinutes(59);
    etime.setSeconds(59);

    preloadGroup(
      /* eslint-disable-line */
      getDevicesData(params.match.params.device_id),
    );
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='DeviceDetail'>
      <Breadcrumbs
        items={[
          { href: '/devices', icon: IconNames.CAMERA, text: 'デバイス' },
          {
            icon: IconNames.CUBE,
            text: device_data == null ? '' : device_data.device_name,
          },
        ]}
      />
      <Title text='SORACOMデバイスの詳細' />

      {!system_state.loading.preloading && device_data != null && (
        <React.Fragment>
          <CategoryTabs
            id='DeviceDetailTabs'
            baseUrl={'/devices/' + device_data?.device_id}
            selectedTabId={params.match.params.panel_id}
          >
            <Tab id='' title='基本情報' />
          </CategoryTabs>
          <Switch>
            <Route exact path='/devices/:device_id' render={(props) => <DevicesBasicInformation {...props} />} />
            <Route
              exact
              path='/devices/:device_id/edit'
              render={(props) => (
                <DeviceIdEditPanel device={device_data} onChangeDevice={handleChangeDevice} {...props} />
              )}
            />
          </Switch>
        </React.Fragment>
      )}
    </PageWholeArea>
  );
};

// -- styled components --
// -- finally export part --

export default DevicesDetailPage;
