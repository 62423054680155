// -- http connection library --
import axios, { AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import { getAccessKey } from 'shared/utils/else/sessionStorageFunctions';

// -- handlers --
import { onReady, onSuccess, onError } from './handlers';

interface AdminClientOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  header_options?: CreateAxiosDefaults<any>['headers'];
  disabled_load?: boolean;
  ended_load?: boolean;
  disabled_error_message?: boolean;
}

// -- main axios base --
const getClient = (responseType: AxiosRequestConfig['responseType'], options: AdminClientOptions = {}) => {
  // -- authorized http client --
  // デフォルトの値を設定
  const { header_options = {}, disabled_load = false, ended_load = true, disabled_error_message = false } = options;
  const client = axios.create({
    headers: { 'x-api-key': getAccessKey(), ...header_options },
    responseType: responseType,
  });

  // -- set interceptors --
  client.interceptors.request.use((request) => onReady(request, disabled_load));
  client.interceptors.response.use(
    (response) => onSuccess(response, ended_load),
    (err) => onError(err, disabled_error_message),
  );

  // -- return part --
  return client;
};

export default getClient;
