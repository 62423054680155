// -- basic library --
import React, { useState } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { jsonpathGroupsPostAPI, RequestJsonpathGroupPost } from 'admin/api/jsonpathGroups';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';
import CSVTable from './CSVTable';
import { CsvRow } from 'shared/models/CsvRow';
import { isJsonpathGroupCategory, jsonpath_group_category_select_data } from 'shared/models/JsonpathGroupCategory';
import TextAreaInput from 'shared/components/atoms/TextAreaInput';
import { ExportCsvStatistics } from 'shared/models/ExportCsvStatistics';

// -- main component --

const JsonpathGroupCreatePage: React.FC = () => {
  const [jsonpath_group_name, setJsonpathGroupName] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [category, setCategoly] = useState<string>('');
  const [dashboard_display, setDashboardDisplay] = useState<boolean>(false);
  const [stream_display, setStreamDisplay] = useState<boolean>(false);
  const [process_display, setProcessDisplay] = useState<boolean>(false);
  const [export_csv_statistics, setExportCsvStatistics] = useState<ExportCsvStatistics>('NONE');
  const [csv_rows, setCsvRows] = useState<CsvRow[]>([]);

  // -- handlers --

  const handleCancel = () => {
    history.push('/jsonpath');
  };

  const handleCsvTableChange = (csv_rows: CsvRow[]) => {
    setCsvRows(csv_rows);
  };

  const handleFinish = async () => {
    if (!isNotOnlySpace(jsonpath_group_name)) {
      AlertDialog.show('JSONパスグループ名は必須です');
      return;
    }
    if (!isJsonpathGroupCategory(category)) {
      AlertDialog.show('カテゴリは必須です');
      return;
    }

    if (!isNotOnlySpace(export_csv_statistics)) {
      AlertDialog.show('集計キーは必須です');
      return;
    }
    const new_display = [];
    if (dashboard_display) {
      new_display.push('DASHBOARD');
    }
    if (stream_display) {
      new_display.push('STREAM');
    }
    if (process_display) {
      new_display.push('PROCESS');
    }

    const jsonpathGroupPostData: RequestJsonpathGroupPost = {
      jsonpath_group_name: jsonpath_group_name,
      category: category,
      display: new_display,
      export_csv_statistics: export_csv_statistics,
      csv_rows: csv_rows,
      memo,
    };
    // JsonpathGroupTableとJaonpathTable同時にPost
    const res = await jsonpathGroupsPostAPI(jsonpathGroupPostData);
    if (res.status === 200) {
      AlertDialog.show('JSONパスグループの追加に成功しました');
      history.push('/jsonpath');
    }
  };

  // -- onload function --

  // -- render part --
  return (
    <PageWholeArea data-testid='JsonpathGroupCreate'>
      <Breadcrumbs
        items={[
          { href: '/jsonpath/groups', icon: IconNames.PERSON, text: 'JSONパスグループ' },
          { icon: IconNames.CUBE_ADD, text: 'JSONパスグループの追加' },
        ]}
      />
      <Title text='JSONパスグループの追加' />
      <Content>
        <FormContainer title='基本情報'>
          <InputComponent text='JSONパスグループ名' required>
            <InputBox
              title='JSONパスグループ名'
              placeholder='入力してください(必須)'
              value={jsonpath_group_name}
              minLength={1}
              onChange={(e) => setJsonpathGroupName(e.currentTarget.value)}
            />
          </InputComponent>
          <InputComponent text='メモ'>
            <TextAreaInput
              style={{ width: '100%' }}
              rows={1}
              title='メモ'
              placeholder='入力してください(任意)'
              value={memo}
              onChange={(e) => setMemo(e.currentTarget.value)}
            />
          </InputComponent>
          <InputComponent text='対象画面' required>
            <CheckBoxWithText
              text='ダッシュボード'
              checked={dashboard_display}
              onClick={() => setDashboardDisplay(!dashboard_display)}
            />
            <CheckBoxWithText
              text='ストリーム'
              checked={stream_display}
              onClick={() => setStreamDisplay(!stream_display)}
              style={{ margin: '10px' }}
            />
            <CheckBoxWithText
              text='プロセス(ファイル処理/プロセスフロー)'
              checked={process_display}
              onClick={() => setProcessDisplay(!process_display)}
              style={{ margin: '10px' }}
            />
          </InputComponent>
          <InputComponent text='カテゴリ' required>
            <SelectBox
              onChange={(e) => setCategoly(e.currentTarget.value)}
              value={category}
              datas={jsonpath_group_category_select_data}
            />
          </InputComponent>
          <InputComponent text='集計'>
            <CheckBoxWithText
              text='集計する'
              checked={export_csv_statistics !== 'NONE'}
              onClick={() => setExportCsvStatistics(export_csv_statistics !== 'NONE' ? 'NONE' : 'KEY')}
            />
          </InputComponent>
          <CSVTable
            bodies={csv_rows}
            categoly={category}
            dashboard_display={dashboard_display}
            show_statistics={export_csv_statistics === 'KEY'}
            setCsvRows={handleCsvTableChange}
          />
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} text='キャンセル' is_margin_right is_white />
          <RoundedButton onClick={handleFinish} text='登録' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default JsonpathGroupCreatePage;
