// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import {
  TableBodyMultipleValueType,
  TableBodyType,
  TableBodyUrlType,
  TableHeaderType,
} from 'shared/components/molecules/Table/type';

// -- type declaration --

export interface TableProcessType extends TableBodyType {
  id: string;
  process_name: TableBodyUrlType;
  running_task_count: string;
  max_task_count: string;
  sqs_queue_messages: TableBodyMultipleValueType<string>;
  order: number | null;
}

interface Params {
  bodies: TableProcessType[];
  selected_bodies: TableProcessType[];
  handleCheckClick: (datas: TableProcessType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'process_name',
    label: 'プロセス名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'running_task_count',
    label: '実行中サーバ数',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'max_task_count',
    label: '最大サーバ数',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'sqs_queue_messages',
    label: '残リクエスト数',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'order',
    label: 'オーダー',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

// -- external functions --

// -- main component --
const ProcessTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='processes'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default ProcessTable;
