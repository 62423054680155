// -- basic library --
import React, { useMemo } from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
export interface FileBoxProps {
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // eventごと利用する
  title?: string;
  button_text?: string;
  accept?: string;
  file_name?: string;
  test_id?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  multiple?: boolean;
}

// -- main component --

const FileBox: React.FC<FileBoxProps> = (params) => {
  /**
   * idが被ると同じコンポーネントを参照してしまう
   * よってidがユニークになるように、ない場合はuuidから生成する
   */
  const id = useMemo(() => {
    return params.id ?? uuidv4();
  }, [params.id]);
  // -- render part --
  return (
    <WholeArea disabled={params.disabled} style={params.style}>
      {params.file_name ? params.file_name : <PlaceholderArea>{params.placeholder}</PlaceholderArea>}
      <Label htmlFor={id} disabled={params.disabled} hide_button={!params.button_text}>
        <Input
          id={id}
          title={params.title}
          type='file'
          onClick={(e) => {
            e.currentTarget.value = '';
          }}
          accept={params.accept}
          multiple={params.multiple}
          onChange={params.onChange}
          disabled={params.disabled}
        />
        {params.button_text}
      </Label>
    </WholeArea>
  );
};

// -- styled components --
const wholearea_disabled_styles = `{
  opacity: 1;
  background-color: ${colors.disabled_background_color};
  cursor: not-allowed;
}`;

const WholeArea = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  overflow: scroll;
  white-space: nowrap;
  width: 100%;
  padding: ${styles.input_box_padding};
  font-family: inherit;
  height: ${styles.input_component_height};
  box-sizing: border-box;
  border: 1px solid ${colors.component_small_border_color};
  border-radius: ${styles.border_radius};
  outline: none;
  position: relative;
  background-color: ${colors.white};
  &:focus {
    border-color: ${colors.selected_border_color};
  }
  ${(params) => (params.disabled ? wholearea_disabled_styles : null)};
`;

const PlaceholderArea = styled.div`
  color: ${colors.placeholder_color};
  font-family: inherit;
  font-weight: normal;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{
  disabled?: boolean;
  hide_button?: boolean;
}>`
  display: ${(p) => (p.hide_button ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 120px;
  height: calc((2 * ${styles.input_component_height}) / 3);
  border-radius: 25px;
  background-color: ${colors.component_main_color};
  color: ${colors.component_text_color};
  border: none;
  position: absolute;
  right: 10px;
  opacity: ${(params) => (params.disabled ? styles.opacity_disabled : 1)};
  top: calc((${styles.input_component_height} - ((2 * ${styles.input_component_height}) / 3)) / 2);
  &:hover {
    opacity: ${(params) => (params.disabled ? styles.opacity_disabled : styles.opacity_hover)};
    cursor: ${(params) => (params.disabled ? 'not-allowed' : 'pointer')};
  }
  font-weight: bold;
`;

// -- finally export part --

export default FileBox;
