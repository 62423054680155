import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import EventTable, { TableEventType, head_cells } from './EventTable';
import { useTenants } from 'admin/hooks/useTenants/useTenants';
import { eventsIdPutAPI } from 'admin/api/Events';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { table_cell_toggle_button_style } from 'shared/styles/styles';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { toStringBoolean } from 'shared/models/StringBoolean';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { useEvents } from 'admin/hooks/useEvents/useEvents';
import { useLocation } from 'react-router-dom';
import { tableHeaderToSearchRequiredItems } from 'shared/components/molecules/Table/tableFunctions';
import { Search, hasValue2, searchRequiredItemTosearch } from 'shared/models/Search';
import { setSearchesFromSessionStorage } from 'shared/utils/else/sessionStorageFunctions';
import { Event } from 'admin/api/Events';

/** イベント監視管理のトップ画面です */
// -- main component --
const Event: React.FC = () => {
  // -- local states --
  const { tenants_record } = useTenants({ status: 'ALL' });
  // eventsの一覧とeventsをロードする関数
  const { events, loadEvents } = useEvents();
  const [selected_bodies, setSelectedBodies] = useState<TableEventType[]>([]);
  const query = new URLSearchParams(useLocation().search);

  const handleOKClick = useCallback(
    async (params: { event_id: string; is_billing: boolean }) => {
      // 今のstatusの反転したbooleanを送信する
      const res = await eventsIdPutAPI({ event_id: params.event_id, is_billing: toStringBoolean(!params.is_billing) });
      if (res.status === 200) {
        AlertDialog.show('課金情報の更新に成功しました。');
      }
      await loadEvents();
    },
    [loadEvents],
  );

  // テーブルの状態でトグルボタンを押した時の関数
  const onChangeStatus = useCallback(
    async (params: {
      event_id: string;
      tenant_name: string;
      channel_event_condition_name: string;
      is_billing: boolean;
    }) => {
      ConfirmDialog.show(
        <div>
          [確認]
          <br />
          {params.tenant_name}の{params.channel_event_condition_name}の課金情報を変更します。
          <br />
          本当によろしいですか？
        </div>,
        () => handleOKClick({ event_id: params.event_id, is_billing: params.is_billing }),
        undefined,
        undefined,
      );
    },
    [handleOKClick],
  );

  const bodies = useMemo(() => {
    return events?.map((item) => {
      const tenant_name = tenants_record[item.tenant_id]?.tenant_name ?? '';
      return {
        id: item.event_id,
        tenant_id: item.tenant_id,
        tenant_name: tenant_name,
        channel_event_condition_name: item.channel_event_condition_name,
        current_status: item.current_status,
        current_status_at: dateToYMDHMS(item.current_status_at),
        updated_at: dateToYMDHMS(item.updated_at),
        is_billing: {
          value: item.is_billing ? 'ON' : 'OFF',
          available_value: item.is_billing ? 'ON' : 'OFF',
          displayable_value: (
            <ToggleButton
              stop_propagation={true}
              onClick={() => {
                onChangeStatus({
                  event_id: item.event_id,
                  tenant_name: tenant_name,
                  channel_event_condition_name: item.channel_event_condition_name,
                  is_billing: item.is_billing,
                });
              }}
              checked={item.is_billing}
              style={table_cell_toggle_button_style}
            />
          ),
        },
      };
    });
  }, [events, tenants_record, onChangeStatus]);

  const handleClick = (datas: TableEventType[]) => {
    setSelectedBodies(datas);
  };

  useEffect(() => {
    setupSessionStorage(query);
    const interval = setInterval(() => {
      loadEvents();
    }, 30000);
    return () => clearInterval(interval);
  }, []); /* eslint-disable-line */

  return (
    <PageWholeArea>
      <TopArea>
        <Title text='イベント監視一覧' />
      </TopArea>
      <BottomArea>
        {bodies !== undefined ? (
          <EventTable bodies={bodies} selected_bodies={selected_bodies} handleCheckClick={handleClick} />
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

/** query がある場合にセッションストレージの検索条件をセットアップします。 */
function setupSessionStorage(query: URLSearchParams) {
  // 指定されたクエリーが無い場合は終了
  if (Array.from(query.keys()).length === 0) {
    return;
  }
  // EventTable のヘッダからSearch[]に変換
  const searches: Search[] = [];
  // クエリで値をセットアップ
  tableHeaderToSearchRequiredItems(head_cells).forEach((item) => {
    const value = query.get(item.key);
    if (value) {
      const search = searchRequiredItemTosearch(item);
      // デフォルトの検索値の分解（datetimeの場合はFROM-TO(TOはあるなら)を取得）
      const _hasValue2 = hasValue2(search.search_type);
      const split_values = value.split(',');
      search.value = _hasValue2 ? split_values[0] : value;
      search.value2 = _hasValue2 && split_values.length > 1 ? split_values[1] : '';
      searches.push(search);
    }
  });
  setSearchesFromSessionStorage('events', 'filter', searches);
}

// -- styled components --

// -- finally export part --

export default Event;
