// -- basic library --
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  JsonpathGroup,
  jsonpathGroupsIdGetAPI,
  jsonpathGroupsIdPutAPI,
  RequestJsonpathGroupIdPut,
} from 'admin/api/jsonpathGroups';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { PageWholeArea, Title, Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';

// -- external types --
import { isNotOnlySpace } from 'shared/utils/is';
import CSVTable from './CSVTable';
import { CsvRow } from 'shared/models/CsvRow';
import { isJsonpathGroupCategory, jsonpath_group_category_select_data } from 'shared/models/JsonpathGroupCategory';
import TextAreaInput from 'shared/components/atoms/TextAreaInput';
import { ExportCsvStatistics } from 'shared/models/ExportCsvStatistics';

// -- type declaration --
type Params = RouteComponentProps<{ jsonpath_group_id: string }>;

// -- main component --

const JsonpathGroupDetailPage: React.FC<Params> = (params) => {
  // -- local states --
  const [jsonpath_group, setJsonpathGroup] = useState<JsonpathGroup | undefined>(undefined);
  const [memo, setMemo] = useState<string>('');
  const [category, setCategoly] = useState<string>('');
  const [dashboard_display, setDashboardDisplay] = useState<boolean>(false);
  const [stream_display, setStreamDisplay] = useState<boolean>(false);
  const [process_display, setProcessDisplay] = useState<boolean>(false);
  const [export_csv_statistics, setExportCsvStatistics] = useState<ExportCsvStatistics>('NONE');
  const [jsonpath_group_name, setJsonPathName] = useState<string>('');
  const [csv_rows, setCsvRows] = useState<CsvRow[]>();

  // -- get external datas --
  const getJsonpathGroupsData = async (jsonpath_group_id: string) => {
    const res = await jsonpathGroupsIdGetAPI({ jsonpath_group_id: jsonpath_group_id });
    if (res.status === 200) {
      setJsonpathGroup(res.data);
      setJsonPathName(res.data.jsonpath_group_name);
      setCategoly(res.data.category);
      if (typeof res.data.display == 'object') {
        for (const display of res.data.display) {
          if (display == 'DASHBOARD') {
            setDashboardDisplay(true);
          }
          if (display == 'STREAM') {
            setStreamDisplay(true);
          }
          if (display == 'PROCESS') {
            setProcessDisplay(true);
          }
        }
      }
      setExportCsvStatistics(res.data.export_csv_statistics);
      setCsvRows(res.data.csv_rows);
      setMemo(res.data.memo ?? '');
    }
  };

  const handleCancel = () => {
    history.push('/jsonpath');
  };

  const handleCsvTableChange = (csv_rows: CsvRow[]) => {
    setCsvRows(csv_rows);
  };

  const handleDisplyChange = (dashboard_display: boolean) => {
    if (!dashboard_display) {
      if (csv_rows) {
        for (let i = 0; csv_rows?.length > i; i++) {
          if (!csv_rows[i].accumulation) {
            csv_rows[i].accumulation = 'NONE';
          }
          if (!csv_rows[i].how_statistic_method) {
            csv_rows[i].how_statistic_method = 'Sum';
          }
          if (!csv_rows[i].stack_id) {
            csv_rows[i].stack_id = '1';
          }
        }
      }
      setDashboardDisplay(true);
    } else {
      setDashboardDisplay(false);
    }
  };

  const handleFinish = async (inputs: {
    jsonpath_group_id: string;
    jsonpath_group_name: string;
    category: string;
    dashboard_display: boolean;
    stream_display: boolean;
    process_display: boolean;
    export_csv_statistics: ExportCsvStatistics;
    memo: string;
  }) => {
    if (!isNotOnlySpace(inputs.jsonpath_group_name)) {
      AlertDialog.show('JSONパスグループ名は必須です');
      return;
    }
    if (!isJsonpathGroupCategory(inputs.category)) {
      AlertDialog.show('カテゴリは必須です');
      return;
    }
    if (!isNotOnlySpace(inputs.export_csv_statistics)) {
      AlertDialog.show('集計キーは必須です');
      return;
    }
    const new_display = [];
    if (dashboard_display) {
      new_display.push('DASHBOARD');
    }
    if (stream_display) {
      new_display.push('STREAM');
    }
    if (process_display) {
      new_display.push('PROCESS');
    }
    const jsonpathGroupIdPutData: RequestJsonpathGroupIdPut = {
      jsonpath_group_id: inputs.jsonpath_group_id,
      jsonpath_group_name: inputs.jsonpath_group_name,
      category: inputs.category,
      display: new_display,
      export_csv_statistics: inputs.export_csv_statistics,
      csv_rows: csv_rows as CsvRow[],
      memo,
    };
    const res = await jsonpathGroupsIdPutAPI(jsonpathGroupIdPutData);
    if (res.status === 200) {
      AlertDialog.show('JSONパスの変更に成功しました');
      history.push('/jsonpath');
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await getJsonpathGroupsData(params.match.params.jsonpath_group_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='JsonpathGroupCreate'>
      <Breadcrumbs
        items={[
          { href: '/jsonpath/groups', icon: IconNames.PERSON, text: 'JSONパスグループ' },
          { icon: IconNames.CUBE_ADD, text: 'JSONパスグループの詳細' },
        ]}
      />
      {jsonpath_group && csv_rows ? (
        <>
          <Title text='JSONパスグループの詳細' />
          <Content>
            <FormContainer title='基本情報'>
              <InputComponent text='JSONパスグループ名' required>
                <InputBox
                  value={jsonpath_group_name}
                  minLength={1}
                  onChange={(e) => setJsonPathName(e.currentTarget.value)}
                />
              </InputComponent>
              <InputComponent text='メモ'>
                <TextAreaInput
                  style={{ width: '100%' }}
                  rows={1}
                  title='メモ'
                  placeholder='入力してください(任意)'
                  value={memo}
                  onChange={(e) => setMemo(e.currentTarget.value)}
                />
              </InputComponent>
              <InputComponent text='対象画面' required>
                <CheckBoxWithText
                  text='ダッシュボード'
                  checked={dashboard_display}
                  onClick={() => handleDisplyChange(dashboard_display)}
                />
                <CheckBoxWithText
                  text='ストリーム'
                  checked={stream_display}
                  onClick={() => setStreamDisplay(!stream_display)}
                  style={{ margin: '10px' }}
                />
                <CheckBoxWithText
                  text='プロセス(ファイル処理/プロセスフロー)'
                  checked={process_display}
                  onClick={() => setProcessDisplay(!process_display)}
                  style={{ margin: '10px' }}
                />
              </InputComponent>
              <InputComponent text='カテゴリ' required>
                <SelectBox
                  onChange={(e) => setCategoly(e.currentTarget.value)}
                  value={category}
                  datas={jsonpath_group_category_select_data}
                />
              </InputComponent>
              <InputComponent text='集計'>
                <CheckBoxWithText
                  text='集計する'
                  checked={export_csv_statistics !== 'NONE'}
                  onClick={() => setExportCsvStatistics(export_csv_statistics !== 'NONE' ? 'NONE' : 'KEY')}
                />
              </InputComponent>
              <CSVTable
                bodies={csv_rows}
                jsonpath_group_id={params.match.params.jsonpath_group_id}
                categoly={category}
                dashboard_display={dashboard_display}
                showStatistics={export_csv_statistics === 'KEY'}
                setCsvRows={handleCsvTableChange}
              />
            </FormContainer>
            <Footer>
              <RoundedButton onClick={handleCancel} is_margin_right is_white text='戻る' />
              <RoundedButton
                onClick={() =>
                  handleFinish({
                    jsonpath_group_id: params.match.params.jsonpath_group_id,
                    jsonpath_group_name: jsonpath_group_name,
                    category: category,
                    dashboard_display: dashboard_display,
                    stream_display: stream_display,
                    process_display: process_display,
                    export_csv_statistics: export_csv_statistics,
                    memo,
                  })
                }
                text='更新'
              />
            </Footer>
          </Content>
        </>
      ) : (
        <Spinner />
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default JsonpathGroupDetailPage;
