import React from 'react';
import PfAlert from 'shared/components/atoms/PfAlert';
import PfProgressBar from 'shared/components/atoms/PfProgressBar';

/**
 * プログレスバー(割合)ダイアログです。
 * シングルトンインスタンスを保持するため、画面上に２つ以上配置しないでください。
 * ダイアログの呼び出し方:
 * ProgressPercentDialog.show(0);
 * valueの更新の仕方
 * ProgressPercentDialog.update(0.1);
 * 中止のstateを取得
 * ProgressPercentDialog.checkAborted()
 *
 */
interface ProgressPercentDialogProps {}
interface ProgressPercentDialogState {
  isOpen: boolean;
  value: number;
  callback?: () => void;
  aborted: boolean; // 中止を監視する変数
}

export default class ProgressPercentDialog extends React.PureComponent<
  ProgressPercentDialogProps,
  ProgressPercentDialogState
> {
  static instance?: ProgressPercentDialog;
  constructor(props: ProgressPercentDialogProps) {
    super(props);
    this.state = {
      isOpen: false,
      value: 0,
      aborted: false,
    };
  }
  componentDidMount() {
    ProgressPercentDialog.instance = this;
  }
  componentWillUnmount() {
    ProgressPercentDialog.instance = undefined;
  }
  static show(
    value: number,
    option?: {
      callback?: () => void;
    },
  ) {
    if (ProgressPercentDialog.instance) {
      ProgressPercentDialog.instance.setState({
        isOpen: true,
        value: value,
        callback: option?.callback,
        aborted: false,
      });
    } else {
      alert(value);
    }
  }
  static updateValue(value: number) {
    if (ProgressPercentDialog.instance) {
      ProgressPercentDialog.instance.setState({ value: value });
    }
  }
  static checkAborted() {
    if (ProgressPercentDialog.instance) {
      return ProgressPercentDialog.instance.state.aborted;
    } else {
      return false;
    }
  }
  onConfirm() {
    // 完了していないと押せない
    if (!this.isFinished()) {
      return;
    }
    this.setState({ isOpen: false });
    if (this.state.callback) {
      this.state.callback();
    }
  }
  async onCancel() {
    // 完了していたら押せない
    if (this.isFinished()) {
      return;
    }
    this.setState({ isOpen: false, aborted: true });
  }
  isFinished() {
    const flag1 = this.state.value >= 1;
    return flag1;
  }
  render() {
    return (
      <>
        {this.state.isOpen ? (
          <PfAlert
            disabled_first_button={!this.isFinished()}
            disabled_last_button={this.isFinished()}
            cancelButtonText={'中止'}
            confirmButtonText={this.isFinished() ? 'OK' : undefined}
            onCancel={() => this.onCancel()}
            onConfirm={() => this.onConfirm()}
            isOpen={this.state.isOpen}
            body_width='100%'
          >
            <div style={{ display: 'block', width: '100%', textAlign: 'center' }}>
              <PfProgressBar value={this.state.value} width='100%' stripes={this.isFinished() ? false : true} />
              <div style={{ marginTop: 15 }}>{Math.min(Math.floor(this.state.value * 100), 100)}% / 100%</div>
              <div style={{ marginTop: 15 }}>
                {this.isFinished() ? 'アップロードが完了しました' : '※中止すると、アップロードに失敗します。'}
              </div>
            </div>
          </PfAlert>
        ) : null}
      </>
    );
  }
}
