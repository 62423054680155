// -- basic library --
import React, { memo } from 'react';
import { Tabs, Breadcrumbs as BpBreadcrumbs, IBreadcrumbProps, ITabsProps, TabId } from '@blueprintjs/core';
import logo from 'assets/IDEA_platform_logo.png';
import history from 'shared/browserHistory';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// ベーステキスト

// ログインエリア
export const LoginBackGround = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 450px;
  overflow: hidden;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background_color};
`;

export const LoginArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
  height: 550px;
  border-radius: 5%;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  pointer-events: all !important;
  opacity: 1;
  text-align: center;
  background-color: ${colors.white};
  padding: ${styles.login_padding_top} ${styles.login_padding_side};
  box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 1px 1px rgb(16 22 26 / 20%), 0 2px 6px rgb(16 22 26 / 20%);
`;

export const LoginBaseArea = styled.div`
  display: flex;
  width: 100%;
`;

export const LoginLogo = memo(function LoginLogo() {
  return <LoginLogoArea src={logo} alt='IntelligenceDesignのロゴ' />;
});

export const LoginLogoArea = styled.img`
  width: 80px;
  height: auto;
`;

export const LoginNormalText = styled.div`
  font-size: 14px;
`;

// ポップオーバーエリア
export const PopoverWholeArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 25px 0px 25px;
  overflow: auto;
`;

// コンテンツの全体領域
export const PageWholeArea = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background_color};
  overflow-y: scroll;
  padding: ${styles.whole_padding};
`;

export const TopArea = styled.div`
  width: 100%;
  height: 6%;
  display: flex;
  align-items: center;
  margin-bottom: ${styles.interval_margin};
`;

export const BottomArea = styled.div`
  width: 100%;
  height: auto;
  display: block;
`;

export const ButtonElementsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// コンテンツエリア
export const Content = styled.div`
  width: 100%;
`;

// パンくずエリア
// 引数の指定方法はBreadcrumbsを参照
// https://blueprintjs.com/docs/#core/components/breadcrumbs.breadcrumbs
export class Breadcrumbs extends React.Component<{
  items: IBreadcrumbProps[];
}> {
  public render() {
    return (
      <BreadcrombArea>
        <BpBreadcrumbs items={this.props.items} />
      </BreadcrombArea>
    );
  }
}
const BreadcrombArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

// タイトルエリア
interface TitleParam {
  text: string;
  icon?: JSX.Element;
  style?: React.CSSProperties;
}
export const Title = memo(function Title(params: TitleParam) {
  return (
    <TitleArea style={params.style}>
      <TitleText>{params.text}</TitleText>
      {params.icon ? <IconArea>{params.icon}</IconArea> : null}
    </TitleArea>
  );
});

export const TitleSmall = memo(function TitleSmall(params: TitleParam) {
  return (
    <TitleArea style={params.style}>
      <SmallTitleText>{params.text}</SmallTitleText>
      {params.icon ? <IconArea>{params.icon}</IconArea> : null}
    </TitleArea>
  );
});
const TitleArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TitleText = styled.h2`
  letter-spacing: ${styles.letter_spacing};
  margin: 0;
`;

const SmallTitleText = styled.h3`
  letter-spacing: ${styles.letter_spacing};
  margin: 0;
`;

const IconArea = styled.div`
  margin-left: 5px;
`;

/**
 * カテゴリータブ
 * baseUrl を指定することで、onChangeを省略してタブによるURL遷移を発火できます
 */

// src/molecules/PageSwitchingTabsを使用するため将来的に使用しない(変更していない旧ページがあるためまだ残しておく)
export const CategoryTabs: React.FC<ITabsProps & { baseUrl?: string }> = (param) => {
  const defaultOnChange = (newTabId: TabId) => {
    const pathAction = newTabId === '' ? '' : '/' + newTabId;
    history.push(param.baseUrl + pathAction);
  };

  const new_param = { ...param }; // paramはread onlyなので変更ができるように自分で定義

  if (!param.onChange) {
    new_param.onChange = defaultOnChange;
  } // onChangeの省略動作

  if (param.selectedTabId === undefined) {
    new_param.selectedTabId = '';
  } // undefined を空文字列に置換

  return (
    <CategoryTabArea>
      <div style={{ borderBottom: '1px solid ' + colors.gray, width: '100%' }}>
        <Tabs {...new_param} />
      </div>
    </CategoryTabArea>
  );
};
const CategoryTabArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 0px 5px 0px;
`;

// テーブルエリア
export const TableController = styled.div`
  display: flex;
`;
export const TableTitle = styled.div`
  width: 100%;
`;
export const TableActions = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

// プロセスを選択する部分の詳細条件でのコンポーネント
export const SubTextBasicProcess = styled.div`
  margin-bottom: ${styles.interval_narrow_margin};
  font-weight: ${styles.font_weight_light};
`;

export const SubTextDetails = styled.div`
  margin-top: ${styles.interval_margin};
  margin-bottom: ${styles.interval_narrow_margin};
  font-weight: ${styles.font_weight_light};
`;

export const AppParamRowArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${styles.interval_narrow_margin};
`;

export const AppParamRowText = styled.div`
    margin-right: ${styles.interval_margin};
    width: 200px;
    max-width: 200px
    white-space: nowrap;
    display: flex;
`;

export const FlexCenterDiv = styled.div<{
  align_center?: boolean;
  justufy_center?: boolean;
}>`
  display: flex;
  align-items: ${(p) => (p.align_center ? 'center' : 'stretch')};
  justify-content: ${(p) => (p.justufy_center ? 'center' : 'flex-start')};
`;

// 上側のボタンエリア
export const TopButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 36px;
  margin: 2px auto;
`;
