// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- type declaration --
interface Params {
  handleClick: (id: string) => void;
  display_datas: { id: string; text: string }[];
  selected_id?: string;
  initial_selected_id?: string;
  style?: React.CSSProperties;
}

// -- main component --

const PageSwitchingTabs: React.FC<Params> = (params) => {
  const initial_selected_id = params.initial_selected_id || '';
  const selected_id = params.selected_id || initial_selected_id;
  // -- render part --
  return (
    <WholeArea style={params.style}>
      <CustomTexts>
        {params.display_datas.map((data, index) => {
          return (
            <CustomText onClick={() => params.handleClick(data.id)} selected={selected_id === data.id} key={index}>
              {data.text}
            </CustomText>
          );
        })}
      </CustomTexts>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${styles.interval_margin};
  display: flex;
  align-items: center;
`;

const CustomTexts = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: calc(3px + 7px - 1px);
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray};
`;
const CustomText = styled.div<{
  selected?: boolean;
}>`
  margin-right: ${styles.interval_margin};
  display: inline-block;
  font-size: ${styles.small_text_size};
  color: ${(params) => (params.selected ? colors.component_main_color : colors.main_font_color)};
  text-decoration: ${(params) => (params.selected ? 'underline' : 'none')};
  text-decoration-thickness: 3px;
  text-underline-offset: 7px;
  cursor: pointer;
  user-select: none;
  &:hover {
    font-weight: bold;
  }
`;

// -- finally export part --

export default PageSwitchingTabs;
