import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { getClient } from './base';
import { StatusType } from 'shared/models/StatusType';

/*** エンティティ ***/

export interface EdgeDevice {
  edge_device_key: string;
  key_type: string;
  tenant_id: string;
  heartbeat_edge_device_name: string;
  heartbeat_ip: string;
  heartbeat_at: ISO8601;
  latlon?: number[];
  latlon_at?: ISO8601;
  status: StatusType;
}

const sample_edge_device1: EdgeDevice = {
  edge_device_key: 'edgekey1',
  key_type: 'a',
  tenant_id: '00000001',
  heartbeat_edge_device_name: 'エッジデバイス1',
  heartbeat_ip: '0.0.0',
  heartbeat_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  status: 'ACTIVE',
};

const sample_edge_device2: EdgeDevice = {
  edge_device_key: 'edgekey2',
  key_type: 'b',
  tenant_id: '00000003',
  heartbeat_edge_device_name: 'エッジデバイス2',
  heartbeat_ip: '01.01.01',
  heartbeat_at: '2023-01-01T00:00:00+09:00' as ISO8601,
  status: 'ACTIVE',
};

export interface EdgeDeviceHeartbeatAt {
  edge_device_key: string;
  heartbeat_at: ISO8601;
  status: StatusType;
}

const sample_edge_device_heartbeat_at1: EdgeDeviceHeartbeatAt = {
  edge_device_key: 'edgekey1',
  heartbeat_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  status: 'ACTIVE',
};

const sample_edge_device_heartbeat_at2: EdgeDeviceHeartbeatAt = {
  edge_device_key: 'edgekey2',
  heartbeat_at: '2023-01-01T00:00:00+09:00' as ISO8601,
  status: 'ACTIVE',
};

/*** Caching mechanism ***/

export class CachedEdgeDevices {
  private searched = false;
  private cache: EdgeDevice[] = [];
  private params: RequestEdgeDevicesGet;
  constructor(params: RequestEdgeDevicesGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<EdgeDevicesWithPaging> = await edgeDevicesGetAPI({
          ...this.params,
          exclusive_start_edge_device_key: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.last_edge_device_key;
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

export interface EdgeDevicesWithPaging {
  items: EdgeDevice[];
  has_next: boolean;
  last_edge_device_key?: string;
}

export interface EdgeDevicesHeartbeatAtWithPaging {
  items: EdgeDeviceHeartbeatAt[];
  has_next: boolean;
  last_edge_device_key?: string;
}

/*** [GET] /api/edge/devices ***/

export interface RequestEdgeDevicesGet {
  exclusive_start_edge_device_key?: string;
}

export const edgeDevicesGetAPI = (params: RequestEdgeDevicesGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/edge/devices`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { ...params };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<EdgeDevicesWithPaging>(axios, path, query, form, method, {
    items: [sample_edge_device1, sample_edge_device2],
    has_next: false,
  });
};

/*** [GET] /api/edge/devices/{edge_device_key} ***/
export interface RequestEdgeDeviceKeyGet {
  edge_device_key: string;
}

export const edgeDevicesKeyGetAPI = (params: RequestEdgeDeviceKeyGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/edge/devices/${params.edge_device_key}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<EdgeDevice>(axios, path, query, form, method, sample_edge_device1);
};

/*** [GET] /api/edge/devices/heartbeat/at ***/

export interface RequestEdgeDevicesHeartbeatAtGet {}

export const edgeDevicesHeartbeatAtGetAPI = (params: RequestEdgeDevicesHeartbeatAtGet) => {
  // クライアントを定義
  const axios = getClient('json', { disabled_load: true });

  // パス・メソッドを定義
  const path = `/api/edge/devices/heartbeat/at`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { ...params };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<EdgeDevicesHeartbeatAtWithPaging>(axios, path, query, form, method, {
    items: [sample_edge_device_heartbeat_at1, sample_edge_device_heartbeat_at2],
    has_next: false,
  });
};

/*** [PUT] /api/edge/devices/{edge_device_key} ***/

export interface RequestEdgeDeviceKeyPut {
  edge_device_key: string;
  tenant_id?: string;
  heartbeat_edge_device_name: string;
}

export const edgeDevicesKeyPutAPI = (params: RequestEdgeDeviceKeyPut) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/edge/devices/${params.edge_device_key}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (typeof value === 'string') {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<EdgeDevice>(axios, path, query, form, method, sample_edge_device1);
};

/*** [DELETE] /api/edge/devices/{edge_device_key} ***/
export interface RequestEdgeDeviceKeyDelete {
  edge_device_key: string;
}

export const edgeDevicesKeyDeleteAPI = (params: RequestEdgeDeviceKeyDelete) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/edge/devices/${params.edge_device_key}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<EdgeDevice>(axios, path, query, form, method, sample_edge_device1);
};
