import { useEffect, useState } from 'react';
import {
  CachedEdgeDevices,
  EdgeDevice,
  EdgeDeviceHeartbeatAt,
  edgeDevicesHeartbeatAtGetAPI,
  edgeDevicesKeyDeleteAPI,
} from 'admin/api/edgeDevices';
import Spinner from 'shared/components/atoms/Spinner';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import EdgeDeviceTable, { TableEdgeDeviceType } from './EdgeDeviceTable';
import history from 'shared/browserHistory';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { isNotSelected } from 'shared/utils/is';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { toEdgeDeviceStatusJP } from 'shared/models/EdgeDeviceStatus';
import { getSpecifiedMinute } from 'shared/utils/get';
import { ISO8601, toISO8601 } from 'shared/models/ISO8601';
import { TableBodyUrlType } from 'shared/components/molecules/Table/type';

export const EdgeDevicesTopPage: React.FC = () => {
  const [table_bodies, setTableBodies] = useState<TableEdgeDeviceType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableEdgeDeviceType[]>([]);
  const [now, setNow] = useState<ISO8601>(toISO8601(Number(getSpecifiedMinute(0)) * 1000));

  // -- handlers --
  const handleClick = (datas: TableEdgeDeviceType[]) => {
    setSelectedBodies(datas);
  };

  const loadTableBodies = async () => {
    const items: EdgeDevice[] = await new CachedEdgeDevices({}).get();
    const return_table_datas: TableEdgeDeviceType[] = [];
    for (const item of items) {
      const return_table_data: TableEdgeDeviceType = {
        id: item.edge_device_key,
        edge_device_key: {
          value: item.edge_device_key,
          url: `/edgeDevices/${item.edge_device_key}/detail`,
          color: 'rgb(57, 131, 141)',
        },
        key_type: item.key_type,
        tenant_id: item.tenant_id,
        heartbeat_edge_device_name: item.heartbeat_edge_device_name,
        heartbeat_ip: item.heartbeat_ip,
        heartbeat_at: dateToYMDHMS(item.heartbeat_at),
        latlon: getLatLonValue(item.latlon),
        latlon_at: dateToYMDHMS(item.latlon_at),
        edge_device_status: toEdgeDeviceStatusJP({ now: now, edge_device_heartbeat_at: item.heartbeat_at }),
        status: item.status,
      };
      return_table_datas.push(return_table_data);
    }
    setTableBodies(return_table_datas);
  };

  const getHeartbeatData = async () => {
    let heartbeats: EdgeDeviceHeartbeatAt[] = [];
    let has_next = true;
    let exclusive_start_key = '';
    while (has_next) {
      const res = await edgeDevicesHeartbeatAtGetAPI({
        exclusive_start_edge_device_key: exclusive_start_key,
      });
      has_next = false;
      if (res.status === 200) {
        heartbeats = heartbeats.concat(res.data.items);
        has_next = res.data.has_next;
        exclusive_start_key = res.data.last_edge_device_key || '';
      }
    }

    setTableBodies((TableBodies) =>
      TableBodies?.map((table_bodies) => {
        const heartbeatData = heartbeats.find((h) => h.edge_device_key === table_bodies.id);
        return heartbeatData
          ? {
              ...table_bodies,
              heartbeat_at: dateToYMDHMS(heartbeatData.heartbeat_at),
              edge_device_status: toEdgeDeviceStatusJP({
                now: now,
                edge_device_heartbeat_at: heartbeatData.heartbeat_at,
              }),
            }
          : table_bodies;
      }),
    );
  };

  const handleOKClick = async () => {
    // 削除関数定義
    const deletes = async () => {
      let has_deleted_flag = false;
      // 並列に削除
      await Promise.all(
        selected_bodies.map((sd) => {
          if (sd.status === 'DELETED') {
            has_deleted_flag = true;
            return;
          }
          return edgeDevicesKeyDeleteAPI({ edge_device_key: sd.id });
        }),
      );
      if (has_deleted_flag) {
        AlertDialog.show(`状態が「DELETED」のデータは削除できませんでした`);
      }

      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/edgeDevices');
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているエッジデバイスを削除します。\n本当によろしいですか？',
      handleOKClick,
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
    // 30秒ごとにheartbeat_atと現在時刻nowを更新
    const interval = setInterval(() => {
      const currentTime = toISO8601(Date.now());
      setNow(currentTime);
      getHeartbeatData();
    }, 30000);
    return () => clearInterval(interval);
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='EdgeDevice'>
      <TopArea>
        <Title text='エッジデバイス' />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <EdgeDeviceTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleClick}
            footer_option={{
              text: '＞チェックしたエッジデバイスを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <>
            <Spinner />
          </>
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

/** デバイス位置情報から、緯度経度のラベルと、GoogleMapへのリンクを持つ `TableBodyUrlType` を返します。 */
function getLatLonValue(latlon: number[] | undefined): TableBodyUrlType | string {
  try {
    if (latlon) {
      return {
        value: `${latlon[0]}, ${latlon[1]}`,
        url: `https://www.google.com/maps/search/?api=1&query=${latlon[0]}%2C${latlon[1]}`,
        color: 'rgb(57, 131, 141)',
        newWindow: true
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return '';
}

export default EdgeDevicesTopPage;
