import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { ProcessFlow, processflowsIdGetAPI } from 'admin/api/processflows';
import { Breadcrumbs, Content, Title, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import ProcessflowsEditFormPanel from './ProcessflowsEditFormPanel';

interface ProcessFlowsIdPageProps {
  process_flow_id: string;
}
interface ProcessFlowsIdPageState {
  processflow?: ProcessFlow;
}
export default class ProcessFlowsDetailPage extends React.PureComponent<
  ProcessFlowsIdPageProps,
  ProcessFlowsIdPageState
> {
  constructor(props: ProcessFlowsIdPageProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    processflowsIdGetAPI({ process_flow_id: this.props.process_flow_id }).then((res) => {
      if (res.status === 200) {
        this.setState({ processflow: res.data });
      }
    });
  }
  render() {
    return (
      <PageWholeArea>
        <Breadcrumbs
          items={[
            { href: '/processflows', icon: IconNames.FLOWS, text: 'プロセスフロー' },
            { icon: IconNames.CUBE, text: this.state.processflow?.process_flow_name },
          ]}
        />
        {this.state.processflow && (
          <>
            <Title text={this.state.processflow.process_flow_name} />
            <Content>
              <ProcessflowsEditFormPanel processflow={this.state.processflow} />
            </Content>
          </>
        )}
      </PageWholeArea>
    );
  }
}
