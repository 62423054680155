// -- basic library --
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { RequestUsersIdPut, usersIdGetAPI, usersIdPutAPI } from 'admin/api/users';

import preloadGroup from 'admin/utils/preloadGroup';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import useObj from 'shared/hooks/useObj/useObj';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { TableauRole, tableau_role_radio_select_items } from 'shared/models/TableauRole';
import RadioBox from 'shared/components/molecules/RadioBox';
import { Tenant, tenantsIdGetAPI } from 'admin/api/tenants';

// -- type declaration --
type Params = RouteComponentProps<{ user_id: string }>;

// -- main component --

const UsersIdDetail: React.FC<Params> = (params) => {
  // -- local states --;
  const {
    obj: form,
    setObj: setForm,
    updateObj: updateForm,
  } = useObj<{
    user_name: string;
    mail_address: string;
    tenant_id: string;
    login_id: string;
    tableau_role: TableauRole;
  }>({ user_name: '', mail_address: '', tenant_id: '', login_id: '', tableau_role: 'Not-used' });
  const [tenant, setTenant] = useState<Tenant | null>(null);
  // tableauを利用するかどうか
  const use_tableau = useMemo(() => {
    return tenant?.use_tableau;
  }, [tenant]);

  // -- handlers --
  // 初期データを取得してstateに格納する
  const loadInitialData = useCallback(async () => {
    // ユーザー情報から初期値をロード
    const res_user = await usersIdGetAPI({ user_id: params.match.params.user_id });
    if (res_user.status !== 200) return;
    setForm({
      tenant_id: res_user.data.tenant_id,
      login_id: res_user.data.login_id,
      user_name: res_user.data.user_name,
      mail_address: res_user.data.mail_address,
      tableau_role: res_user.data.tableau_role,
    });
    // 該当のテナントデータをロード
    const res_tenant = await tenantsIdGetAPI({ tenant_id: res_user.data.tenant_id });
    if (res_tenant.status !== 200) return;
    setTenant(res_tenant.data);
  }, [params.match.params.user_id, setForm]);

  const handleCancel = () => {
    history.push('/users');
  };

  const handleFinish = async () => {
    const { tableau_role } = form;
    const user_put_data: RequestUsersIdPut = {
      user_id: params.match.params.user_id,
      tableau_role: use_tableau ? tableau_role : undefined,
    };
    const res = await usersIdPutAPI(user_put_data);
    if (res.status === 200) {
      AlertDialog.show('ユーザーの編集に成功しました');
      history.push('/users');
    }
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(loadInitialData());
  }, [loadInitialData]);

  // -- render part --
  return (
    <PageWholeArea>
      <Breadcrumbs
        items={[
          { href: '/users', icon: IconNames.PERSON, text: 'ユーザー' },
          { icon: IconNames.CUBE, text: params.match.params.user_id },
        ]}
      />
      <Title text='ユーザーの詳細' />
      <Content>
        <FormContainer title='テナント情報'>
          <InputComponent text='テナント：'>{form.tenant_id}</InputComponent>
        </FormContainer>
        <FormContainer title='基本情報'>
          <InputComponent text='ログインID：'>{form.login_id}</InputComponent>
          <InputComponent text='メールアドレス：'>{form.mail_address}</InputComponent>
          <InputComponent text='ユーザー名：'>{form.user_name}</InputComponent>
          {use_tableau && (
            <InputComponent text='Tableau'>
              <RadioBox
                datas={tableau_role_radio_select_items}
                selectedValue={form.tableau_role}
                handleChangeClick={(value) => updateForm({ tableau_role: value as TableauRole })}
              />
            </InputComponent>
          )}
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} is_margin_right is_white text='戻る' />
          <RoundedButton onClick={handleFinish} text='更新' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default UsersIdDetail;
