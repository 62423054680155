import { AxiosInstance, AxiosResponse } from 'axios';
import FileDownload from 'js-file-download';
import { Query, toAxiosQuery } from 'shared/models/Query';

import toMock from './toMock';

const sendDownloadAxios = <ResponseType>(
  axios: AxiosInstance,
  path: string,
  query: Query,
  form: FormData,
  mock_response?: ResponseType,
) => {
  const axios_query = toAxiosQuery(query);
  // yarn buildした時以外はモックを使用
  if (process.env.NODE_ENV !== 'production') {
    toMock(axios, path, axios_query, 'get', mock_response);
  }

  return axios.get(path, axios_query).then((res: AxiosResponse<Blob>) => {
    const content_disposition = (res && res.headers && res.headers['content-disposition']) || '';
    const start_index = content_disposition.indexOf('filename=') + 9;
    const end_index = content_disposition.length;
    const file_name: string = content_disposition.substring(start_index, end_index);
    let new_file_name = '';
    // クオテーションで囲まれている場合はクオテーションを削除
    for (let i = 0; i < file_name.length; i++) {
      if (i === 0 && (file_name[i] === "'" || file_name[i] === '"')) {
        continue;
      }
      if (i === file_name.length - 1 && (file_name[i] === "'" || file_name[i] === '"')) {
        continue;
      }
      new_file_name += file_name[i];
    }
    if (content_disposition.indexOf('filename*=') !== -1) {
      new_file_name = content_disposition.substring(content_disposition.indexOf("''") + 2, content_disposition.length);
      new_file_name = decodeURI(new_file_name).replace(/\+/g, ' ');
    }
    FileDownload(res.data, new_file_name);
  });
};

// -- finally export part --
export default sendDownloadAxios;
