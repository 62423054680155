import { assertNever } from 'shared/utils/else/assertNever';

export type ShowStatus = 'PUBLIC' | 'PRIVATE';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isShowStatus = (data: any): data is ShowStatus => data === 'PUBLIC' || data === 'PRIVATE';

export const showStatusToJP = (status: ShowStatus) => {
  switch (status) {
    case 'PUBLIC':
      return '公開';
    case 'PRIVATE':
      return '非公開';
    default:
      assertNever(status);
      return '';
  }
};
