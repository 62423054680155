import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from './base';
import { addFormData } from 'shared/utils/converter';
import { getSubDomain } from 'shared/utils/get';

// import createMultipleAPIDatas from 'utils/createMultipleAPIDatas';

/***
 * エンティティ
 * use_tableauがtrueの時は必ずtableau_identifierがstring
 * ***/

export type Tenant = {
  tenant_id: string;
  tenant_name: string;
  tenant_domain: string;
  tenant_generator2_domain: string | null;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
} & ({ use_tableau: false; tableau_id: null } | { use_tableau: true; tableau_id: string });

// テナントを選択する時に、渡す必要最低限のエンティティ
export interface TenantForSelect {
  tenant_name: string;
  tenant_id: string;
}

export interface TenantsWithPaging {
  items: Tenant[];
  has_next: boolean;
}

const sub_domain = getSubDomain();

export const sample_tenant_1: Tenant = {
  tenant_id: '00000001',
  tenant_name: '第一商事',
  tenant_domain: 'domain1' + sub_domain,
  tenant_generator2_domain: 'gene2_domain',
  use_tableau: true,
  tableau_id: 'ao9c7vq2b4',
  status: 'DELETED',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

export const sample_tenant_2: Tenant = {
  tenant_id: '00000002',
  tenant_name: '第一商事',
  tenant_domain: 'domain1' + sub_domain,
  tenant_generator2_domain: null,
  use_tableau: false,
  tableau_id: null,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** Caching mechanism ***/

/**
 * このクラスを使うと、statusを指定して、テナントを全件取得することができます。
 * statusを省略すると、ACTIVEとみなされます。
 */
export class CachedTenants {
  private searched = false;
  private cache: Tenant[] = [];
  private params: RequestTenantsGet;
  constructor(params: RequestTenantsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<TenantsWithPaging> = await tenantsGetAPI({
          ...this.params,
          exclusive_start_tenant_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next || res.data.items.length === 0) {
            break;
          } else {
            esk = res.data.items[res.data.items.length - 1].tenant_id;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/tenants ***/
export interface RequestTenantsGet {
  status?: StatusType;
  exclusive_start_tenant_id?: string;
}

export const tenantsGetAPI = (params: RequestTenantsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/tenants`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<TenantsWithPaging>(axios, path, query, form, method, {
    items: [sample_tenant_1, sample_tenant_2],
    has_next: false,
  });
};

/*** [POST] /api/tenants ***/

export interface RequestTenantsPost {
  tenant_name: string;
  tenant_domain: string;
  tenant_generator2_domain?: string;
  use_tableau: boolean;
  tableau_id?: string;
}

export const tenantsPostAPI = (params: RequestTenantsPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/tenants`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    addFormData({ key, value, form });
  }

  // 送信
  return sendAxios<Tenant>(axios, path, query, form, method, sample_tenant_1);
};

/*** [GET] /api/tenants/{id} ***/

export interface RequestTenantsIdGet {
  tenant_id: string;
}

export const tenantsIdGetAPI = (params: RequestTenantsIdGet) => {
  const { tenant_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/tenants/${tenant_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Tenant>(axios, path, query, form, method, sample_tenant_1);
};

/*** [PUT] /api/tenants/{id} ***/

export interface RequestTenantsIdPut {
  tenant_id: string;
  tenant_name: string;
  tenant_domain: string;
  tenant_generator2_domain?: string;
  use_tableau?: boolean;
  tableau_id?: string;
}

export const tenantsPutAPI = (params: RequestTenantsIdPut) => {
  const { tenant_id, ...form_params } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/tenants/${tenant_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(form_params)) {
    addFormData({ key, value, form });
  }

  // 送信
  return sendAxios<Tenant>(axios, path, query, form, method, sample_tenant_1);
};

/*** [DELETE] /api/tenants/{id} ***/

export interface RequestTenantsIdDelete {
  tenant_id: string;
}

export const tenantsDeleteAPI = (params: RequestTenantsIdDelete) => {
  const { tenant_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/tenants/${tenant_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Tenant>(axios, path, query, form, method, sample_tenant_1);
};
