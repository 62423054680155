// -- basic library --
import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { BillingSummariesDataDownloadGetAPI, BillingSummariesDataDownloadZipGetAPI } from 'admin/api/billingSummary';
import styled from 'styled-components';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import styles from 'shared/styles/styles';
import { BottomArea, Content } from 'shared/components/molecules/ContentsArea';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
import loadWrapperFunc from 'admin/utils/loadWrapperFunc';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import ResultsTableFooter from './ResultsTableFooter';

interface Params {
  yearmonth: string;
  bodies: TableBillingType[];
  selected_bodies: TableBillingType[];
  handleCheckClick: (datas: TableBillingType[]) => void;
  loadTableBodies: () => void;
  setSelectedBodies: (datas: TableBillingType[]) => void;
  handleCancelClick: () => void;
}

export interface TableBillingType {
  id: string;
  tenant_id: TableBodyUrlType;
  tenant_name: string;
  demand_price_summary: string;
  yearmonth: string;
}

export type TableBillingDataDownloadPackageType = {
  id: string;
  tenant_id: TableBodyUrlType;
  tenant_name: string;
  demand_price_summary: string;
  button_element: JSX.Element;
};


// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'tenant_name',
    label: 'テナント名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'demand_price_summary',
    label: '請求金額',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'button_element',
    label: '',
    useDoubleTableButtonStyles: true,
  },
];

// -- main component --
const BillingTable: React.FC<Params> = (params) => {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableBillingDataDownloadPackageType[] | undefined>(undefined);

  const downloadCsv = async (items: TableBillingType) => {
    const res =  BillingSummariesDataDownloadGetAPI({
      yearmonth: items.yearmonth,
      tenant_id: items.id,
    });
    return res;
  }

  const loadData = async () => {
    const items = params.bodies;
    const return_table_datas: TableBillingDataDownloadPackageType[] = [];
    for (let i = 0; i < items.length; i++) {
      const return_table_data: TableBillingDataDownloadPackageType = {
        id: items[i].id,
        tenant_id: items[i].tenant_id,
        tenant_name: items[i].tenant_name,
        demand_price_summary: items[i].demand_price_summary,
        button_element: (
          <ButtonElementsArea>
            {items[i] ? (
              <RoundedButton
                text='CSVダウンロード'
                onClick={() => downloadCsv(items[i])}
                style={{
                  width: styles.small_button_width,
                  height: 30,
                  marginRight: styles.interval_narrow_margin,
                }}
              />
            ) : (
              // ダウンロードボタンの無効時はボタン要素で描画
              <RoundedButton
                text='CSVダウンロード'
                style={{
                  width: styles.small_button_width,
                  height: 30,
                  marginRight: styles.interval_narrow_margin,
                }}
                stop_propagation
                disabled={true}
              />
            )}
          </ButtonElementsArea>
        ),
      };
      return_table_datas.push(return_table_data);
    }
    setTableBodies(return_table_datas);
  };

  // 一括ダウンロードのポップアップでOK押下した際の動作
  const handleOKClick = async (selected_datas: TableBillingType[]) => {
    // ダウンロード関数定義
  const multipleDownload = async () => {
      // 並列にダウンロード
    await Promise.all(
      selected_datas.map((sd) => {
        return BillingSummariesDataDownloadGetAPI({
          yearmonth: params.yearmonth,
          tenant_id: sd.id,
        });
      }),
    );
    await params.loadTableBodies();
    params.setSelectedBodies([]);
  };
  // 実行
  await loadWrapperFunc(multipleDownload);
};

const handleDownloadClick = async () => {
  ConfirmDialog.show(
    '[確認]\n選択されているユーザーのcsvファイルをダウンロードします。\n時間がかかりますがよろしいですか？',
    () => handleOKClick(params.selected_bodies),
    params.handleCancelClick,
    undefined,
  );
};
  // 一括ダウンロードのポップアップでOK押下した際の動作
  const handleZipOKClick = async (yearmonth: string) => {
    // ダウンロード関数定義
  const multipleDownload = async () => {
    return BillingSummariesDataDownloadZipGetAPI({
      yearmonth: yearmonth,
    });
  };
  // 実行
  await loadWrapperFunc(multipleDownload);
};

const handleDownloadZipClick = async () => {
  ConfirmDialog.show(
    '[確認]\n全てのユーザーのcsvファイルをzip形式でダウンロードしますか？',
    () => handleZipOKClick(params.yearmonth),
    params.handleCancelClick,
    undefined,
  );
};


  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await loadData();
    })();
  }, [params.bodies]); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      <BottomArea>
        {table_bodies? (
          <>
            <BaseTable
              bodies={table_bodies}
              headers={headers}
              table_name='billingSummaries'
              selected_bodies={params.selected_bodies}
              handleCheckClick={params.handleCheckClick}
            />
            <ResultsTableFooter
              yearmonth={params.yearmonth}
              selected_bodies={params.selected_bodies}
              style={{ marginTop: styles.interval_narrow_margin }}
              onSelectedDataDownload={handleDownloadClick}
              onAllDownload={handleDownloadZipClick}
            />
          </>
        ) : (
          <Spinner />
        )}
      </BottomArea>
    </Content>
  );
};

// -- styled components --
const ButtonElementsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// -- finally export part --
export default BillingTable;
