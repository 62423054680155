import sendAxios from 'shared/axios/sendAxios';
import { Query } from 'shared/models/Query';
import { getClient } from './base';
import { toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { BillingProcessType } from 'shared/models/BillingProcessType';
import { StatusType } from 'shared/models/StatusType';
import { AxiosResponse } from 'axios';

/*** エンティティ ***/

export interface BillingCollect {
  yearmonth: string;
  component_id: string;
  process_type: BillingProcessType;
  amount: number;
  unit_price: number;
  demand_price: number;
  process_flow_name: string;
  process_flow: string;
  folder_name: string;
  file_name: string;
  stream_data_number: number;
  file_time: number;
  file_fps: number;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface BillingCollectsWithPaging {
  items: BillingCollect[];
  has_next: boolean;
}

const sample_billing_collect_1: BillingCollect = {
  yearmonth: '202301',
  component_id: '00000001:STREAM_STORAGE:000000001234;1',
  process_type: 'COUNT',
  amount: 20,
  unit_price: 1500,
  demand_price: 250,
  process_flow_name: 'プロセスフロー1',
  process_flow: '(C)人検知',
  folder_name: 'フォルダ名1',
  file_name: 'ファイル名1',
  stream_data_number: 1,
  file_time: 10,
  file_fps: 20,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_billing_collect_2: BillingCollect = {
  yearmonth: '202301',
  component_id: '00000001:FILE_PROCESS:000000001234:2',
  process_type: 'COUNT',
  amount: 5,
  unit_price: 1500,
  demand_price: 125,
  process_flow_name: 'プロセスフロー2',
  process_flow: '(C)人検知',
  folder_name: 'フォルダ名1',
  file_name: 'ファイル名2',
  stream_data_number: 2,
  file_time: 5,
  file_fps: 10,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};
const sample_billing_collect_3: BillingCollect = {
  yearmonth: '202301',
  component_id: '00000001:FILE_PROCESS:000000001234:10',
  process_type: 'COUNT',
  amount: 10,
  unit_price: 1500,
  demand_price: 250,
  process_flow_name: 'プロセスフロー3',
  process_flow: '(C)人カウント',
  folder_name: 'フォルダ名2',
  file_name: 'ファイル名3',
  stream_data_number: 3,
  file_time: 10,
  file_fps: 10,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** Caching mechanism ***/
export class CachedBillingCollects {
  private yearmonth: string;
  private tenant_id: string;
  private component_name: string;
  private process_type: string;
  private searched = false;
  private cache: BillingCollect[] = [];
  constructor(params: RequestBillingCollectsGet) {
    this.yearmonth = params.yearmonth;
    this.tenant_id = params.tenant_id;
    this.process_type = params.process_type;
    this.component_name = params.component_name;
    if (params?.process_type) {
      this.process_type = params.process_type;
    }
  }
  async get() {
    if (!this.searched) {
      // let esk: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<BillingCollectsWithPaging> = await billingCollectsYeGetAPI({
          yearmonth: this.yearmonth,
          tenant_id: this.tenant_id,
          process_type: this.process_type,
          component_name: this.component_name,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next) {
            break;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/billing/collects/{yearmonth} ***/

export interface RequestBillingCollectsGet {
  yearmonth: string;
  tenant_id: string;
  component_name: string;
  process_type: string;
  exclusive_start_tenant_id?: string;
}

export const billingCollectsYeGetAPI = (params: RequestBillingCollectsGet) => {
  const { yearmonth, tenant_id, component_name, process_type, exclusive_start_tenant_id } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/billing/collects/${yearmonth}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    tenant_id,
    component_name,
    process_type,
    exclusive_start_tenant_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<BillingCollectsWithPaging>(axios, path, query, form, method, {
    items: [sample_billing_collect_1, sample_billing_collect_2, sample_billing_collect_3],
    has_next: false,
  });
};
