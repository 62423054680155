import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';

export type HeartbeatsDialogFields = {
  key: string;
  name: string;
  description: string;
  default_schedule: string;
  additionals: string;
  required: boolean;
  is_hidden: boolean;
};
interface HeartbeatsDialogProps {
  title: string;
  isOpen: boolean;
  isKeyFix: boolean;
  fields: HeartbeatsDialogFields;
  handleOkClose?: (event: React.MouseEvent<HTMLElement>, s: HeartbeatsDialogFields) => void;
  handleCancelClose?: () => void;
}
interface HeartbeatsDialogState {
  message: string;
  key: string;
  name: string;
  description: string;
  defaultSchedule: string;
  additionals: string;
  required: boolean;
  isHidden: boolean;
}
export default class HeartbeatsDialog extends React.PureComponent<HeartbeatsDialogProps, HeartbeatsDialogState> {
  constructor(props: HeartbeatsDialogProps) {
    super(props);
    this.state = {
      message: '',
      key: props.fields.key,
      name: props.fields.name,
      description: props.fields.description,
      defaultSchedule: props.fields.default_schedule,
      additionals: props.fields.additionals,
      required: props.fields.required,
      isHidden: props.fields.is_hidden,
    };
  }
  private handleOkClose = (e: React.MouseEvent<HTMLElement>) => {
    // 入力チェック
    if (this.state.key.trim() === '') {
      this.setState({ message: 'キーは入力必須です。' });
      return;
    }
    if (this.state.name.trim() === '') {
      this.setState({ message: '表示名は入力必須です。' });
      return;
    }
    if (this.props.handleOkClose) {
      this.props.handleOkClose(e, {
        key: this.state.key,
        name: this.state.name,
        description: this.state.description,
        default_schedule: this.state.defaultSchedule,
        additionals: this.state.additionals,
        required: this.state.required,
        is_hidden: this.state.isHidden,
      });
    }
  };
  render() {
    return (
      <PfDialog title={this.props.title} isOpen={this.props.isOpen} onClose={this.props.handleCancelClose}>
        {this.state.message !== '' && <Callout intent={Intent.DANGER}>{this.state.message}</Callout>}
        <InputComponent text='キー' required={!this.props.isKeyFix}>
          <InputBox
            title='キー'
            placeholder=''
            onChange={(e) => this.setState({ key: e.currentTarget.value })}
            value={this.state.key}
            disabled={this.props.isKeyFix}
          />
        </InputComponent>
        <InputComponent text='表示名' required>
          <InputBox
            title='表示名'
            placeholder=''
            onChange={(e) => this.setState({ name: e.currentTarget.value })}
            value={this.state.name}
          />
        </InputComponent>
        <InputComponent text='説明文'>
          <InputBox
            title='説明文'
            placeholder=''
            onChange={(e) => this.setState({ description: e.currentTarget.value })}
            value={this.state.description}
          />
        </InputComponent>
        <InputComponent text='デフォルト値'>
          <InputBox
            title='デフォルト値'
            placeholder=''
            onChange={(e) => this.setState({ defaultSchedule: e.currentTarget.value })}
            value={this.state.defaultSchedule}
          />
        </InputComponent>
        <InputComponent text='プロセスに渡すJSON'>
          <InputBox
            title='プロセスに渡すJSON'
            placeholder=''
            onChange={(e) => this.setState({ additionals: e.currentTarget.value })}
            value={this.state.additionals}
          />
        </InputComponent>
        <InputComponent text='必須'>
          <CheckBoxWithText
            text='必須'
            checked={this.state.required}
            onClick={() => this.setState({ required: !this.state.required })}
          />
        </InputComponent>
        <InputComponent text='非表示'>
          <CheckBoxWithText
            text='非表示'
            checked={this.state.isHidden}
            onClick={() => this.setState({ isHidden: !this.state.isHidden })}
          />
        </InputComponent>

        <Footer>
          <RoundedButton onClick={this.props.handleCancelClose} text='キャンセル' is_margin_right is_white />
          <RoundedButton onClick={this.handleOkClose} text='設定' />
        </Footer>
      </PfDialog>
    );
  }
}
