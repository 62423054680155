// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';

// -- type declaration --

interface Params {
  bodies: TableParameterType[];
  selected_bodies: TableParameterType[];
  handleCheckClick: (datas: TableParameterType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableParameterType {
  id: string;
  parameter_name: TableBodyUrlType;
  value: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

// -- external functions --

// -- main component --
const ParameterTable: React.FC<Params> = (params) => {
  const headers: TableHeaderType[] = [
    {
      id: 'parameter_name',
      label: 'パラメータ名',
      sortable: true,
      search_props: {
        type: 'name',
        default_display: true,
      },
    },
    {
      id: 'value',
      label: 'パラメータ値',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'status',
      label: 'ステータス',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'created_at',
      label: '作成日',
      sortable: true,
      search_props: {
        type: 'datetime',
      },
    },
    {
      id: 'updated_at',
      label: '更新日',
      sortable: true,
      search_props: {
        type: 'datetime',
      },
    },
    {
      id: 'deleted_at',
      label: '削除日',
      sortable: true,
      search_props: {
        type: 'datetime',
      },
    },
  ];

  // -- render part --
  return <BaseTable headers={headers} table_name='parameters' {...params} />;
};

// -- styled components --

// -- finally export part --
export default ParameterTable;
