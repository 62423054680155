import { stringToBoolean, toStringBoolean } from 'shared/models/StringBoolean';

export const getRememberMe = () => {
  const rember_me_str = localStorage.getItem('remember_me') ?? '';
  return stringToBoolean(rember_me_str);
};

export const setRememberMe = (remember_me?: boolean) => {
  if (typeof remember_me !== 'boolean') return;
  localStorage.setItem('remember_me', toStringBoolean(remember_me));
};

export const getLoginId = () => {
  const login_id = localStorage.getItem('login_id') ?? '';
  return login_id;
};

export const setLoginId = (login_id?: string) => {
  if (typeof login_id !== 'string') return;
  localStorage.setItem('login_id', login_id);
};
