interface TableauAuthoringVizProps {
  src: string;
  token?: string;
  width?: string;
  height?: string;
  'hide-tabs'?: boolean;
  toolbar?: 'top' | 'bottom' | 'hidden';
}

export function TableauAuthoringViz(props: TableauAuthoringVizProps) {
  return (
    <div>
      <tableau-authoring-viz {...props} />
    </div>
  );
}

interface TableauVizProps {
  src: string;
  token?: string;
  width?: string;
  height?: string;
  'hide-tabs'?: boolean;
  toolbar?: 'top' | 'bottom' | 'hidden';
}

export function TableauViz(props: TableauVizProps) {
  return (
    <div>
      <tableau-viz {...props} hide-edit-button={true} />
    </div>
  );
}
