// -- basic library --
import React, { useMemo } from 'react';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import { Table, Tbody, Td, Th, Thead, Tr, CheckBoxArea } from 'shared/components/atoms/PfTable';
import CheckBox from 'shared/components/molecules/CheckBox';
import PageTurningComponentForTable from 'shared/components/molecules/Table/PageTurningComponentForTable/PageTurningComponentForTable';
import styles, { table_cell_button_width } from 'shared/styles/styles';
import SelectBoxOrder from '../SelectBoxOrder';
import DynamicTableBodyValue from './DynamicTableBodyValue/DynamicTableBodyValue';
import SortIconForTable from './SortIconForTable/SortIconForTable';
import TableSearch from './TableSearch/TableSearch';

// -- external functions --
import {
  checkHeaderChecked,
  getDisplayBody,
  getsortedDatas,
  getTablePageNumberBottom,
  getTablePageNumberEnd,
  getTablePageNumberTop,
  tableHeaderToSearchRequiredItems,
} from './tableFunctions';
import {
  FooterArea,
  OptionFunctionArea,
  PageNumberText,
  SubTopArea,
  TableArea,
  ThChildrensArea,
  TopArea,
  WholeArea,
} from './tableStyles';
import { BaseTableProps } from './type';
import { useTable } from './useTable';

// ページ切り替えボタンの数字の表示数
// 例えば5ならば <- 1 2 3 4 5 -> となる
const page_display_number = 5;

// -- main component --

const BaseTable: React.FC<BaseTableProps> = (props) => {
  const {
    hide_toparea,
    head_sticky = true,
    side_sticky = true,
    headers,
    checkbox_abridgement,
    handleAddDatas,
    selected_bodies,
    style,
    orderProps,
    has_next,
    footer_option,
    handleAPISearch,
    table_name,
    onDownloadClick,
  } = props;
  const search_required_items = useMemo(() => tableHeaderToSearchRequiredItems(headers), [headers]);
  // 必要なstateと関数を取得
  const {
    searched_bodies,
    // ページに表示させるデータの個数(トップエリアを隠す場合は最大の整数)
    selected_page_number,
    bodies_per_page,
    sort_info,
    body_checkboxes,
    header_ids,
    handleTablePageChange,
    onSearch,
    handleTableHeaderCheckboxClick,
    handleSortClick,
    handleTableTrClick,
  } = useTable(props);

  // -- render part --
  return (
    <WholeArea style={style}>
      {/*昇順/降順を選択して、データを再取得するコンポーネント*/}
      {orderProps && (
        <SubTopArea>
          <div style={{ width: '100%', flexBasis: '100%' }}></div>
          <SelectBoxOrder
            value={orderProps.order}
            setValue={orderProps.setOrder}
            onFinished={() => handleTablePageChange(1)}
          />
        </SubTopArea>
      )}
      {search_required_items.length > 0 && (
        <SubTopArea>
          <TableSearch
            search_required_items={search_required_items}
            onSearch={onSearch}
            onAPISearch={handleAPISearch}
            table_name={table_name}
            onDownloadClick={onDownloadClick}
          />
        </SubTopArea>
      )}
      {!hide_toparea && (
        <TopArea>
          <div style={{ width: '100%', flexBasis: '100%' }}></div>
          <div style={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
            <PageNumberText>
              <span data-testid='table-page-number-top'>
                {getTablePageNumberTop(selected_page_number, searched_bodies, bodies_per_page)}
              </span>
              <span style={{ margin: `auto ${styles.interval_x_narrow_margin}` }}>/</span>
              <span data-testid='table-page-number-bottom'>{getTablePageNumberBottom(searched_bodies)}</span>
              <span>{getTablePageNumberEnd(has_next)}</span>
            </PageNumberText>
            <PageTurningComponentForTable
              handleAddDatas={handleAddDatas}
              has_next={has_next}
              handleClick={(num: number) => {
                handleTablePageChange(num);
              }}
              selected_page_number={selected_page_number}
              bodies_per_page={bodies_per_page}
              bodies={searched_bodies}
              page_display_number={page_display_number}
            />
          </div>
        </TopArea>
      )}
      {/* テーブル */}
      <TableArea>
        <Table sticky={head_sticky || side_sticky}>
          <Thead>
            <tr>
              {/* テーブルヘッダーのチェックボックス */}

              <Th
                style={{ width: 35 }}
                onClick={() =>
                  handleTableHeaderCheckboxClick(
                    checkHeaderChecked({ selected_bodies, searched_bodies, selected_page_number, bodies_per_page }),
                    selected_bodies,
                  )
                }
                head_sticky={head_sticky}
                side_sticky={side_sticky}
              >
                {!checkbox_abridgement && (
                  <CheckBoxArea>
                    <CheckBox
                      checked={checkHeaderChecked({
                        selected_bodies,
                        searched_bodies,
                        selected_page_number,
                        bodies_per_page,
                      })}
                    />
                  </CheckBoxArea>
                )}
              </Th>

              {/* テーブルヘッダー */}
              {headers.map((header, index) => {
                const h = { ...header };
                /**
                 * 横スクロール
                 * minWidthが効かないので暫定対応(6個以上の列が存在するテーブルは小さすぎて見えないので)
                 * [NOTICE]これはユーザー画面にはない
                 * */
                if (headers.length > 6) {
                  h.style = {
                    ...h.style,
                    width: 250,
                  };
                }
                if (h.useTableButtonStyles) {
                  h.style = {
                    ...h.style,
                    width: table_cell_button_width,
                  };
                }
                if (h.useDoubleTableButtonStyles) {
                  h.style = {
                    ...h.style,
                    width: `calc(${styles.super_small_button_width} * 2 + ${styles.interval_narrow_margin} * 3)`,
                  };
                }
                return (
                  <Th
                    key={index}
                    style={{
                      paddingLeft: styles.interval_narrow_margin,
                      width: h.width,
                      ...h.style,
                    }}
                    head_sticky={head_sticky}
                    side_sticky={side_sticky}
                  >
                    <ThChildrensArea>
                      {header.label}
                      {header.sortable && (
                        <SortIconForTable
                          handleClick1={() => handleSortClick(header.id, false)}
                          handleClick2={() => handleSortClick(header.id, true)}
                          selected1={sort_info?.key === header.id && !sort_info.reverse}
                          selected2={sort_info?.key === header.id && sort_info.reverse}
                        />
                      )}
                    </ThChildrensArea>
                  </Th>
                );
              })}
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
            {getsortedDatas(searched_bodies, sort_info)
              .slice((selected_page_number - 1) * bodies_per_page, selected_page_number * bodies_per_page)
              .map((body, index1) => {
                const body_id = body.id;
                const new_body = getDisplayBody(body, header_ids);
                return (
                  <Tr
                    key={index1}
                    checked={body_checkboxes[body_id] ? body_checkboxes[body_id] : false}
                    onClick={() => handleTableTrClick(body, selected_bodies)}
                  >
                    {/* テーブルボディーのチェックボックス */}

                    <Td
                      style={{ width: 35 }}
                      last_row={
                        (index1 + 1) % bodies_per_page === 0 || index1 + 1 === searched_bodies.length ? true : false
                      }
                      side_sticky={side_sticky}
                    >
                      {!checkbox_abridgement && (
                        <CheckBoxArea>
                          <CheckBox checked={body_checkboxes[body_id] ? body_checkboxes[body_id] : false} />
                        </CheckBoxArea>
                      )}
                    </Td>

                    {/* テーブルボディーのデータ */}
                    {Object.entries(new_body).map(([key, value]) => {
                      return (
                        <Td
                          key={key}
                          last_row={
                            (index1 + 1) % bodies_per_page === 0 || index1 + 1 === searched_bodies.length ? true : false
                          }
                          style={{ paddingLeft: styles.interval_narrow_margin }}
                          side_sticky={side_sticky}
                        >
                          <DynamicTableBodyValue key_name={key} value={value} row_index={index1} />
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableArea>

      {/* フッター */}
      {footer_option && (
        <FooterArea>
          <OptionFunctionArea>
            <FunctionalText
              text={footer_option.text}
              onClick={footer_option.disabled ? undefined : footer_option.handleClick}
              disabled={footer_option.disabled}
            />
          </OptionFunctionArea>
        </FooterArea>
      )}
    </WholeArea>
  );
};

// -- finally export part --

export default BaseTable;
