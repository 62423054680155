// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- external datas --

const getMax = (type?: string, max?: number | string) => {
  switch (type) {
    case 'date':
      return max ?? '9999-12-31'; // 年の入力を4桁までにする
    case 'datetime-local':
      return max ?? '9999-12-31T23:59:59'; // 年の入力を4桁までにする
    default:
      return max;
  }
};

export interface InputBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef?: React.RefObject<HTMLInputElement>;
  is_error?: boolean;
}
export default class InputBox extends React.Component<InputBoxProps> {
  render() {
    const max = getMax(this.props.type, this.props.max);
    return (
      <Input
        {...this.props}
        max={max}
        ref={this.props.inputRef}
        onWheel={this.props.type === 'number' ? (e) => e.currentTarget.blur() : undefined}
      />
    );
  }
}

// -- styled components --

const Input = styled.input<{
  is_error?: boolean;
}>`
  width: 100%;
  padding: ${styles.input_box_padding};
  height: ${styles.input_component_height};
  box-sizing: border-box;
  border: 1px solid ${colors.component_small_border_color};
  border-radius: ${styles.border_radius};
  outline: none;
  font-family: inherit;
  color: ${colors.main_font_color};
  background-color: ${(params) => (params.is_error ? colors.error_color : colors.white)};
  &:focus {
    border-color: ${colors.component_main_color};
  }
  &:disabled {
    opacity: 1;
    background-color: ${colors.disabled_background_color};
    cursor: not-allowed;
  }
  &:placeholder {
    color: ${colors.placeholder_color};
  }
`;
