import { useCallback, useMemo, useState } from 'react';
import { isBetweenRange } from 'shared/utils/is';
import { keyBy } from 'shared/utils/converter';
import { Search } from 'shared/models/Search';
import {
  getSearchesFromSessionStorage,
  setSearchesFromSessionStorage,
} from 'shared/utils/else/sessionStorageFunctions';

function getInitialSearches(initial_searches: Search[], table_name: string, search_type: string) {
  const searches_from_session_storage = getSearchesFromSessionStorage(table_name, search_type);
  if (!searches_from_session_storage || searches_from_session_storage.length === 0) {
    return initial_searches;
  } else {
    return searches_from_session_storage;
  }
}

export function useSearches(initial_searches: Search[], table_name: string, search_type: string) {
  const [searches, setSearches] = useState<Search[]>(getInitialSearches(initial_searches, table_name, search_type));
  // 前回のsearchesのvalueと差分がある場合はtrue
  const searches_record = useMemo(() => {
    return keyBy(searches, (t) => t.search_key);
  }, [searches]);

  const customSearches = useCallback(
    (new_searches: Search[]) => {
      setSearches(new_searches);
      setSearchesFromSessionStorage(table_name, search_type, new_searches);
    },
    [table_name, search_type],
  );

  // searchesに1つsearchを追加する
  const addSearch = useCallback(() => {
    const new_searches = [...searches, { search_key: '', search_name: '', value: '' }];
    customSearches(new_searches);
  }, [searches, customSearches]);

  // searchesの中のindex番目の値を削除
  const deleteSearch = useCallback(
    (index: number) => {
      if (!isBetweenRange(index, 0, searches.length - 1)) return;
      const new_searches = [...searches.slice(0, index), ...searches.slice(index + 1)];
      customSearches(new_searches);
    },
    [searches, customSearches],
  );

  // searchesのindex番目を更新する
  const updateSearch = useCallback(
    (index: number, search: Search) => {
      if (!isBetweenRange(index, 0, searches.length - 1)) return;
      const new_searches = [...searches];
      new_searches[index] = search;
      customSearches(new_searches);
    },
    [searches, customSearches],
  );

  // searchesを初期状態に戻す
  const initializedSearches = useCallback(() => {
    const new_searches = [...initial_searches];
    customSearches(new_searches);
  }, [initial_searches, customSearches]);

  return useMemo(() => {
    return { searches, searches_record, setSearches, addSearch, deleteSearch, updateSearch, initializedSearches };
  }, [searches, searches_record, setSearches, addSearch, deleteSearch, updateSearch, initializedSearches]);
}
