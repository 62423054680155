import { SelectData } from 'shared/components/atoms/SelectBox';

export type CellFormat = 'STRING' | 'SUBSTRING' | 'NUMBER' | 'DATETIME';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCellFormat = (data: any): data is CellFormat =>
  data === 'STRING' || data === 'SUBSTRING' || data === 'NUMBER' || data === 'DATETIME';

export const cell_formats: SelectData[] = [
  {
    name: 'STRING',
    value: 'STRING',
  },
  {
    name: 'SUBSTRING',
    value: 'SUBSTRING',
  },
  {
    name: 'NUMBER',
    value: 'NUMBER',
  },
  {
    name: 'DATETIME',
    value: 'DATETIME',
  },
];
