import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { Breadcrumbs, Content, Title, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import ProcessflowsCreateFormPanel from './ProcessflowsCreateFormPanel';

interface ProcessFlowsCreatePageProps {}
interface ProcessFlowsCreatePageState {}
export default class ProcessFlowsCreatePage extends React.PureComponent<
  ProcessFlowsCreatePageProps,
  ProcessFlowsCreatePageState
> {
  constructor(props: ProcessFlowsCreatePageProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <PageWholeArea>
        <Breadcrumbs
          items={[
            { href: '/processflows', icon: IconNames.FLOWS, text: 'プロセスフロー' },
            { icon: IconNames.CUBE_ADD, text: 'プロセスフローの追加' },
          ]}
        />
        <Title text='プロセスフローの追加' />
        <Content>
          <ProcessflowsCreateFormPanel />
        </Content>
      </PageWholeArea>
    );
  }
}
