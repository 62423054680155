// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

interface FunctionalTextProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  disabled?: boolean;
  color?: string;
  stop_propagation?: boolean;
}
export function FunctionalText(props: FunctionalTextProps) {
  const new_props = { ...props };
  if (props.stop_propagation) {
    new_props.onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      props.onClick?.(event);
      // 親からのeventの伝達を止める
      event.stopPropagation();
    };
  }
  return <Text {...new_props}>{props.text}</Text>;
}

export default FunctionalText;

// -- styled components --

const Text = styled.div<{
  disabled?: boolean;
  color?: string;
}>`
  display: inline-block;
  text-decoration: ${({ disabled }) => {
    return disabled ? 'none' : 'underline';
  }};
  font-size: ${styles.small_text_size};
  opacity: ${({ disabled }) => (disabled ? styles.opacity_disabled : 1)};
  color: ${({ color }) => color ?? 'inherit'};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${({ disabled }) => {
      return disabled ? 'inherit' : colors.component_main_color;
    }};
  }
`;
