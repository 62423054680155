import { SelectData } from 'shared/components/atoms/SelectBox';

/** 課金で使用するプロセスタイプ */
export type ProcessType = 'DETECTION' | 'COUNT' | 'STAYING';
export const ProcessTypes: ProcessType[] = ['DETECTION', 'COUNT', 'STAYING'];
export function convertProcessType(s?: string, undef?: boolean) {
  for (const v of ProcessTypes) if (v === s) return s;
  return undef ? '' : ProcessTypes[0];
}

export const processTypeToProcessJP = (process_type: ProcessType) => {
  let process_jp = '';
  switch (process_type) {
    case 'DETECTION':
      process_jp = '物体検知 (30,000円/月)';
      break;
    case 'COUNT':
      process_jp = 'カウント (20,000円/月)';
      break;
    case 'STAYING':
      process_jp = '滞留検知 (20,000円/月)';
      break;
  }
  return process_jp;
};

export const processTypeToProcessflowJP = (process_type: ProcessType) => {
  let processflow_jp = '';
  switch (process_type) {
    case 'DETECTION':
      processflow_jp = '物体検知 (1,500円/時間)';
      break;
    case 'COUNT':
      processflow_jp = 'カウント (1,500円/時間)';
      break;
    case 'STAYING':
      processflow_jp = '滞留検知 (3,000円/時間)';
      break;
  }
  return processflow_jp;
};

export const processTypeToProcessUnitPrice = (process_type: ProcessType | '') => {
  switch (process_type) {
    case 'DETECTION':
      return 30000;
    case 'COUNT':
      return 20000;
    case 'STAYING':
      return 20000;
    default:
      return 0;
  }
};

export const processTypeToProcessflowUnitPrice = (process_type: ProcessType | '') => {
  switch (process_type) {
    case 'DETECTION':
      return 1500;
    case 'COUNT':
      return 1500;
    case 'STAYING':
      return 3000;
    default:
      return 0;
  }
};

export const convertProcessTypesToProcessSelectDatas = () => {
  const select_datas: SelectData[] = ProcessTypes.map((pt) => {
    return {
      name: processTypeToProcessJP(pt),
      value: pt,
    };
  });
  return select_datas;
};

export const convertProcessTypesToProcessflowSelectDatas = () => {
  const select_datas: SelectData[] = ProcessTypes.map((pt) => {
    return {
      name: processTypeToProcessflowJP(pt),
      value: pt,
    };
  });
  return select_datas;
};
