import { Route, Router, Switch } from 'react-router-dom';
import history from 'shared/browserHistory';

import './App.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

import PfAdminDocument from './components/molecules/PfAdminDocument';
import Dashboards from './pages/Dashboards/TopPage';
import BillingsPage from './pages/Billings/TopPage';
import BillingsDetailPage from './pages/Billings/DetailPage';
import BillingsFileProcessDetailPage from './pages/Billings/FileProcessDetailPage';
import DeviceCreatePage from './pages/Devices/CreatePage';
import DevicesDetailPage from './pages/Devices/DetailPage';
import Devices from './pages/Devices/TopPage';
import EdgeDevicesTopPage from './pages/EdgeDevices/TopPage';
import EdgeDevicesDetailPage from './pages/EdgeDevices/DetailPage';
import Events from './pages/Events/TopPage';
import Generator from './pages/Generator/TopPage';
import InitialPasswordChange from './pages/InitialPasswordChange/TopPage';
import JsonpathGroupCreatePage from './pages/JsonpathGroups/CreatePage';
import JsonpathGroupDetailPage from './pages/JsonpathGroups/DetailPage';
import Jsonpath from './pages/JsonpathGroups/TopPage';
import Login from './pages/Login';
import ModelCreatePage from './pages/Models/CreatePage';
import ModelSet from './pages/Models/DetailPage';
import Models from './pages/Models/TopPage';
import Notifications from './pages/Notifications/TopPage';
import NotificationsCreatePage from './pages/Notifications/CreatePage';
import NotificationsDetailPage from './pages/Notifications/DetailPage';
import ParametersCreatePage from './pages/Parameters/CreatePage';
import ParametersDetailPage from './pages/Parameters/DetailPage';
import ParametersTopPage from './pages/Parameters/TopPage';
import ProcessesCreatePage from './pages/Processes/CreatePage';
import ProcessesDetailPage from './pages/Processes/DetailPage';
import ProcessesTopPage from './pages/Processes/TopPage';
import ProcessFlowsCreatePage from './pages/Processflows/CreatePage';
import ProcessFlowsDetailPage from './pages/Processflows/DetailPage';
import ProcessFlowsTopPage from './pages/Processflows/TopPage';
import StreamDetailPage from './pages/Streams/DetailPage';
import Streams from './pages/Streams/TopPage';
import TenantCreate from './pages/Tenants/CreatePage';
import TenantSet from './pages/Tenants/DetailPage';
import Tenants from './pages/Tenants/TopPage';
import Top from './pages/Top';
import UsersCreate from './pages/Users/CreatePage';
import UsersIdDetail from './pages/Users/DetailPage';
import Users from './pages/Users/TopPage';
import Tableau from './pages/Tableau';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from './redux/slices/authSlice';

function App() {
  const auth_state = useSelector(authSelector);
  const { is_logged_in } = auth_state;
  // Redirectコンポーネントがうまく動作しないので、browserHistoryを監視して空ページのリダイレクトする
  useEffect(() => {
    if (history.location.pathname === '/') {
      history.push(is_logged_in ? '/top' : '/login');
    }
  }, [is_logged_in]);
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/initialPasswordChange' component={InitialPasswordChange} />
        <PfAdminDocument>
          <Switch>
            <Route exact path='/top' component={Top} />
            <Route exact path='/tenants' component={Tenants} />
            <Route exact path='/tenants/create' component={TenantCreate} />
            <Route exact path='/tenants/:tenant_id/set' component={TenantSet} />
            <Route exact path='/billings/:yearmonth?' component={BillingsPage} />
            <Route exact path='/billings/:yearmonth/:tenant_id/detail' component={BillingsDetailPage} />
            <Route
              exact
              path='/billings/:yearmonth/:tenant_id/:component_name/:process_type/fileprocess/detail'
              component={BillingsFileProcessDetailPage}
            />
            <Route exact path='/users' component={Users} />
            <Route exact path='/users/create' component={UsersCreate} />
            <Route exact path='/users/:user_id/detail' component={UsersIdDetail} />
            <Route exact path='/notifications' component={Notifications} />
            <Route
              exact
              path='/notifications/:notification_id?/create/:panel_id?'
              component={NotificationsCreatePage}
            />
            <Route exact path='/notifications/:notification_id/detail/:panel_id?' component={NotificationsDetailPage} />
            <Route exact path='/dashboards' component={Dashboards} />
            <Route exact path='/devices' component={Devices} />
            <Route exact path='/devices/create' component={DeviceCreatePage} />
            <Route exact path='/devices/:device_id/:panel_id?' component={DevicesDetailPage} />
            <Route exact path='/edgeDevices' component={EdgeDevicesTopPage} />
            <Route exact path='/edgeDevices/:edge_device_key/detail' component={EdgeDevicesDetailPage} />
            <Route exact path='/events' component={Events} />
            <Route exact path='/models' component={Models} />
            <Route exact path='/models/create' component={ModelCreatePage} />
            <Route exact path='/models/:model_id/set' component={ModelSet} />
            <Route exact path='/processes' component={ProcessesTopPage} />
            <Route exact path='/processes/create' component={ProcessesCreatePage} />
            <Route
              exact
              path='/processes/:processId/:tabId'
              render={(p) => (
                <ProcessesDetailPage process_id={p.match.params.processId} tab_id={p.match.params.tabId} />
              )}
            />
            <Route exact path='/processflows' component={ProcessFlowsTopPage} />
            <Route exact path='/processflows/create' component={ProcessFlowsCreatePage} />
            <Route
              exact
              path='/processflows/:processFlowId'
              render={(p) => <ProcessFlowsDetailPage process_flow_id={p.match.params.processFlowId} />}
            />
            <Route exact path='/jsonpath' component={Jsonpath} />
            <Route exact path='/jsonpath/create' component={JsonpathGroupCreatePage} />
            <Route exact path='/jsonpath/:jsonpath_group_id/detail' component={JsonpathGroupDetailPage} />
            <Route exact path='/parameters' component={ParametersTopPage} />
            <Route exact path='/parameters/create' component={ParametersCreatePage} />
            <Route exact path='/parameters/:name/set' component={ParametersDetailPage} />
            <Route exact path='/generator' component={Generator} />
            <Route exact path='/streams' component={Streams} />
            <Route exact path='/streams/:stream_id/:tenant_id/detail' component={StreamDetailPage} />
            <Route exact path='/tableau' component={Tableau} />
          </Switch>
        </PfAdminDocument>
      </Switch>
    </Router>
  );
}

export default App;
