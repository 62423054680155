// -- basic library --
import React, { useEffect, useState } from 'react';
import { IconNames } from '@blueprintjs/icons';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';
import { RouteComponentProps } from 'react-router-dom';
import InputDoubleBox from 'shared/components/molecules/InputDoubleBox';
import { isUserType, UserType } from 'shared/models/UserType';
import RadioBox from 'shared/components/molecules/RadioBox';
import { notificationsIdGetAPI, notificationsIdPutAPI, RequestNotificationsIdPut } from 'admin/api/notifications';
import MarkdownEditor from 'shared/components/molecules/MarkdownEditor';

// -- type declaration --
type Params = RouteComponentProps<{ notification_id: string }>;

// -- main component --
const NotificationsCreate: React.FC<Params> = (params) => {
  // -- local states --
  const [show_date, setShowDate] = useState<string>('');
  const [show_date_from, setShowDateFrom] = useState<string>('');
  const [show_date_to, setShowDateTo] = useState<string>('2099-12-31');
  const [show_type, setUserType] = useState<UserType>('GENERAL');
  const [is_draft, setIsDraft] = useState<boolean>();
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [image_files, setImageFiles] = useState<string[]>([]);
  const [download_urls, setDownloadUrls] = useState<string[]>([]);
  const [uuid, setUuid] = useState<string>('');

  // 初期値設定
  const getNotificationsData = async (notification_id: string) => {
    const res = await notificationsIdGetAPI({ notification_id: notification_id });
    if (res.status === 200) {
      setShowDate(res.data.show_date);
      setShowDateFrom(res.data.show_date_from);
      setShowDateTo(res.data.show_date_to);
      setUserType(res.data.show_type);
      setIsDraft(res.data.is_draft);
      setTitle(res.data.title);
      setContent(res.data.content ?? '');
      setImageFiles(res.data.image_files ?? []);
      setDownloadUrls(res.data.download_urls ?? []);
      setUuid(res.data.uuid ?? '');
    }
  };

  // 同じ条件で新規作成時の関数
  const onCopyCreate = () => {
    history.push(`/notifications/${params.match.params.notification_id}/create`);
  };

  // validate
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!isNotOnlySpace(show_date)) {
      throw new Error('表示日は必須です');
    }
    if (!isNotOnlySpace(show_date_from)) {
      throw new Error('表示期間は必須です');
    }
    if (!isNotOnlySpace(show_date_to)) {
      throw new Error('表示期間は必須です');
    }
    const date_from = new Date(show_date_from);
    const date_to = new Date(show_date_to);
    if (date_from.getTime() > date_to.getTime()) {
      throw new Error('表示期間は(左側) ＜ (右側)');
    }
    if (!isNotOnlySpace(title)) {
      throw new Error('タイトルは必須です。');
    }
  };

  // 下書き保存時の関数
  const handleDraft = async () => {
    // 入力チェック
    try {
      validate();
      if (!isNotOnlySpace(content)) {
        throw new Error('本文は必須です');
      }
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    const notificationPutData: RequestNotificationsIdPut = {
      notification_id: params.match.params.notification_id,
      show_date: show_date,
      show_date_from: show_date_from,
      show_date_to: show_date_to,
      show_type: show_type,
      title: title,
      content: content,
      image_files: image_files,
      download_urls: download_urls,
      uuid: uuid,
      is_draft: 'True',
    };
    const res = await notificationsIdPutAPI(notificationPutData);
    if (res.status === 200) {
      AlertDialog.show('下書き保存に成功しました');
      history.push('/notifications');
    }
  };

  // 更新時の関数
  const handleFinish = async () => {
    // 入力チェック
    try {
      validate();
      if (!isNotOnlySpace(content)) {
        throw new Error('本文は必須です');
      }
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    const notificationPutData: RequestNotificationsIdPut = {
      notification_id: params.match.params.notification_id,
      show_date: show_date,
      show_date_from: show_date_from,
      show_date_to: show_date_to,
      show_type: show_type,
      title: title,
      content: content,
      image_files: image_files,
      download_urls: download_urls,
      uuid: uuid,
      is_draft: 'False',
    };
    const res = await notificationsIdPutAPI(notificationPutData);
    if (res.status === 200) {
      AlertDialog.show('お知らせの更新に成功しました');
      history.push('/notifications');
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // お知らせ情報の取得
      await getNotificationsData(params.match.params.notification_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='UsersCreate'>
      <Breadcrumbs
        items={[
          { href: '/notifications', icon: IconNames.NOTIFICATIONS, text: 'お知らせ一覧' },
          { icon: IconNames.CUBE, text: 'お知らせの追加' },
        ]}
      />
      <Title text='お知らせの詳細' />
      <Content>
        <FormContainer title='お知らせ情報'>
          <InputComponent text='表示日' required>
            <InputBox
              type='date'
              data-testid='show_date'
              onChange={(e) => setShowDate(e.currentTarget.value)}
              value={show_date}
              style={{ width: 'calc((100% - 40px) / 2)' }}
            />
          </InputComponent>
          <InputComponent text='表示期間' required>
            <InputDoubleBox
              value1={show_date_from}
              value2={show_date_to}
              handleChangeClick1={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.currentTarget;
                setShowDateFrom(value);
              }}
              handleChangeClick2={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.currentTarget;
                setShowDateTo(value);
              }}
              interval_text='〜'
              types={['date', 'date']}
              steps={[1, 1]}
              test_ids={['show_date_from', 'show_date_to']}
            />
          </InputComponent>
          <InputComponent text='表示区分'>
            <RadioBox
              datas={[
                { name: '一般ユーザ', value: 'GENERAL' },
                { name: '管理者', value: 'ADMIN' },
              ]}
              selectedValue={show_type}
              handleChangeClick={(value) => {
                if (isUserType(value)) {
                  setUserType(value);
                }
              }}
            />
          </InputComponent>
          <InputComponent text='タイトル' required>
            <InputBox
              title='タイトル'
              placeholder='入力してください(必須)'
              value={title}
              minLength={1}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </InputComponent>
        </FormContainer>
        <FormContainer title='本文'>
          <MarkdownEditor
            content={content}
            onChange={setContent}
            image_files={image_files}
            updateImages={setImageFiles}
            download_urls={download_urls}
            updateUrls={setDownloadUrls}
            uuid={uuid}
            setUuid={setUuid}
          />
        </FormContainer>
        <Footer>
          <RoundedButton
            onClick={handleDraft}
            text='下書き保存'
            disabled={is_draft === false}
            is_margin_right
            is_white
          />
          <RoundedButton onClick={handleFinish} text='更新' is_margin_right />
          <RoundedButton onClick={onCopyCreate} text='同じ条件で新規作成' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default NotificationsCreate;
