import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';

// -- type declaration --

interface Params {
  bodies: TableModelType[];
  selected_bodies: TableModelType[];
  handleCheckClick: (datas: TableModelType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableModelType {
  id: string;
  model_id: TableBodyUrlType;
  tenant_id: string;
  model_name: string;
  status: string;
}

// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'model_id',
    label: 'ID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'tenant_id',
    label: '対象テナントID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'model_name',
    label: 'モデル名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'status',
    label: '状態',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const ModelTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='models'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default ModelTable;
