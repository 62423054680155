import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from './base';
import { addFormData } from 'shared/utils/converter';
import { TableauRole } from 'shared/models/TableauRole';

// import createMultipleAPIDatas from 'utils/createMultipleAPIDatas';

/*** エンティティ ***/

export interface User {
  tenant_id: string;
  user_id: string;
  user_name: string;
  status: StatusType;
  login_id: string;
  mail_address: string;
  tableau_role: TableauRole;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface UsersWithPaging {
  items: User[];
  has_next: boolean;
}

const sample_user_1: User = {
  tenant_id: '00000001',
  user_id: '00000001',
  user_name: 'テストユーザー1',
  status: 'DELETED',
  login_id: 'test_user_1',
  mail_address: 'test1@sample.com',
  tableau_role: 'Explorer',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_user_2: User = {
  tenant_id: '00000001',
  user_id: '00000002',
  user_name: 'テストユーザー1',
  status: 'ACTIVE',
  login_id: 'test_user_1',
  mail_address: 'test1@sample.com',
  tableau_role: 'Not-used',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** Caching mechanism ***/

export class CachedUsers {
  private searched = false;
  private cache: User[] = [];
  private params: RequestUsersGet;
  constructor(params: RequestUsersGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<UsersWithPaging> = await usersGetAPI({
          ...this.params,
          exclusive_start_user_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.user_id).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/users ***/
export interface RequestUsersGet {
  status?: StatusType;
  exclusive_start_user_id?: string;
}

export const usersGetAPI = (params: RequestUsersGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/users`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<UsersWithPaging>(axios, path, query, form, method, {
    items: [sample_user_1, sample_user_2],
    has_next: false,
  });
};

/*** [POST] /api/users ***/

export interface RequestUsersPost {
  tenant_id: string;
  user_name: string;
  mail_address: string;
  login_id: string;
  tableau_role?: TableauRole;
}

export const usersPostAPI = (params: RequestUsersPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/users`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    addFormData({ key, value, form });
  }

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};

/*** [GET] /api/users/{user_id} ***/

export interface RequestUsersIdGet {
  user_id: string;
}

export const usersIdGetAPI = (params: RequestUsersIdGet) => {
  const { user_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/users/${user_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};

/*** [PUT] /api/users/{user_id} ***/

export interface RequestUsersIdPut {
  user_id: string;
  tableau_role?: TableauRole;
}

export const usersIdPutAPI = (params: RequestUsersIdPut) => {
  const { user_id, ...form_params } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/users/${user_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(form_params)) {
    addFormData({ key, value, form });
  }

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};

/*** [DELETE] /api/users/{user_id} ***/

export interface RequestUsersIdDelete {
  user_id: string;
}

export const usersIdDeleteAPI = (params: RequestUsersIdDelete) => {
  const { user_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/users/${user_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};
