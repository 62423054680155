// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { SelectData } from '../atoms/SelectBox';

// -- type declaration --
export interface RadioBoxProps {
  id?: string;
  handleChangeClick?: (value: string, sub_value?: string) => void;
  selectedValue: string;
  selectedSubValue?: string; // valueを２つ使いたい時はこちらも使う
  datas: SelectData<string>[];
  title?: string;
  test_id?: string;
  required?: boolean;
  style?: React.CSSProperties;
  child_style?: React.CSSProperties;
  column?: boolean;
  disabled?: boolean;
}

// -- main component --

const RadioBox: React.FC<RadioBoxProps> = (params) => {
  // -- preparations --

  // -- render part --
  return (
    <WholeArea style={params.style} column={params.column} disabled={params.disabled}>
      {params.datas.map((data, index) => (
        <div
          data-testid={data.name}
          key={index}
          onClick={!params.disabled ? () => params.handleChangeClick?.(data.value, data.sub_value) : undefined}
        >
          <Label
            htmlFor={`${params.id}_${data.value}_${data.sub_value}`}
            checked={
              params.selectedSubValue
                ? params.selectedValue === data.value && params.selectedSubValue === data.sub_value
                : params.selectedValue === data.value
            }
            style={params.child_style}
            disabled={params.disabled}
          >
            <InputRadioBox id={`${params.id}_${data.value}_${data.sub_value}`} type='radio' checked={false} disabled />
            {data.name}
          </Label>
        </div>
      ))}
    </WholeArea>
  );
};

// -- styled components --
const WholeArea = styled.div<{
  column?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  height: ${(params) => (params.column ? 'auto' : styles.input_component_height)};
  display: flex;
  flex-direction: ${(params) => (params.column ? 'column' : 'row')};
  opacity: ${(p) => (p.disabled ? styles.opacity_disabled : 1)};
`;
const InputRadioBox = styled.input`
  display: none;
`;

const Label = styled.label<{
  checked: boolean;
  disabled?: boolean;
}>`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;
  padding: 0px 30px;
  position: relative;
  width: auto;
  &::before {
    height: 16px;
    width: 16px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: calc(-5px - (16px - 10px) / 2);
    background: ${colors.white};
    border: 1px solid ${colors.component_small_border_color};
    border-radius: 50%;
  }
  &::after {
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: calc(-5px - (16px - 10px) / 2);
    opacity: ${(params) => (params.checked ? 1 : 0)};
    border: 5px solid ${colors.component_main_color};
    border-radius: 50%;
  }
`;

// -- finally export part --

export default RadioBox;
