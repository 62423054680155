import React, { useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import { Table, Tbody, Td, Th, Thead, Tr, CheckBoxArea } from 'shared/components/atoms/PfTable';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBox from 'shared/components/molecules/CheckBox';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { FlexCenterDiv } from 'shared/components/molecules/ContentsArea';
import JsonpathInput from 'shared/components/molecules/JsonpathInput';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { CsvRow } from 'shared/models/CsvRow';
import { isStringBoolean } from 'shared/models/StringBoolean';
import { cell_formats } from 'shared/models/CellFormat';
import styled from 'styled-components';
import { UpIcon, DownIcon } from 'shared/components/atoms/Icons';

// -- type declaration --

interface Params {
  bodies: CsvRow[];
  show_statistics: boolean;
  setCsvRows: (csv_rows: CsvRow[]) => void;
}

// -- main component --

const CSVTable: React.FC<Params> = (params) => {
  // -- local states --
  const [selected_indexes, setSelectedIndexes] = useState<number[]>([]);
  // n行を追加するのnの部分
  const [add_row_number, setAddRowNumber] = useState<number>(1);

  // -- functions --
  const onChangeAddRowNumber = (value: number) => {
    // const new_value = Number(e.currentTarget.value)
    if (Number.isNaN(value)) {
      setAddRowNumber(value);
    } else {
      setAddRowNumber(Math.min(Math.max(1, value), 100));
    }
  };

  // 入力が変更された時の関数
  const handleCsvRowsChangeClick = (value: string, index: number, key: string) => {
    const new_csv_rows = params.bodies.slice();
    if (key === 'header_name') {
      new_csv_rows[index].header_name = value;
    }
    if (key === 'json_path') {
      new_csv_rows[index].json_path = value;
    }
    if (key === 'cell_format_args') {
      new_csv_rows[index].cell_format_args = value;
    }
    if (key === 'cell_format') {
      new_csv_rows[index].cell_format = value;
      if (value !== 'SUBSTRING') {
        new_csv_rows[index].cell_format_args = '';
      }
    }
    if (key === 'statistic_method') {
      new_csv_rows[index].statistic_method = value;
    }
    if (key === 'fill' && isStringBoolean(value)) {
      new_csv_rows[index].fill = value;
    }
    params.setCsvRows(new_csv_rows);
  };
  // テーブルのヘッダーがチェックがどうか判断
  const checkHeaderChecked = () => {
    return params.bodies.length > 0 && params.bodies.length === selected_indexes.length ? true : false;
  };

  const handleHeadCheckClick = () => {
    let arr: number[] = [];
    if (selected_indexes.length < params.bodies.length) {
      arr = new Array(params.bodies.length).fill(null).map((_, i) => i);
    }
    setSelectedIndexes(arr);
  };

  const handleCheckClick = (selected_index: number) => {
    let new_selected_indexes = [...selected_indexes];
    if (selected_indexes.includes(selected_index)) {
      new_selected_indexes = selected_indexes.filter((si) => si !== selected_index);
    } else {
      new_selected_indexes.push(selected_index);
    }
    setSelectedIndexes(new_selected_indexes);
  };

  // const autoCsvSetting = async(stream_id: string) => {
  // let all_jsonpathes: string[] = []
  // const res = await streamsIdJsonpathGetAPI({ stream_id: stream_id })
  // if(res.status === 200){
  //   all_jsonpathes = res.data.jsonpaths
  // }
  // if(all_jsonpathes.length <= 0){
  //   AlertDialog.show('有効なjsonpathを取得できませんでした。');
  //   return;
  // }
  // const new_csv_rows: CsvRow[] = []
  // for(const jp of all_jsonpathes){
  //   new_csv_rows.push({
  //     header_name: '',
  //     json_path: jp,
  //     cell_format: '',
  //     cell_format_args: '',
  //     statistic_method: 'Key',
  //     fill: 'False',
  //   })
  // }
  // params.setCsvRows(new_csv_rows);
  // }

  // const handleAutoSettingCsvClick = () => {
  //   if (params.stream_id !== undefined) {
  //     const stream_id = params.stream_id;
  //     ConfirmDialog.show(
  //       <div style={{ color: colors.red }}>
  //         [確認]入力しているCSV定義を上書きして、
  //         <br />
  //         自動的にCSV定義を設定します。
  //         <br />
  //         入力されたデータを上書きしてよろしいですか?
  //       </div>,
  //       () => autoCsvSetting(stream_id),
  //       () => {},
  //       undefined
  //     );
  //   } else {
  //     AlertDialog.show('ストリームが選択されていません。');
  //   }
  // };

  // row_numberの数だけ行を追加する。(MAX100)
  const handleAddClick = (row_number = 1) => {
    if (Number.isNaN(row_number)) {
      AlertDialog.show('行数を1~100の間で入力してください');
      return;
    }
    const new_csv_rows = [...params.bodies];
    if (new_csv_rows.length >= 100) {
      AlertDialog.show('すでにCSV定義は最大の100行に達しています。');
      return;
    }
    // MINは1
    let add_row_number = Math.max(1, row_number);
    // 100行が最大なので、その差分がMAX
    if (add_row_number > 100 - new_csv_rows.length) {
      add_row_number = Math.min(add_row_number, 100 - new_csv_rows.length);
      AlertDialog.show(`CSV定義は最大で100行までの為、${100 - new_csv_rows.length}行のみ追加しました`);
    }
    // 指定分だけ行を追加する。
    for (let i = 0; i < add_row_number; i++) {
      new_csv_rows.push({
        header_name: '',
        json_path: '',
        cell_format: '',
        cell_format_args: '',
        statistic_method: 'Key',
        fill: 'False',
      });
    }
    params.setCsvRows(new_csv_rows);
  };

  // CSV定義を[]にする関数
  const handleDeleteAllClick = () => {
    params.setCsvRows([]);
  };

  // CSV定義をリセットする際は確認ダイアログを表示する
  const handleResetClick = () => {
    ConfirmDialog.show(
      <div style={{ color: colors.red }}>
        [確認]入力しているCSV定義をリセットします。
        <br />
        本当によろしいですか?
      </div>,
      () => handleDeleteAllClick(),
      () => {},
      undefined,
    );
  };

  // CSV定義を削除する関数
  const handleDeleteClick = () => {
    let new_csv_rows = params.bodies.slice();
    if (selected_indexes.length <= 0) {
      AlertDialog.show('削除する行を選択してください');
      return;
    }
    new_csv_rows = new_csv_rows.filter((ncr, i) => !selected_indexes.includes(i));
    params.setCsvRows(new_csv_rows);
    // 選択されていたテーブルのindexは消去
    setSelectedIndexes([]);
  };

  const handleUpClick = (index: number) => {
    const new_csv_rows = [...params.bodies];
    if (index >= 1) {
      [new_csv_rows[index], new_csv_rows[index - 1]] = [new_csv_rows[index - 1], new_csv_rows[index]];
    }
    params.setCsvRows(new_csv_rows);
  };

  const handleDownClick = (index: number) => {
    const new_csv_rows = [...params.bodies];
    if (index <= new_csv_rows.length - 2) {
      [new_csv_rows[index], new_csv_rows[index + 1]] = [new_csv_rows[index + 1], new_csv_rows[index]];
    }
    params.setCsvRows(new_csv_rows);
  };

  // -- render part --
  const header = params.show_statistics
    ? ['ヘッダ名', 'JSONパス', '出力式', '引数', '集計', '複製']
    : ['ヘッダ名', 'JSONパス', '出力式', '引数', '複製'];
  return (
    <WholeArea>
      {/* テーブル */}
      <TableArea>
        <Table>
          <Thead>
            <tr>
              {/* テーブルヘッダー */}
              <Th style={{ width: 35 }}></Th>
              <Th style={{ width: 35 }}>
                <CheckBoxArea>
                  <CheckBox checked={checkHeaderChecked()} onClick={handleHeadCheckClick} />
                </CheckBoxArea>
              </Th>
              {header.map((h, i) => (
                <Th
                  key={i}
                  style={{
                    width: `calc((100% -35px)/ ${header.length})`,
                    paddingLeft: styles.interval_narrow_margin,
                  }}
                >
                  <ThChildrensArea>{h}</ThChildrensArea>
                </Th>
              ))}
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {params.bodies.map((body, index1) => {
              return (
                <Tr key={index1}>
                  {/* テーブルボディーのチェックボックス */}
                  <Td>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <UpIcon onClick={() => handleUpClick(index1)} />
                      <DownIcon onClick={() => handleDownClick(index1)} />
                    </div>
                  </Td>
                  <Td
                    key={`checkbox_${index1}`}
                    style={{ width: 35 }}
                    last_row={index1 + 1 === params.bodies.length ? true : false}
                  >
                    <CheckBoxArea>
                      <CheckBox checked={selected_indexes.includes(index1)} onClick={() => handleCheckClick(index1)} />
                    </CheckBoxArea>
                  </Td>
                  <Td key={`header_name_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <InputBox
                      value={body.header_name}
                      onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'header_name')}
                    />
                  </Td>
                  <Td key={`json_path_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <JsonpathInput
                      value={body.json_path}
                      onJsonpathChanged={(item: string) => handleCsvRowsChangeClick(item, index1, 'json_path')}
                      placeholder='$から始めてください'
                    />
                  </Td>
                  <Td key={`cell_format_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <SelectBox
                      value={body.cell_format}
                      datas={cell_formats}
                      onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'cell_format')}
                      long
                    />
                  </Td>
                  <Td key={`cell_format_args_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <InputBox
                      value={body.cell_format_args}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCsvRowsChangeClick(e.currentTarget.value, index1, 'cell_format_args')
                      }
                      placeholder='出力式がSUBSTRINGの時のみ入力可'
                      disabled={params.bodies[index1].cell_format !== 'SUBSTRING'}
                    />
                  </Td>
                  {params.show_statistics && (
                    <Td
                      key={`statistic_method_${index1}`}
                      last_row={index1 + 1 === params.bodies.length ? true : false}
                    >
                      <SelectBox
                        onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'statistic_method')}
                        value={body.statistic_method || 'Key'}
                        default_text='null'
                        datas={[
                          { name: 'キー', value: 'Key' },
                          { name: '最初の値', value: 'FirstValue' },
                          { name: '最後の値', value: 'LastValue' },
                          { name: '最大値', value: 'Maximum' },
                          { name: '最小値', value: 'Minimum' },
                          { name: '合計値', value: 'Sum' },
                          { name: '平均値', value: 'Average' },
                          { name: '値の数', value: 'Count' },
                          { name: '最頻値', value: 'Mode' },
                        ]}
                      />
                    </Td>
                  )}
                  <Td key={`fill_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    <SelectBox
                      value={body.fill}
                      datas={[
                        {
                          name: 'True',
                          value: 'True',
                        },
                        {
                          name: 'False',
                          value: 'False',
                        },
                      ]}
                      onChange={(e) => handleCsvRowsChangeClick(e.currentTarget.value, index1, 'fill')}
                      long
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableArea>

      {/* フッター */}
      <FooterArea>
        <FlexCenterDiv align_center={true} style={{ marginRight: styles.interval_margin }}>
          <InputNumberBox value={add_row_number} onChange={onChangeAddRowNumber} style={{ width: 75 }} />
          行
          <RoundedButton
            text='追加'
            onClick={() => handleAddClick(add_row_number)}
            small={true}
            is_margin_right={true}
            style={{
              marginLeft: styles.interval_x_narrow_margin,
            }}
          />
        </FlexCenterDiv>
        <RoundedButton text='削除' onClick={handleDeleteClick} small={true} is_margin_right={true} is_white />
        <RoundedButton text='リセット' onClick={handleResetClick} small={true} is_white />
      </FooterArea>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

const TableArea = styled.div`
  max-width: 100%;
  width: 100%;
  overflow-y: scroll;
  border-radius: ${styles.table_radius_px};
`;

const FooterArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 25px;
  margin-top: ${styles.interval_narrow_margin};
`;

const ThChildrensArea = styled.div`
  display: flex;
  align-items: center;
`;

// -- finally export part --

export default CSVTable;
