// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- external components --

// -- type declaration --
interface Params {
  handleClick?: () => void;
  img_src: string;
  data_testid?: string;
  disabled?: boolean;
  text?: string;
  style?: React.CSSProperties;
  img_style?: React.CSSProperties;
}

// -- main component --

const IconButton: React.FC<Params> = (params) => {
  // -- preparations --

  // -- render part --
  return (
    <Button
      onClick={params.handleClick}
      disabled={params.disabled}
      data-testid={params.data_testid}
      style={params.style}
      title={params.text}
    >
      <IconImg src={params.img_src} style={params.img_style} />
    </Button>
  );
};

// -- styled components --

const Button = styled.button`
  display: flex;
  width: auto;
  height: auto;
  box-shadow: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  background-color: ${colors.white};
  &:disabled {
    cursor: not-allowed !important;
    opacity: ${styles.opacity_disabled};
    background-color: ${colors.white} !important;
  }
  &:hover {
    opacity: ${(params) => (params.disabled ? styles.opacity_disabled : styles.opacity_hover)};
    cursor: ${(params) => (params.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const IconImg = styled.img`
  width: 100%;
  height: 100%;
`;

// -- finally export part --

export default IconButton;
