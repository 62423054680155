import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import BaseIcon, { BaseIconProps } from './BaseIcon';

/**
 * 更新を表すアイコンです。
 */

export const EditIcon = memo(function EditIcon(props: Partial<BaseIconProps>) {
  return <BaseIcon {...props} icon={IconNames.EDIT} />;
});
