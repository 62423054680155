import { useCallback, useMemo, useState } from 'react';

export default function useObj<T>(defaults: T) {
  const [obj, setObj] = useState<T>(defaults);
  const updateObj = useCallback(<K extends keyof T>(new_obj: Pick<T, K> | T) => {
    setObj((current) => {
      return { ...current, ...new_obj };
    });
  }, []);

  return useMemo(() => {
    return { obj, setObj, updateObj };
  }, [obj, setObj, updateObj]);
}
