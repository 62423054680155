// -- basic library --
import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
/**
 * plugins for markdown
 */
// Support GFM (tables, autolinks, tasklists, strikethrough)
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
// to HTML
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';
import './MarkdownText.css';

const toCustomMarkdownText = (input: string) => {
  // brタグが2行分の改行になってしまうのを防ぐ
  const custom_input = input.replace(/<br>(\r)?\n/g, '<br>');
  const lines = custom_input.split('\n');
  const converted_lines = [];

  for (const line of lines) {
    if (line.startsWith('　')) {
      // 空白行を削除させないでそのまま表示させる
      converted_lines.push(`<p>${line}</p>\n`);
      continue;
    }
    converted_lines.push(line);
  }

  return converted_lines.join('\n');
};

export interface MarkdownTextProps {
  content: string;
}

// -- main component --
const MarkdownText: React.FC<MarkdownTextProps> = ({ content }) => {
  // contentの文字列をカスタマイズする
  const markdown_text = useMemo(() => {
    const indented_lines = toCustomMarkdownText(content);
    return indented_lines;
  }, [content]);

  // -- render part --
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkBreaks]}
      // 順番をrehypeRaw, rehypeSanitizeにないと全htmlタグが抹消される
      rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeStringify]}
      className='markdown-body'
    >
      {markdown_text}
    </ReactMarkdown>
  );
};

// -- styled components --

// -- finally export part --

export default MarkdownText;
