import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { SoracomEnvFreeParamType } from 'shared/models/Soracom';
import { SoracomStatus } from 'shared/models/SoracomStatus';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from './base';

/*** エンティティ ***/

export interface SoracomParameter {
  SORACOM_ENV_MODE: number;
  SORACOM_ENV_WAIT: number;
  SORACOM_ENV_CROP_TOP: number;
  SORACOM_ENV_CROP_BOTTOM: number;
  SORACOM_ENV_CROP_LEFT: number;
  SORACOM_ENV_CROP_RIGHT: number;
  SORACOM_ENV_UPLOAD_SIZE_W: number;
  SORACOM_ENV_UPLOAD_SIZE_H: number;
  SORACOM_ENV_URI: string;
  SORACOM_EXEC_PARAM: string;
  SORACOM_ENV_FREE_PARAM: SoracomEnvFreeParamType;
}

export interface Device {
  tenant_id: string;
  device_id: string;
  device_name: string;
  device_type: string;
  soracom_model_id: string;
  soracom_device_id: string;
  soracom_device_imsi: string;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  soracom_status?: SoracomStatus;
  soracom_parameter?: SoracomParameter;
  demogrid?: {enabled?: boolean, tenant_id?: string, camera_id?: string, domain?: string};
}

export interface DevicesWithPaging {
  items: Device[];
  has_next: boolean;
}

const sample_device_1: Device = {
  tenant_id: '00000001',
  device_id: '00000001',
  device_name: 'device_1',
  device_type: 'SORACOM',
  soracom_model_id: '00000001',
  soracom_device_id: 'd-samplesample',
  soracom_device_imsi: '112233445566778',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
  soracom_status: {
    device_name: 'soracom_device_1',
    sim_is_online: true,
    is_powered_on: true,
    application_is_working: true,
    last_modified_time: 1577804400,
    application_name: 'sample_app_1',
    application_version: '1.0.0',
    application_deployed_at: '2020-01-01T00:00:00Z' as ISO8601,
    application_launched_at: '2020-01-01T00:00:00Z' as ISO8601,
    updated_at: '2020-01-01T00:00:00Z' as ISO8601,
  },
  soracom_parameter: {
    SORACOM_ENV_MODE: 0,
    SORACOM_ENV_WAIT: 0,
    SORACOM_ENV_CROP_TOP: 0,
    SORACOM_ENV_CROP_BOTTOM: 0,
    SORACOM_ENV_CROP_LEFT: 0,
    SORACOM_ENV_CROP_RIGHT: 0,
    SORACOM_ENV_UPLOAD_SIZE_W: 1,
    SORACOM_ENV_UPLOAD_SIZE_H: 1,
    SORACOM_ENV_URI: '',
    SORACOM_EXEC_PARAM: '',
    SORACOM_ENV_FREE_PARAM: {},
  },
};
/*** Caching mechanism ***/

export class CachedDevices {
  private searched = false;
  private cache: Device[] = [];
  private params: RequestDevicesGet;
  constructor(params: RequestDevicesGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<DevicesWithPaging> = await devicesGetAPI({
          ...this.params,
          exclusive_start_device_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.device_id).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/devices ***/

export interface RequestDevicesGet {
  status?: StatusType;
  exclusive_start_device_id?: string;
}

export const devicesGetAPI = (params: RequestDevicesGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/devices`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { ...params };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<DevicesWithPaging>(axios, path, query, form, method, {
    items: new Array(30).fill(null).map((_, i) => {
      const v = JSON.parse(JSON.stringify(sample_device_1));
      v.device_id = ('00000000' + String(i)).slice(-8);
      v.status = i % 3 === 0 ? 'DELETED' : 'ACTIVE';
      v.deleted_at = v.status === 'DELETED' ? '2020-01-01T00:00:00' : null;
      return v;
    }),
    has_next: false,
  });
};

/*** [POST] /api/devices ***/

export interface RequestDevicesPost {
  tenant_id: string;
  soracom_device_id: string;
  device_name: string;
}

export const devicesPostAPI = (params: RequestDevicesPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/devices`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [GET] /api/devices/{id} ***/

export interface RequestDevicesIdGet {
  id: string;
}

export const devicesIdGetAPI = (params: RequestDevicesIdGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/devices/${params.id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [PUT] /api/devices/{id} ***/

export interface RequestDevicesIdPut {
  device_id: string;
  device_name: string;
  device_group_id: string;
  subscriber_group_id: string;
  demogrid_changes: boolean;
  demogrid_tenant_id: string;
  demogrid_camera_id: string;
  demogrid_domain: string;
}

export const devicesIdPutAPI = (params: RequestDevicesIdPut) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/devices/${params.device_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

export interface RequestDevicesIdDelete {
  id: string;
}

export const devicesIdDeleteAPI = (params: RequestDevicesIdDelete) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/devices/${params.id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

export interface RequestDevicesIdNowGet {
  id: string;
}

export const devicesIdNowGetAPI = (params: RequestDevicesIdNowGet) => {
  // クライアントを定義
  const axios = getClient('arraybuffer');

  // パス・メソッドを定義
  const path = `/api/devices/${params.id}/now`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, form, method, new ArrayBuffer(6));
};
