import sendAxios from 'shared/axios/sendAxios';
import { Query } from 'shared/models/Query';
import { getClient } from './base';

/*** エンティティ ***/

/*** [GET] /api/aws/ecr-rep-tags ***/
export interface awsEcrreptagsGetAPIResponse {
  tags: string[];
}
export const awsEcrreptagsGetAPI = () => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/aws/ecr-rep-tags`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<awsEcrreptagsGetAPIResponse>(axios, path, query, form, method, {
    tags: ['tag1', 'tag2', 'tag3'],
  });
};

/*** [GET] /api/aws/ecs-cluster ***/
export interface awsEcsclusterGetAPIResponse {
  capacity_providers: string[];
}
export const awsEcsclusterGetAPI = () => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/aws/ecs-cluster`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<awsEcsclusterGetAPIResponse>(axios, path, query, form, method, {
    capacity_providers: ['cp1', 'cp2', 'cp3'],
  });
};

/*** [GET] /api/aws/vpc ***/
export interface awsVpcsGetAPIResponse {
  vpcs: {
    vpcId: string;
    name: string;
    securityGroups: {
      groupId: string;
      name: string;
    }[];
  }[];
}

const sampleAwsVpcsGetAPIResponse: awsVpcsGetAPIResponse = {
  vpcs: [
    {
      vpcId: '00000001',
      name: 'vpc1',
      securityGroups: [
        {
          groupId: '1',
          name: 'group1',
        },
      ],
    },
  ],
};
export const awsVpcsGetAPI = () => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/aws/vpcs`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<awsVpcsGetAPIResponse>(axios, path, query, form, method, sampleAwsVpcsGetAPIResponse);
};
