export const colors = {
  // generic color codes
  black: 'black',
  gray: '#e0e0e0',
  dark_gray: '#666666',
  dark_gray2: '#182026',
  white: '#FFFFFF',
  red: 'red',
  blue: 'blue',
  yellow: 'yellow',
  light_green: 'rgba(206,238,154,1)',
  // named color codes
  background_color: '#f5f5f5',
  text_color: 'black',
  shadow_color: 'black',
  // purposed color codes
  component_small_border_color: '#919191',
  component_main_color: 'rgba(57, 131, 141, 1)',
  component_border_color: '#52b7bc',
  component_text_color: '#FFFFFF',
  placeholder_color: 'rgba(170, 170, 170, 0.8)',
  selected_border_color: '#4497ca',
  disabled_background_color: 'rgba(204, 204, 204, 1)',
  toggle_background_color: '#d9d9d9',
  icon_safe_color: '#2dcd98',
  icon_dangerous_color: '#f45867',
  image_insert_background_color: '#3c957c',
  main_font_color: '#6a6a6a',
  table_hover_background_color: 'rgba(0, 0, 0, 0.04)',
  table_checked_background_color: 'rgba(0, 136, 145, 0.3)',
  alert_color: 'rgba(226, 99, 107, 1)',
  error_color: 'rgba(231, 207, 209)',
  crosslineLRColor: '#F06D00', // 検知ラインのLR color オレンジ色
  crosslineRLColor: '#0094E4', // 検知ラインのRL color 優しい青色
};

export const sampleGraghColors: string[] = [
  'rgb(31, 119, 180)',
  'rgb(255, 127, 14)',
  'rgb(44, 160, 44)',
  'rgb(214, 39, 40)',
  'rgb(148, 103, 189)',
  'rgb(140, 86, 75)',
  'rgb(227, 119, 194)',
  'rgb(127, 127, 127)',
  'rgb(188, 189, 34)',
  'rgb(23, 190, 207)',
  'rgb(174, 199, 232)',
  'rgb(255, 187, 120)',
  'rgb(152, 223, 138)',
  'rgb(255, 152, 150)',
  'rgb(197, 176, 213)',
  'rgb(196, 156, 148)',
  'rgb(199, 199, 199)',
  'rgb(219, 219, 141)',
  'rgb(158, 218, 229)',
  'rgb(1, 200, 45)',
];
