import { parametersNameGetAPI } from 'admin/api/parameters';
import { useEffect, useState } from 'react';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import RoundedCard from 'shared/components/atoms/RoundedCard';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';

// -- main component --
const Top = () => {
  const [eventLink, setEventLink] = useState<string>();

  useEffect(() => {
    parametersNameGetAPI({parameter_name: 'ADMIN_TOP_EVENT_URL'})
    .then(res => {
      if (res.status === 200) {
        setEventLink(res.data.value);
      }
    })
  }, []);

  // -- render part --
  return (
    <PageWholeArea data-testid='Top'>
      <TopArea>
        <Title text='トップ' />
      </TopArea>
      <BottomArea>
        {eventLink && 
          <RoundedCard style={{width: '400px'}}>
            <div style={{marginBottom: '5px'}}>イベント監視一覧</div>
            <div><RoundedButton text='イベント監視一覧へ' onClick={() => location.href=eventLink} /></div>
          </RoundedCard>
        }
      </BottomArea>
    </PageWholeArea>
  );
};

export default Top;
