import { ReactNode } from 'react';
import { FormGroup as BPFormGroup, FormGroupProps as BPFormGroupProps } from '@blueprintjs/core';
import styled from 'styled-components';

export interface FormGroupProps extends BPFormGroupProps {
  children?: ReactNode;
}

export const FormGroup = styled((props: FormGroupProps) => <BPFormGroup {...props} />)`
  margin: 20px 0px;
  padding: 0px 10px;
`;

export default FormGroup;
