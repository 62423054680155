import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';

interface Params {
  bodies: TableUserType[];
  selected_bodies: TableUserType[];
  handleCheckClick: (datas: TableUserType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableUserType {
  id: string;
  user_id: TableBodyUrlType;
  user_name: string;
  login_id: string;
  mail_address: string;
  tenant_id: string;
  tenant_name: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

// -- external functions --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'user_id',
    label: 'ユーザーID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'user_name',
    label: 'ユーザー名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'login_id',
    label: 'ログインID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'tenant_name',
    label: 'テナント名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'mail_address',
    label: 'メールアドレス',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'status',
    label: 'ステータス',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'created_at',
    label: '作成日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'updated_at',
    label: '更新日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'deleted_at',
    label: '削除日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
];

// -- main component --
const UserTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='users'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default UserTable;
