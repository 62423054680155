import React from 'react';
import { Device, devicesIdPutAPI } from 'admin/api/device';
import { SoracomGroup, soracomGroupsGetAPI } from 'admin/api/soracom';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';
import ToggleButton from 'shared/components/molecules/ToggleButton';

interface DeviceIdEditPanelProps {
  device: Device;
  onChangeDevice: () => void;
}
interface DeviceIdEditPanelState {
  device_name: string;
  device_group_id: string;
  subscriber_group_id: string;
  soracom_groups: SoracomGroup[];
  demogrid_enabled: boolean;
  demogrid_tenant_id: string;
  demogrid_camera_id: string;
  demogrid_domain: string;
}
export default class DeviceIdEditPanel extends React.PureComponent<DeviceIdEditPanelProps, DeviceIdEditPanelState> {
  constructor(props: DeviceIdEditPanelProps) {
    super(props);
    this.state = {
      device_name: props.device.device_name,
      device_group_id: props.device.soracom_status?.device_group_id || '',
      subscriber_group_id: props.device.soracom_status?.subscriber_group_id || '',
      soracom_groups: [],
      demogrid_enabled: props.device.demogrid?.enabled || false,
      demogrid_tenant_id: props.device.demogrid?.tenant_id || '',
      demogrid_camera_id: props.device.demogrid?.camera_id || '',
      demogrid_domain: props.device.demogrid?.domain || '',
    };
  }
  componentDidMount() {
    // SORACOMグループの取得
    soracomGroupsGetAPI({}).then((res) => {
      if (res.status === 200) this.setState({ soracom_groups: res.data });
    });
  }
  private onCancelClick = () => {
    history.push('/devices/' + this.props.device.device_id);
  };
  private onUpdateClick = () => {
    if (!isNotOnlySpace(this.state.device_name)) {
      AlertDialog.show('デバイス名(顧客から見える情報)は必須です');
      return;
    }
    if (this.state.demogrid_enabled) {
      if (!isNotOnlySpace(this.state.demogrid_tenant_id)) {
        AlertDialog.show('DemoGrid テナントIDは必須です');
        return;
      }
      if (!isNotOnlySpace(this.state.demogrid_camera_id)) {
        AlertDialog.show('DemoGrid カメラIDは必須です');
        return;
      }
      if (!isNotOnlySpace(this.state.demogrid_domain)) {
        AlertDialog.show('DemoGrid ドメインは必須です');
        return;
      }
    }
    devicesIdPutAPI({
      device_id: this.props.device.device_id,
      device_name: this.state.device_name,
      device_group_id: this.state.device_group_id,
      subscriber_group_id: this.state.subscriber_group_id,
      demogrid_changes: true,
      demogrid_tenant_id: this.state.demogrid_enabled ? this.state.demogrid_tenant_id : '',
      demogrid_camera_id: this.state.demogrid_enabled ? this.state.demogrid_camera_id : '',
      demogrid_domain: this.state.demogrid_enabled ? this.state.demogrid_domain : '',
    }).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('デバイスの更新に成功しました。');
        history.push('/devices/' + this.props.device.device_id);
        // 上位コンポーネントにデバイス情報が変化したことを通知する
        this.props.onChangeDevice();
      }
    });
  };
  render() {
    return (
      <Content>
        <FormContainer title='デバイス基本情報'>
          <InputComponent text='デバイス名(顧客から見える情報)' required>
            <InputBox
              onChange={(e) => this.setState({ device_name: e.currentTarget.value })}
              title='デバイス名(顧客から見える情報)'
              placeholder='入力してください(必須)'
              value={this.state.device_name}
              required
            />
          </InputComponent>
        </FormContainer>
        <FormContainer title='デバイスのグループ情報'>
          {this.state.soracom_groups.length > 0 && (
            <>
              <InputComponent text='デバイスのグループ：'>
                <SelectBox
                  onChange={(e) => this.setState({ device_group_id: e.currentTarget.value })}
                  value={this.state.device_group_id}
                  datas={this.state.soracom_groups.map((d) => {
                    return {
                      name: d.soracom_group_name,
                      value: d.soracom_group_id,
                    };
                  })}
                  long
                />
              </InputComponent>
              <InputComponent text='Subscriber(SIM)のグループ：'>
                <SelectBox
                  onChange={(e) => this.setState({ subscriber_group_id: e.currentTarget.value })}
                  value={this.state.subscriber_group_id}
                  datas={this.state.soracom_groups.map((d) => {
                    return {
                      name: d.soracom_group_name,
                      value: d.soracom_group_id,
                    };
                  })}
                  long
                />
              </InputComponent>
            </>
          )}
        </FormContainer>
        <FormContainer title='DemoGridとの連携'>
          <ToggleButton
            onClick={() => this.setState({demogrid_enabled: !this.state.demogrid_enabled})}
            checked={this.state.demogrid_enabled} />
          {this.state.demogrid_enabled &&
            <>
              <InputComponent text='DemoGrid テナントID' required>
                <InputBox
                  onChange={(e) => this.setState({ demogrid_tenant_id: e.currentTarget.value })}
                  title='DemoGrid テナントID'
                  placeholder='入力してください(必須)'
                  value={this.state.demogrid_tenant_id}
                  required
                />
              </InputComponent>
              <InputComponent text='DemoGrid カメラID' required>
                <InputBox
                  onChange={(e) => this.setState({ demogrid_camera_id: e.currentTarget.value })}
                  title='DemoGrid カメラID'
                  placeholder='入力してください(必須)'
                  value={this.state.demogrid_camera_id}
                  required
                />
              </InputComponent>
              <InputComponent text='DemoGrid ドメイン' required>
                <InputBox
                  onChange={(e) => this.setState({ demogrid_domain: e.currentTarget.value })}
                  title='DemoGrid ドメイン'
                  placeholder='入力してください(必須)'
                  value={this.state.demogrid_domain}
                  required
                />
              </InputComponent>
            </>
          }
        </FormContainer>
        <Footer>
          <RoundedButton onClick={this.onCancelClick} text='戻る' is_margin_right is_white />
          <RoundedButton onClick={this.onUpdateClick} text='更新' />
        </Footer>
      </Content>
    );
  }
}
