import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { BaseIconProps, BaseIcon } from './BaseIcon';

/**
 * 追加を表すアイコンです。
 */
export const AddIcon = memo(function AddIcon(props: Partial<BaseIconProps>) {
  return <BaseIcon {...props} icon={IconNames.ADD} />;
});
