import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import BaseIcon, { BaseIconProps } from './BaseIcon';

/* 上下を表すアイコンです */
export const UpIcon = memo(function UpIcon(props: Partial<BaseIconProps>) {
  return <BaseIcon {...props} icon={IconNames.CHEVRON_UP} />;
});

export const DownIcon = memo(function DownIcon(props: Partial<BaseIconProps>) {
  return <BaseIcon {...props} icon={IconNames.CHEVRON_DOWN} />;
});
