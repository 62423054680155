import { memo } from 'react';
import sidemenu_device from 'assets/device.svg';
import sidemenu_event from 'assets/event.svg';
import sidemenu_dashboard from 'assets/dashboard.svg';
import sidemenu_generator from 'assets/generator.svg';
import sidemenu_home from 'assets/home2.svg';
import sidemenu_jsonpathgroup from 'assets/jsonpath-group.svg';
import sidemenu_model from 'assets/model.svg';
import sidemenu_notification from 'assets/notification.svg';
import sidemenu_parameter from 'assets/parameter.svg';
import sidemenu_processflow from 'assets/process-flow.svg';
import sidemenu_process from 'assets/process.svg';
import sidemenu_stream from 'assets/stream.svg';
import sidemenu_tenant from 'assets/tenant.svg';
import sidemenu_users from 'assets/users.svg';
import sidemenu_billings from 'assets/dollar.svg';
import PfSideMenu, { SideMenuItem } from 'shared/components/molecules/PfSideMenu';

const side_menu_items: SideMenuItem[] = [
  {
    id: 'top',
    text: 'トップ',
    img_src: sidemenu_home,
  },
  {
    id: 'tenants',
    text: 'テナント',
    img_src: sidemenu_tenant,
  },
  {
    id: 'billings',
    text: '請求照会',
    img_src: sidemenu_billings,
  },
  {
    id: 'users',
    text: 'ユーザー',
    img_src: sidemenu_users,
  },
  {
    id: 'notifications',
    text: 'お知らせ管理',
    img_src: sidemenu_notification,
  },
  {
    id: 'models',
    text: 'モデル管理',
    img_src: sidemenu_model,
  },
  {
    id: 'devices',
    text: 'デバイス管理',
    img_src: sidemenu_device,
  },
  {
    id: 'events',
    text: 'イベント監視管理',
    img_src: sidemenu_event,
  },
  {
    id: 'dashboards',
    text: 'ダッシュボード管理',
    img_src: sidemenu_dashboard,
  },
  {
    id: 'processes',
    text: 'プロセス管理',
    img_src: sidemenu_process,
  },
  {
    id: 'processflows',
    text: 'プロセスフロー管理',
    img_src: sidemenu_processflow,
  },
  {
    id: 'jsonpath',
    text: 'JSONパスグループ管理',
    img_src: sidemenu_jsonpathgroup,
  },
  {
    id: 'parameters',
    text: 'パラメータ管理',
    img_src: sidemenu_parameter,
  },
  {
    id: 'edgeDevices',
    text: 'エッジデバイス管理',
    img_src: sidemenu_device,
  },
  {
    id: 'streams',
    text: 'ストリーム情報',
    img_src: sidemenu_stream,
  },
  {
    id: 'generator',
    text: 'ジェネレーター管理',
    img_src: sidemenu_generator,
  },
];
/**
 * [管理者画面]サイドメニュー
 */
export const PfAdminSideMenu = memo(function PfUserSideMenu() {
  return <PfSideMenu default_id='dashboards' side_menu_items={side_menu_items} is_admin_user={true} />;
});

export default PfAdminSideMenu;
