import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StringBoolean } from 'shared/models/StringBoolean';
import { getClient } from './base';

/*** エンティティ ***/

export interface Event {
  event_id: string;
  tenant_id: string;
  channel_id: string;
  channel_event_condition_name: string;
  current_status: string;
  current_status_at?: ISO8601;
  is_billing: boolean;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601;
}

export interface EventWithPaging {
  items: Event[];
  has_next: boolean;
}

const sample_event_1: Event = {
  event_id: '00000001:00000002:1',
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_condition_name: 'Demo0監視用',
  current_status: 'OK',
  current_status_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  is_billing: true,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** [GET] /api/events ***/

export interface RequestEventsGet {}
export const eventsGetAPI = (params: RequestEventsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/events`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<EventWithPaging>(axios, path, query, form, method, {
    items: [sample_event_1],
    has_next: false,
  });
};

/*** [PUT] /api/events/{event_id} ***/

export interface RequestEventsIdPut {
  event_id: string;
  is_billing: StringBoolean;
}

export const eventsIdPutAPI = (params: RequestEventsIdPut) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/events/${params.event_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Event>(axios, path, query, form, method, sample_event_1);
};
