import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { generatorHsGetAPI, generatorHsPostAPI, ResponseGeneratorHsGet } from 'admin/api/generator';
import { Content, Title, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import { colors } from 'shared/styles/colors';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import styled from 'styled-components';

interface GeneratorProps {}
interface GeneratorState {
  instances?: ResponseGeneratorHsGet[];
}
export default class Generator extends React.PureComponent<GeneratorProps, GeneratorState> {
  constructor(props: GeneratorProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.refreshHs();
  }
  private onRunClick = (instance_id: string) => {
    if (!window.confirm('このインスタンスを起動してよろしいですか？')) return;
    generatorHsPostAPI({ instance_id: instance_id, action: 'run' }).then((res) => {
      if (res.status === 200) this.refreshHs();
    });
  };
  private onStopClick = (instance_id: string) => {
    if (!window.confirm('このインスタンスを停止してよろしいですか？')) return;
    generatorHsPostAPI({ instance_id: instance_id, action: 'stop' }).then((res) => {
      if (res.status === 200) this.refreshHs();
    });
  };
  private onRemarkEditClick = (instance_id: string, remark?: string) => {
    const new_value = window.prompt('備考タグを編集', remark ? remark : '');
    if (new_value === null) return;
    generatorHsPostAPI({ instance_id: instance_id, action: 'remark', remark_tag: new_value }).then((res) => {
      if (res.status === 200) this.refreshHs();
    });
  };
  private refreshHs = () => {
    generatorHsGetAPI({}).then((res) => {
      if (res.status === 200) this.setState({ instances: res.data });
    });
  };
  render() {
    return (
      <PageWholeArea>
        <Title text='ジェネレーター' icon={<Icon icon={IconNames.AIRPLANE} iconSize={24} color={colors.gray} />} />
        <Content>
          <Button onClick={() => this.refreshHs()}>最新の情報に更新</Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>インスタンスID</TableCell>
                <TableCell>インスタンス名</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>最終起動日時</TableCell>
                <TableCell>備考タグ</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>ID / PW</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            {this.state.instances &&
              this.state.instances.map((e, i) => (
                <TableBody key={i}>
                  <TableRow>
                    <TableCell>{e.instance_id}</TableCell>
                    <TableCell>{e.instance_name}</TableCell>
                    <TableCell>
                      <span style={{ color: e.status === 'running' ? 'red' : 'black' }}>{e.status}</span>
                    </TableCell>
                    <TableCell>{dateToYMDHMS(e.launch_time)}</TableCell>
                    <TableCell>
                      {e.remark_tag}
                      <Icon
                        icon={IconNames.EDIT}
                        onClick={() => this.onRemarkEditClick(e.instance_id, e.remark_tag)}
                        style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <TableCell>
                      {e.generator_url && (
                        <a href={e.generator_url} target='_blank' rel='noopener noreferrer'>
                          {e.generator_url}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {e.generator_id ? e.generator_id : 'Unknown'} / {e.generator_pw ? e.generator_pw : 'Unknown'}
                    </TableCell>
                    <TableCell>
                      <ButtonFont onClick={() => this.onRunClick(e.instance_id)} disabled={e.status !== 'stopped'}>
                        起動
                      </ButtonFont>
                      &nbsp;
                      <ButtonFont onClick={() => this.onStopClick(e.instance_id)} disabled={e.status !== 'running'}>
                        終了
                      </ButtonFont>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </Content>
      </PageWholeArea>
    );
  }
}

const ButtonFont = styled(Button)`
  font-family: 'Noto Sans JP';
  width: 90px;
`;
