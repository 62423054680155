// -- basic library --
import React from 'react';

// -- external components --
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyType, TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { ProcessType } from 'shared/models/ProcessType';
// -- external types --

// -- external functions --

// -- type declaration --

interface Params {
  bodies: TableProcessFlowType[];
  selected_bodies: TableProcessFlowType[];
  handleCheckClick: (datas: TableProcessFlowType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export interface TableProcessFlowType extends TableBodyType {
  id: string;
  process_flow_id: TableBodyUrlType;
  tenant_id: string;
  process_flow_name: string;
  process_type: ProcessType | '';
  memo: string;
  order: number | null;
}

// -- external functions --

// -- main component --
const ProcessFlowTable: React.FC<Params> = (params) => {
  const head_cells: TableHeaderType[] = [
    {
      id: 'process_flow_id',
      label: 'プロセスフローID',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'tenant_id',
      label: '対象テナントID',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'process_flow_name',
      label: 'プロセスフロー名',
      sortable: true,
      search_props: {
        type: 'name',
        default_display: true,
      },
    },
    {
      id: 'process_type',
      label: '課金対象',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'memo',
      label: 'メモ',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'order',
      label: 'オーダー',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
  ];
  // -- render part --
  return <BaseTable {...params} table_name='processflows' headers={head_cells} />;
};

// -- styled components --

// -- finally export part --
export default ProcessFlowTable;
