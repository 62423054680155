import sendAxios from 'shared/axios/sendAxios';
import { AppParameter } from 'shared/models/AppParameter';
import { ISO8601 } from 'shared/models/ISO8601';
import { ProcessType } from 'shared/models/ProcessType';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { StringBoolean } from 'shared/models/StringBoolean';
import { getClient } from './base';

/*** エンティティ ***/

export interface ProcessFlowStep {
  step_id: number;
  process_id: string;
  output_name: string;
  input_streams: string[];
  integration_flag: boolean;
  app_parameter: AppParameter;
}

export interface ProcessFlow {
  tenant_id: string;
  process_flow_id: string;
  process_flow_name: string;
  steps: ProcessFlowStep[];
  is_billing: boolean | null;
  process_type: ProcessType;
  unit_price: number;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  memo: string | null;
  order: number | null;
}

const sampel_processflow1: ProcessFlow = {
  tenant_id: '00000001',
  process_flow_id: '00000001',
  process_flow_name: 'process_flow1',
  status: 'DELETED',
  steps: [],
  is_billing: true,
  process_type: 'COUNT',
  unit_price: 1500,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  memo: 'memo',
  order: 0,
};

/*** Caching mechanism ***/
export interface CachedProcessFlowsProps {
  tenant_id: string;
}
export class CachedProcessFlows {
  private searched = false;
  private cache: ProcessFlow[] = [];
  private tenant_id: string;
  constructor(params: CachedProcessFlowsProps) {
    this.tenant_id = params.tenant_id;
  }
  async get() {
    if (!this.searched) {
      const res = await processflowsTenantDetailGetAPI({
        tenant_id: this.tenant_id,
      });
      if (res.status === 200) {
        const processFlows = res.data.items;
        this.cache = processFlows;
      }
      this.searched = true;
    }
    return this.cache;
  }
}

export interface ProcessFlowsWithPaging {
  items: ProcessFlow[];
  has_next: boolean;
}

/*** [GET] /api/processflows/{tenant_id} ***/

export interface RequestProcessFlowsTenantDetailGet {
  tenant_id: string;
}
export const processflowsTenantDetailGetAPI = (params: RequestProcessFlowsTenantDetailGet) => {
  const { tenant_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processflows/${tenant_id}/detail`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ProcessFlowsWithPaging>(axios, path, query, form, method, {
    items: [sampel_processflow1],
    has_next: false,
  });
};

/*** [GET] /api/processflows ***/

export interface RequestProcessFlowsGet {}
export const processflowsGetAPI = (params: RequestProcessFlowsGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processflows`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ProcessFlowsWithPaging>(axios, path, query, form, method, {
    items: [sampel_processflow1],
    has_next: false,
  });
};

/*** [POST] /api/processflows ***/

export interface RequestProcessFlowsPost {
  tenant_id: string; // ','区切りで複数指定可能
  process_flow_name: string;
  steps: ProcessFlow['steps'];
  is_billing: StringBoolean;
  process_type?: ProcessType | '';
  unit_price: number;
  memo?: string;
  order?: number;
}
export const processflowsPostAPI = (params: RequestProcessFlowsPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processflows`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'steps') {
      // stepの構造化
      (value as ProcessFlow['steps']).forEach((step, i) => {
        form.append(`step[${i}].step_id`, String(step.step_id));
        form.append(`step[${i}].process_id`, String(step.process_id));
        form.append(`step[${i}].output_name`, step.output_name ?? '');
        step.input_streams.forEach((s, j) => {
          form.append(`step[${i}].input_stream[${j}]`, s);
        });
        let integration_flag = 'True';
        if (!step.integration_flag) {
          integration_flag = '';
        }
        form.append(`step[${i}].integration_flag`, integration_flag);
        form.append(`step[${i}].app_parameter`, JSON.stringify(step.app_parameter));
      });
    } else if (typeof value === 'string' || typeof value === 'number') {
      form.append(key, String(value));
    }
  }

  // 送信
  return sendAxios<ProcessFlow>(axios, path, query, form, method, sampel_processflow1);
};

/*** [GET] /api/processflows/{id} ***/

export interface RequestProcessFlowsIdGet {
  process_flow_id: string;
}

export const processflowsIdGetAPI = (params: RequestProcessFlowsIdGet) => {
  const { process_flow_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processflows/${process_flow_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ProcessFlow>(axios, path, query, form, method, sampel_processflow1);
};

/*** [PUT] /api/processflows/{id} ***/

export interface RequestProcessFlowsIdPut {
  process_flow_id: string;
  process_flow_name?: string;
  tenant_id: string; // ','区切りで複数指定可能
  steps?: ProcessFlow['steps'];
  is_billing: StringBoolean;
  process_type?: ProcessType | '';
  unit_price: number;
  memo?: string;
  order?: number;
}
export const processflowsIdPutAPI = (params: RequestProcessFlowsIdPut) => {
  const { process_flow_id, ...form_params } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processflows/${process_flow_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(form_params)) {
    if (key === 'steps') {
      // stepの構造化
      (value as ProcessFlow['steps']).forEach((step, i) => {
        form.append(`step[${i}].step_id`, String(step.step_id));
        form.append(`step[${i}].process_id`, String(step.process_id));
        form.append(`step[${i}].output_name`, step.output_name ?? '');
        step.input_streams.forEach((s, j) => {
          form.append(`step[${i}].input_stream[${j}]`, s);
        });
        let integration_flag = 'True';
        if (!step.integration_flag) {
          integration_flag = '';
        }
        form.append(`step[${i}].integration_flag`, integration_flag);
        form.append(`step[${i}].app_parameter`, JSON.stringify(step.app_parameter));
      });
    } else if (typeof value === 'string' || typeof value === 'number') {
      form.append(key, String(value));
    }
  }

  // 送信
  return sendAxios<ProcessFlow>(axios, path, query, form, method, sampel_processflow1);
};

/*** [DELETE] /api/processflows/{id} ***/

export interface RequestProcessFlowsIdDelete {
  process_flow_id: string;
}
export const processflowsIdDeleteAPI = (params: RequestProcessFlowsIdDelete) => {
  const { process_flow_id } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/processflows/${process_flow_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ProcessFlow>(axios, path, query, form, method, sampel_processflow1);
};
