// -- basic library --
import React, { ReactNode } from 'react';
import RequiredIcon from 'shared/components/atoms/RequiredIcon';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- type declaration --
interface Params {
  text: string;
  required?: boolean;
  style?: React.CSSProperties;
  children?: ReactNode;
}

// -- main component --

const InputComponent = (params: Params) => {
  // -- preparations --

  // -- render part --
  return (
    <WholeArea style={params.style}>
      <InputTitle>
        {params.text}
        {params.required ? <RequiredIcon /> : null}
      </InputTitle>
      {params.children}
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  display: block;
  align-items: center;
  margin-bottom: ${styles.input_component_margin_bottom};
  font-family: inherit;
`;

const InputTitle = styled.div`
  min-width: 180px;
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  font-family: inherit;
  font-weight: bold;
`;

// -- finally export part --

export default InputComponent;
