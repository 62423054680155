import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, InputGroup, Text } from '@blueprintjs/core';
import { accountsLoginPostAPI } from 'admin/api/accounts';
import { authActions, authSelector } from 'admin/redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import { FormGroup } from 'shared/components/atoms/FormGroup';
import SecurityBox from 'shared/components/atoms/SecurityBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import styled from 'styled-components';
import { isExistedPathname } from 'shared/utils/is';

// -- main component --

const Login: React.FC = () => {
  // -- redux preparations --
  const dispatch = useDispatch();
  const auth_state = useSelector(authSelector);

  // -- local states --
  const [login_id, setLoginId] = useState('');
  const [password, setPassword] = useState('');

  /**
   * 遷移先のpathnameを取得する
   */
  const getTransitionPathname = useCallback(() => {
    let pathname = '/top';
    const { location } = window;

    const urlSearchParams = new URLSearchParams(location.search);

    // redirectが存在しない or 空文字列の場合は、初期値を加える
    const redirect = urlSearchParams.get('redirect') || `${location.protocol}//${window.location.host}/top`;
    try {
      const redirectUrl = new URL(redirect);
      if (isExistedPathname(redirectUrl.pathname)) {
        pathname = redirectUrl.pathname + redirectUrl.search;
      }
    } catch (e) {
      console.log(`このエラーは無視してください。エラー内容：${e}`);
    }

    return pathname;
  }, []);

  // -- handlers --
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginId(e.currentTarget.value);
  };
  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };
  const handleLogin = async (request_data: { login_id: string; password: string }) => {
    if (!request_data.login_id || !request_data.password) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }
    const res = await accountsLoginPostAPI(request_data);
    if (res.status === 200) {
      if (res.data.challenge_name === 'NEW_PASSWORD_REQUIRED') {
        dispatch(
          authActions.signInFirst({
            login_id: login_id,
            session: res.data.session,
          }),
        );
        AlertDialog.show('通信に成功しました。初期パスワードを変更するために"初期パスワード変更画面"に遷移します。');
        history.push(`/initialPasswordChange`);
      } else if (res.data.authentication_result) {
        const pathname = getTransitionPathname();
        dispatch(
          authActions.signIn({
            access_key: res.data.authentication_result.access_token,
            refresh_token: res.data.authentication_result.refresh_token,
            session: res.data.session,
            login_id: login_id,
          }),
        );
        history.push(pathname);
      }
    }
  };

  // -- render part --
  return auth_state.is_logged_in ? (
    <Redirect to='/top' />
  ) : (
    <BackGround data-testid='Login'>
      <Wrap>
        <Title>
          IDEA platform
          <br />
          Administrator
        </Title>
        <SignInCard>
          <FormGroup>
            <UserNameBox placeholder='ログインID' onChange={handleNameChange} data-testid='login_id' />
            <SecurityBox placeholder='パスワード' value={password} onChange={handlePassChange} id='password' />
          </FormGroup>
          <ButtonWrap>
            <EnterButton intent='primary' onClick={() => handleLogin({ login_id: login_id, password: password })}>
              ログイン
            </EnterButton>
          </ButtonWrap>
        </SignInCard>
      </Wrap>
      <AlertDialog />
    </BackGround>
  );
};

// -- styled components --

const Wrap = styled.div`
  width: 400px;
  height: 220px;
  position: absolute;
  margin: auto;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
`;

const Title = styled(Text)`
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: black;
`;

const UserNameBox = styled(InputGroup)`
  margin-bottom: 14px;
`;

const BackGround = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: #f5f8fa;
`;

const SignInCard = styled.div`
  width: 340px;
  height: auto;
  margin: auto;
  padding-top: 0px;
  padding-bottom: 15px;
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const EnterButton = styled(Button)`
  display: inline-block;
  width: 94%;
  text-align: center;
  margin: auto;
`;

// -- finally export part --

export default Login;
