import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from './base';

/*** エンティティ ***/

export interface Parameter {
  parameter_name: string;
  value: string;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface ParametersWithPaging {
  items: Parameter[];
  has_next: boolean;
}

const sample_parameter_1: Parameter = {
  parameter_name: 'パラメータ1',
  value: '10.09',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** Caching mechanism ***/

export class CachedParameters {
  private searched = false;
  private cache: Parameter[] = [];
  private params: RequestParametersGet;
  constructor(params: RequestParametersGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<ParametersWithPaging> = await parametersGetAPI({
          ...this.params,
          exclusive_start_parameter_name: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.parameter_name).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/parameters ***/

export interface RequestParametersGet {
  status?: StatusType;
  exclusive_start_parameter_name?: string;
}

export const parametersGetAPI = (params: RequestParametersGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/parameters`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<ParametersWithPaging>(axios, path, query, form, method, {
    items: [sample_parameter_1],
    has_next: false,
  });
};

/*** [POST] /api/parameters ***/

export interface RequestParametersPost {
  parameter_name: string;
  value: string;
}

export const parametersPostAPI = (params: RequestParametersPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/parameters`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Parameter>(axios, path, query, form, method, sample_parameter_1);
};

/*** [GET] /api/parameters/{parameter_name} ***/

export interface RequestParametersNameGet {
  parameter_name: string;
}

export const parametersNameGetAPI = (params: RequestParametersNameGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/parameters/${params.parameter_name}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<Parameter>(axios, path, query, form, method, sample_parameter_1);
};

/*** [PUT] /api/parameters/{parameter_name} ***/

export interface RequestParametersNamePut {
  parameter_name: string;
  value: string;
}

export const parametersNamePutAPI = (params: RequestParametersNamePut) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/parameters/${params.parameter_name}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<Parameter>(axios, path, query, form, method, sample_parameter_1);
};

/*** [DELETE] /api/parameters/{parameter_name} ***/

export interface RequestParametersNameDelete {
  parameter_name: string;
}

export const parametersNameDeleteAPI = (params: RequestParametersNameDelete) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/parameters/${params.parameter_name}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<Parameter>(axios, path, query, form, method, sample_parameter_1);
};
