import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { tenantsPostAPI } from 'admin/api/tenants';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { PageWholeArea, Title, Content, Breadcrumbs, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styles from 'shared/styles/styles';
import { getSubDomain } from 'shared/utils/get';
import useObj from 'shared/hooks/useObj/useObj';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { tenantsPostValidator } from 'admin/utils/validators/tenants';
import TableauIdInputStatement from '../components/TableauIdInputStatement';
// -- main component --

const TenantCreate: React.FC = () => {
  // -- local states --
  const { obj: form, updateObj: updateForm } = useObj<{
    tenant_name: string;
    tenant_domain: string;
    tenant_generator2_domain: string;
    use_tableau: boolean;
    tableau_id: string | null;
  }>({
    tenant_name: '',
    tenant_domain: '',
    tenant_generator2_domain: '',
    use_tableau: false,
    tableau_id: null,
  });

  // -- handlers --

  const handleCancel = () => {
    history.push('/tenants');
  };

  const updateTableauId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (!value) {
      updateForm({ tableau_id: null });
    } else {
      updateForm({ tableau_id: value });
    }
  };

  const handleFinish = async () => {
    try {
      const request = tenantsPostValidator(form);
      const res = await tenantsPostAPI(request);
      if (res.status === 200) {
        AlertDialog.show('新規テナントの追加に成功しました');
        history.push('/tenants');
      }
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
  };

  // -- render part --
  return (
    <PageWholeArea data-testid='TenantCreate'>
      <Breadcrumbs
        items={[
          { href: '/tenants', icon: IconNames.CONTROL, text: 'テナント' },
          { icon: IconNames.CUBE_ADD, text: 'テナントの追加' },
        ]}
      />
      <Title text='テナントの追加' />
      <Content>
        <FormContainer title='基本情報'>
          <InputComponent text='テナント名' required>
            <InputBox
              title='テナント名'
              placeholder='入力してください(必須)'
              value={form.tenant_name}
              minLength={1}
              onChange={(e) => updateForm({ tenant_name: e.currentTarget.value })}
            />
          </InputComponent>
          <InputComponent text='テナントドメイン' required>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InputBox
                title='テナントドメイン'
                placeholder='.と空白及び大文字入力はできません'
                value={form.tenant_domain}
                minLength={1}
                onChange={(e) =>
                  updateForm({ tenant_domain: e.currentTarget.value.replace('.', '').trim().toLocaleLowerCase() })
                }
                style={{ width: 'calc(100% - 250px)' }}
              />
              <div style={{ width: '250px', marginLeft: styles.interval_x_narrow_margin }}>{getSubDomain()}</div>
            </div>
          </InputComponent>
          <InputComponent text='テナントGenerator2ドメイン'>
            <InputBox
              title='テナントGenerator2ドメイン'
              placeholder='入力してください(任意)'
              value={form.tenant_generator2_domain}
              onChange={(e) => updateForm({ tenant_generator2_domain: e.currentTarget.value })}
            />
          </InputComponent>
          <InputComponent text='Tableau'>
            <CheckBoxWithText
              text='利用する'
              checked={form.use_tableau}
              onClick={() => updateForm({ use_tableau: !form.use_tableau })}
            />
          </InputComponent>
          {form.use_tableau && (
            <InputComponent text='Tableau識別子' required>
              <InputBox
                title='Tableau識別子'
                placeholder=''
                value={form.tableau_id ?? ''}
                onChange={updateTableauId}
                maxLength={50}
              />
              <TableauIdInputStatement />
            </InputComponent>
          )}
        </FormContainer>
        <Footer>
          <RoundedButton onClick={handleCancel} text='キャンセル' is_white is_margin_right />
          <RoundedButton onClick={handleFinish} text='登録' />
        </Footer>
      </Content>
    </PageWholeArea>
  );
};

// -- finally export part --

export default TenantCreate;
