import { PretendDateType } from 'shared/models';
import { isInvalidDate } from '../is';

/**
 * year:yyyy
 * month: MM
 * day: dd
 * hour: HH
 * minute: mm
 * second: ss
 * mili second: SSS
 */

/**
 * 日付を yyyy/MM/dd HH:mm:ss 形式の文字列で返します。
 * そのような変換ができない場合は、空文字列を返します。
 *
 * @param date 日付を表す文字列や、ミリ秒のタイムスタンプ
 * @returns 日付を表す文字列、または空文字列。
 */
export function dateToYMDHMS(date?: PretendDateType) {
  if (!date) return '';
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  const year = ('0000' + dt.getFullYear()).slice(-4);
  const month = ('00' + (dt.getMonth() + 1)).slice(-2);
  const day = ('00' + dt.getDate()).slice(-2);
  const hour = ('00' + dt.getHours()).slice(-2);
  const minute = ('00' + dt.getMinutes()).slice(-2);
  const second = ('00' + dt.getSeconds()).slice(-2);
  return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
}

/**
 * 日付をHH:mmで返却
 */
export const dateToHM = (date: PretendDateType) => {
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  // 時間のみを取得
  const hour = ('00' + dt.getHours()).slice(-2);
  const minute = ('00' + dt.getMinutes()).slice(-2);
  return `${hour}:${minute}`;
};
/**
 * 日付をMM/DDで返却
 */
export const dateToMD = (date: PretendDateType, fill_with_zero = true) => {
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  //基本は0埋め
  let month = ('00' + (dt.getMonth() + 1)).slice(-2);
  let day = ('00' + dt.getDate()).slice(-2);
  //0埋めしない時
  if (!fill_with_zero) {
    month = String(dt.getMonth() + 1);
    day = String(dt.getDate());
  }
  return `${month}/${day}`;
};

export const dateToNumeric = (date: PretendDateType) => {
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  const year = ('0000' + dt.getFullYear()).slice(-4);
  const month = ('00' + (dt.getMonth() + 1)).slice(-2);
  const day = ('00' + dt.getDate()).slice(-2);
  const hour = ('00' + dt.getHours()).slice(-2);
  const minute = ('00' + dt.getMinutes()).slice(-2);
  const second = ('00' + dt.getSeconds()).slice(-2);
  return `${year}${month}${day}${hour}${minute}${second}`;
};

export const dateToYMD = (date: PretendDateType, connector: '/' | '-' = '/') => {
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  const year = ('0000' + dt.getFullYear()).slice(-4);
  const month = ('00' + (dt.getMonth() + 1)).slice(-2);
  const day = ('00' + dt.getDate()).slice(-2);
  return `${year}${connector}${month}${connector}${day}`;
};

export const dateToMDHM = (date: PretendDateType, fill_with_zero = true) => {
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  let month = ('00' + (dt.getMonth() + 1)).slice(-2);
  let day = ('00' + dt.getDate()).slice(-2);
  const hour = ('00' + dt.getHours()).slice(-2);
  const minute = ('00' + dt.getMinutes()).slice(-2);
  if (!fill_with_zero) {
    month = String(dt.getMonth() + 1);
    day = String(dt.getDate());
  }
  return `${month}/${day} ${hour}:${minute}`;
};

export const dateToYMDHM = (date: PretendDateType) => {
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  const year = ('0000' + dt.getFullYear()).slice(-4);
  const month = ('00' + (dt.getMonth() + 1)).slice(-2);
  const day = ('00' + dt.getDate()).slice(-2);
  const hour = ('00' + dt.getHours()).slice(-2);
  const minute = ('00' + dt.getMinutes()).slice(-2);
  return `${year}/${month}/${day} ${hour}:${minute}`;
};

export const secondsToTime = (total_seconds: number) => {
  const hour = Math.trunc(total_seconds / 3600);
  const min = Math.trunc(total_seconds / 60) % 60;
  const seconds = (total_seconds % 60) % 60;
  return {
    hour: hour,
    min: min,
    sec: seconds,
  };
};

export const dateToMDDofW = (date: PretendDateType) => {
  const dt = new Date(date);
  if (isInvalidDate(dt)) return '';
  const month = ('00' + (dt.getMonth() + 1)).slice(-2);
  const day = ('00' + dt.getDate()).slice(-2);
  const day_of_week = dt.getDay();
  const day_of_week_str = ['日', '月', '火', '水', '木', '金', '土'][day_of_week];
  return `${month}/${day}(${day_of_week_str})`;
};

export const dateToTimeStamp = (dt: PretendDateType) => {
  const date = new Date(dt);
  if (isInvalidDate(date)) return 0;
  return Math.floor(date.getTime() / 1000);
};
