// -- basic library --
import React, { ReactNode, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, FormGroup as OriginalFormGroup, FormGroupProps, Text } from '@blueprintjs/core';
import { accountsLoginNewpasswordPostAPI } from 'admin/api/accounts';
import { authActions, authSelector } from 'admin/redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import SecurityBox from 'shared/components/atoms/SecurityBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import styled from 'styled-components';
import history from 'shared/browserHistory';
import { getLoginId } from 'shared/utils/else/localStorage';

// -- main component --

const InitialPasswordChange: React.FC = () => {
  // -- redux preparations --
  const dispatch = useDispatch();
  const auth_state = useSelector(authSelector);
  const login_id = getLoginId();

  // -- local states --
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const handlePasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(e.currentTarget.value);
  };

  const handleFinished = async (
    request_data: {
      new_password: string;
      username: string; // = login_id
      session: string;
    },
    password_confirmation: string,
  ) => {
    if (!request_data.new_password || !password_confirmation || !request_data.session || !request_data.username) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }

    if (password !== password_confirmation) {
      AlertDialog.show('新しいパスワードと新しいパスワード確認用が一致しません');
      return;
    }

    const res = await accountsLoginNewpasswordPostAPI(request_data);
    if (res.status === 200) {
      dispatch(
        authActions.signIn({
          access_key: res.data.authentication_result.access_token,
          session: res.data.session,
          login_id: request_data.username,
        }),
      );
      history.push('/top');
    }
    return;
  };

  // -- render part --
  return auth_state.is_logged_in ? (
    <Redirect to='/top' />
  ) : (
    <BackGround>
      <Wrap>
        <Title>初期パスワード変更画面</Title>
        <SignInCard>
          <FormGroup>
            <>
              <InputBoxArea>
                <SecurityBox
                  placeholder='新しいパスワード'
                  value={password}
                  onChange={handlePasswordChange}
                  id='newPassword'
                />
              </InputBoxArea>
              <InputBoxArea>
                <SecurityBox
                  placeholder='新しいパスワード確認用'
                  value={password_confirmation}
                  onChange={handlePasswordConfirmationChange}
                  id={'newPasswordConfirmation'}
                />
              </InputBoxArea>
            </>
          </FormGroup>
          <ButtonWrap>
            <EnterButton
              intent='primary'
              onClick={() =>
                handleFinished(
                  {
                    new_password: password,
                    session: auth_state.session,
                    username: login_id,
                  },
                  password_confirmation,
                )
              }
            >
              初期パスワードを変更してログイン
            </EnterButton>
          </ButtonWrap>
          <PassChange>
            <Link to='/login'>キャンセル</Link>
          </PassChange>
        </SignInCard>
      </Wrap>
      <AlertDialog />
    </BackGround>
  );
};

// -- styled components --

const FormGroup = styled((props: FormGroupProps & { children?: ReactNode }) => <OriginalFormGroup {...props} />)`
  margin: 20px 0px;
  padding: 0px 10px;
`;

const Wrap = styled.div`
  width: 400px;
  height: 220px;
  position: absolute;
  margin: auto;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
`;

const InputBoxArea = styled.div`
  margin-bottom: 15px;
`;

const Title = styled(Text)`
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: black;
  margin-bottom: 2.5rem;
`;

const PassChange = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const BackGround = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: #f5f8fa;
`;

const SignInCard = styled.div`
  width: 340px;
  height: auto;
  margin: auto;
  padding-top: 0px;
  padding-bottom: 15px;
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const EnterButton = styled(Button)`
  display: inline-block;
  width: 94%;
  text-align: center;
  margin: auto;
`;

// -- finally export part --

export default InitialPasswordChange;
