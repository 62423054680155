import { memo } from 'react';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

/**
 * ページフッター
 */
const PfFooter = memo(function PfFooter() {
  const now = new Date();
  const year = now.getFullYear();
  return <StyledFooter>&copy; {year} Intelligence Design Inc.</StyledFooter>;
});

const StyledFooter = styled.div`
  width: 100%;
  height: 35px;
  background-color: ${colors.dark_gray};
  color: white;
  display: flex;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  padding: 8px 30px;
`;

export default PfFooter;
