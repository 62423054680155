/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance, AxiosResponse } from 'axios';
import { Query, toAxiosQuery } from 'shared/models/Query';

import toMock from './toMock';

const sendAxios = <ResponseType>(
  axios: AxiosInstance,
  path: string,
  query: Query,
  form: any,
  method: string,
  mock_response: ResponseType,
): Promise<AxiosResponse<ResponseType, any>> => {
  const axios_query = toAxiosQuery(query);
  // yarn buildした時以外はモックを使用
  if (process.env.NODE_ENV !== 'production') {
    toMock(axios, path, axios_query, method, mock_response);
  }

  if (method === 'post') {
    return axios.post(path, form).then((res: AxiosResponse<ResponseType>) => res);
  } else if (method === 'put') {
    return axios.put(path, form).then((res: AxiosResponse<ResponseType>) => res);
  } else if (method === 'delete') {
    return axios.delete(path, axios_query).then((res: AxiosResponse<ResponseType>) => res);
  }
  return axios.get(path, axios_query).then((res: AxiosResponse<ResponseType>) => res);
};

// -- finally export part --
export default sendAxios;
