import { useCallback, useEffect, useRef, useState } from 'react';
import MarkdownText, { MarkdownTextProps } from './MarkdownText';
import TextAreaInput from '../atoms/TextAreaInput';
import InputComponent from './InputComponent';
import PageSwitchingTabs from './PageSwitchingTabs';
import styled from 'styled-components';
import NotificationFileBox from 'admin/components/molecules/NotificationImageFileBox';
import { ImageDataFileType } from 'shared/models';
import { notificationsImagePostAPI, notificationsImagePutAPI } from 'admin/api/notifications';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import RoundedButton from '../atoms/RoundedButton';

export interface MarkdownEditor extends MarkdownTextProps {
  onChange(content: string): void;
  image_files?: string[];
  updateImages(image_files: string[]): void;
  download_urls?: string[];
  updateUrls(download_urls: string[]): void;
  uuid?: string;
  setUuid(uuid: string): void;
}

type Mode = 'edit' | 'preview';

export default function MarkdownEditor(props: MarkdownEditor) {
  const { content } = props;
  const [mode, setMode] = useState<Mode>('edit');

  const handleTabsClick = (new_mode: string) => {
    if (new_mode !== 'edit' && new_mode !== 'preview') return;
    setMode(new_mode);
  };

  return (
    <div>
      <PageSwitchingTabs
        display_datas={[
          { id: 'edit', text: '編集' },
          { id: 'preview', text: 'プレビュー' },
        ]}
        handleClick={handleTabsClick}
        selected_id={mode}
      />
      {mode === 'edit' ? (
        <EditableTextArea {...props} />
      ) : mode === 'preview' ? (
        <MarkdownText content={content} />
      ) : null}
    </div>
  );
}

const EditableTextArea = ({
  onChange,
  content,
  updateImages,
  image_files,
  updateUrls,
  download_urls,
  uuid,
  setUuid,
}: MarkdownEditor) => {
  const textarea_ref: React.RefObject<HTMLTextAreaElement> | null = useRef(null);
  const [selection, setSelection] = useState<{ start: number; end: number }>();
  const new_image_files: ImageDataFileType[] = [];
  if (image_files) {
    image_files.forEach((image_file) => {
      const image_data: ImageDataFileType = {
        file: null,
        file_name: image_file,
      };
      new_image_files.push(image_data);
    });
  }

  const insertBrTag = () => {
    if (!textarea_ref.current) return;
    // カーソル開始位置
    const selection_start = textarea_ref.current.selectionStart;
    const br_tag = '<br>';
    // brタグをカーソル位置に挿入した新たなcontent
    const new_content = content.substring(0, selection_start) + br_tag + content.substring(selection_start);
    onChange(new_content);
    // カーソル位置を挿入したbrタグの後に変更
    const new_selection_start = selection_start + br_tag.length;
    setSelection({ start: new_selection_start, end: new_selection_start });
  };

  const insertImage = (index: number) => {
    if (!textarea_ref.current || !image_files || !download_urls) return;
    // カーソル開始位置
    const selection_start = textarea_ref.current.selectionStart;
    // URLの設定
    const url = download_urls[index];
    // ファイル名の設定
    const extension = image_files[index].split('.').pop();
    const alt = 'image' + String(index + 1) + '.' + extension;
    // 出力値設定
    const Image = `<br><img src="${url}" alt="${alt}" width="100%"><br>`;
    // 画像をカーソル位置に挿入した新たなcontent
    const new_content = content.substring(0, selection_start) + Image + content.substring(selection_start);
    onChange(new_content);
    // カーソル位置を挿入した画像の後に変更
    const new_selection_start = selection_start + Image.length;
    setSelection({ start: new_selection_start, end: new_selection_start });
  };

  // selectionの変更を監視して、textareaのカーソル位置を変更する
  const onSelectionChange = useCallback(() => {
    if (!selection) return; // prevent running on start
    if (!textarea_ref.current) return;
    const { start, end } = selection;
    textarea_ref.current.focus();
    textarea_ref.current.setSelectionRange(start, end);
  }, [selection]);

  const updateImageFile = async (index: number, image_file: ImageDataFileType) => {
    if (image_file.file === null) return;
    const out_file_name = 'image' + String(index);
    const res = await notificationsImagePostAPI({
      out_file_name: out_file_name,
      image_file: image_file,
      image_file_name: image_file.file_name,
      uuid: uuid ? uuid : '',
    });
    if (res.status === 200) {
      await notificationsImagePutAPI({
        image_data: image_file,
        upload_url: res.data.upload_url,
      });
      new_image_files.push(image_file);
      if (image_files && download_urls) {
        // すでにファイルが設定されている場合、URLとファイル名を各リストに追加
        const updatedImageFiles = [...image_files];
        updatedImageFiles[index - 1] = image_file.file_name;
        await updateImages(updatedImageFiles);
        const updatedDownloadUrls = [...download_urls];
        updatedDownloadUrls[index - 1] = res.data.download_url;
        await updateUrls(updatedDownloadUrls);
      } else {
        // ファイル未設定の場合、リストを新規作成
        const updatedImageFiles = [];
        updatedImageFiles.push(new_image_files[0].file_name);
        await updateImages(updatedImageFiles);
        const updatedDownloadUrls = [];
        updatedDownloadUrls.push(res.data.download_url);
        await updateUrls(updatedDownloadUrls);
      }
      if (uuid === '') {
        await setUuid(res.data.uuid);
      }
    }
  };

  // setSelectionRangeメソッドはuseEffect()でラップされる必要がある
  useEffect(() => {
    onSelectionChange();
  }, [onSelectionChange]);

  return (
    <InputComponent text='本文（マークダウン形式）'>
      <EditorIconsWrapper>
        <RoundedButton
          text='改行挿入'
          onClick={insertBrTag}
          small={true}
          style={{
            marginLeft: styles.interval_x_narrow_margin,
            backgroundColor: colors.image_insert_background_color,
            borderRadius: '10px',
          }}
        />
      </EditorIconsWrapper>

      <TextAreaInput
        ref={textarea_ref}
        style={{ width: '100%' }}
        rows={20}
        title='本文'
        placeholder='入力してください'
        value={content}
        onChange={(e) => onChange(e.currentTarget.value)}
      />

      <WholeArea>
        {Array.from({ length: new_image_files?.length + 1 }, (_, index) => {
          const image_file = new_image_files?.[index];
          return (
            <div style={{ display: 'flex', alignItems: 'center' }} key={index + 1}>
              <NotificationFileBox file={image_file} setFile={(new_file) => updateImageFile(index + 1, new_file)} />
              {new_image_files?.length > index && (
                <EditorIconsWrapper>
                  <RoundedButton
                    text={`画像${index + 1}挿入`}
                    onClick={() => insertImage(index)}
                    small={true}
                    style={{
                      backgroundColor: colors.image_insert_background_color,
                      borderRadius: '10px',
                    }}
                  />
                </EditorIconsWrapper>
              )}
            </div>
          );
        })}
      </WholeArea>
    </InputComponent>
  );
};

const EditorIconsWrapper = styled.div`
  height: 37px;
  display: flex;
  align-items: center;
`;

export const WholeArea = styled.div`
  max-height: 500px;
  width: 100%;
  background-color: ${colors.background_color};
  overflow-y: auto;
  padding: '20px 0px';
  margin-bottom: 0px;
`;
