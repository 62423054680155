// -- basic library --
import React, { useState } from 'react';
import { TenantForSelect } from 'admin/api/tenants';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import MultiSelectBox from 'shared/components/molecules/MultiSelectBox';
import styles from 'shared/styles/styles';

// -- type declaration --

interface Params {
  tenant_id: string;
  tenants: TenantForSelect[];
  setTenantId: (tenant_id: string) => void;
  callback?: (tenant_id: string) => void;
}

// -- main component --

/***
 * テナントを複数選択可能なコンポーネント
 * ***/
const TenantSelectBox: React.FC<Params> = (params) => {
  const [specify, setSpecify] = useState<'all' | 'select'>(params.tenant_id === 'all' ? 'all' : 'select');

  // all or selectを選んだ時の関数
  const handleSpecifyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const new_specify = e.currentTarget.value;
    let new_tenant_id = '';
    if (new_specify === 'all') {
      setSpecify(new_specify);
      new_tenant_id = 'all';
    } else if (new_specify === 'select') {
      setSpecify(new_specify);
    }
    params.setTenantId(new_tenant_id);
    if (params.callback) {
      params.callback(new_tenant_id);
    }
  };

  // tenant_idを持って、tenant_nameを取得する(ない時はtenant_idを返す)
  const getTenantName = (tenant_id: string): string => {
    const specified_tenant = params.tenants.find((tenant) => tenant.tenant_id === tenant_id);
    return specified_tenant ? specified_tenant.tenant_name : tenant_id;
  };

  // tenantのitemが選ばれた時の関数
  const onItemSelect = (item: SelectData) => {
    const new_tenant_ids = params.tenant_id.length > 0 ? params.tenant_id.split(',') : [];
    if (new_tenant_ids.indexOf(item.value) === -1) {
      new_tenant_ids.push(item.value);
      params.setTenantId(new_tenant_ids.length === 0 ? '' : new_tenant_ids.join(','));
      if (params.callback && new_tenant_ids.length > 0) {
        params.callback(new_tenant_ids.join(','));
      }
    }
  };

  // tenantのitemが削除された時の関数
  const onRemove = (item: SelectData) => {
    const tenant_ids = params.tenant_id.length > 0 ? params.tenant_id.split(',') : [];
    if (tenant_ids.indexOf(item.value) !== -1) {
      const new_tenant_ids: string[] = [];
      for (const v of tenant_ids) {
        if (v !== item.value) {
          new_tenant_ids.push(v);
        }
      }
      params.setTenantId(new_tenant_ids.length === 0 ? '' : new_tenant_ids.join(','));
      if (params.callback) {
        params.callback(new_tenant_ids.join(','));
      }
    }
  };
  // -- render part --
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SelectBox
        datas={[
          { name: '全て', value: 'all' },
          { name: '選択する', value: 'select' },
        ]}
        value={specify}
        onChange={handleSpecifyChange}
        style={{ marginRight: styles.interval_narrow_margin }}
      />
      {specify === 'select' && (
        <MultiSelectBox
          onItemSelect={onItemSelect}
          selectedItems={params.tenant_id.split(',').map((t) => {
            return {
              name: getTenantName(t),
              value: t,
            };
          })}
          items={params.tenants.map((tenant) => {
            return {
              name: tenant.tenant_name,
              value: tenant.tenant_id,
            };
          })}
          onRemove={onRemove}
        />
      )}
    </div>
  );
};

// -- finally export part --

export default TenantSelectBox;
