import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { BaseIconProps, BaseIcon } from './BaseIcon';

/**
 * 削除を表すアイコンです。
 */
export const DeleteIcon = memo(function DeleteIcon(props: Partial<BaseIconProps>) {
  return <BaseIcon {...props} icon={IconNames.TRASH} />;
});
