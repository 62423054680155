import React from 'react';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { TableBillingType } from './BillingTable';
import { isNotSelected, isSelected1 } from 'shared/utils/is';

interface ResultsTableFooterProps {
  yearmonth: string;
  selected_bodies?: TableBillingType[];
  style?: React.CSSProperties;
  onSelectedDataDownload: () => void;
  onAllDownload: () => void;
}

/**
 * 請求一覧テーブルのフッター
 */
const ResultsTableFooter = (props: ResultsTableFooterProps) => {
  return (
    <div style={{ display: 'block', ...props.style }}>
      <DLFunctionArea>
        <FunctionalText
          text={'＞チェックしたテナントのCSV一括ダウンロード'}
          onClick={props.onSelectedDataDownload}
          disabled={props.selected_bodies? isNotSelected(props.selected_bodies.length) : isNotSelected(0)}
        />
        <FunctionalText
          text={'＞全てのテナントをzip形式でダウンロード'}
          onClick={props.onAllDownload}
          disabled={isSelected1(props.yearmonth.length)}
        />
      </DLFunctionArea>
    </div>
  );
};

// -- styled components --
const DLFunctionArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${styles.interval_narrow_margin};
`;

export default ResultsTableFooter;
