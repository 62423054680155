import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { Query } from 'shared/models/Query';
import { getClient } from './base';
import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import sendDownloadAxios from 'shared/axios/sendDownloadAxios';

/*** エンティティ ***/

export interface BillingSummary {
  yearmonth: string;
  tenant_id: string;
  demand_price_summary: number;
  s3_csv_path: string;
}

export interface BillingCsv {
  csv_index: number; // 元のcsvのindexを表す。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  csv: Record<string, any>; // さまざまな属性が存在する。({列: 値}の関係)
}
export interface BillingsCsvWithPaging {
  items: BillingCsv[];
  headers: string[];
  has_next: boolean;
}
export interface BillingSummariesWithPaging {
  items: BillingSummary[];
  has_next: boolean;
}

const sample_billing_1: BillingSummary = {
  yearmonth: '202301',
  tenant_id: '00000001',
  demand_price_summary: 21500,
  s3_csv_path: 'billing/202301/00000001/202301_00000001.csv',
};

const sample_billing_2: BillingSummary = {
  yearmonth: '202301',
  tenant_id: '00000002',
  demand_price_summary: 200,
  s3_csv_path: 'billing/202301/00000002/202301_00000002.csv',
};

const sample_csv1: BillingCsv = {
  csv_index: 0,
  csv: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
  },
};

/*** Caching mechanism ***/

export class CachedBillings {
  private searched = false;
  private cache: BillingSummary[] = [];
  private params: RequestBillingSummariesGet;
  constructor(params: RequestBillingSummariesGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<BillingSummariesWithPaging> = await billingSummariesYeGetAPI({
          ...this.params,
          exclusive_start_tenant_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.tenant_id).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/billings/{yearmonth} ***/

export interface RequestBillingSummariesGet {
  yearmonth: string;
  exclusive_start_tenant_id?: string;
}  

export const billingSummariesYeGetAPI = (params: RequestBillingSummariesGet) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/billing/summaries/${params.yearmonth}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };  

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }  

  // 送信
  return sendAxios<BillingSummariesWithPaging>(axios, path, query, form, method, {
    items: [sample_billing_1, sample_billing_2],
    has_next: false,
  });  
};  

/*** [GET] /api/billings/{yearmonth}/{tenant_id} ***/

export interface RequestbillingsIdGet {
  tenant_id: string;
  yearmonth: string;
}

export const billingsIdGetAPI = (params: RequestbillingsIdGet) => {
  const { tenant_id, yearmonth } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/billings/${yearmonth}/${tenant_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<BillingSummary>(axios, path, query, form, method, sample_billing_1);
};
/*** [POST] /api/billings ***/

export interface RequestbillingsPost {
  tenant_id: string;
  tenant_name: string;
}

export const billingsPostAPI = (params: RequestbillingsPost) => {
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/billings`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {
    ...params,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<BillingSummary>(axios, path, query, form, method, sample_billing_1);
};


/*** [PUT] /api/billings/{tenant_id} ***/

export interface RequestbillingsIdPut {
  yearmonth: string;
  tenant_id: string;
  demand_price_summary: number;
}

export const billingsIdPutAPI = (params: RequestbillingsIdPut) => {
  const { tenant_id, yearmonth, demand_price_summary } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/billings/${yearmonth}/${tenant_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = { demand_price_summary };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<BillingSummary>(axios, path, query, form, method, sample_billing_1);
};

/*** [DELETE] /api/billings/{tenant_id} ***/

export interface RequestbillingsIdDelete {
  yearmonth: string;
  tenant_id: string;
}

export const billingsIdDeleteAPI = (params: RequestbillingsIdDelete) => {
  const { tenant_id, yearmonth } = params;
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/billings/${yearmonth}/${tenant_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<BillingSummary>(axios, path, query, form, method, sample_billing_1);
};

export interface RequestBillingsIdDataNumberDownloadGet extends CommonAPIRequestType {
  yearmonth: string;
  tenant_id: string;
}


export const BillingSummariesDataDownloadGetAPI = (params: RequestBillingsIdDataNumberDownloadGet) => {
  const { tenant_id } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient('blob');

  const path = `/api/billing/summaries/${params.yearmonth}/download`;
  // パス・メソッドを定義

  // [get, put]クエリストリングを定義
  const query: Query = { tenant_id };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendDownloadAxios(axios, path, query, form, new ArrayBuffer(8));
};

export interface RequestBillingsIdDataNumberDownloadZipGet extends CommonAPIRequestType {
  yearmonth: string;
}


export const BillingSummariesDataDownloadZipGetAPI = (params: RequestBillingsIdDataNumberDownloadZipGet) => {
  const { yearmonth } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient('blob');

  const path = `/api/billing/summaries/${params.yearmonth}/download/zip`;
  // パス・メソッドを定義

  // [get, put]クエリストリングを定義
  const query: Query = { yearmonth };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendDownloadAxios(axios, path, query, form, new ArrayBuffer(8));
};

export interface RequestBillingSummariesCsvGet extends CommonAPIRequestType {
  yearmonth: string;
  tenant_id: string;
  original_file_name?: string;
  start_csv_index?: number; // csvの取得を始めるindex
  acquisitions?: number; // csvを取得する件数
}

// /*** [GET] /api/billing/summaries/{yearmonth}/csv ***/
/*** バッチで作成されたCSVをJSON形式で取得できるAPI ***/

export const billingSummariesYeCsvGetAPI = (params: RequestBillingSummariesCsvGet) => {
  const {
    yearmonth,
    tenant_id,
    start_csv_index,
    acquisitions,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient('json');

  // パス・メソッドを定義
  const path = `/api/billing/summaries/${yearmonth}/csv`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    tenant_id,
    start_csv_index,
    acquisitions,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<BillingsCsvWithPaging>(axios, path, query, form, method, {
    items: Array(50)
      .fill(null)
      .map((_, i) => {
        const d = { ...sample_csv1 };
        d.csv_index = i;
        return d;
      }),
    headers: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
    has_next: false,
  });
};
