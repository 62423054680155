import { Process } from 'admin/api/processes';
import { ProcessFlow, ProcessFlowStep } from 'admin/api/processflows';
import { isAppParameterJsonpath } from 'shared/utils/is';
// import checkAppParameterRequired from "./checkAppParameterRequired";

/***
 * プロセスフローのstepsをチェックする
 * ***/
export const checkProcessFlowSteps = (steps: ProcessFlow['steps'], processes: Process[]) => {
  if (steps.length <= 0) {
    return false;
  }
  // 条件を満たしているか。1つのstepでもFalseだったらFalse
  let flag = true;
  for (let i = 0; i < steps.length; i++) {
    flag = flag && checkProcessFlowStep(steps[i], processes);
    if (flag === false) break;
  }
  return flag;
};

/***
 * プロセスフローのstepをチェックする(steps[i]をチェックする)
 * ***/

export const checkProcessFlowStep = (step: ProcessFlowStep, processes: Process[]) => {
  // process_idの存在条件
  const flag1 = step.process_id.trim().length > 0;
  // 指定のプロセス
  const process = processes.find((pr) => pr.process_id === step.process_id);
  // jsonpathのapp_parameterの条件
  const flag2 = isAppParameterJsonpath(step.app_parameter, process?.app_parameters);
  // ストリームを選択したか(空白の入力はカウントしない)
  const flag3 =
    step.input_streams.filter((ist) => ist.trim().length > 0).length ===
    (process?.input_stream_constraints.length || 0);
  return flag1 && flag2 && flag3;
};
