// -- basic library --

import React from 'react';
// -- external components --
import {
  InputStreamConstraint,
  OutputStreamConstraint,
  ProcessEcsparameter,
  processesPostAPI,
  RequestProcessesPost,
} from 'admin/api/processes';
import { CachedTenants, TenantForSelect } from 'admin/api/tenants';
import TenantSelectBox from 'admin/components/molecules/TenantSelectBox';
import history from 'shared/browserHistory';
import { FormContainer } from 'shared/components/atoms/FormContainer';
import InputBox from 'shared/components/atoms/InputBox';
import RadioBox from 'shared/components/molecules/RadioBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import Slider from 'shared/components/atoms/Slider';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import MultiSelectBox from 'shared/components/molecules/MultiSelectBox';
import AppParameterDialog, { AppParameterDialogFields } from 'shared/dialogs/AppParameterDialog';
import HeartbeatsDialog, { HeartbeatsDialogFields } from 'shared/dialogs/HeartbeatsDialog';
import { isNotOnlySpace, isValidNumber } from 'shared/utils/is';
import AppParameterTable, { TableAppParameterType } from '../components/AppParameterTable';
import HeartbeatTable, { TableHeartbeatType } from '../components/HeartbeatTable';
import InstanceForm from '../components/InstanceForm';
import { toStringBoolean } from 'shared/models/StringBoolean';
import { isProcessingTenancy, ProcessingTenancy } from 'shared/models/process/ProcessingTenancy';
import TextAreaInput from 'shared/components/atoms/TextAreaInput';
import { CheckBoxArea } from 'shared/components/atoms/PfTable';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import {
  convertProcessType,
  convertProcessTypesToProcessSelectDatas,
  ProcessType,
  processTypeToProcessUnitPrice,
} from 'shared/models/ProcessType';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import { RuntimeType } from 'shared/models/process/RuntimeType';

// -- external functions --

// -- external datas --
const output_stream_data_types: string[] = ['IMAGE', 'VIDEO', 'AUDIO', 'METRIC', 'PDF'];
const output_stream_data_number_types: string[] = ['TIMESTAMP', 'SEQUENCE', 'USER_SPECIFIC'];

// -- main component --
interface InformationRegisterPanelProps {}
interface InformationRegisterPanelState {
  tenant_id: string;
  tenants: TenantForSelect[];
  process_name: string;
  input_stream_constraints: InputStreamConstraint[];
  input_sync: boolean;
  output_stream_constraints: OutputStreamConstraint[];
  app_parameters: {
    key: string;
    name: string;
    description: string;
    default_value: string;
    input_type: string;
    list_items: string;
    required: boolean;
    validate_regex: string;
    is_hidden: boolean;
  }[];
  heartbeats: {
    key: string;
    name: string;
    description: string;
    default_schedule: string;
    additionals: string;
    required: boolean;
    is_hidden: boolean;
  }[];
  processing_tenancy: ProcessingTenancy;
  app_module: string;
  app_class: string;
  runtime_type: RuntimeType;
  ecs_parameter: ProcessEcsparameter;
  selected_app_parameter_datas: TableAppParameterType[];
  app_parameter_data: TableAppParameterType[];
  is_app_parameter_dialog_open: boolean;
  app_parameter_dialog_fields: AppParameterDialogFields;
  selected_heartbeat_datas: TableHeartbeatType[];
  heartbeat_data: TableHeartbeatType[];
  is_heartbeat_dialog_open: boolean;
  heartbeat_dialog_fields: HeartbeatsDialogFields;
  image_tags: string[];
  ui_visible: boolean;
  is_billing: boolean;
  process_type: ProcessType | '';
  unit_price: number;
  memo: string;
  order: number;
}
export default class InformationRegisterPanel extends React.PureComponent<
  InformationRegisterPanelProps,
  InformationRegisterPanelState
> {
  constructor(props: InformationRegisterPanelProps) {
    super(props);
    this.state = {
      tenant_id: 'all',
      tenants: [],
      process_name: '',
      input_stream_constraints: [],
      input_sync: false,
      output_stream_constraints: [],
      app_parameters: [],
      heartbeats: [],
      processing_tenancy: 'Stateful',
      app_module: '',
      app_class: '',
      runtime_type: 'ECS',
      ecs_parameter: {
        image: '',
        cpu: 512,
        memory: 1024,
        gpu: 0,
        cpu_arch: 'X86_64',
        compatibilities: ['EC2', 'FARGATE'],
        capacity_providers: [],
        vpc: '',
        security_group_name: '',
        max_tasks: 0,
      },
      selected_app_parameter_datas: [],
      app_parameter_data: [],
      is_app_parameter_dialog_open: false,
      app_parameter_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_value: '',
        input_type: '',
        list_items: '',
        required: false,
        validate_regex: '',
        is_hidden: false,
      },
      selected_heartbeat_datas: [],
      heartbeat_data: [],
      is_heartbeat_dialog_open: false,
      heartbeat_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_schedule: '',
        additionals: '',
        required: false,
        is_hidden: false,
      },
      image_tags: [],
      ui_visible: true,
      is_billing: false,
      process_type: '',
      unit_price: 0,
      memo: '',
      order: NaN,
    };
  }
  async componentDidMount() {
    // テナント一覧の取得
    this.setState({ tenants: [...(await new CachedTenants({}).get())] });
  }
  setBilling = (is_billing: boolean) => {
    // 課金対象の設定
    this.setState({
      is_billing: is_billing,
    });
    // OFFの場合、プロセスタイプと単価をリセット
    if (!is_billing) {
      this.setState({
        process_type: '',
        unit_price: 0,
      });
    }
  };
  handleBillingChange = (process_type: string) => {
    // 課金情報の設定
    this.setState({
      process_type: convertProcessType(process_type),
      unit_price: processTypeToProcessUnitPrice(convertProcessType(process_type)),
    });
  };
  /** 入力ストリーム数に応じて入力制約を調節します。 */
  handleInputStreamCountChange = (count: number) => {
    const next_constraints = [...this.state.input_stream_constraints];
    // カットダウン
    while (count < next_constraints.length) {
      next_constraints.pop();
    }
    // グローアップ
    while (next_constraints.length < count) {
      next_constraints.push({
        data_type: 'VIDEO,IMAGE,METRIC,AUDIO,PDF',
        data_number_type: 'TIMESTAMP,SEQUENCE,USER_SPECIFIC',
        max_repeats: 1,
        no_message: false,
      });
    }
    this.setState({ input_stream_constraints: next_constraints });
  };
  handleInputStreamDataTypeChange = (index: number, item: SelectData) => {
    const next_constraints = [...this.state.input_stream_constraints];
    const c = next_constraints[index];
    if (c.data_type.indexOf(item.value) === -1) {
      if (c.data_type.length === 0) {
        c.data_type = item.value;
      } else {
        c.data_type += ',' + item.value;
      }
      this.setState({ input_stream_constraints: next_constraints });
    }
  };
  handleInputStreamDataTypeRemove = (index: number, item: SelectData) => {
    const next_constraints = [...this.state.input_stream_constraints];
    const c = next_constraints[index];
    if (c.data_type.indexOf(item.value) !== -1) {
      const a = [];
      for (const v of c.data_type.split(',')) {
        if (v !== item.value) {
          a.push(v);
        }
      }
      c.data_type = a.length === 0 ? '' : a.join(',');
      this.setState({ input_stream_constraints: next_constraints });
    }
  };
  handleInputStreamDataNumberTypeChange = (index: number, item: SelectData) => {
    const next_constraints = [...this.state.input_stream_constraints];
    const c = next_constraints[index];
    if (c.data_number_type.indexOf(item.value) === -1) {
      if (c.data_number_type.length === 0) {
        c.data_number_type = item.value;
      } else {
        c.data_number_type += ',' + item.value;
      }
      this.setState({ input_stream_constraints: next_constraints });
    }
  };
  handleInputStreamDataNumberTypeRemove = (index: number, item: SelectData) => {
    const next_constraints = [...this.state.input_stream_constraints];
    const c = next_constraints[index];
    if (c.data_number_type.indexOf(item.value) !== -1) {
      const a = [];
      for (const v of c.data_number_type.split(',')) {
        if (v !== item.value) {
          a.push(v);
        }
      }
      c.data_number_type = a.length === 0 ? '' : a.join(',');
      this.setState({ input_stream_constraints: next_constraints });
    }
  };
  handleInputStreamNoMessageChange = (index: number, value: boolean) => {
    const next_constraints = [...this.state.input_stream_constraints];
    next_constraints[index].no_message = value;
    this.setState({ input_stream_constraints: next_constraints });
  };
  handleInputStreamMaxRepeatsChange = (index: number, value: number) => {
    const next_constraints = [...this.state.input_stream_constraints];
    next_constraints[index].max_repeats = value;
    this.setState({ input_stream_constraints: next_constraints });
  };
  /** 出力ストリーム数の変更 */
  handleOutputStreamCountChange = (value: number) => {
    const next_constraints = [...this.state.output_stream_constraints];
    while (next_constraints.length < value) {
      next_constraints.push({
        data_type: '',
        data_number_type: '',
        repeat: false,
      });
    }
    while (value < next_constraints.length) {
      next_constraints.pop();
    }
    this.setState({ output_stream_constraints: next_constraints });
  };
  handleOutputStreamDataTypesChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const next_constraints = [...this.state.output_stream_constraints];
    next_constraints[index].data_type = e.currentTarget.value;
    this.setState({ output_stream_constraints: next_constraints });
  };
  handleOutputStreamDataNumberTypesChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const next_constraints = [...this.state.output_stream_constraints];
    next_constraints[index].data_number_type = e.currentTarget.value;
    this.setState({ output_stream_constraints: next_constraints });
  };
  handleOutputStreamDataRepeatChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const next_constraints = [...this.state.output_stream_constraints];
    next_constraints[index].repeat = e.currentTarget.value === 'TRUE';
    this.setState({ output_stream_constraints: next_constraints });
  };
  createOutputStreamDataTypes = () => {
    const ret: SelectData[] = [];
    for (const v of output_stream_data_types) {
      ret.push({
        name: v,
        value: v,
      });
    }
    for (let i = 0; i < this.state.input_stream_constraints.length; i++) {
      ret.push({
        name: '入力ストリーム(' + (i + 1) + ') と同じ',
        value: 'TRANSFER:' + i,
      });
    }
    return ret;
  };
  createOutputStreamDataNumberTypes = () => {
    const ret: SelectData[] = [];
    for (const v of output_stream_data_number_types) {
      ret.push({
        name: v,
        value: v,
      });
    }
    for (let i = 0; i < this.state.input_stream_constraints.length; i++) {
      ret.push({
        name: '入力ストリーム(' + (i + 1) + ') と同じ',
        value: 'TRANSFER:' + i,
      });
    }
    return ret;
  };
  handleAppParameterClick = (datas: TableAppParameterType[]) => {
    this.setState({ selected_app_parameter_datas: datas });
  };
  handleAppParameterCreateClick = () => {
    // history.push(`/processes/create`);
    this.setState({
      app_parameter_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_value: '',
        input_type: '',
        list_items: '',
        required: false,
        validate_regex: '',
        is_hidden: false,
      },
      is_app_parameter_dialog_open: true,
    });
  };
  handleAppParameterSetClick = (key: string) => {
    // history.push(`/processes/${process_id}/set`);
    for (const p of this.state.app_parameter_data) {
      if (p.key.value === key) {
        this.setState({
          app_parameter_dialog_fields: {
            key: p.key.value,
            name: p.name,
            description: p.description,
            default_value: p.default_value,
            input_type: p.input_type,
            list_items: p.list_items,
            required: p.required === 'true',
            validate_regex: p.validate_regex,
            is_hidden: p.is_hidden === 'true',
          },
          is_app_parameter_dialog_open: true,
        });
      }
    }
  };
  handleAppParameterDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されている項目を削除します。\n本当によろしいですか？',
      () => this.handleAppParameterDeleteOKClick(this.state.selected_app_parameter_datas),
      this.handleAppParameterDeleteCancelClick,
      undefined,
    );
  };
  handleHeartbeatClick = (datas: TableHeartbeatType[]) => {
    this.setState({ selected_heartbeat_datas: datas });
  };
  handleHeartbeatCreateClick = () => {
    this.setState({
      heartbeat_dialog_fields: {
        key: '',
        name: '',
        description: '',
        default_schedule: '',
        additionals: '',
        required: false,
        is_hidden: false,
      },
      is_heartbeat_dialog_open: true,
    });
  };
  handleHeartbeatSetClick = (key: string) => {
    // history.push(`/processes/${process_id}/set`);
    for (const p of this.state.heartbeat_data) {
      if (p.key.value === key) {
        this.setState({
          heartbeat_dialog_fields: {
            key: p.key.value,
            name: p.name,
            description: p.description,
            default_schedule: p.default_schedule,
            additionals: p.additionals,
            required: p.required === 'true',
            is_hidden: p.is_hidden === 'true',
          },
          is_heartbeat_dialog_open: true,
        });
      }
    }
  };
  handleHeartbeatDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されている項目を削除します。\n本当によろしいですか？',
      () => this.handleHeartbeatDeleteOKClick(this.state.selected_heartbeat_datas),
      this.handleHeartbeatDeleteCancelClick,
      undefined,
    );
  };
  handleAppParameterDeleteOKClick = async (selected_datas: TableAppParameterType[]) => {
    const aft: TableAppParameterType[] = [];
    for (const p of this.state.app_parameter_data) {
      let f = true;
      for (const d of selected_datas) {
        if (d.key.value === p.key.value) {
          f = false;
        }
      }
      if (f) aft.push(p);
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_value: e.default_value,
        input_type: e.input_type,
        list_items: e.list_items,
        required: e.required === 'true',
        validate_regex: e.validate_regex,
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({
      selected_app_parameter_datas: [],
      app_parameter_data: aft,
      app_parameters: aft2,
    });
  };
  handleAppParameterDeleteCancelClick = () => {
    // do nothing
  };
  handleHeartbeatDeleteOKClick = async (selected_datas: TableHeartbeatType[]) => {
    const aft: TableHeartbeatType[] = [];
    for (const p of this.state.heartbeat_data) {
      let f = true;
      for (const d of selected_datas) {
        if (d.key === p.key) {
          f = false;
        }
      }
      if (f) aft.push(p);
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_schedule: e.default_schedule,
        additionals: e.additionals,
        required: e.required === 'true',
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({
      selected_heartbeat_datas: [],
      heartbeat_data: aft,
      heartbeats: aft2,
    });
  };
  handleHeartbeatDeleteCancelClick = () => {
    // do nothing
  };
  handleAppParameterDialogOkClose = (e: React.MouseEvent<HTMLElement>, f: AppParameterDialogFields) => {
    let find = false;
    for (const p of this.state.app_parameter_data) {
      if (p.key.value === f.key) {
        p.name = f.name;
        p.description = f.description;
        p.default_value = f.default_value;
        p.input_type = f.input_type;
        p.list_items = f.list_items;
        p.required = f.required ? 'true' : 'false';
        p.validate_regex = f.validate_regex;
        p.is_hidden = f.is_hidden ? 'true' : 'false';
        find = true;
        break;
      }
    }
    const aft = [...this.state.app_parameter_data];
    if (!find) {
      aft.push({
        id: f.key,
        key: {
          value: f.key,
          onClick: () => this.handleAppParameterSetClick(f.key),
        },
        name: f.name,
        description: f.description,
        default_value: f.default_value,
        input_type: f.input_type,
        list_items: f.list_items,
        required: f.required ? 'true' : 'false',
        validate_regex: f.validate_regex,
        is_hidden: f.is_hidden ? 'true' : 'false',
      });
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_value: e.default_value,
        input_type: e.input_type,
        list_items: e.list_items,
        required: e.required === 'true',
        validate_regex: e.validate_regex,
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({ app_parameter_data: aft, app_parameters: aft2, is_app_parameter_dialog_open: false });
  };
  handleAppParameterDialogCancelClose = () => {
    this.setState({ is_app_parameter_dialog_open: false });
  };
  handleHeartbeatDialogOkClose = (e: React.MouseEvent<HTMLElement>, f: HeartbeatsDialogFields) => {
    let find = false;
    for (const p of this.state.heartbeat_data) {
      if (p.key.value === f.key) {
        p.name = f.name;
        p.description = f.description;
        p.default_schedule = f.default_schedule;
        p.additionals = f.additionals;
        p.required = f.required ? 'true' : 'false';
        p.is_hidden = f.is_hidden ? 'true' : 'false';
        find = true;
        break;
      }
    }
    const aft = [...this.state.heartbeat_data];
    if (!find) {
      aft.push({
        id: f.key,
        key: {
          value: f.key,
          onClick: () => this.handleHeartbeatSetClick(f.key),
        },
        name: f.name,
        description: f.description,
        default_schedule: f.default_schedule,
        additionals: f.additionals,
        required: f.required ? 'true' : 'false',
        is_hidden: f.is_hidden ? 'true' : 'false',
      });
    }
    const aft2 = aft.map((e) => {
      return {
        id: e.key,
        key: e.key.value,
        name: e.name,
        description: e.description,
        default_schedule: e.default_schedule,
        additionals: e.additionals,
        required: e.required === 'true',
        is_hidden: e.is_hidden === 'true',
      };
    });
    this.setState({ heartbeat_data: aft, heartbeats: aft2, is_heartbeat_dialog_open: false });
  };
  handleHeartbeatDialogCancelClose = () => {
    this.setState({ is_heartbeat_dialog_open: false });
  };
  handleMainCancelClick = () => {
    history.push('/processes');
  };
  handleInstanceFormChange = (runtimeType: RuntimeType, ecsParameter: ProcessEcsparameter) => {
    this.setState({
      runtime_type: runtimeType,
      ecs_parameter: ecsParameter,
    });
  };

  handleMainFinishClick = async () => {
    const {
      tenant_id,
      process_name,
      app_module,
      app_class,
      runtime_type,
      ecs_parameter,
      input_sync,
      ui_visible,
      input_stream_constraints,
      output_stream_constraints,
      app_parameters,
      heartbeats,
      processing_tenancy,
      is_billing,
      process_type,
      unit_price,
      memo,
      order,
    } = this.state;
    if (!Number.isNaN(order) && !Number.isInteger(order)) {
      AlertDialog.show('オーダーを入力する場合は、整数でなければなりません');
      return;
    }
    if (
      !isNotOnlySpace(tenant_id) ||
      !isNotOnlySpace(process_name) ||
      !isNotOnlySpace(app_module) ||
      !isNotOnlySpace(app_class) ||
      !InstanceForm.validate(runtime_type, ecs_parameter)
    ) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }

    if (this.state.is_billing && this.state.process_type === '') {
      AlertDialog.show('プロセスタイプが選択されていません。');
      return;
    }
    const is_ecs = runtime_type === 'ECS';

    let processes_post_data: RequestProcessesPost = {
      tenant_id,
      process_name,
      input_sync: toStringBoolean(input_sync),
      input_stream_constraints,
      output_stream_constraints,
      app_parameters,
      heartbeats,
      processing_tenancy,
      app_module,
      app_class,
      runtime_type,
      ui_visible: toStringBoolean(ui_visible),
      is_billing: toStringBoolean(is_billing),
      process_type,
      unit_price,
      memo,
      order: isValidNumber({ num: order, integer: true, allow0: true }) ? order : undefined,
    };
    if (is_ecs) {
      processes_post_data = {
        ...processes_post_data,
        ecs_image: ecs_parameter.image,
        ecs_cpu: ecs_parameter.cpu,
        ecs_memory: ecs_parameter.memory,
        ecs_gpu: ecs_parameter.gpu,
        ecs_cpu_arch: ecs_parameter.cpu_arch,
        ecs_compatibilities: ecs_parameter.compatibilities.join(),
        ecs_capacity_providers: ecs_parameter.capacity_providers.join(),
        ecs_vpc: ecs_parameter.vpc,
        ecs_security_group_name: ecs_parameter.security_group_name,
        ecs_max_tasks: ecs_parameter.max_tasks,
      };
    }

    const res = await processesPostAPI(processes_post_data);
    if (res.status === 200) {
      AlertDialog.show('プロセスの追加に成功しました');
      history.push('/processes');
    }
  };
  render() {
    return (
      <>
        <Content>
          <FormContainer title='基本情報'>
            <InputComponent text='テナント' required>
              <TenantSelectBox
                tenant_id={this.state.tenant_id}
                tenants={this.state.tenants}
                setTenantId={(tenant_id) =>
                  this.setState({
                    tenant_id: tenant_id,
                  })
                }
              />
            </InputComponent>
            <InputComponent text='プロセス名' required>
              <InputBox
                placeholder='入力してください(必須)'
                value={this.state.process_name}
                onChange={(e) => this.setState({ process_name: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='テナント可視性' required>
              <SelectBox
                onChange={(e) => this.setState({ ui_visible: e.currentTarget.value === 'true' })}
                value={this.state.ui_visible ? 'true' : 'false'}
                datas={[
                  { name: 'チャンネルプロセス画面で選択可能', value: 'true' },
                  { name: 'チャンネルプロセス画面で選択できない', value: 'false' },
                ]}
              />
            </InputComponent>
            <InputComponent text='課金対象'>
              <CheckBoxArea>
                <CheckBoxWithText
                  text='設定する'
                  checked={this.state.is_billing}
                  onClick={() => this.setBilling(!this.state.is_billing)}
                />
              </CheckBoxArea>
            </InputComponent>
            {this.state.is_billing && (
              <InputComponent text='プロセスタイプ'>
                <SelectBox
                  onChange={(e) => this.handleBillingChange(e.currentTarget.value)}
                  value={this.state.process_type}
                  datas={convertProcessTypesToProcessSelectDatas()}
                />
              </InputComponent>
            )}
            <InputComponent text='メモ'>
              <TextAreaInput
                style={{ width: '100%' }}
                rows={1}
                title='メモ'
                placeholder='入力してください(任意)'
                value={this.state.memo}
                onChange={(e) =>
                  this.setState({
                    memo: e.currentTarget.value,
                  })
                }
              />
            </InputComponent>
            <InputComponent text='オーダー'>
              <InputNumberBox
                value={this.state.order}
                onChange={(value) =>
                  this.setState({
                    order: value,
                  })
                }
              />
            </InputComponent>
          </FormContainer>

          <FormContainer title='入出力情報' description='プロセスの入力と出力'>
            <InputComponent text='入力ストリーム数' required>
              <Slider
                value={this.state.input_stream_constraints.length}
                onChange={this.handleInputStreamCountChange}
                min={0}
                max={10}
              />
            </InputComponent>

            {this.state.input_stream_constraints.map((c, index) => {
              return (
                <div key={index}>
                  <InputComponent text={`入力ストリームデータの種類(${index + 1})`} required>
                    <MultiSelectBox
                      onItemSelect={(item) => this.handleInputStreamDataTypeChange(index, item)}
                      selectedItems={c.data_type.split(',').map((d) => {
                        return {
                          name: d,
                          value: d,
                        };
                      })}
                      items={[
                        {
                          name: 'VIDEO',
                          value: 'VIDEO',
                        },
                        {
                          name: 'IMAGE',
                          value: 'IMAGE',
                        },
                        {
                          name: 'METRIC',
                          value: 'METRIC',
                        },
                        {
                          name: 'AUDIO',
                          value: 'AUDIO',
                        },
                        {
                          name: 'PDF',
                          value: 'PDF',
                        },
                      ]}
                      onRemove={(item) => this.handleInputStreamDataTypeRemove(index, item)}
                    />
                  </InputComponent>
                  <InputComponent text={`入力ストリームデータ番号の基準(${index + 1})`} required>
                    <MultiSelectBox
                      onItemSelect={(item) => this.handleInputStreamDataNumberTypeChange(index, item)}
                      selectedItems={c.data_number_type.split(',').map((d) => {
                        return {
                          name: d,
                          value: d,
                        };
                      })}
                      items={[
                        {
                          name: 'TIMESTAMP',
                          value: 'TIMESTAMP',
                        },
                        {
                          name: 'SEQUENCE',
                          value: 'SEQUENCE',
                        },
                        {
                          name: 'USER_SPECIFIC',
                          value: 'USER_SPECIFIC',
                        },
                      ]}
                      onRemove={(item) => this.handleInputStreamDataNumberTypeRemove(index, item)}
                    />
                  </InputComponent>
                  <InputComponent text={`入力ストリームの通知(${index + 1}) `} required>
                    <SelectBox
                      onChange={(e) => this.handleInputStreamNoMessageChange(index, e.currentTarget.value === 'true')}
                      value={c.no_message ? 'true' : 'false'}
                      datas={[
                        { name: '通知する', value: 'false' },
                        { name: '通知しない', value: 'true' },
                      ]}
                    />
                  </InputComponent>
                  <InputComponent text={`最大数(${index + 1}) `} required>
                    <Slider
                      value={c.max_repeats}
                      onChange={(value) => this.handleInputStreamMaxRepeatsChange(index, value)}
                      min={1}
                      max={10}
                    />
                  </InputComponent>
                </div>
              );
            })}
            <InputComponent text='入力データの同期'>
              <SelectBox
                onChange={(e) => this.setState({ input_sync: e.currentTarget.value === 'true' })}
                value={this.state.input_sync ? 'true' : 'false'}
                datas={[
                  { name: '同期する', value: 'true' },
                  { name: '同期しない', value: 'false' },
                ]}
              />
            </InputComponent>
            <InputComponent text={`出力ストリーム数`} required>
              <Slider
                value={this.state.output_stream_constraints.length}
                onChange={this.handleOutputStreamCountChange}
                min={0}
                max={10}
              />
            </InputComponent>

            {this.state.output_stream_constraints.map((c, index) => {
              return (
                <div key={index}>
                  <InputComponent text={`出力ストリームデータの種類(${index + 1}): `} required key={index}>
                    <SelectBox
                      onChange={(e) => this.handleOutputStreamDataTypesChange(e, index)}
                      value={c.data_type}
                      datas={this.createOutputStreamDataTypes()}
                    />
                  </InputComponent>
                  <InputComponent text={`出力ストリームデータ番号の基準(${index + 1}): `} required key={index}>
                    <SelectBox
                      onChange={(e) => this.handleOutputStreamDataNumberTypesChange(e, index)}
                      value={c.data_number_type}
                      datas={this.createOutputStreamDataNumberTypes()}
                    />
                  </InputComponent>
                  {(c.data_type.startsWith('TRANSFER') || c.data_number_type.startsWith('TRANSFER')) && (
                    <InputComponent text={`出力ストリームの繰り返し(${index + 1}): `} required key={index}>
                      <SelectBox
                        onChange={(e) => this.handleOutputStreamDataRepeatChange(e, index)}
                        value={c.repeat ? 'TRUE' : 'FALSE'}
                        datas={[
                          { name: 'FALSE', value: 'FALSE' },
                          { name: 'TRUE', value: 'TRUE' },
                        ]}
                      />
                    </InputComponent>
                  )}
                </div>
              );
            })}
          </FormContainer>

          <FormContainer title='アプリケーション情報' description='プロセスで実行されるアプリケーション'>
            <InputComponent text='テナンシー' required>
              <RadioBox
                datas={[
                  { name: '専有(Stateful)', value: 'Stateful' },
                  { name: '共有(Stateless)', value: 'Stateless' },
                ]}
                selectedValue={this.state.processing_tenancy}
                handleChangeClick={(value) => {
                  if (isProcessingTenancy(value)) {
                    this.setState({ processing_tenancy: value });
                  }
                }}
              />
            </InputComponent>
            <InputComponent text='アプリモジュール名' required>
              <InputBox
                title='アプリモジュール名'
                placeholder='入力してください(必須)'
                value={this.state.app_module}
                onChange={(e) => this.setState({ app_module: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='アプリクラス名' required>
              <InputBox
                title='アプリクラス名'
                placeholder='入力してください(必須)'
                value={this.state.app_class}
                onChange={(e) => this.setState({ app_class: e.currentTarget.value })}
              />
            </InputComponent>
            <InputComponent text='アプリパラメータ'>
              <AppParameterTable
                bodies={this.state.app_parameter_data}
                selected_bodies={this.state.selected_app_parameter_datas}
                handleCheckClick={this.handleAppParameterClick}
                buttons={[
                  {
                    text: '削除',
                    onClick: this.handleAppParameterDeleteClick,
                    is_white: true,
                    is_margin_right: true,
                  },
                  {
                    text: '追加',
                    onClick: this.handleAppParameterCreateClick,
                  },
                ]}
              />
            </InputComponent>
            <InputComponent text='ハートビート'>
              <HeartbeatTable
                bodies={this.state.heartbeat_data}
                selected_bodies={this.state.selected_heartbeat_datas}
                handleCheckClick={this.handleHeartbeatClick}
                buttons={[
                  {
                    text: '削除',
                    onClick: this.handleHeartbeatDeleteClick,
                    is_white: true,
                    is_margin_right: true,
                  },
                  {
                    text: '追加',
                    onClick: this.handleHeartbeatCreateClick,
                  },
                ]}
              />
            </InputComponent>
          </FormContainer>

          <InstanceForm
            processing_tenancy={this.state.processing_tenancy}
            runtimeType={this.state.runtime_type}
            ecsParameter={this.state.ecs_parameter}
            onChange={this.handleInstanceFormChange}
          />
          {this.state.is_app_parameter_dialog_open && (
            <AppParameterDialog
              title='アプリパラメータ'
              isOpen={this.state.is_app_parameter_dialog_open}
              fields={this.state.app_parameter_dialog_fields}
              isKeyFix={this.state.app_parameter_dialog_fields.key !== ''}
              handleOkClose={this.handleAppParameterDialogOkClose}
              handleCancelClose={this.handleAppParameterDialogCancelClose}
            />
          )}
          {this.state.is_heartbeat_dialog_open && (
            <HeartbeatsDialog
              title='ハートビート'
              isOpen={this.state.is_heartbeat_dialog_open}
              fields={this.state.heartbeat_dialog_fields}
              isKeyFix={this.state.heartbeat_dialog_fields.key !== ''}
              handleOkClose={this.handleHeartbeatDialogOkClose}
              handleCancelClose={this.handleHeartbeatDialogCancelClose}
            />
          )}
        </Content>
        <Footer>
          <RoundedButton onClick={this.handleMainCancelClick} text='キャンセル' is_white is_margin_right />
          <RoundedButton
            onClick={this.handleMainFinishClick}
            text='登録'
            disabled={
              this.state.input_stream_constraints.length <= 10 &&
              this.state.output_stream_constraints.length <= 10 &&
              isNotOnlySpace(this.state.process_name) &&
              isNotOnlySpace(this.state.app_module) &&
              isNotOnlySpace(this.state.app_class) &&
              InstanceForm.validate(this.state.runtime_type, this.state.ecs_parameter)
                ? false
                : true
            }
          />
        </Footer>
      </>
    );
  }
}

// -- styled components --
