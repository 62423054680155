// -- basic library --
import React from 'react';

// -- external components --
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyMultipleValueType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { table_cell_toggle_button_width } from 'shared/styles/styles';

// -- type declaration --

interface Params {
  bodies: TableDashboardType[];
  selected_bodies: TableDashboardType[];
  handleCheckClick: (datas: TableDashboardType[]) => void;
}

export interface TableDashboardType {
  id: string;
  tenant_id: string;
  tenant_name: string;
  dashboard_id: string;
  dashboard_name: string;
  created_at: string;
  is_billing: TableBodyMultipleValueType<string>;
}

const head_cells: TableHeaderType[] = [
  {
    id: 'tenant_id',
    label: 'テナントID',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'tenant_name',
    label: 'テナント名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'dashboard_id',
    label: 'ダッシュボードID',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'dashboard_name',
    label: 'ダッシュボード名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'created_at',
    label: '作成日',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'is_billing',
    label: '課金対象',
    sortable: true,
    width: table_cell_toggle_button_width,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const DashboardTable: React.FC<Params> = (params) => {
  // -- render part --
  return <BaseTable {...params} table_name='dashboards' headers={head_cells} />;
};

// -- styled components --

// -- finally export part --
export default DashboardTable;
