import React from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { PfDialog } from '../atoms/PfDialog';

/**
 * 単一項目のみ入力させるシンプルな入力ダイアログです。
 * シングルトンインスタンスを保持するため、画面上に２つ以上配置しないでください。
 * ダイアログの呼び出し方:
 *  InputDialog.show(...);
 */
interface InputDialogProps {}
interface InputDialogState {
  isOpen: boolean;
  title: string;
  message: string | JSX.Element | string[];
  inputLabel: string;
  inputValue: string;
  callback?: (value: string | undefined, isCancel: boolean) => void;
}
export default class InputDialog extends React.PureComponent<InputDialogProps, InputDialogState> {
  static instance?: InputDialog;
  constructor(props: InputDialogProps) {
    super(props);
    this.state = {
      isOpen: false,
      title: '',
      message: '',
      inputLabel: '',
      inputValue: '',
    };
  }
  componentDidMount() {
    InputDialog.instance = this;
  }
  componentWillUnmount() {
    InputDialog.instance = undefined;
  }
  onClose = () => {
    this.setState({ isOpen: false });
    if (this.state.callback) {
      this.state.callback(undefined, true);
    }
  };
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: e.currentTarget.value });
  };
  onCancelClick = () => {
    this.setState({ isOpen: false });
    if (this.state.callback) {
      this.state.callback(undefined, true);
    }
  };
  onOkClick = () => {
    this.setState({ isOpen: false });
    if (this.state.callback) {
      this.state.callback(this.state.inputValue, false);
    }
  };
  static show(
    title: string,
    message: string | JSX.Element | string[],
    inputLabel: string,
    inputValue: string,
    callback?: (value: string | undefined, isCancel: boolean) => void,
  ) {
    if (InputDialog.instance) {
      InputDialog.instance.setState({
        isOpen: true,
        title: title,
        message: message,
        inputLabel: inputLabel,
        inputValue: inputValue,
        callback: callback,
      });
    } else {
      // promptで代替
      const value = prompt(String(message), inputValue);
      if (callback) {
        if (value === null) {
          callback(undefined, true);
        } else {
          callback(value, false);
        }
      }
    }
  }
  render() {
    return (
      <>
        {this.state.isOpen ? (
          <PfDialog isOpen={this.state.isOpen} title={this.state.title} onClose={this.onClose}>
            <div>{this.state.message}</div>
            <div>{this.state.inputLabel}</div>
            <div>
              <InputBox value={this.state.inputValue} onChange={this.onChange} />
            </div>
            <div>
              <RoundedButton text='キャンセル' onClick={this.onCancelClick} />
              <RoundedButton text='OK' onClick={this.onOkClick} />
            </div>
          </PfDialog>
        ) : null}
      </>
    );
  }
}
